// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  validator = 'validator',
  eventEntrance = 'eventEntrance',
  entrance = 'entrance',
}

// eslint-disable-next-line no-shadow
export enum FormInput {
  userId = 'userId',
}

// eslint-disable-next-line no-shadow
export enum FormInputEventEntrance {
  entranceId = 'entranceId',
}

// eslint-disable-next-line no-shadow
export enum FormInputEntrance {
  id = 'id',
  name = 'name',
}

export interface DataRow {
  message: string;
  personName: string;
  ipCreation: string;
  createdAt: string;
  actions: string;
}
