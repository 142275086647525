import React from 'react';
import { ReactComponent as CloseX } from '@/assets/images/svg/closeX.svg';
import { CustomTable } from '@/components/Table';
import Entrance from '@/model/Entrance';

interface StateProps {
  entrances: Entrance[];
}
interface DispatchProps {
  removeEntrance(entranceId: string): void;
}

type Props = StateProps & DispatchProps;

export const EntranceList: React.FC<Props> = ({ entrances, removeEntrance }): JSX.Element => {
  const dataTable = entrances.map(item => ({
    name: item.name,
    actions: (
      <CloseX
        className="mr-2 svg-icon action-icon"
        onClick={() => {
          removeEntrance(item.id);
        }}
      />
    ),
  }));

  return (
    <React.Fragment>
      {entrances.length > 0 ? (
        <CustomTable
          columns={[
            {
              name: 'Nome do portão',
              selector: row => row.name,
              width: '90%',
            },
            {
              name: '',
              selector: row => row.actions,
            },
          ]}
          data={dataTable}
          progressPending={false}
          numberRowsPerPage={dataTable.length}
        />
      ) : (
        <>
          <div style={{ padding: '10px 0 20px 0', color: '#A5A5A5' }}>
            Você ainda não inseriu nenhum validador neste Evento.
          </div>
          <div style={{ color: '#A5A5A5', paddingBottom: '30px' }}>
            Aqui será exibida uma lista dos usuários validadores inseridos neste Evento.
          </div>
        </>
      )}
    </React.Fragment>
  );
};
