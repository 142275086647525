import React, { useEffect, useState } from 'react';
import validators from '@/helpers/validators';
import { unmask, updateMask as updateMaskCash } from '@/helpers/masks/cashNumber';
import useForm from '@/hooks/useForm';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import api from '@/services/api';
import { EventProducer } from '@/model/EventProducer';
import { useParams } from 'react-router-dom';
import { useEvent } from '../../hook/useEvent';
import { FeeEventContainer } from './ui';
import { States } from '..';
import { FormInput, UrlParams } from './types';

interface StateProps {
  phaseCompletion?: boolean;
}

interface DispatchProps {
  getEventPhaseCompletion: () => void;
}

type Props = StateProps & DispatchProps;

export const FeeEventScreen: React.FC<Props> = (): JSX.Element => {
  const params = useParams<UrlParams>();
  const [state, setState] = useState<States>(States.default);
  const { eventState, onChange: onChangeEvent } = useEvent();

  const { formData, formErrors, onChangeFormInput, isFormValid } = useForm({
    initialData: {
      calcAdmFee: 'false',
      pdv: '',
      credit: '',
      installment: '',
      debit: '',
      pix: '',
      bankslip: '',
      food: '',
      technical: '',
      trip: '',
      rentPos: '',
    },
    validators: {
      calcAdmFee: [validators.required],
      pdv: [validators.required],
      credit: [validators.required],
      installment: [validators.required],
      debit: [validators.required],
      pix: [validators.required],
      bankslip: [validators.required],
      food: [validators.required],
      technical: [validators.required],
      trip: [validators.required],
      rentPos: [validators.required],
    },
    formatters: {
      pdv: updateMaskCash,
      credit: updateMaskCash,
      installment: updateMaskCash,
      debit: updateMaskCash,
      pix: updateMaskCash,
      bankslip: updateMaskCash,
      food: updateMaskCash,
      technical: updateMaskCash,
      trip: updateMaskCash,
      rentPos: updateMaskCash,
    },
  });

  const handleOnFetcEventFee = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventProducer>(`/event/${params.id}/producer`);
      if (data && data.pdv) {
        onChangeFormInput(FormInput.calcAdmFee)(`${data.calcAdmFee}`);
        onChangeFormInput(FormInput.pdv)(`${Number(data.pdv) * 100}`);
        onChangeFormInput(FormInput.credit)(`${Number(data.credit) * 100}`);
        onChangeFormInput(FormInput.installment)(`${Number(data.installment) * 100}`);
        onChangeFormInput(FormInput.debit)(`${Number(data.debit) * 100}`);
        onChangeFormInput(FormInput.pix)(`${Number(data.pix) * 100}`);
        onChangeFormInput(FormInput.bankslip)(`${data.bankslip}`);
        onChangeFormInput(FormInput.food)(`${data.food}`);
        onChangeFormInput(FormInput.technical)(`${data.technical}`);
        onChangeFormInput(FormInput.trip)(`${data.trip}`);
        onChangeFormInput(FormInput.rentPos)(`${data.rentPos}`);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnSaveEventFee = async (): Promise<void> => {
    if (isFormValid()) {
      try {
        setState(States.loading);
        const payload: EventProducer = {
          calcAdmFee: formData[FormInput.calcAdmFee] === 'true',
          pdv: Number(unmask(formData[FormInput.pdv])),
          credit: Number(unmask(formData[FormInput.credit])),
          installment: Number(unmask(formData[FormInput.installment])),
          debit: Number(unmask(formData[FormInput.debit])),
          pix: Number(unmask(formData[FormInput.pix])),
          bankslip: Number(unmask(formData[FormInput.bankslip])),
          food: Number(unmask(formData[FormInput.food])),
          technical: Number(unmask(formData[FormInput.technical])),
          trip: Number(unmask(formData[FormInput.trip])),
          rentPos: Number(unmask(formData[FormInput.rentPos])),
        };
        await api.post(`/event/${params.id}/producer`, payload);
        onChangeEvent({ ...eventState, currentStep: eventState.currentStep + 1 });
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    handleOnFetcEventFee();
  }, []);
  return (
    <FeeEventContainer
      state={state}
      eventState={eventState}
      onChangeEvent={onChangeEvent}
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      onSaveEventFee={handleOnSaveEventFee}
    />
  );
};
