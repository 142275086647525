export interface DataTable {
  name: string;
  userName: string;
  type: string;
  amountSend: number;
  actived: string;
  actions: string;
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
  email = 'email',
}

// eslint-disable-next-line no-shadow
export enum FormInputFilter {
  filterSearch = 'filterSearch',
  inputSearch = 'inputSearch',
}

// eslint-disable-next-line no-shadow
export enum FormInputEmail {
  id = 'id',
  name = 'name',
  type = 'type',
  host = 'host',
  port = 'port',
  tls = 'tls',
  ssl = 'ssl',
  userName = 'userName',
  password = 'password',
  actived = 'actived',
}

export interface EmailTypeSelect {
  id: string;
  name: string;
}
