import React from 'react';
import { Route } from '@/navigation/Route';
import { SupportScreen } from './screens/Support';
import { ChatScreen } from './screens/Chat';

export const SUPPORT_ROUTES = {
  itself: '/atendimento-suporte',
  chat: '/chat/:id',
};

export const SupportNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route path={SUPPORT_ROUTES.itself} component={SupportScreen} isPrivateRoute />
    <Route path={SUPPORT_ROUTES.chat} component={ChatScreen} isPrivateRoute />
  </React.Fragment>
);
