import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { path } from '@/navigation/path';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import Home from '@/model/Home';
import { REACT_APP_USER } from '@/utils/config';
import { HomeContainer } from './ui';

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}
// const events: Events[] = [];
// eventos.eventos.forEach(event => events.push(event));

export const HomeScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);

  const [eventList, setEventList] = useState<Home | undefined>();

  const user = JSON.parse(localStorage.getItem(String(REACT_APP_USER)) ?? '{}');
  const { userType } = user;

  const history = useHistory();
  // TODO - Pegar o usuário do localstorage
  // const userProfileObject = JSON.parse(localStorage.getItem(String(REACT_APP_USER)) ?? '{}');

  const handleFetchEvents = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get('/home');

      if (data) {
        setEventList(data ?? {});
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  useEffect(() => {
    handleFetchEvents();
  }, []);

  const handleOnViewAllEvents = (): void => {
    history.push(path.Dashboard.Events.itself);
  };

  return (
    <HomeContainer
      events={eventList}
      onViewAllEvents={handleOnViewAllEvents}
      state={state}
      userType={userType}
    />
  );
};
