import React, { Fragment } from 'react';
import FilterVector from '@/assets/images/svg/FilterVector';
import { Button, Loading } from '@/components';
import { Container, Label } from 'reactstrap';
import { RegisterContent } from '@/features/artist/components/RegisterContent';
import { ReactComponent as Status } from '@/assets/images/svg/status.svg';
import { ReactComponent as Pen } from '@/assets/images/svg/pen.svg';
import { ReactComponent as Trash } from '@/assets/images/svg/lixeira.svg';
import { ActionProps, Dialog } from '@/components/Dialog';
import { ColumnStatus, CustomTable } from '@/components/Table';
import Pagination from '@/components/Utils/Pagination';
import { FilterContent } from '@/features/artist/components/FilterContent';
import { FormErrors, OnChangeFormInput, FormData } from '@/hooks/useForm';
import { colors } from '@/styles/colors';
import Artist from '@/model/Artist';
import Page from '@/model/Page';
import StatusType from '@/model/StatusType';
import { ArtistControllerUser } from '@/features/artist/types';
import { columnsContractor } from './table';

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}
export interface DataRowContractor {
  id: string;
  name: string;
  document: string;
  telephone: string;
  companyType: number;
  actions: string;
}

export interface DataRowUser {
  id: number;
  name: string;
  login: string;
  actions: string;
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
  registerArtist = 'registerArtist',
}

interface StateProps {
  state: States;
  artist: Artist;
  title: React.ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  currentPage: Page<Artist, Artist>;
  formData: FormData;
  formErrors: FormErrors;
  formDataFilter: FormData;
  formErrorsFilter: FormErrors;
  controllerAppendUser: ArtistControllerUser;
}

interface DispatchProps {
  onToggle: () => void;
  onPaginationChange: (page: number) => void;
  onSave: () => void;
  changeColorColumn: (status: StatusType) => string;
  onChangeFormInputFilter: OnChangeFormInput;
  onShouldShowModal: ({
    value,
    newTitleModal,
    artist,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    artist?: Artist | undefined;
  }) => void;
  onChangeFormInput: OnChangeFormInput;
  onShowDelete: (artist: Artist) => void;
  onFilter: () => void;
  clearFilter: () => void;
}

type Props = StateProps & DispatchProps;

export const ArtistContainer: React.FC<Props> = ({
  currentPage,
  changeColorColumn,
  onToggle,
  onShouldShowModal,
  onShowDelete,
  artist,
  clearFilter,
  state,
  title,
  visible,
  shouldShowModal,
  onFilter,
  onSave,
  formErrors,
  formDataFilter,
  formErrorsFilter,
  onChangeFormInputFilter,
  formData,
  onChangeFormInput,
  controllerAppendUser,
  onPaginationChange,
}) => {
  const dataTableContractor = currentPage.list
    ? currentPage.list.map(item => ({
        id: item.id,
        name: (
          <ColumnStatus statusColor={String(changeColorColumn(Number(item.status)))}>
            {item.name}
          </ColumnStatus>
        ),
        document: item.document,
        telephone: item.telephone,
        actions: (
          <React.Fragment>
            <Pen
              className="mr-4 svg-icon action-icon"
              onClick={(): void => {
                onToggle();
                onShouldShowModal({
                  value: ShouldShowModal.registerArtist,
                  newTitleModal: `${item.name}`,
                  artist: item,
                });
              }}
            />
            <Trash
              className="mr-2 svg-icon action-icon svg-icon-trash"
              onClick={() => {
                onShowDelete(item);
              }}
            />
          </React.Fragment>
        ),
      }))
    : [];

  const renderActionDialogToCancel: ActionProps = {
    title: 'Cancelar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };

  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => clearFilter(),
    theme: 'noneBorder',
  };

  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={shouldShowModal !== ShouldShowModal.filter}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
            [ShouldShowModal.registerArtist]: renderActionDialogToCancel,
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: {
              title: 'Aplicar',
              onClick: () => onFilter(),
            },
            [ShouldShowModal.registerArtist]: {
              title: artist?.id ? 'Salvar' : 'Cadastrar novo artista',
              onClick: () => onSave(),
              disabled: Object.keys(formErrors).length === 0 && formErrors.constructor === Object,
            },
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formDataFilter}
                formErrors={formErrorsFilter}
                onChangeFormInput={onChangeFormInputFilter}
              />
            ),
            [ShouldShowModal.registerArtist]: (
              <RegisterContent
                formData={formData}
                formErrors={formErrors}
                onChangeFormInput={onChangeFormInput}
                artist={artist}
                onShouldShowModal={onShouldShowModal}
                controllerAppendUser={controllerAppendUser}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>

      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <div className="pageTitle" style={{ display: 'grid' }}>
            <Label>Artistas</Label>
          </div>
          <div className="button-filter-container">
            <Button
              title="+ Cadastrar novo Artista"
              onClick={(): void => {
                onToggle();
                onShouldShowModal({
                  value: ShouldShowModal.registerArtist,
                  newTitleModal: 'Cadastrar novo artista',
                });
              }}
            />
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onToggle();
                  onShouldShowModal({
                    value: ShouldShowModal.filter,
                    newTitleModal: '',
                  });
                }}
              >
                <FilterVector />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex pb-2 status-container">
          <div className="eventStatus subText">
            <Status style={{ color: '#7AD81B' }} />
            Artista ativo
          </div>
          <div className="eventStatus subText">
            <Status style={{ color: colors.red }} />
            Artista inativo
          </div>
        </div>
        <CustomTable
          columns={columnsContractor}
          data={dataTableContractor}
          numberRowsPerPage={currentPage.pageSize}
          progressPending={state === States.loading}
          theme="primary"
        />
        <Pagination
          currentPage={currentPage.page}
          totalCount={currentPage.total}
          pageSize={currentPage.pageSize}
          onPageChange={page => onPaginationChange(page)}
          total={currentPage.total}
        />
      </Container>
    </Fragment>
  );
};
