import React from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { SelectCustom } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import User from '@/model/User';
import { FormInput } from '../../types';

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
  users: User[];
}

interface DispatchProps {
  onChange: OnChangeFormInput;
}

type Props = StateProps & DispatchProps;

export const RegisterValidatorContent: React.FC<Props> = ({
  formData,
  formErrors,
  onChange,
  users,
}) => (
  <Form
    noValidate={true}
    onSubmit={(e): void => {
      e.preventDefault();
    }}
  >
    <Row>
      <Col md={8}>
        <FormGroup className="mb-2">
          <SelectCustom
            name="userId"
            label="Validador do evento"
            placeholder="Digite ou selecione o validador do evento"
            value={formData[FormInput.userId]}
            onChange={e => onChange(FormInput.userId)(e?.value as string)}
            error={formErrors.userId && formErrors.userId[0]}
            options={users.map(user => ({
              label: user.name,
              value: user.id,
            }))}
            isClearable
          />
        </FormGroup>
      </Col>
    </Row>
  </Form>
);
