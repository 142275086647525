import React from 'react';
import { ReactComponent as Success } from '@/assets/images/svg/success.svg';

interface QrCodeContentProps {
  qrCode: string;
}

export const QrCodeContent: React.FC<QrCodeContentProps> = ({ qrCode }) => (
  <div className="filter-modal-content">
    <div className="exclude-container">
      <Success color="#E64F49" />
      <div className="header-title-text modal__title">
        <h5 className="modal__confirmation-title">Seu Pedido foi realizado com sucesso</h5>
      </div>
      <div className="modal__confirmation-text">
        Para concluir o pagamento, efetue a leitura do <strong>QrCode</strong> abaixo na POS.
      </div>
      <div className="modal__confirmation-text">
        <img src={qrCode} style={{ width: '300px' }} />
      </div>
    </div>
  </div>
);
