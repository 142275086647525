// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

// eslint-disable-next-line no-shadow
export enum RegisterAcquiringFeeFormInput {
  chargeSetupId = 'chargeSetupId',
  type = 'type',
}

// eslint-disable-next-line no-shadow
export enum RegisterPaymentTypeFormInput {
  id = 'id',
  acquiringFeeId = 'acquiringFeeId',
  paymentType = 'paymentType',
  feeType = 'feeType',
  valueFee = 'valueFee',
}

// eslint-disable-next-line no-shadow
export enum RegisterBrandFormInput {
  id = 'id',
  acquiringFeeId = 'acquiringFeeId',
  paymentTypeId = 'paymentTypeId',
  cardBrand = 'cardBrand',
  valueFee = 'valueFee',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  registerAcquiringFee = 'registerAcquiringFee',
  registerPaymentType = 'registerPaymentType',
  registerBrand = 'registerBrand',
}
