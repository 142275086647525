import React from 'react';
import './styles.scss';

type DataItem = {
  title: string | string[];
  value: number | string | string[];
  fee?: number | string | string[];
  feeValue?: number | string | string[];
  netAmount?: number | string | string[];
};

type FooterCustomProps = {
  data: DataItem[];
  summaryData?: string | number;
  theme?: 'primary' | 'secondary';
};

export const FooterCustom: React.FC<FooterCustomProps> = ({ data, theme, summaryData }) => (
  <footer
    className={`d-flex justify-cont ent-center fixed-bottom ${
      theme !== 'secondary' ? '' : 'flex-column'
    }`}
    style={{
      padding: '25px',
      backgroundColor: '#FFFFFF',
      width: '100%',
      overflow: 'auto',
      zIndex: 1,
    }}
  >
    <div className={theme === 'secondary' ? 'd-flex' : ''}>
      <div className={theme !== 'secondary' ? 'd-flex' : ''}>
        {data.map(({ title, value, fee, feeValue, netAmount }, index) => (
          <div className={index === data.length - 1 ? '' : 'mb-2'} key={index}>
            <div className="d-flex">
              <div
                className={`d-flex w-100 noWrap footerClass`}
                style={{
                  color: '#828282',
                }}
              >
                <span className="mr-2 text-darkgray-light">{title}</span>
                <span className="text-darkgray-500">{value}</span>
                <div className="circle-grey my-auto" />
                {theme === 'secondary' && (
                  <>
                    <span className="mr-2 text-darkgray-light">Taxas: </span>
                    <span className="text-darkgray-500">{fee}</span>
                    <div className="circle-grey my-auto" />
                    <span className="mr-2 text-darkgray-light">Valor das taxas: </span>
                    <span className="text-darkgray-500">{feeValue}</span>
                    <div className="circle-grey my-auto" />
                    <span className="mr-2 text-darkgray-light">Valor líquido: </span>
                    <span className="text-darkgray-500">{netAmount}</span>
                    <div className="circle-grey my-auto" />
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {theme === 'secondary' && (
        <>
          <div
            style={{
              padding: '35px 20px',
              borderLeft: '2px solid #A5A5A5',
              height: '10px',
              alignSelf: 'center',
            }}
          />
          <div className="d-flex justify-content-center align-items-center noWrap">
            <span className="text-darkgray-light mr-2">Valor liq total:</span>
            <span className="text-darkgray-700">{summaryData}</span>
          </div>
        </>
      )}
    </div>
  </footer>
);

export default FooterCustom;
