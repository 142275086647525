import { TableColumn } from 'react-data-table-component';

export interface DataRow {
  cpfCreated: string;
  token: string;
  createdAt: string;
  used: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any;
}

export interface DataRowToken {
  orderId: string;
  ticket: string;
  sequence: string;
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Usuário',
    selector: row => row.cpfCreated,
    width: '300px',
  },
  {
    name: 'Token',
    selector: row => row.token,
    width: '300px',
  },
  {
    name: 'Data',
    selector: row => row.createdAt,
    width: '170px',
  },
  {
    name: 'Utilizado',
    selector: row => row.used,
    width: '100px',
  },
  {
    name: 'Ações',
    selector: row => row.actions,
  },
];

export const tokenColumns: TableColumn<DataRowToken>[] = [
  {
    name: 'Pedido',
    selector: row => row.orderId,
    width: '300px',
  },
  {
    name: 'Ingresso',
    selector: row => row.ticket,
    width: '300px',
  },
  {
    name: 'Sequencia',
    selector: row => row.sequence,
    width: '170px',
  },
];
