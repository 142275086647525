import React from 'react';
import { Route } from '@/navigation/Route';
import { RedemptionScreen } from './screens';

export const REDEMPTION_ROUTES = {
  itself: '/financial/redemption',
};

export const RedemptionNavigation = (): JSX.Element => (
  <Route exact path={REDEMPTION_ROUTES.itself} component={RedemptionScreen} isPrivateRoute />
);
