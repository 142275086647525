import { InputText, Radio } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import React from 'react';
import { Form } from 'reactstrap';
import { FormInputFilter } from '../../types';

interface FilterContentProps {
  formData: FormData;
  formErrors: FormErrors;
  onChangeFormInput: OnChangeFormInput;
}

export const FilterContent: React.FC<FilterContentProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
}) => {
  const optionFilter = [
    { label: 'Protocolo', value: 'protocol' },
    { label: 'Nome', value: 'name' },
    { label: 'CPF', value: 'cpf' },
    { label: 'E-mail', value: 'e-mail' },
    { label: 'Status', value: 'status' },
  ];

  const statusLabels = [
    { label: 'Novo', value: 'NEW' },
    { label: 'Aguardando', value: 'WAITING' },
    { label: 'Resolvido', value: 'RESOLVED' },
    { label: 'Fechado', value: 'CLOSED' },
    { label: 'Reaberto', value: 'REOPENED' },
    { label: 'Cancelado', value: 'CANCELED' },
    { label: 'Em Andamento', value: 'IN_PROGRESS' },
    { label: 'Aguardando o Cliente', value: 'WAITING_FOR_CLIENT' },
  ];

  return (
    <div className="filter-modal-content">
      <Form noValidate={true} onSubmit={(e): void => e.preventDefault()}>
        <span className="filter-name">Filtrar por:</span>
        <Radio
          options={optionFilter}
          name="filterSearch"
          style={{ fontSize: '21px' }}
          value={formData[FormInputFilter.filterSearch]}
          onChange={e => onChangeFormInput(FormInputFilter.filterSearch)(e.target.value)}
          error={formErrors.filterSearch && formErrors.filterSearch[0]}
        />
        {formData[FormInputFilter.filterSearch] === 'status' ? (
          <>
            <h3 className="filter-status-name">Selecione os Status</h3>
            <Radio
              options={statusLabels}
              name="inputSearch"
              value={formData[FormInputFilter.inputSearch]}
              onChange={e => onChangeFormInput(FormInputFilter.inputSearch)(e.target.value)}
              error={formErrors.inputSearch && formErrors.inputSearch[0]}
              isRow={true}
            />
          </>
        ) : (
          <InputText
            name="inputSearch"
            label={
              optionFilter.find(values => formData[FormInputFilter.filterSearch] === values.value)
                ?.label ?? ''
            }
            placeholder={
              formData[FormInputFilter.filterSearch] === 'protocol' ? 'Digite o protocolo' : ''
            }
            maxLength={18}
            value={formData[FormInputFilter.inputSearch]}
            onChange={e => onChangeFormInput(FormInputFilter.inputSearch)(e.target.value)}
            error={formErrors.inputSearch && formErrors.inputSearch[0]}
          />
        )}
      </Form>
    </div>
  );
};
