import { Loading } from '@/components';
import api from '@/services/api';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EventGeneralReport from '@/model/EventGeneralReport';
import EventReportPayment from '@/model/EventReportPayment';
import { ReportControllerProps } from '../../types';
import { GeneralSaleContent } from './ui';

export interface GeneralSaleProps {
  generatePDF: () => void;
  onShowModal: () => void;
  payments: EventReportPayment;
  reportController: ReportControllerProps;
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export const GeneralSale: React.FC<GeneralSaleProps> = ({
  generatePDF,
  onShowModal,
  payments,
  reportController,
}) => {
  const [state, setState] = useState<States>(States.default);

  const { eventId, generalSale, setGeneralSale } = reportController;

  const handleGetGeneralSale = async (): Promise<void> => {
    if (generalSale === undefined) {
      try {
        setState(States.loading);
        const { data } = await api.get<EventGeneralReport>(
          `event/report/${eventId}/general?singleEvent=${reportController.singleEvent}`,
        );
        if (data) {
          setGeneralSale(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    handleGetGeneralSale();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <GeneralSaleContent
        generatePDF={generatePDF}
        onShowModal={onShowModal}
        payments={payments}
        generalSale={generalSale}
      />
    </>
  );
};
