export interface DataColumn {
  id: string;
  name: string;
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  detail = 'detail',
  filter = 'filter',
  token = 'token',
}

// eslint-disable-next-line no-shadow
export enum FormInputName {
  filterSearch = 'filterSearch',
  inputSearch = 'inputSearch',
}
