// eslint-disable-next-line no-shadow
export enum FormInput {
  eventId = 'eventId',
  sectionId = 'sectionId',
  ticketId = 'ticketId',
  amount = 'amount',
  email = 'email',
}

export interface DataRow {
  event: string;
  section: string;
  ticket: string;
  amount: number;
  date: Date;
}
