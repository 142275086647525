import React, { useEffect, useState } from 'react';
import Email from '@/model/Email';
import Page from '@/model/Page';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import { ActionProps } from '@/components/Dialog';
import EmailType from '@/model/EmailType';
import { FormInputEmail, FormInputFilter, ShouldShowModal, States } from '../types';
import { EmailList } from './ui/EmailList';

export const EmailScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState(States.default);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>({} as ShouldShowModal);
  const [email, setEmail] = useState<Email>();
  const [emails, setEmails] = useState({} as Page<Email, Email>);
  const [togglePassword, setTogglePassword] = useState<boolean>(false);

  const { visible, title, onChangeTitle, onToggle } = useDialog();

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    resetForm: resetFormFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
  } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
  });

  const {
    formData: formDataEmail,
    formErrors: formErrorsEmail,
    onChangeFormInput: onChangeFormInputEmail,
    isFormValid: isFormValidEmail,
    resetForm: resetFormEmail,
  } = useForm({
    initialData: {
      id: '',
      name: '',
      type: '0',
      host: '',
      port: '',
      tls: 'false',
      ssl: 'true',
      userName: '',
      password: '',
      actived: 'true',
    },
    validators: {
      name: [validators.required],
      type: [validators.required],
      host: [validators.required],
      port: [validators.required],
      tls: [validators.required],
      ssl: [validators.required],
      userName: [validators.required],
      password: [validators.required],
      actived: [validators.required],
    },
  });

  const getModules = async (page: number): Promise<void> => {
    setState(States.loading);
    try {
      const payload: Page<Email, Email> = {
        page,
        pageSize: 10,
        sort: 'userName',
        order: 'ASC',
      };
      if (emails && emails.page) {
        payload.pageSize = emails.pageSize;
        payload.entity = emails.entity;
        payload.sort = emails.sort;
        payload.order = emails.order;
      }
      if (
        formDataFilter[FormInputFilter.filterSearch] === 'email' &&
        formDataFilter[FormInputFilter.inputSearch]
      ) {
        if (!payload.entity) {
          payload.entity = {
            userName: formDataFilter[FormInputFilter.inputSearch],
          } as Email;
        }
      } else {
        payload.entity = undefined as unknown as Email;
      }

      const response = await api.post<Page<Email, Email>>('/email/list', payload);
      setEmails(response.data);
      setState(States.default);
    } catch (error) {
      setState(States.default);
    }
  };

  const toEmailType = (type: string): EmailType => {
    let emailType = EmailType.CONTACT;
    if (type === '0') {
      emailType = EmailType.PAYMENT;
    } else if (type === '1') {
      emailType = EmailType.CONTACT;
    } else if (type === '2') {
      emailType = EmailType.NOT_REPPLY;
    }
    return emailType;
  };

  const save = async (): Promise<void> => {
    try {
      if (isFormValidEmail()) {
        setState(States.loading);
        const id = email && email.id ? email.id : (undefined as unknown as string);
        const payload = {
          id,
          name: formDataEmail[FormInputEmail.name],
          type: toEmailType(formDataEmail[FormInputEmail.type]),
          host: formDataEmail[FormInputEmail.host],
          port: Number(formDataEmail[FormInputEmail.port]),
          tls: formDataEmail[FormInputEmail.tls] === 'true',
          ssl: formDataEmail[FormInputEmail.ssl] === 'true',
          userName: formDataEmail[FormInputEmail.userName],
          password: formDataEmail[FormInputEmail.password],
          actived: formDataEmail[FormInputEmail.actived] === 'true',
        } as Email;

        await api.post<void>('/email', payload);
        toast.success(
          `Email "${formDataEmail[FormInputEmail.userName]}" ${
            id ? 'alterado' : 'cadastrado'
          } com sucesso!`,
        );

        setState(States.default);
        resetFormEmail();
        onToggle();
        getModules(1);
      }
    } catch (error) {
      setState(States.default);
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const onFilter = async (): Promise<void> => {
    if (isFormValidFilter()) {
      await getModules(1);
      resetFormFilter();
      onToggle();
    }
  };

  const clearFilter = (): void => {
    resetFormFilter();
    formDataFilter[FormInputFilter.inputSearch] = '';
    onFilter();
  };

  const renderActionDialogToClearFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => {
      clearFilter();
    },
    theme: 'noneBorder',
  };

  const renderActionDialogToCancel: ActionProps = {
    title: 'Cancelar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };

  const openModal = async (
    value: ShouldShowModal,
    modalTitle: string,
    emailSelected: Email,
  ): Promise<void> => {
    onChangeTitle(modalTitle);
    setShouldShowModal(value);
    if (value === ShouldShowModal.email) {
      if (emailSelected) {
        setEmail(emailSelected);
      } else {
        setEmail(undefined as unknown as Email);
        resetFormEmail();
      }
    }
    onToggle();
  };

  const handleOnActivateAndInactivate = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    onChangeFormInputEmail(FormInputEmail.actived)(String(e.target.checked));
  };

  const paginationChange = (pageNumber: number): void => {
    const page = {
      ...emails,
      page: pageNumber,
    };
    setEmails(page);
    getModules(pageNumber);
  };

  useEffect(() => {
    if (email && email.id) {
      onChangeFormInputEmail(FormInputEmail.id)(email.id);
      onChangeFormInputEmail(FormInputEmail.name)(email.name);
      onChangeFormInputEmail(FormInputEmail.type)(`${email.type}`);
      onChangeFormInputEmail(FormInputEmail.host)(email.host);
      onChangeFormInputEmail(FormInputEmail.port)(`${email.port}`);
      onChangeFormInputEmail(FormInputEmail.tls)(`${email.tls}`);
      onChangeFormInputEmail(FormInputEmail.ssl)(`${email.ssl}`);
      onChangeFormInputEmail(FormInputEmail.userName)(email.userName);
      onChangeFormInputEmail(FormInputEmail.password)(email.password);
      onChangeFormInputEmail(FormInputEmail.actived)(`${email.actived}`);
    }
  }, [email]);

  useEffect(() => {
    getModules(1);
  }, []);

  return (
    <EmailList
      state={state}
      modalTitle={title}
      visible={visible}
      shouldShowModal={shouldShowModal}
      renderActionDialogToClearFilter={renderActionDialogToClearFilter}
      renderModalActionProps={renderActionDialogToCancel}
      email={email}
      formDataFilter={formDataFilter}
      formErrorsFilter={formErrorsFilter}
      formDataEmail={formDataEmail}
      formErrorsEmail={formErrorsEmail}
      title="Emails"
      emails={emails}
      onToggle={onToggle}
      onFilter={onFilter}
      save={save}
      changeFormInputFilter={onChangeFormInputFilter}
      onChangeFormInputEmail={onChangeFormInputEmail}
      openModal={openModal}
      togglePassword={togglePassword}
      setTogglePassword={setTogglePassword}
      onActivateAndInactivate={handleOnActivateAndInactivate}
      paginationChange={paginationChange}
    />
  );
};
