import { TableColumn } from '@/components/Table';
import { DataRow } from '@/features/log/types';

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Atividade',
    selector: row => row.message,
    minWidth: '200px',
  },
  {
    name: 'Responsável',
    selector: row => row.personName,
  },
  {
    name: 'IP responsável',
    selector: row => row.ipCreation,
  },
  {
    name: 'Data da atividade',
    selector: row => row.createdAt,
  },
  {
    name: 'Ações',
    selector: row => row.actions,
    maxWidth: '140px',
  },
];
