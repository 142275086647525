import React from 'react';
import { Route } from '@/navigation/Route';
import { ProducerReportScreen } from './screens';

export const PRODUCER_REPORT_ROUTES = {
  itself: '/financial/event/producer-report/:id',
};

export const ProducerReportNavigation = (): JSX.Element => (
  <Route
    exact
    path={PRODUCER_REPORT_ROUTES.itself}
    component={ProducerReportScreen}
    isPrivateRoute
  />
);
