import { TableColumn } from '@/components/Table';

interface DataRowPosEvent {
  numberPos: string;
  expirationDate: string;
  waiter: string;
  commission: string;
  actions: string;
}

export const columnsPosEvent: TableColumn<DataRowPosEvent>[] = [
  {
    name: 'Nº de série',
    selector: row => row.numberPos,
  },
  {
    name: 'Data do vínculo',
    selector: row => row.expirationDate,
  },
  {
    name: 'Porcentagem do garçom',
    selector: row => row.waiter,
  },
  {
    name: 'Porcentagem de comissão',
    selector: row => row.commission,
  },
  {
    name: '',
    selector: row => row.actions,
    right: true,
  },
];
