import { TableColumn } from '@/components/Table';
import { formatToCPFOrCNPJ, formatToPhone } from 'brazilian-values';
import { DataRowContractor, DataRowUser } from '.';

export const columnsContractor: TableColumn<DataRowContractor>[] = [
  {
    name: 'Nome do artista',
    selector: row => row.name,
    minWidth: '200px',
  },
  {
    name: 'CPF/CNPJ',
    selector: row => formatToCPFOrCNPJ(row.document),
  },
  {
    name: 'Telefone',
    selector: row => formatToPhone(row.telephone),
  },
  {
    name: 'Ações',
    selector: row => row.actions,
    maxWidth: '120px',
  },
];

export const columnsUser: TableColumn<DataRowUser>[] = [
  {
    name: 'Nome do PDV',
    selector: row => row.name,
    maxWidth: '325px',
  },
  {
    name: 'Login',
    selector: row => formatToCPFOrCNPJ(row.login),
    maxWidth: '140px',
  },
  {
    name: '',
    selector: row => row.actions,
    maxWidth: '85px',
  },
];
