import { DataList } from '@/components/DataList';
import { toPercentage } from '@/helpers/common/amount';
import { toCurrency } from '@/helpers/masks/toCurrency';
import Event from '@/model/Event';
import React from 'react';

interface StateProps {
  event: Event | undefined;
}

export const ConfirmationProducer: React.FC<StateProps> = ({ event }): JSX.Element => (
  <>
    <h5 className="mb-2 border-bottom-title mb-5 container-event">Produtor</h5>
    {event && event.producer && (
      <div style={{ overflow: 'auto' }}>
        <div
          className="mb-4"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            columnGap: '30px',
          }}
        >
          <DataList
            data={[
              {
                title: 'PDV',
                content: `${toPercentage(event.producer.pdv)} %`,
              },
              {
                title: 'Crédito à vista',
                content: `${toPercentage(event.producer.credit)} %`,
              },
              {
                title: 'Crédito parcelado',
                content: `${toPercentage(event.producer.installment)} %`,
              },
              {
                title: 'Débito',
                content: `${toPercentage(event.producer.debit)} %`,
              },
              {
                title: 'PIX',
                content: `${toPercentage(event.producer.pix)} %`,
              },
              {
                title: 'Boleto',
                content: `R$ ${toCurrency(event.producer.bankslip)}`,
              },
              {
                title: 'Alimentação',
                content: toCurrency(event.producer.food),
              },
              {
                title: 'Técnicos',
                content: toCurrency(event.producer.technical),
              },
              {
                title: 'Viagem',
                content: toCurrency(event.producer.trip),
              },
              {
                title: 'Alug. POS',
                content: toCurrency(event.producer.rentPos),
              },
            ]}
          />
        </div>
      </div>
    )}
  </>
);
