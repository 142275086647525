import { Dialog, DropdonwFlags, Loading } from '@/components';
import { CustomTable } from '@/components/Table';
import { States } from '@/helpers/common/states';
import OrderSite from '@/model/OrderSite';
import React from 'react';
import { ThumbsDown } from 'react-feather';
import { Container } from 'reactstrap';
import { updateMask } from '@/helpers/masks/cpf';
import dayjs from 'dayjs';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import { ReactComponent as X } from '@/assets/images/svg/x.svg';
import { DataColumn, ShouldShowModal } from '@/features/cancelRequest/types';
import OrderPayment from '@/model/OrderPayment';
import { ActionProps } from '@/components/Dialog';
import { CancelRequestDetailContent } from '@/features/cancelRequest/components/CancelRequestDetailContent';
import { DataRow, columns } from './table';

interface StateProps {
  state: States;
  orders: OrderSite[];
  title: string | React.ReactNode;
  visible: boolean;
  modal: ShouldShowModal;
  order?: OrderSite;
}

interface DispatchProps {
  onShouldShowModal: (
    value: ShouldShowModal,
    newTitleModal: string | React.ReactNode,
    orderSelected: OrderSite,
  ) => void;
  onToggle: () => void;
  onShowCancelOrder: (orderSelected: OrderSite) => void;
  onShowReactivate: (orderSelected: OrderSite) => void;
}

type Props = StateProps & DispatchProps;

export const CancelRequestContainer: React.FC<Props> = ({
  state,
  orders,
  onShouldShowModal,
  title,
  visible,
  onToggle,
  modal,
  order,
  onShowCancelOrder,
  onShowReactivate,
}): JSX.Element => {
  const mountDataColumnPaymentType = (payments: OrderPayment[]): DataColumn[] => {
    const dataColumnProfiles: DataColumn[] = [];
    if (payments && payments.length > 0) {
      payments.forEach((data, index) => {
        dataColumnProfiles.push({
          id: String(index),
          name: data.transaction,
        });
      });
    }
    return dataColumnProfiles;
  };

  const dataTable = orders.map<DataRow>(data => ({
    client: `${data.client.name} - ${updateMask(data.client.cpf)}`,
    orderId: data.id,
    orderDate: dayjs(data.orderDate).format('DD/MM/YYYY [às] HH:mm'),
    orderValue: toCurrency(data.totalValue),
    transaction:
      // eslint-disable-next-line no-nested-ternary
      data.payments && data.payments.length > 1 ? (
        <DropdonwFlags pointerClass={true} dataColumn={mountDataColumnPaymentType(data.payments)} />
      ) : data.payments && data.payments.length === 1 ? (
        data.payments[0].transaction
      ) : (
        ''
      ),
    actions: (
      <React.Fragment>
        <Detail
          className={'mr-4 svg-icon action-icon'}
          onClick={() => onShouldShowModal(ShouldShowModal.detail, 'Pedido', data)}
        />
        <X className={'mr-4 svg-icon-error action-icon'} onClick={() => onShowCancelOrder(data)} />
        <ThumbsDown className="svg-icon action-icon" onClick={() => onShowReactivate(data)} />
      </React.Fragment>
    ),
  }));

  const renderActionDialogToCancel: ActionProps = {
    title: 'Fechar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };
  return (
    <React.Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={'center'}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.detail]: renderActionDialogToCancel,
          }[modal],
          {
            [ShouldShowModal.detail]: {
              title: 'OK',
            },
          }[modal],
        ]}
      >
        {
          {
            [ShouldShowModal.detail]: <CancelRequestDetailContent order={order} />,
          }[modal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <h5 className="pageTitle">Solicitações de cancelamento</h5>
        </div>
        <CustomTable
          columns={columns}
          data={dataTable}
          numberRowsPerPage={orders.length}
          progressPending={state === States.loading}
        />
      </Container>
    </React.Fragment>
  );
};
