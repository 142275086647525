import React, { useState } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import cep from 'cep-promise';
import { Button, InputText, SelectCustom, Switch } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import Contractor from '@/model/Contractor';
import { statesUf } from '@/constant/states';
import { CustomTable } from '@/components/Table';
import { isValid as isValidCEP } from '@/helpers/masks/cep';
import { ReactComponent as CloseX } from '@/assets/images/svg/closeX.svg';
import { convertToBoolean } from '@/helpers/common/convertToBoolean';
import User from '@/model/User';
import Artist from '@/model/Artist';
import { columnsUser } from '../../screens/List/ui/table';
import { ShouldShowModal } from '../../screens/List/ui';
import { ArtistControllerUser } from '../../types';

interface RegisterContentProps {
  formData: FormData;
  formErrors: FormErrors;
  onChangeFormInput: OnChangeFormInput;

  onShouldShowModal: ({
    value,
    newTitleModal,
    contractor,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    contractor?: Contractor;
    isEdit?: boolean;
  }) => void;
  artist: Artist | undefined;
  controllerAppendUser: ArtistControllerUser;
}

// eslint-disable-next-line no-shadow
export enum FormInputName {
  name = 'name',
  document = 'document',
  telephone = 'telephone',
  email = 'email',
  zipCode = 'zipCode',
  state = 'state',
  city = 'city',
  district = 'district',
  street = 'street',
  number = 'number',
  complement = 'complement',
  status = 'status',
}

export const RegisterContent: React.FC<RegisterContentProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
  artist,
  controllerAppendUser,
}) => {
  const [isDisable, setIsDisable] = useState<boolean>(true);

  const isValidAddresswithCEP = (): boolean => {
    const { zipCode } = formData;
    return !(zipCode.length === 9 && isValidCEP(zipCode));
  };

  const dataTableUser =
    artist && artist.users && artist.users.length > 0
      ? artist.users.map((item, index) => ({
          id: item.id,
          name: item.name,
          login: item.cpf,
          actions: (
            <CloseX
              className="mr-2 svg-icon action-icon"
              onClick={() => {
                controllerAppendUser.removeUser(index);
              }}
            />
          ),
        }))
      : [];

  return (
    <Form
      noValidate={true}
      onSubmit={(e): void => {
        e.preventDefault();
      }}
    >
      <Row>
        <Col md={8}>
          <h5 className="mb-5 border-bottom-title fw-700">Informações gerais e endereço</h5>
          <FormGroup className="mb-2">
            <InputText
              name="name"
              label="Nome da empresa (contratante)"
              placeholder="Digite o nome da empresa"
              value={formData[FormInputName.name]}
              onChange={e => onChangeFormInput(FormInputName.name)(e.target.value)}
              error={formErrors.name && formErrors.name[0]}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <Switch
            name="status"
            label={`Empresa ${
              convertToBoolean(formData[FormInputName.status]) ? 'ativa' : 'inativa'
            }`}
            onChange={e => onChangeFormInput(FormInputName.status)(String(e.target.checked))}
            checked={convertToBoolean(formData[FormInputName.status])}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="document"
              label="CPF/CNPJ"
              maxLength={18}
              placeholder="Digite o CPF ou CNPJ do cliente"
              value={formData[FormInputName.document]}
              onChange={e => onChangeFormInput(FormInputName.document)(e.target.value)}
              error={formErrors.document && formErrors.document[0]}
            />
          </FormGroup>

          <FormGroup className="mb-2">
            <InputText
              name="telephone"
              label="Telefone celular"
              placeholder="(00) 0 0000-0000"
              value={formData[FormInputName.telephone]}
              onChange={e => onChangeFormInput(FormInputName.telephone)(e.target.value)}
              error={formErrors.telephone && formErrors.telephone[0]}
            />
          </FormGroup>

          <FormGroup className="mb-2">
            <InputText
              name="email"
              label="E-mail da empresa"
              placeholder="email@email.com"
              value={formData[FormInputName.email]}
              onChange={e => onChangeFormInput(FormInputName.email)(e.target.value)}
              error={formErrors.email && formErrors.email[0]}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="zipCode"
              label="CEP (opcional)"
              placeholder="Digite o CEP da empresa"
              maxLength={9}
              value={formData[FormInputName.zipCode]}
              onChange={e => {
                onChangeFormInput(FormInputName.zipCode)(e.target.value);
                if (e.target.value.length === 9 && isValidCEP(e.target.value)) {
                  cep(e.target.value).then(data => {
                    if (!data.neighborhood && !data.street) {
                      setIsDisable(false);
                    } else {
                      setIsDisable(true);
                    }
                    onChangeFormInput(FormInputName.state)(data.state);
                    onChangeFormInput(FormInputName.city)(data.city);
                    onChangeFormInput(FormInputName.district)(data.neighborhood);
                    onChangeFormInput(FormInputName.street)(data.street);
                  });
                }
              }}
              error={formErrors.zipCode && formErrors.zipCode[0]}
            />
          </FormGroup>

          <Row>
            <Col md={4} className="pl-0">
              <FormGroup className="mb-2">
                <SelectCustom
                  name="state"
                  label="Estado (opcional)"
                  placeholder="SP"
                  value={formData[FormInputName.state]}
                  onChange={e => onChangeFormInput(FormInputName.state)(e?.target?.value as string)}
                  error={formErrors.state && formErrors.state[0]}
                  options={statesUf}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={8} className="pr-0">
              <FormGroup className="mb-2">
                <InputText
                  name="city"
                  label="Cidade (opcional)"
                  placeholder="Campinas"
                  value={formData[FormInputName.city]}
                  onChange={e => onChangeFormInput(FormInputName.city)(e?.target.value as string)}
                  error={formErrors.city && formErrors.city[0]}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup className="mb-2">
            <InputText
              name="district"
              label="Bairro (opcional)"
              placeholder="Centro"
              value={formData[FormInputName.district]}
              onChange={e => onChangeFormInput(FormInputName.district)(e.target.value)}
              error={formErrors.district && formErrors.district[0]}
              disabled={isDisable}
            />
          </FormGroup>

          <FormGroup className="mb-2">
            <InputText
              name="street"
              label="Logradouro (opcional)"
              placeholder="Rua 123 da Costa"
              value={formData[FormInputName.street]}
              onChange={e => onChangeFormInput(FormInputName.street)(e.target.value)}
              error={formErrors.street && formErrors.street[0]}
              disabled={isDisable}
            />
          </FormGroup>

          <FormGroup className="mb-2">
            <InputText
              name="number"
              label="Número (opcional)"
              placeholder="Ex: 789"
              maxLength={6}
              value={formData[FormInputName.number]}
              onChange={e => onChangeFormInput(FormInputName.number)(e.target.value)}
              error={formErrors.number && formErrors.number[0]}
              disabled={isValidAddresswithCEP()}
            />
          </FormGroup>

          <FormGroup className="mb-2">
            <InputText
              name="complement"
              label="Complemento (opcional)"
              placeholder="Ex: Apto 12"
              value={formData[FormInputName.complement]}
              onChange={e => onChangeFormInput(FormInputName.complement)(e.target.value)}
              error={formErrors.complement && formErrors.complement[0]}
              disabled={isValidAddresswithCEP()}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <h5 className="mt-5 mb-5 border-bottom-title fw-700">Usuários do artista</h5>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectCustom
              name="user"
              label="Usuário do artista"
              placeholder="Digite ou selecione o usuário do artista"
              value={controllerAppendUser.user?.id}
              onChange={e => controllerAppendUser.onChangeUser(e?.value as string)}
              options={controllerAppendUser.users.map((itemUser: User) => ({
                label: itemUser.name,
                value: itemUser.id,
              }))}
              isClearable
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <div style={{ padding: '37px 0' }}>
            <Button
              title="Inserir usuário"
              theme="noneBorder"
              onClick={() => {
                controllerAppendUser.addUser();
              }}
              disabled={
                controllerAppendUser.user === undefined ||
                controllerAppendUser.user.id === '' ||
                controllerAppendUser.user === null
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={7}>
          <h5 className="mb-4 border-bottom-title fw-400">Usuários inseridos na empresa</h5>
          {artist && artist.users && artist.users.length > 0 ? (
            <CustomTable
              columns={columnsUser}
              data={dataTableUser}
              theme="tertiary"
              progressPending={false}
              numberRowsPerPage={1}
            />
          ) : (
            <>
              <div style={{ padding: '10px 0 20px 0', color: '#A5A5A5' }}>
                Você ainda não adicionou nenhum usuário ao artista
              </div>
              <div style={{ color: '#A5A5A5', paddingBottom: '30px' }}>
                Aqui será exibida a lista dos usuários inseridos.
              </div>
            </>
          )}
        </Col>
      </Row>
    </Form>
  );
};
