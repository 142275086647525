/* eslint-disable no-unused-expressions */
import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { Button, DropdonwFlags, DropdownMenu, Loading } from '@/components';
import { Container } from 'reactstrap';
import FilterVector from '@/assets/images/svg/FilterVector';
import { SimpleSelect } from '@/components/SimpleSelect';
import { CustomTable, ColumnStatus, ColumnImage } from '@/components/Table';
import Pagination from '@/components/Utils/Pagination';
import { ReactComponent as EventAction } from '@/assets/images/svg/eventAction.svg';
import { ReactComponent as Pen } from '@/assets/images/svg/pen.svg';
import { ReactComponent as Validator } from '@/assets/images/svg/Validator.svg';
import { ReactComponent as Map } from '@/assets/images/svg/map.svg';
import { ReactComponent as Ticket } from '@/assets/images/svg/ticket.svg';
import { ReactComponent as Copy } from '@/assets/images/svg/copy.svg';
import { ReactComponent as TicketManagement } from '@/assets/images/svg/TicketManagement.svg';
import { ReactComponent as Block } from '@/assets/images/svg/block.svg';
import { ReactComponent as Report } from '@/assets/images/svg/report.svg';
import { ReactComponent as Gift } from '@/assets/images/svg/gift.svg';
import { ReactComponent as BlackAlert } from '@/assets/images/svg/blackAlert.svg';
import { ReactComponent as Bracelet } from '@/assets/images/svg/bracelet.svg';
import { ReactComponent as PrintTickets } from '@/assets/images/svg/printTickets.svg';
import { StatusFilter } from '@/components/StatusFilter';
import { ActionProps, Dialog } from '@/components/Dialog';
import { ActionProps as ActionPropsMenu } from '@/components/DropdownMenu';
import { FormErrors, OnChangeFormInput, FormData, IsFormValid } from '@/hooks/useForm';
import { FilterContent } from '@/features/events/components/FilterContent';
import Event from '@/model/Event';
import { EventRequestParams } from '@/features/events/types';
import { useHistory } from 'react-router-dom';
import { RegisterVoucher } from '@/features/events/components/RegisterVoucher';
import dayjs from 'dayjs';
import Voucher from '@/model/Voucher';
import { colors } from '@/styles/colors';
import { useEvent } from '@/features/registerEvent/hook/useEvent';
import { RegisterCourtesy } from '@/features/events/components/RegisterCourtesy';
import EventCourtesy from '@/model/EventCourtesy';
import Section from '@/model/Section';
import Tickets from '@/model/Tickets';
import { DetailCourtesy } from '@/features/events/components/DetailCourtesy';
import UserType from '@/model/UserType';
import { columns } from './table';

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
  voucher = 'voucher',
  courtesy = 'courtesy',
  courtesyDetail = 'courtesyDetail',
}
export interface DataRow {
  id: string;
  image: string;
  status: number;
  name: string;
  startDate: string;
  endDate: string;
  city: string;
  actions: string;
}

interface EventContainerProps {
  menuList: string[];
  paginationSelect: { value: number; label: string }[];
  shouldShowModal: ShouldShowModal;
  currentPage: EventRequestParams;
  title: string | React.ReactNode;
  listEvent: Event[];
  state: States;
  visible: boolean;
  formDataFilter: FormData;
  formErrorsFilter: FormErrors;
  pagination: { pageSize: number };
  fullListEvent: Event[];
  voucherState: Voucher[];
  eventState: Event | undefined;
  formDataVoucher: FormData;
  formErrorsVoucher: FormErrors;
  courtesys: EventCourtesy[];
  sections: Section[];
  tickets: Tickets[];
  formDataCourtesy: FormData;
  formErrorsCourtesy: FormErrors;
  courtesy: EventCourtesy;
  userType: UserType;
  disableName: boolean;
  disableEmail: boolean;
  disablePhone: boolean;
  disableBirthDate: boolean;
  disableMotherName: boolean;
  file: string;
  handleOnShowDeleteProduct: (eventSelected: string, VoucherSelected: string) => void;
  isFormValidVoucher: IsFormValid;
  copyToClipboard: (text: string) => void;
  onChangeFormInputVoucher: OnChangeFormInput;
  onRefuseEvent: (event: Event) => void;
  onReleaseEvent: (event: Event) => void;
  handleOnFilterStatus: (status: number) => void;
  clearFilter: () => void;
  onFilter: () => Promise<void>;
  setPagination: Dispatch<SetStateAction<{ pageSize: number }>>;
  changeColorColumn: (status: number) => string;
  onPaginationChange: (page: number) => void;
  onToggle: () => void;
  onChangeFormInputFilter: OnChangeFormInput;
  clearFilterStatus: () => void;
  handleOnSaveVoucher: (event: Event) => Promise<void>;
  handleFetchVoucher: (event: Event) => Promise<void>;
  onShouldShowModal: ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    event?: Event;
  }) => void;
  onClone: (eventId: string) => void;
  onChangeCourtesy: OnChangeFormInput;
  listTickets: (eventId: string, sectionId: string) => void;
  onCheckCpf: (cpf: string) => void;
  onAddCourtesy: (eventId: string) => Promise<void>;
  onGetCourtesy: (eventId: string, courtesyId: string) => Promise<void>;
  onDeleteCourtesy: (eventId: string, courtesyId: string) => Promise<void>;
  onChangeFileInput: (inputName: string) => (file: File | undefined) => void;
  ListOnline: (eventId: string, eventName: string) => void;
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const EventContainer: React.FC<EventContainerProps> = ({
  menuList,
  paginationSelect,
  shouldShowModal,
  formDataFilter,
  formErrorsFilter,
  listEvent,
  currentPage,
  state,
  title,
  visible,
  pagination,
  fullListEvent,
  voucherState,
  eventState,
  formDataVoucher,
  formErrorsVoucher,
  courtesys,
  sections,
  tickets,
  formDataCourtesy,
  formErrorsCourtesy,
  courtesy,
  userType,
  disableName,
  disableEmail,
  disablePhone,
  disableBirthDate,
  disableMotherName,
  file,
  handleOnShowDeleteProduct,
  isFormValidVoucher,
  copyToClipboard,
  onChangeFormInputVoucher,
  handleFetchVoucher,
  onRefuseEvent,
  onReleaseEvent,
  handleOnFilterStatus,
  clearFilter,
  onFilter,
  setPagination,
  onPaginationChange,
  changeColorColumn,
  onChangeFormInputFilter,
  onToggle,
  clearFilterStatus,
  handleOnSaveVoucher,
  onShouldShowModal,
  onClone,
  onChangeCourtesy,
  listTickets,
  onCheckCpf,
  onAddCourtesy,
  onGetCourtesy,
  onDeleteCourtesy,
  onChangeFileInput,
  ListOnline,
}) => {
  const { eventState: eventStateContext, onChange: onChangeEvent } = useEvent();
  const dataEventType = [
    { id: 0, name: 'Evento mono' },
    { id: 1, name: 'Evento pai' },
    { id: 2, name: 'Evento filho' },
  ];

  // const renderActionDialogToCancel: ActionProps = {
  //   title: 'Cancelar',
  //   onClick: (): void => onToggle(),
  //   theme: 'noneBorder',
  // };

  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => clearFilter(),
    theme: 'noneBorder',
  };

  const history = useHistory();

  const foundPermission = (value: string): boolean => {
    let found = false;
    if (menuList && menuList.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < menuList.length; i++) {
        if (menuList[i] === value) {
          found = true;
          break;
        }
      }
    }
    return found;
  };

  const mountMenuActions = (event: Event): ActionPropsMenu[] => {
    const list: ActionPropsMenu[] = [];
    if (menuList && menuList.length > 0) {
      if (foundPermission('Editar')) {
        list.push({
          icon: <Pen style={{ transform: 'scale(0.9)' }} />,
          title: 'Editar',
          action: () => {
            onChangeEvent({ ...eventStateContext, currentStep: 0 });
            history.push(`/dashboard/event/edit/${event.id}`);
          },
        });
      }
      if (foundPermission('Validadores')) {
        list.push({
          icon: <Validator style={{ transform: 'scale(0.9)' }} />,
          title: 'Validadores',
          action: () => {
            onChangeEvent({ ...eventStateContext, currentStep: 0 });
            history.push(`/dashboard/event/validator/${event.id}`);
          },
        });
      }
      if (foundPermission('Mapa')) {
        list.push({
          icon: <Map style={{ transform: 'scale(0.9)', marginLeft: '-5px' }} />,
          title: 'Mapa',
          action: () => {
            onChangeEvent({ ...eventStateContext, currentStep: 0 });
            history.push(`/dashboard/event/map/${event.id}`);
          },
        });
      }
      if (foundPermission('Gestão de ingressos')) {
        list.push({
          title: 'Gestão de ingressos',
          icon: <TicketManagement />,
          action: () => {
            onChangeEvent({ ...eventStateContext, currentStep: 0 });
            history.push(`/dashboard/event/ticket/${event.id}`);
          },
        });
      }
      if (foundPermission('Voucher de desconto')) {
        list.push({
          title: 'Voucher de desconto',
          icon: <Ticket style={{ transform: 'scale(0.9)' }} />,
          action: () => {
            onShouldShowModal({
              newTitleModal: 'Cadastrar voucher de desconto',
              value: ShouldShowModal.voucher,
              event,
            });
          },
        });
      }
      if (foundPermission('Bloquear')) {
        list.push({
          title: 'Bloquear',
          icon: <Block />,
        });
      }
      if (foundPermission('Relatórios')) {
        list.push({
          title: 'Relatórios',
          icon: <Report />,
          action: () => {
            history.push({
              pathname: `/dashboard/event/reports/${event.id}/false`,
            });
          },
        });
      }
      if (foundPermission('Editar')) {
        list.push({
          icon: <PrintTickets style={{ transform: 'scale(0.9)', marginLeft: '-8px' }} />,
          title: 'Lista Online',
          action: () => {
            onChangeEvent({ ...eventStateContext, currentStep: 0 });
            ListOnline(event.id, event.name);
          },
        });
      }
      if (foundPermission('Clonar')) {
        list.push({
          title: 'Clonar',
          icon: <Copy style={{ transform: 'scale(0.9)', marginLeft: '-1px' }} />,
          action: () => {
            onClone(event.id);
          },
        });
      }
      if (foundPermission('Cortesia nominal')) {
        list.push({
          title: 'Cortesia nominal',
          icon: <Gift />,
          action: () => {
            onShouldShowModal({
              newTitleModal: 'Cadastrar cortesia nominal',
              value: ShouldShowModal.courtesy,
              event,
            });
          },
        });
      }
      if (foundPermission('Pulseira')) {
        list.push({
          title: 'Pulseira',
          icon: <Bracelet />,
          action: () => {
            onChangeEvent({ ...eventStateContext, currentStep: 0 });
            history.push(`/dashboard/event/bracelet/${event.id}`);
          },
        });
      }
      if (foundPermission('Liberar evento')) {
        if (event.eventStatus === 1) {
          list.push({
            divider: true,
            title: (
              <div className="d-flex">
                <Button
                  style={{ minWidth: '164px' }}
                  size="sm"
                  title="Liberar evento"
                  onClick={(): void => {
                    onReleaseEvent(event);
                  }}
                />
              </div>
            ),
          });
        }
      }
      if (foundPermission('Recusar evento')) {
        if (event.eventStatus === 1) {
          list.push({
            title: (
              <Button
                style={{ minWidth: '164px' }}
                size="sm"
                theme="outlineGray"
                title="Recusar evento"
                onClick={(): void => onRefuseEvent(event)}
              />
            ),
          });
        }
      }
    }
    return list;
  };

  const dataTablePos = listEvent?.map(event => ({
    id: event.id,
    image: (
      <ColumnStatus justify="right" statusColor={String(changeColorColumn(event.eventStatus))}>
        <ColumnImage srcImage={event.imageBase64} />
      </ColumnStatus>
    ),
    name: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>{event.name}</div>
        {
          <DropdonwFlags
            className="mt-2"
            dataColumn={dataEventType.filter(value => value.id === event.eventType)}
          />
        }
      </div>
    ),
    city: `${event.address.city}/${event.address.state}`,
    startDate:
      event.startDate === null
        ? '-----'
        : // eslint-disable-next-line no-useless-concat
          `${dayjs(event.startDate).format('DD/MM/YYYY')} às ${String(event.startDate)
            .split('T')[1]
            .slice(0, 5)}`,
    endDate:
      event.endDate === null
        ? '-----'
        : `${dayjs(event.endDate).format('DD/MM/YYYY')} às ${String(event.endDate)
            .split('T')[1]
            .slice(0, 5)}`,
    actions:
      userType === UserType.CONTRACTOR ||
      userType === UserType.PDV ||
      userType === UserType.SUB_PDV ||
      userType === UserType.ARTIST ? (
        ''
      ) : (
        <DropdownMenu title={<EventAction />} actions={mountMenuActions(event)} />
      ),
  }));

  return (
    <>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        footerBorder={shouldShowModal === ShouldShowModal.voucher ? 'none' : 'top'}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={shouldShowModal !== ShouldShowModal.filter}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
            [ShouldShowModal.voucher]: {},
            [ShouldShowModal.courtesy]: {},
            [ShouldShowModal.courtesyDetail]: {},
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: {
              title: 'Aplicar',
              onClick: (): Promise<void> => onFilter(),
            },
            [ShouldShowModal.voucher]: {},
            [ShouldShowModal.courtesy]: {},
            [ShouldShowModal.courtesyDetail]: {},
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formDataFilter}
                formErrors={formErrorsFilter}
                onChangeFormInput={onChangeFormInputFilter}
              />
            ),
            [ShouldShowModal.voucher]: (
              <RegisterVoucher
                handleOnSaveVoucher={handleOnSaveVoucher}
                voucherState={voucherState}
                handleFetchVoucher={handleFetchVoucher}
                eventState={eventState}
                onChangeFormInputVoucher={onChangeFormInputVoucher}
                formDataVoucher={formDataVoucher}
                formErrorsVoucher={formErrorsVoucher}
                isFormValidVoucher={isFormValidVoucher}
                copyToClipboard={copyToClipboard}
                handleOnShowDeleteProduct={handleOnShowDeleteProduct}
              />
            ),
            [ShouldShowModal.courtesy]: (
              <RegisterCourtesy
                event={eventState as Event}
                courtesys={courtesys}
                sections={sections}
                tickets={tickets}
                formData={formDataCourtesy}
                formErrors={formErrorsCourtesy}
                onChange={onChangeCourtesy}
                disableName={disableName}
                disableEmail={disableEmail}
                disablePhone={disablePhone}
                disableBirthDate={disableBirthDate}
                disableMotherName={disableMotherName}
                onCheckCpf={onCheckCpf}
                listTickets={listTickets}
                onAdd={onAddCourtesy}
                onGet={onGetCourtesy}
                onDelete={onDeleteCourtesy}
                onChangeFileInput={onChangeFileInput}
                file={file}
              />
            ),
            [ShouldShowModal.courtesyDetail]: <DetailCourtesy courtesy={courtesy} />,
          }[shouldShowModal]
        }
      </Dialog>
      <Fragment>
        {/* <Loading isVisible={state === States.loading} /> */}
        <Container className="mainContainer" fluid={true}>
          <div
            className="d-flex justify-content-between event-page-title-container"
            style={{ paddingBottom: '30px' }}
          >
            <div className="pageTitle event-page-title" style={{ display: 'grid' }}>
              <h5 className="pageTitle" style={{ marginBottom: '1px' }}>
                Todos os eventos cadastrados
              </h5>
              {userType !== UserType.CONTRACTOR &&
                userType !== UserType.PDV &&
                userType !== UserType.SUB_PDV &&
                userType !== UserType.ARTIST && (
                  <div className="d-flex">
                    <div style={{ width: 'fit-content' }}>
                      <BlackAlert style={{ marginRight: '10px', marginBottom: '10px' }} />
                    </div>
                    <p className="eventDraftCounter">
                      Você tem{' '}
                      <span style={{ color: colors.black, fontWeight: '500' }}>
                        {fullListEvent?.filter(event => event.eventStatus === 0).length} eventos{' '}
                      </span>
                      em rascunho
                    </p>
                  </div>
                )}
            </div>
            <div className="button-filter-container event-button-filter">
              {userType !== UserType.CONTRACTOR &&
                userType !== UserType.PDV &&
                userType !== UserType.SUB_PDV &&
                userType !== UserType.ARTIST && (
                  <Button
                    title="+ Cadastrar novo evento"
                    onClick={() => history.push('/dashboard/event/create')}
                  />
                )}

              <div className="d-flex event-filter-container">
                <div className="select-label-container" style={{ marginLeft: '15px' }}>
                  <SimpleSelect
                    name={'Exibir'}
                    value={pagination}
                    options={paginationSelect}
                    placeholder="10 por página"
                    label="Exibir:"
                    onChange={e => {
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      setPagination({ pageSize: Number(e?.value) });
                    }}
                  />
                </div>
                <div className="filter-container m-filter">
                  <div
                    className="filter-content"
                    onClick={(): void =>
                      onShouldShowModal({
                        value: ShouldShowModal.filter,
                        newTitleModal: '',
                      })
                    }
                  >
                    <FilterVector />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {userType !== UserType.CONTRACTOR &&
            userType !== UserType.PDV &&
            userType !== UserType.SUB_PDV &&
            userType !== UserType.ARTIST && (
              <div className="d-flex pb-2 status-container statusFilter">
                <StatusFilter
                  handleOnFilterStatus={handleOnFilterStatus}
                  clearFilter={clearFilterStatus}
                />
              </div>
            )}

          <CustomTable
            columns={columns}
            data={dataTablePos}
            numberRowsPerPage={10}
            theme="primary"
            progressPending={false}
            getRowId={e => {
              onChangeEvent({ ...eventStateContext, currentStep: 0 });
              userType === UserType.CONTRACTOR ||
              userType === UserType.PDV ||
              userType === UserType.SUB_PDV ||
              userType === UserType.ARTIST
                ? history.push(`/dashboard/event/reports/${e.id}/false`)
                : history.push(`/dashboard/event/${e.id}`);
            }}
          />
          <Pagination
            currentPage={currentPage.page}
            totalCount={currentPage.total}
            pageSize={currentPage.pageSize}
            onPageChange={page => onPaginationChange(page)}
            total={currentPage.total}
          />
        </Container>
      </Fragment>
    </>
  );
};
