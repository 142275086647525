import React from 'react';
import { Route } from '@/navigation/Route';
import { EventValidatorScreen } from './screens';

export const EVENTS_VALIDATOR_ROUTES = {
  itself: '/dashboard/event/validator/:id',
};

export const EventValidatorNavigation = (): JSX.Element => (
  <Route
    exact
    path={EVENTS_VALIDATOR_ROUTES.itself}
    component={EventValidatorScreen}
    isPrivateRoute
  />
);
