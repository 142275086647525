import React, { useEffect, useState } from 'react';
import Page from '@/model/Page';
import Event from '@/model/Event';
import { useDialog } from '@/hooks/useDialog';
import { AxiosError } from 'axios';
import api from '@/services/api';
import { toast } from 'react-toastify';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import { FormInput, ShouldShowModal, States } from '../types';
import { FinancialContainer } from './ui';

export const FinancialScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [page, setPage] = useState<Page<Event, Event>>({
    page: 1,
    pageSize: 10,
    sort: 'startDate',
    order: 'DESC',
    total: 1,
  });
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const handleOnPage = async (pageData: Page<Event, Event>): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Page<Event, Event>>(
        '/event-money-transfer/finantial/event/page',
        pageData,
      );
      if (data) {
        setPage(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
    validators: {
      inputSearch: [validators.required],
    },
  });

  const handleOnClearFilter = async (): Promise<void> => {
    resetForm();
    const newPage: Page<Event, Event> = {
      page: 1,
      pageSize: 10,
      sort: 'startDate',
      order: 'DESC',
      total: 1,
    };
    await handleOnPage(newPage);
  };

  const handleOnFilter = async (): Promise<void> => {
    if (isFormValid()) {
      const newPage: Page<Event, Event> = {
        page: 1,
        pageSize: 10,
        entity: {
          name: formData[FormInput.inputSearch],
        } as Event,
        sort: 'startDate',
        order: 'DESC',
        total: 1,
      };
      onToggle();

      await handleOnPage(newPage);
    }
  };

  const handleOnShouldShowModal = ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
  }): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnPaginationChange = async (newPageNumber: number): Promise<void> => {
    const newPage: Page<Event, Event> = {
      ...page,
      page: newPageNumber,
    };
    await handleOnPage(newPage);
  };

  useEffect(() => {
    handleOnPage(page);
  }, []);
  return (
    <FinancialContainer
      state={state}
      title={title}
      visible={visible}
      onToggle={onToggle}
      page={page}
      onPaginationChange={handleOnPaginationChange}
      onShouldShowModal={handleOnShouldShowModal}
      shouldShowModal={shouldShowModal}
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      onClearFilter={handleOnClearFilter}
      onFilter={handleOnFilter}
    />
  );
};
