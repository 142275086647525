import { Loading } from '@/components';
import api, { AxiosError } from '@/services/api';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EventMainTrackingReport from '@/model/EventMainTrackingReport';
import Page from '@/model/Page';
import EventTrackingReport from '@/model/EventTrackingReport';
import { ReportControllerProps } from '../../types';
import { TrackingContent } from './ui';

export interface TrackingProps {
  generatePDF: () => void;
  reportController: ReportControllerProps;
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export const Tracking: React.FC<TrackingProps> = ({ reportController, generatePDF }) => {
  const [state, setState] = useState<States>(States.default);
  const {
    eventId,
    singleEvent,
    tracking,
    setTracking,
    trackingPagination,
    setTrackingPagination,
    onPageTracking,
  } = reportController;

  const handleGetTracking = async (): Promise<void> => {
    if (tracking === undefined) {
      try {
        setState(States.loading);
        const { data } = await api.get<EventMainTrackingReport>(
          `event/report/${eventId}/tracking?singleEvent=${singleEvent}`,
        );
        if (data) {
          setTracking(data);
          const newPage: Page<EventTrackingReport, EventTrackingReport> = {
            page: 1,
            pageSize: 10,
            list: data.trackings,
            total: data.total,
          };
          setTrackingPagination(newPage);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    handleGetTracking();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <TrackingContent
        tracking={tracking}
        generatePDF={generatePDF}
        pagination={trackingPagination}
        onPage={onPageTracking}
      />
    </>
  );
};
