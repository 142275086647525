import React from 'react';

const ProductIcon = (): JSX.Element => (
  <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0759 4.89525C12.8787 4.69577 12.6487 4.52023 12.4038 4.38282V0.221653C12.4038 0.0993301 12.3045 0 12.1822 0H9.51921C9.39689 0 9.29756 0.0993301 9.29756 0.221653V4.38351C8.31161 4.94028 7.7045 5.97657 7.7045 7.12007V8.85737L0.221653 8.85723C0.0993299 8.85723 0 8.95656 0 9.07889V18.5026C0 18.625 0.0993299 18.7243 0.221653 18.7243C6.57835 18.7243 11.1902 18.7233 13.7783 18.7233C13.9007 18.7233 14 18.624 14 18.5017V7.11997C13.9997 6.28153 13.6713 5.49124 13.0757 4.89542L13.0759 4.89525ZM11.9596 0.44462V2.2294H9.74236V0.44462H11.9596ZM9.62003 4.71377C9.69445 4.67594 9.74126 4.59974 9.74126 4.51633V2.67289H11.9609V4.51633C11.9609 4.60029 12.0086 4.67716 12.0835 4.71458C12.3309 4.83813 12.5649 5.00835 12.7618 5.20782C13.6559 6.10247 13.5569 7.04582 13.5569 7.85008H8.14833V7.12008C8.14833 6.09808 8.71221 5.17598 9.62017 4.71374L9.62003 4.71377ZM8.14819 8.29321H13.5567V15.2773L9.85919 15.2774V9.07884C9.85919 8.7833 9.60556 8.85718 8.1476 8.85718L8.14773 8.2932L8.14819 8.29321ZM6.3864 10.8687C5.63421 10.2905 5.9118 10.2832 4.93104 11.0389L4.2288 10.4978C4.18894 10.467 4.14131 10.4517 4.09342 10.4517C4.04552 10.4517 3.9979 10.467 3.95803 10.4978L3.47664 10.8687L3.47337 9.30051H6.38549L6.3864 10.8687ZM0.443526 9.30052H3.03016C3.03465 11.3177 2.96431 11.4226 3.15875 11.5177C3.2344 11.555 3.32461 11.5462 3.39169 11.4943L4.09365 10.9532C5.09768 11.727 4.75071 11.7379 5.76891 10.9532C6.39876 11.4373 6.51702 11.6105 6.70641 11.5177C6.78206 11.4804 6.82996 11.403 6.82996 11.3188L6.82873 9.30052C7.01637 9.30052 8.21429 9.30107 9.41603 9.30107V18.281H0.443375L0.443526 9.30052ZM9.85961 18.2801V15.7206H13.5568V18.2801H9.85961Z"
      fill="#222222"
    />
    <path
      d="M6.34355 12.3278H3.50765C3.10271 12.3278 2.77344 12.6573 2.77344 13.0623C2.77344 13.4672 3.10272 13.7968 3.50765 13.7968H6.34355C6.74849 13.7968 7.07776 13.4672 7.07776 13.0623C7.07776 12.6573 6.74848 12.3278 6.34355 12.3278ZM6.34355 13.3533H3.50765C3.34723 13.3533 3.21687 13.2227 3.21687 13.0621C3.21687 12.9017 3.34723 12.7709 3.50765 12.7709H6.34355C6.50397 12.7709 6.63433 12.9016 6.63433 13.0621C6.63433 13.2227 6.50411 13.3533 6.34355 13.3533Z"
      fill="#222222"
    />
  </svg>
);

export default ProductIcon;
