import React from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { SelectCustom } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import Entrance from '@/model/Entrance';
import { toast } from 'react-toastify';
import { ReactComponent as Pen } from '@/assets/images/svg/pen.svg';
import { FormInputEventEntrance, ShouldShowModal } from '../../types';

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
  entrances: Entrance[];
}

interface DispatchProps {
  onChange: OnChangeFormInput;
  openModal(value: ShouldShowModal, modalTitle: string, toggle: boolean, entrance?: Entrance): void;
}

type Props = StateProps & DispatchProps;

export const RegisterEventEntranceContent: React.FC<Props> = ({
  formData,
  formErrors,
  onChange,
  entrances,
  openModal,
}) => (
  <Form
    noValidate={true}
    onSubmit={(e): void => {
      e.preventDefault();
    }}
  >
    <Row>
      <Col md={8}>
        <FormGroup className="mb-2">
          <SelectCustom
            name="entranceId"
            label="Portaria do evento"
            placeholder="Digite ou selecione a portaria do evento"
            value={formData[FormInputEventEntrance.entranceId]}
            onChange={e => onChange(FormInputEventEntrance.entranceId)(e?.value as string)}
            error={formErrors.entranceId && formErrors.entranceId[0]}
            options={entrances.map(entrance => ({
              label: entrance.name,
              value: entrance.id,
            }))}
            isClearable
          />
          <div className="d-flex flex-column mb-5" style={{ marginTop: '-20px' }}>
            <span className="d-flex">
              <div
                className="mr-5 link-green"
                onClick={() =>
                  openModal(ShouldShowModal.entrance, 'Cadastrar nova portaria', false)
                }
              >
                + cadastrar nova categoria de evento
              </div>
              <div
                className="link-grey"
                onClick={() => {
                  if (formData[FormInputEventEntrance.entranceId] !== '') {
                    const entranceSelected = entrances.find(
                      entrance => entrance.id === formData[FormInputEventEntrance.entranceId],
                    );
                    openModal(ShouldShowModal.entrance, 'Editar portaria', false, entranceSelected);
                  } else {
                    toast.warn('Selecione uma categoria para editar');
                  }
                }}
              >
                <Pen height={12} width={12} /> editar
              </div>
            </span>
          </div>
        </FormGroup>
      </Col>
    </Row>
  </Form>
);
