import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { ValidationHourReport } from '@/model/ValidationHourReport';

export interface StyledLineProps {
  data: ValidationHourReport[]; // Modifica para receber o array de ValidationDateReport
  dataSetName: string;
  color: string;
  tableTitle?: string;
}

export const StyledAreLine: React.FC<StyledLineProps> = ({ data, color, tableTitle }) => {
  ChartJS.register(...registerables);
  console.log(data);
  const labels = data.map(item => item.hour);
  const amounts = data.map(item => item.amount);

  const dataset = {
    label: 'Quantidade',
    data: amounts,
    borderColor: color,
    backgroundColor: `${color}10`,
  };

  const chartData = {
    labels,
    datasets: [dataset],
  };

  return (
    <div className="mt-5" style={{ width: '400px' }}>
      <div className="mb-2">{tableTitle}</div>
      <Line width={400} height={250} data={chartData} />
    </div>
  );
};
