import { ButtonGroup, Checkbox, InputText, SelectCustom } from '@/components';
import EventMapTicket from '@/model/EventMapTicket';
import Ticket from '@/model/Ticket';
import React from 'react';
import { ReactComponent as CloseX } from '@/assets/images/svg/closeX.svg';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import { ReactComponent as Pen } from '@/assets/images/svg/pen.svg';
import { Col, FormGroup, Row } from 'reactstrap';
import { CustomTable } from '@/components/Table';
import EventMap from '@/model/EventMap';
import {
  FormInputTicket,
  MapControler,
  OnShouldShowModalProps,
  ShouldShowModal,
} from '../../types';

interface TableProps {
  sectionId?: string;
  ticketControler: MapControler;
  eventMap: EventMap;
  tickets: Ticket[];
  mapTickets: EventMapTicket[];
  onShouldShowModal: (props: OnShouldShowModalProps) => void;
  openTicket: (sectionId: string, ticketId?: string) => void;
  removeTicket: (sectionId: string, ticketId: string) => void;
}

export const TicketContent: React.FC<TableProps> = ({
  sectionId,
  ticketControler,
  eventMap,
  tickets,
  mapTickets,
  onShouldShowModal,
  openTicket,
  removeTicket,
}): JSX.Element => {
  const dataTicket = mapTickets.map(item => ({
    id: item.id,
    name: item.ticket.name,
    actions: (
      <div className="d-flex m-2 ml-4">
        <Detail
          className={'mr-4 svg-icon action-icon'}
          onClick={() =>
            onShouldShowModal({
              value: ShouldShowModal.map,
              newTitleModal: 'Mapa',
              eventMap,
              eventKey: item.eventKey,
            })
          }
        />
        <Pen
          className="mr-4 svg-icon action-icon"
          onClick={() => openTicket(sectionId as string, item.id)}
        />
        <CloseX
          className="mr-2 svg-icon action-icon svg-icon-trash"
          onClick={() => removeTicket(sectionId as string, item.id)}
        />
      </div>
    ),
  }));
  return (
    <div>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="eventKey"
              label="Event Key"
              placeholder="Digite a key do evento do Seats.io. Ex: 87b18d05-e051-4ad6-bfaa-4ce52bb68630"
              value={ticketControler.formData[FormInputTicket.eventKey]}
              onChange={e =>
                ticketControler.onChangeFormInput(FormInputTicket.eventKey)(e.target.value)
              }
              error={ticketControler.formErrors.eventKey && ticketControler.formErrors.eventKey[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectCustom
              name="ticket"
              label="Ingresso"
              placeholder="Digite ou selecione o ingresso"
              value={ticketControler.formData[FormInputTicket.ticket]}
              onChange={e =>
                ticketControler.onChangeFormInput(FormInputTicket.ticket)(e?.value as string)
              }
              error={ticketControler.formErrors.ticket && ticketControler.formErrors.ticket[0]}
              options={tickets.map(ticket => ({
                label: ticket.name,
                value: ticket.id,
              }))}
              isClearable
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <ButtonGroup
              label="Este evento terá mesas?"
              name="hasTable"
              value={ticketControler.formData[FormInputTicket.hasTable]}
              onChange={e =>
                ticketControler.onChangeFormInput(FormInputTicket.hasTable)(e.target.value)
              }
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              error={ticketControler.formErrors.hasTable && ticketControler.formErrors.hasTable[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      {ticketControler.formData[FormInputTicket.hasTable] === 'true' && (
        <>
          <Row>
            <Col md={8}>
              <FormGroup className="mb-2">
                <InputText
                  name="seats"
                  label="Quantidade de assentos"
                  placeholder="Digite a quantidade de assentos"
                  type="number"
                  value={ticketControler.formData[FormInputTicket.seats]}
                  onChange={e =>
                    ticketControler.onChangeFormInput(FormInputTicket.seats)(e.target.value)
                  }
                  error={ticketControler.formErrors.seats && ticketControler.formErrors.seats[0]}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <FormGroup className="mb-2">
                <Checkbox
                  label="Pode ser reservado como um todo"
                  name="bookableAsAWhole"
                  checked={ticketControler.formData[FormInputTicket.bookableAsAWhole] === 'true'}
                  onChange={e =>
                    ticketControler.onChangeFormInput(FormInputTicket.bookableAsAWhole)(
                      String(e.target.checked),
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      {tickets && tickets.length > 0 && (
        <>
          <h5 className="mb-2 border-bottom-title mb-5">Ingressos</h5>
          <Row>
            <Col md={8}>
              {mapTickets && mapTickets.length > 0 ? (
                <CustomTable
                  columns={[
                    {
                      name: 'Mesa',
                      selector: row => row.name,
                    },
                    {
                      name: '',
                      selector: row => row.actions,
                      right: true,
                    },
                  ]}
                  data={dataTicket}
                  theme="tertiary"
                  progressPending={false}
                  numberRowsPerPage={dataTicket.length}
                />
              ) : (
                <>
                  <div style={{ padding: '10px 0 20px 0', color: '#A5A5A5' }}>
                    Você ainda não inseriu nenhum ingresso.
                  </div>
                  <div style={{ color: '#A5A5A5', paddingBottom: '30px' }}>
                    Aqui será exibida uma lista de ingressos inseridos para essa categoria.
                  </div>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
