import React from 'react';
import { Route } from '@/navigation/Route';
import { CancelRequestScreen } from './screens/List';

export const CANCEL_REQUEST_ROUTES = {
  itself: '/support/cancel',
};

export const CancelRequestNavigation = (): JSX.Element => (
  <Route exact path={CANCEL_REQUEST_ROUTES.itself} component={CancelRequestScreen} isPrivateRoute />
);
