import { Button, ButtonGroup, InputText, Loading } from '@/components';
import React from 'react';
import { Col, Container, Form, FormGroup, Row } from 'reactstrap';
import { EventState } from '@/features/registerEvent/hook/useEvent';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { FormInput, States } from '../types';

interface StateProps {
  state: States;
  eventState: EventState;
  formData: FormData;
  formErrors: FormErrors;
}

interface DispatchProps {
  onChangeEvent: (data: Partial<EventState>) => void;
  onChangeFormInput: OnChangeFormInput;
  onSaveEventFee: () => void;
}

type Props = StateProps & DispatchProps;

export const FeeEventContainer: React.FC<Props> = ({
  state,
  eventState,
  onChangeEvent,
  formData,
  onChangeFormInput,
  formErrors,
  onSaveEventFee,
}) => (
  <Form
    noValidate={true}
    onSubmit={(e): void => {
      e.preventDefault();
      e.stopPropagation();
      onSaveEventFee();
    }}
  >
    <Loading isVisible={state === States.loading} />
    <Container className="mainContainer" fluid={true}>
      <div className="container-event">
        <h5 className="mb-2 border-bottom-title mb-5">Taxas</h5>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <ButtonGroup
                label="Taxas administrativas"
                name="calcAdmFee"
                value={formData[FormInput.calcAdmFee]}
                onChange={e => onChangeFormInput(FormInput.calcAdmFee)(e.target.value)}
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                error={formErrors.calcAdmFee && formErrors.calcAdmFee[0]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="pdv"
                label="PDV"
                placeholder="1.00%"
                maxLength={5}
                value={formData[FormInput.pdv]}
                onChange={e => onChangeFormInput(FormInput.pdv)(e.target.value)}
                error={formErrors.pdv && formErrors.pdv[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="credit"
                label="Crédito à vista"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.credit]}
                onChange={e => onChangeFormInput(FormInput.credit)(e.target.value)}
                error={formErrors.credit && formErrors.credit[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="installment"
                label="Crédito parcelado"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.installment]}
                onChange={e => onChangeFormInput(FormInput.installment)(e.target.value)}
                error={formErrors.installment && formErrors.installment[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="debit"
                label="Débito"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.debit]}
                onChange={e => onChangeFormInput(FormInput.debit)(e.target.value)}
                error={formErrors.debit && formErrors.debit[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="pix"
                label="Pix"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.pix]}
                onChange={e => onChangeFormInput(FormInput.pix)(e.target.value)}
                error={formErrors.pix && formErrors.pix[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="bankslip"
                label="Boleto"
                placeholder="2,50"
                maxLength={10}
                value={formData[FormInput.bankslip]}
                onChange={e => onChangeFormInput(FormInput.bankslip)(e.target.value)}
                error={formErrors.bankslip && formErrors.bankslip[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5 className="mb-2 border-bottom-title mb-5">Despesas</h5>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="food"
                label="Alimentação"
                placeholder="2,50"
                maxLength={10}
                value={formData[FormInput.food]}
                onChange={e => onChangeFormInput(FormInput.food)(e.target.value)}
                error={formErrors.food && formErrors.food[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="technical"
                label="Técnicos"
                placeholder="2,50"
                maxLength={10}
                value={formData[FormInput.technical]}
                onChange={e => onChangeFormInput(FormInput.technical)(e.target.value)}
                error={formErrors.technical && formErrors.technical[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="trip"
                label="Viagem"
                placeholder="1.000,00"
                maxLength={10}
                value={formData[FormInput.trip]}
                onChange={e => onChangeFormInput(FormInput.trip)(e.target.value)}
                error={formErrors.trip && formErrors.trip[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="rentPos"
                label="Aluguel POS"
                placeholder="80,00"
                maxLength={10}
                value={formData[FormInput.rentPos]}
                onChange={e => onChangeFormInput(FormInput.rentPos)(e.target.value)}
                error={formErrors.rentPos && formErrors.rentPos[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <div className="footer-register-event">
          <Button
            title="Voltar"
            theme="noneBorder"
            onClick={() => {
              onChangeEvent({ ...eventState, currentStep: eventState.currentStep - 1 });
            }}
          />

          <Button title="Avançar para Confirmação" onClick={onSaveEventFee} />
        </div>
      </div>
    </Container>
  </Form>
);
