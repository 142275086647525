import React from 'react';

const TicketIcon = (): JSX.Element => (
  <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.63672 0L5.20692 -1.04744e-07L5.20692 0.347092C5.20692 1.11805 4.58935 1.73562 3.81839 1.73562C3.04744 1.73562 2.42987 1.11805 2.42987 0.347092L2.42987 -2.24457e-07L7.00951e-05 -3.29201e-07L6.94489e-05 14.5792L2.42987 14.5792L2.42987 14.2321C2.42987 13.4611 3.04744 12.8436 3.81839 12.8436C4.58935 12.8436 5.20692 13.4611 5.20692 14.2321L5.20692 14.5792L7.63672 14.5792L7.63672 0ZM6.94253 0.694184L6.94253 10.0665L6.24835 10.0665L6.24835 10.7607L6.94253 10.7607L6.94253 13.8848L5.83065 13.8848C5.6585 12.9095 4.84142 12.1492 3.81839 12.1492C2.79512 12.1492 1.97805 12.9092 1.80613 13.8848L0.694254 13.8848L0.694254 10.7607L1.38844 10.7607L1.38844 10.0665L0.694254 10.0665L0.694255 0.694184L1.80613 0.694184C1.97805 1.66976 2.79512 2.4298 3.81839 2.4298C4.84142 2.4298 5.65849 1.66951 5.83066 0.694184L6.94253 0.694184ZM5.55401 10.0665L4.16549 10.0665L4.16549 10.7607L5.55401 10.7607L5.55401 10.0665ZM3.4713 10.0665L2.08278 10.0665L2.08278 10.7607L3.4713 10.7607L3.4713 10.0665Z"
      fill="#222222"
    />
  </svg>
);

export default TicketIcon;
