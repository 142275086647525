const unmask = (value: string): string => value.replace(/\D/g, '');

const isValid = (value: string): boolean => {
  const str = unmask(value);
  const nb = Number(str);
  return nb >= 0;
};

const updateMask = (value: string): string => unmask(value);

export { isValid, unmask, updateMask };
