import React from 'react';
import { ReactComponent as Warning } from '@/assets/images/svg/warning.svg';
import { colors } from '@/styles/colors';

export const ConfirmContent: React.FC = (): JSX.Element => (
  <React.Fragment>
    <div className="exclude-container">
      <Warning color={colors.red} />
      <div className="header-title-text modal__title">
        <h5 className="modal__confirmation-title">Você está prestes a efetuar um resgate</h5>
      </div>
      <div className="modal__confirmation-text">
        <span>Sua solicitação será encaminhada para nossa equipe financeira para análise</span>
        <br />
        <span>Nosso prazo para análise é de até 2 (dois) dias úteis</span>
      </div>
    </div>
  </React.Fragment>
);
