import React from 'react';
import { Button } from '@/components';
import { toCurrency } from '@/helpers/masks/toCurrency';
import EventMainBleedingReport from '@/model/EventMainBleedingReport';
import EventBleedingEventReport from '@/model/EventBleedingEventReport';
import EventBleedingDetailReport from '@/model/EventBleedingDetailReport';
import { DataList } from '@/components/DataList';
import { Card } from 'reactstrap';
import { CustomTable } from '@/components/Table';

export interface BleedingContentProps {
  generatePDF: () => void;
  bleeding: EventMainBleedingReport | undefined;
}

export const BleedingContent: React.FC<BleedingContentProps> = ({ bleeding, generatePDF }) => {
  const dataSangria = [
    {
      title: 'Local:',
      content: bleeding?.summary.city,
    },
    {
      title: 'Site:',
      content: toCurrency(bleeding?.summary.saleSite || 0),
    },
    {
      title: 'Arrecadação:',
      content: toCurrency(bleeding?.summary?.collection || 0),
    },
    {
      title: 'Retirado:',
      content: toCurrency(bleeding?.summary.withdrawals || 0),
    },
    {
      title: 'Disponível:',
      content: toCurrency(bleeding?.summary.available || 0),
    },
    {
      title: 'Cartão:',
      content: toCurrency(bleeding?.summary.saleCard || 0),
    },
    {
      title: 'Dinheiro:',
      content: toCurrency(bleeding?.summary.saleMoney || 0),
    },

    {
      title: 'Pix:',
      content: toCurrency(bleeding?.summary.salePix || 0),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5>Sangria</h5>
        <Button theme="outlineDark" onClick={() => generatePDF()} title="Exportar relatório" />
      </div>
      <div
        className="mb-4 mt-5"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
          columnGap: '30px',
        }}
      >
        <DataList data={dataSangria} />
      </div>{' '}
      {bleeding?.details?.map((details: EventBleedingDetailReport) => (
        <>
          <h6 className="mb-4 collapseTableText">{details.channelName}</h6>
          <Card
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <div className="p-4">
              <CustomTable
                theme="secondary"
                numberRowsPerPage={0}
                progressPending={false}
                columns={[
                  {
                    name: 'Evento',
                    selector: row => row.name,
                  },
                  {
                    name: 'Vendidos',
                    selector: row => row.ticketsSold,
                  },
                  {
                    name: 'Arrecadado',
                    selector: row => row.totalCollected,
                  },
                  {
                    name: 'Retirado',
                    selector: row => row.withdrawals,
                  },
                  {
                    name: 'Disponível',
                    selector: row => row.available,
                  },
                  {
                    name: 'Venda cartão',
                    selector: row => row.saleCard,
                  },
                  {
                    name: 'Venda dinheiro',
                    selector: row => row.saleMoney,
                  },
                  {
                    name: 'Venda pix',
                    selector: row => row.salePix,
                  },
                ]}
                data={
                  details?.events?.map((event: EventBleedingEventReport) => ({
                    name: event.eventName,
                    ticketsSold: event.ticketsSold,
                    totalCollected: toCurrency(event.totalCollected),
                    withdrawals: toCurrency(event.withdrawals),
                    available: toCurrency(event.available),
                    saleCard: toCurrency(event.saleCard),
                    saleMoney: toCurrency(event.saleMoney),
                    salePix: toCurrency(event.salePix),
                  })) || []
                }
              />
            </div>
            <hr className="mb-0" style={{ minWidth: '1080px' }} />
            <div
              style={{
                padding: '30px 40px',
                gap: '40px',
              }}
              className="d-flex justify-content-center"
            >
              <>
                <div className="d-flex">
                  <div className="mr-1 text-small-gray-regular">Vendidos:</div>
                  <div className="text-small-black-600">
                    {toCurrency(details?.summary.totalSold || 0)}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="mr-1 text-small-gray-regular">Venda total dinheiro:</div>
                  <div className="text-small-black-600">
                    {toCurrency(details?.summary.fullCashSale || 0)}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="mr-1 text-small-gray-regular">Total retirado:</div>
                  <div className="text-small-black-600">
                    {toCurrency(details?.summary.totalWithdrawal || 0)}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="mr-1 text-small-gray-regular">Total disponível:</div>
                  <div className="text-small-black-600">
                    {toCurrency(details?.summary.totalAvailable || 0)}
                  </div>
                </div>
              </>
            </div>
          </Card>
        </>
      ))}
    </>
  );
};
