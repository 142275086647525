/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import {
  GeneralInformationContainer,
  ShouldShowModal,
  States,
} from '@/features/registerEvent/screens/GeneralInformation/ui';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import { useDialog } from '@/hooks/useDialog';
import { FormInputName as FormInputNameToSaveCategory } from '@/features/registerEvent/components/RegisterCategoryContent';
import { FormInputName as FormInputNameToSaveFatherEvent } from '@/features/registerEvent/components/RegisterFatherEvent';
import { FormInputName as FormInputNameToSaveGeneralInformation } from '@/features/registerEvent/components/GeneralInformationContent';
import { updateMask as updateMaskCEP } from '@/helpers/masks/cep';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import api from '@/services/api';
import EventCategory from '@/model/EventCategory';
import Contractor from '@/model/Contractor';
import { convertToBoolean } from '@/helpers/common/convertToBoolean';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import EventGeneralInformation from '@/model/EventGeneralInformation';
import Artist from '@/model/Artist';
import {
  categoryStatesProps,
  fatherEventStatesProps,
  formCategoryProps,
  formFatherEventProps,
  modalConfigProps,
  NameFiles,
  onShouldShowModalProps,
  contractorStatesProps,
  artistStatesProps,
} from '../../types';
import { useEvent } from '../../hook/useEvent';

type UrlParams = {
  id: string;
};

export const GeneralInformationScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const history = useHistory();
  const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.category);

  const [category, setCategory] = useState<EventCategory>();
  const [categoryList, setCategoryList] = useState<EventCategory[]>([]);

  const [fatherEvent, setFatherEvent] = useState<any>();
  const [fatherEventList, setFatherEventList] = useState<any[]>([]);
  const [contractors, setContractors] = useState<Contractor[]>([
    { administrator: true } as Contractor,
  ]);
  const [artists, setArtists] = useState<Artist[]>([{} as Artist]);
  const [contractorList, setContractorList] = useState<Contractor[]>([]);
  const [artistList, setArtistList] = useState<Artist[]>([]);

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const { eventState, onChange: onChangeEvent } = useEvent();
  const params = useParams<UrlParams>();

  const [dataCurrentStep, setDataCurrentStep] = useState<EventGeneralInformation>(
    {} as EventGeneralInformation,
  );

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        resetFormCategory();
        setCategory(undefined);
      }, 500);
    }
  }, [visible]);

  const {
    formData: formDataGeneralInformation,
    formErrors: formErrorsGeneralInformation,
    onChangeFormInput: onChangeFormInputGeneralInformation,
    isFormValid: isFormValidGeneralInformation,
    setErrors: setErrorsGeneralInformation,
    setFormErrors: setFormErrorsGeneralInformation,
  } = useForm({
    initialData: {
      name: '',
      namePos: '',
      passportName: '',
      establishmentName: '',
      zipCode: updateMaskCEP(''),
      state: '',
      city: '',
      district: '',
      street: '',
      complement: '',
      number: '',
      eventType: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      eventCategory: '',
      censure: '',
      facebookUrl: '',
      instagramUrl: '',
      imageBase64: '',
      imageDetailBase64: '',
      imagePosBase64: '',
      imageBluetooth: '',
      showImageLogo: 'false',
      publishWebsite: 'false',
      sendTicketEmail: 'true',
      textSize: '',
      ticketPhrase: '',
      websiteDescription: '',
      imageDescriptionUrl: '',
      discountDescription: '',
      pixel: '',
    },
    validators: {
      name: [validators.required],
      namePos: [validators.required],
      establishmentName: [validators.required],
      zipCode: [validators.required, validators.cep],
      eventType: [validators.required],
      startDate: [validators.required],
      endDate: [validators.required],
      startTime: [validators.required],
      websiteStartSaleDate: [validators.required],
      websiteEndSaleDate: [validators.required],
      endTime: [validators.required],
      eventCategory: [validators.required],
      censure: [validators.required],
      showImageLogo: [validators.required],
      publishWebsite: [validators.required],
      sendTicketEmail: [validators.required],
      textSize: [validators.required],
    },
    formatters: {
      zipCode: updateMaskCEP,
    },
  });

  const {
    formData: formDataCategory,
    formErrors: formErrorsCategory,
    onChangeFormInput: onChangeFormInputCategory,
    isFormValid: isFormValidCategory,
    resetForm: resetFormCategory,
  } = useForm({
    initialData: {
      name: '',
    },
    validators: {
      name: [validators.required],
    },
    formatters: {},
  });

  const {
    formData: formDataFatherEvent,
    formErrors: formErrorsFatherEvent,
    onChangeFormInput: onChangeFormInputFatherEvent,
    isFormValid: isFormValidFatherEvent,
    // resetForm: resetFormFatherEvent,
  } = useForm({
    initialData: {
      name: '',
    },
    validators: {
      name: [validators.required],
    },
    formatters: {},
  });

  // Config Modal --------------------------------------------------------------
  const handleOnShouldShowModal = ({
    value,
    newTitleModal,
    category: categorySelected,
  }: onShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();

    if (categorySelected?.id && value === ShouldShowModal.category) {
      setCategory(categorySelected);
    }
  };

  const controllerModalConfig: modalConfigProps = {
    title,
    visible,
    onChangeTitle,
    onToggle,
    onShouldShowModal: handleOnShouldShowModal,
    shouldShowModal,
  };
  // Config Modal --------------------------------------------------------------

  // Change file input ---------------------------------------------------------
  const handleOnChangeFileInput =
    (inputName: string) =>
    (file: File | undefined): void => {
      // validate if file is image
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
            onChangeFormInputGeneralInformation(inputName)('');
            onChangeFormInputGeneralInformation(inputName)(base64);
          }
        };
      } else {
        setErrorsGeneralInformation({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };
  // Change file input ---------------------------------------------------------

  // Controller Forms ----------------------------------------------------------
  const controllerFormGeneralInformation = {
    formData: formDataGeneralInformation,
    formErrors: formErrorsGeneralInformation,
    onChangeFormInput: onChangeFormInputGeneralInformation,
    setFormErrors: setFormErrorsGeneralInformation,
    onChangeFormFileInput: handleOnChangeFileInput,
    formNameFiles,
  };

  const controllerFormCategory: formCategoryProps = {
    formData: formDataCategory,
    formErrors: formErrorsCategory,
    onChangeFormInput: onChangeFormInputCategory,
  };

  const controllerFormFatherEvent: formFatherEventProps = {
    formData: formDataFatherEvent,
    formErrors: formErrorsFatherEvent,
    onChangeFormInput: onChangeFormInputFatherEvent,
  };
  // Controller Forms ----------------------------------------------------------

  // Controller States ----------------------------------------------------------
  const controllerContractorState: contractorStatesProps = {
    contractorList,
    setContractorList,
  };

  const controllerArtistState: artistStatesProps = {
    artistList,
    setArtistList,
  };

  const controllerCategoryState: categoryStatesProps = {
    category,
    setCategory,
    categoryList,
    setCategoryList,
  };

  const controllerFatherEventState: fatherEventStatesProps = {
    fatherEvent,
    setFatherEvent,
    fatherEventList,
    setFatherEventList,
  };
  // Controller States ----------------------------------------------------------

  // Fetch Data -----------------------------------------------------------------
  const handleGetEvetById = async (id: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventGeneralInformation>(`/event/general-information/${id}`);
      if (data.contractors && data.contractors.length > 0) {
        setContractors(data.contractors);
      } else {
        setContractors([{} as Contractor]);
      }

      if (data.artists && data.artists.length > 0) {
        setArtists(data.artists);
      } else {
        setArtists([{} as Artist]);
      }
      setDataCurrentStep(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleFecthContractorList = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Contractor[]>(`/contractor/find`);
      setContractorList(data ?? []);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleFecthArtistList = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Artist[]>(`/artist/find`);
      setArtistList(data ?? []);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleFecthCategoryList = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<any[]>(`/event-category/find`);
      setCategoryList(data ?? []);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleFecthFatherEventList = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<any[]>(`/event/find`);
      // filter father event when event type is father
      const fatherEventListFiltered = data.filter(
        (fatherEventValue: any) => fatherEventValue.eventType === 1,
      );
      setFatherEventList(fatherEventListFiltered ?? []);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };
  const handleOnSaveCategory = async (): Promise<void> => {
    try {
      if (isFormValidCategory()) {
        const payload: any = {
          id: category?.id,
          name: formDataCategory[FormInputNameToSaveCategory.name],
          description: 'Campo não existe',
        };

        if (!payload.id) {
          delete payload.id;

          await api.post<any>('/event-category', payload);
          toast.success('Categoria cadastrado com sucesso!');
        } else {
          await api.put<any>('/event-category', payload);
          toast.success('Categoria atualizada com sucesso!');
        }

        onToggle();
        handleFecthCategoryList();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnSaveGeneralInformation = async (): Promise<void> => {
    try {
      const childrenType =
        formDataGeneralInformation[FormInputNameToSaveGeneralInformation.eventType] === '2';

      if (isFormValidGeneralInformation()) {
        if (childrenType && !fatherEvent) {
          setFormErrorsGeneralInformation({
            [FormInputNameToSaveGeneralInformation.eventType]: [
              'É necessário vincular o evento pai',
            ],
          });
          return;
        }

        if (contractors && contractors.length > 0) {
          let countAdm = 0;
          let error = false;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < contractors.length; i++) {
            if (!contractors[i].id) {
              toast.error('Nenhum contratante adicionado!');
              error = true;
              break;
            }
            if (contractors[i].administrator) {
              countAdm += 1;
            }
          }
          if (countAdm === 0) {
            toast.error('Selecionar um contratante como administrador!');
            error = true;
          } else if (countAdm > 1) {
            toast.error('Existe mais de um contratante como administrador!');
            error = true;
          }
          if (error) {
            return;
          }
        } else {
          toast.error('Nenhum contratante adicionado!');
          return;
        }
        setState(States.loading);

        const payloadStartData = dayjs(
          `${formDataGeneralInformation[FormInputNameToSaveGeneralInformation.startDate]}T${
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.startTime]
          }`,
        ).format('YYYY-MM-DDTHH:mm');

        const payloadEndData = dayjs(
          `${formDataGeneralInformation[FormInputNameToSaveGeneralInformation.endDate]}T${
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.endTime]
          }`,
        ).format('YYYY-MM-DDTHH:mm');

        const payloadWebsiteStartSaleDate = dayjs(
          formDataGeneralInformation[FormInputNameToSaveGeneralInformation.websiteStartSaleDate],
        ).format('YYYY-MM-DDTHH:mm');

        const payloadWebsiteEndSaleDate = dayjs(
          formDataGeneralInformation[FormInputNameToSaveGeneralInformation.websiteEndSaleDate],
        ).format('YYYY-MM-DDTHH:mm');

        const payload: EventGeneralInformation = {
          id: dataCurrentStep?.id,
          fatherEvent: childrenType ? fatherEvent : '',
          name: formDataGeneralInformation[FormInputNameToSaveGeneralInformation.name],
          posName: formDataGeneralInformation[FormInputNameToSaveGeneralInformation.namePos],
          passportName:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.passportName] &&
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.passportName].trim()
              .length > 0
              ? formDataGeneralInformation[FormInputNameToSaveGeneralInformation.passportName]
              : undefined,
          establishmentName:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.establishmentName],
          eventType: +formDataGeneralInformation[FormInputNameToSaveGeneralInformation.eventType],
          address: {
            id: dataCurrentStep.address?.id,
            zipCode: formDataGeneralInformation[FormInputNameToSaveGeneralInformation.zipCode],
            state: formDataGeneralInformation[FormInputNameToSaveGeneralInformation.state],
            city: formDataGeneralInformation[FormInputNameToSaveGeneralInformation.city],
            district: formDataGeneralInformation[FormInputNameToSaveGeneralInformation.district],
            street: formDataGeneralInformation[FormInputNameToSaveGeneralInformation.street],
            complement:
              formDataGeneralInformation[FormInputNameToSaveGeneralInformation.complement],
            number: formDataGeneralInformation[FormInputNameToSaveGeneralInformation.number],
            latitude:
              parseFloat(
                formDataGeneralInformation[FormInputNameToSaveGeneralInformation.latitude],
              ) || 0.0,
            longitude:
              parseFloat(
                formDataGeneralInformation[FormInputNameToSaveGeneralInformation.longitude],
              ) || 0.0,
          },
          startDate: payloadStartData as unknown as Date,
          endDate: payloadEndData as unknown as Date,
          websiteStartSaleDate: payloadWebsiteStartSaleDate as unknown as Date,
          websiteEndSaleDate: payloadWebsiteEndSaleDate as unknown as Date,
          eventCategory: {
            id: formDataGeneralInformation[FormInputNameToSaveGeneralInformation.eventCategory],
          } as EventCategory,
          contractors,
          artists,
          censure: +formDataGeneralInformation[FormInputNameToSaveGeneralInformation.censure],
          facebookUrl:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.facebookUrl],
          instagramUrl:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.instagramUrl],
          imageBase64:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.imageBase64],
          imageDetailBase64:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.imageDetailBase64],
          imagePosBase64:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.imagePosBase64],
          imageBluetooth:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.imageBluetooth],
          showImageLogo: convertToBoolean(
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.showImageLogo],
          ),
          publishWebsite: convertToBoolean(
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.publishWebsite],
          ),
          sendTicketEmail: convertToBoolean(
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.sendTicketEmail],
          ),
          textSize: +formDataGeneralInformation[FormInputNameToSaveGeneralInformation.textSize],
          websiteDescription:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.websiteDescription],
          imageDescriptionUrl:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.imageDescriptionUrl],
          discountDescription:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.discountDescription],
          pixel:
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.pixel] &&
            formDataGeneralInformation[FormInputNameToSaveGeneralInformation.pixel].trim().length >
              0
              ? formDataGeneralInformation[FormInputNameToSaveGeneralInformation.pixel]
              : undefined,
        };

        if (!payload.id) {
          delete payload.id;
          delete payload.address.id;
        }
        const { data } = await api.post<any>('/event/general-information', payload);

        // onToggle();
        onChangeEvent({ ...eventState, currentStep: eventState.currentStep + 1 });
        history.push(`/dashboard/event/edit/${data.id}`);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnSaveFatherEvent = async (): Promise<void> => {
    try {
      if (isFormValidFatherEvent()) {
        setFatherEvent(formDataFatherEvent[FormInputNameToSaveFatherEvent.name]);
        setFormErrorsGeneralInformation({
          [FormInputNameToSaveGeneralInformation.eventType]: [''],
        });
        onToggle();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };
  // Fetch Data -----------------------------------------------------------------

  // Controllers Actions --------------------------------------------------------
  const controllerGeneralInformationActions = {
    onSave: handleOnSaveGeneralInformation,
  };

  const controllerCategoryActions = {
    onSave: handleOnSaveCategory,
  };

  const controllerFatherEventActions = {
    onSave: handleOnSaveFatherEvent,
  };

  const controllerContractorActions = {
    onGetList: handleFecthContractorList,
  };
  // Controllers Actions --------------------------------------------------------

  // Execute Actions ------------------------------------------------------------
  useEffect(() => {
    handleFecthCategoryList();
    handleFecthFatherEventList();
    handleFecthContractorList();
    handleFecthArtistList();
  }, []);

  useEffect(() => {
    if (params.id) {
      handleGetEvetById(params.id);
    }
  }, [params]);
  // Execute Actions ------------------------------------------------------------

  const handleOnAddContractor = (): void => {
    const newContractors: Contractor[] = [];
    contractors.forEach(data => {
      newContractors.push(data);
    });
    newContractors.push({} as Contractor);
    setContractors(newContractors);
  };

  const handleOnChangeContractor = (contractorId: string, index: number): void => {
    const contractor = contractorList.find(data => data.id === contractorId);
    if (contractor) {
      const newContractors: Contractor[] = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < contractors.length; i++) {
        if (i === index) {
          newContractors.push(contractor);
        } else {
          newContractors.push(contractors[i]);
        }
      }
      setContractors(newContractors);
    }
  };

  const handleOnChangeContractorAdministrator = (administrator: string, index: number): void => {
    const newContractors: Contractor[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < contractors.length; i++) {
      if (i === index) {
        newContractors.push({ ...contractors[i], administrator: administrator === 'true' });
      } else {
        newContractors.push(contractors[i]);
      }
    }
    setContractors(newContractors);
  };

  const handleOnRemoveContractor = (index: number): void => {
    const newContractors: Contractor[] = [];
    contractors.forEach(data => {
      newContractors.push(data);
    });
    newContractors.splice(index, 1);
    setContractors(newContractors);
  };

  const handleOnAddArtist = (): void => {
    const newArtists: Artist[] = [];
    artists.forEach(data => {
      newArtists.push(data);
    });
    newArtists.push({} as Artist);
    setArtists(newArtists);
  };

  const handleOnChangeArtist = (artistId: string, index: number): void => {
    const artist = artistList.find(data => data.id === artistId);
    if (artist) {
      const newArtists: Artist[] = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < artists.length; i++) {
        if (i === index) {
          newArtists.push(artist);
        } else {
          newArtists.push(artists[i]);
        }
      }
      setArtists(newArtists);
    }
  };

  const handleOnRemoveArtist = (index: number): void => {
    const newArtists: Artist[] = [];
    artists.forEach(data => {
      newArtists.push(data);
    });
    newArtists.splice(index, 1);
    setArtists(newArtists);
  };

  useEffect(() => {
    if (dataCurrentStep?.id) {
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.name)(
        dataCurrentStep?.name ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.namePos)(
        dataCurrentStep?.posName ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.passportName)(
        dataCurrentStep?.passportName ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.establishmentName)(
        dataCurrentStep?.establishmentName ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.eventType)(
        String(dataCurrentStep?.eventType) ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.zipCode)(
        dataCurrentStep?.address?.zipCode ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.state)(
        dataCurrentStep?.address?.state ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.city)(
        dataCurrentStep?.address?.city ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.district)(
        dataCurrentStep?.address?.district ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.street)(
        dataCurrentStep?.address?.street ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.complement)(
        dataCurrentStep?.address?.complement ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.number)(
        dataCurrentStep?.address?.number ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.latitude)(
        String(dataCurrentStep?.address?.latitude ?? ''),
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.longitude)(
        String(dataCurrentStep?.address?.longitude ?? ''),
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.startDate)(
        dayjs(dataCurrentStep?.startDate).format('YYYY-MM-DD') ?? '',
      );
      onChangeFormInputGeneralInformation(
        FormInputNameToSaveGeneralInformation.websiteStartSaleDate,
      )(dayjs(dataCurrentStep?.websiteStartSaleDate).format('YYYY-MM-DD') ?? '');
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.websiteEndSaleDate)(
        dayjs(dataCurrentStep?.websiteEndSaleDate).format('YYYY-MM-DD') ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.endDate)(
        dayjs(dataCurrentStep?.endDate).format('YYYY-MM-DD') ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.startTime)(
        dataCurrentStep?.startDate
          ? String(dataCurrentStep?.startDate).split('T')[1].slice(0, 5)
          : '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.endTime)(
        dataCurrentStep.endDate ? String(dataCurrentStep?.endDate).split('T')[1].slice(0, 5) : '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.eventCategory)(
        dataCurrentStep?.eventCategory?.id ?? '',
      );
      setContractors(dataCurrentStep.contractors);
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.censure)(
        String(dataCurrentStep?.censure) ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.facebookUrl)(
        dataCurrentStep?.facebookUrl ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.instagramUrl)(
        dataCurrentStep?.instagramUrl ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.showImageLogo)(
        String(dataCurrentStep.showImageLogo),
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.publishWebsite)(
        String(dataCurrentStep.publishWebsite),
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.sendTicketEmail)(
        String(dataCurrentStep.sendTicketEmail),
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.textSize)(
        String(dataCurrentStep?.textSize) ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.websiteDescription)(
        String(dataCurrentStep?.websiteDescription) ?? '',
      );
      onChangeFormInputGeneralInformation(
        FormInputNameToSaveGeneralInformation.imageDescriptionUrl,
      )(dataCurrentStep?.imageDescriptionUrl ?? '');
      onChangeFormInputGeneralInformation(
        FormInputNameToSaveGeneralInformation.discountDescription,
      )(String(dataCurrentStep?.discountDescription) ?? '');
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.pixel)(
        String(dataCurrentStep?.pixel) ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.imageBase64)(
        dataCurrentStep?.imageBase64 ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.imageDetailBase64)(
        dataCurrentStep?.imageDetailBase64 ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.imagePosBase64)(
        dataCurrentStep?.imagePosBase64 ?? '',
      );
      onChangeFormInputGeneralInformation(FormInputNameToSaveGeneralInformation.imageBluetooth)(
        dataCurrentStep?.imageBluetooth ?? '',
      );
      setFormNameFiles(filesValues => ({
        ...filesValues,
        imageBase64: dataCurrentStep?.imageBase64?.split('/').pop() as string,
        imageDetailBase64: dataCurrentStep?.imageDetailBase64?.split('/').pop() as string,
        imagePosBase64: dataCurrentStep?.imagePosBase64?.split('/').pop() as string,
        imageBluetooth: dataCurrentStep?.imageBluetooth?.split('/').pop() as string,
        imageDescriptionUrl: dataCurrentStep?.imageDescriptionUrl?.split('/').pop() as string,
      }));
      setFatherEvent(dataCurrentStep?.fatherEvent ?? '');
    }
  }, [dataCurrentStep]);

  useEffect(() => {
    if (category?.id) {
      onChangeFormInputCategory(FormInputNameToSaveCategory.name)(
        categoryList.find(item => item.id === category.id)?.name ?? '',
      );
    }
  }, [category]);

  return (
    <GeneralInformationContainer
      state={state}
      formGeneralInformation={controllerFormGeneralInformation}
      formCategory={controllerFormCategory}
      formFatherEvent={controllerFormFatherEvent}
      modalConfig={controllerModalConfig}
      GeneralInformationActions={controllerGeneralInformationActions}
      categoryStates={controllerCategoryState}
      categoryActions={controllerCategoryActions}
      fatherEventStates={controllerFatherEventState}
      fatherEventActions={controllerFatherEventActions}
      contractors={contractors}
      contractorState={controllerContractorState}
      contractorActions={controllerContractorActions}
      onAddContractor={handleOnAddContractor}
      onChangeContractor={handleOnChangeContractor}
      onChangeContractorAdministrator={handleOnChangeContractorAdministrator}
      onRemoveContractor={handleOnRemoveContractor}
      artists={artists}
      controllerArtistState={controllerArtistState}
      onAddArtist={handleOnAddArtist}
      onChangeArtist={handleOnChangeArtist}
      onRemoveArtist={handleOnRemoveArtist}
    />
  );
};
