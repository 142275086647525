import React from 'react';
import { Button } from '@/components';
import { StyledPie } from '@/components/StyledPie';
import { CustomTable } from '@/components/Table';
import { Card, Col, Row } from 'reactstrap';
import { toCurrency } from '@/helpers/masks/toCurrency';
import EventGeneralReport from '@/model/EventGeneralReport';
import { DataList } from '@/components/DataList';
import EventReportPayment from '@/model/EventReportPayment';
import EventGeneralSaleChannelReport from '@/model/EventGeneralSaleChannelReport';
import SuperCollapse from '@/components/sharedComponents/SuperCollapse';
import { TableFooter } from '../../TableFooter';

export interface GeneralSaleContentProps {
  generatePDF: () => void;
  onShowModal: () => void;
  payments: EventReportPayment;
  generalSale: EventGeneralReport | undefined;
}

export const GeneralSaleContent: React.FC<GeneralSaleContentProps> = ({
  payments,
  generalSale,
  generatePDF,
  onShowModal,
}) => {
  const total = payments
    ? payments.money +
      payments.pix +
      payments.debit +
      payments.credit +
      payments.installmentSaleTotal +
      payments.bankSlip
    : 0;
  const mountPipePayments = (): EventGeneralSaleChannelReport[] => {
    const list: EventGeneralSaleChannelReport[] = [];
    const money: EventGeneralSaleChannelReport = {
      name: 'Dinheiro',
      value: payments.money,
      count: 0,
      percent: (payments.money * 100) / total,
    };
    list.push(money);
    const pix: EventGeneralSaleChannelReport = {
      name: 'Pix',
      value: payments.pix,
      count: 0,
      percent: (payments.pix * 100) / total,
    };
    list.push(pix);
    const debit: EventGeneralSaleChannelReport = {
      name: 'Débito',
      value: payments.debit,
      count: 0,
      percent: (payments.debit * 100) / total,
    };
    list.push(debit);
    const credit: EventGeneralSaleChannelReport = {
      name: 'Crédito',
      value: payments.credit + payments.installmentSaleTotal,
      count: 0,
      percent: ((payments.credit + payments.installmentSaleTotal) * 100) / total,
    };
    list.push(credit);
    const bankSlip: EventGeneralSaleChannelReport = {
      name: 'Boleto',
      value: payments.bankSlip,
      count: 0,
      percent: (payments.bankSlip * 100) / total,
    };
    list.push(bankSlip);
    return list;
  };
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h5>Vendas gerais</h5>
        <Button theme="outlineDark" onClick={() => generatePDF()} title="Exportar relatório" />
      </div>
      <div>
        <div className="d-flex justify-content-between">
          <h6 className="mb-2">Vendas por tipo de pagamento</h6>
          <Button theme="noneBorder" onClick={() => onShowModal()} title="Ver detalhes" />
        </div>

        <div
          className="mb-4 customTableScroll"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            gridColumnGap: '40px',
            overflow: 'auto',
            alignItems: 'end',
          }}
        >
          <DataList
            data={
              payments
                ? [
                    {
                      title: `Dinheiro (${
                        payments && payments.money ? ((payments.money * 100) / total).toFixed(2) : 0
                      } %)`,
                      content: payments.money ? `${toCurrency(payments.money)}` : '-----',
                    },
                    {
                      title: `Pix (${
                        payments && payments.pix ? ((payments.pix * 100) / total).toFixed(2) : 0
                      } %)`,
                      content: payments.pix ? `${toCurrency(payments.pix)}` : '-----',
                    },
                    {
                      title: `Débito (${
                        payments && payments.debit ? ((payments.debit * 100) / total).toFixed(2) : 0
                      } %)`,
                      content: payments.debit ? `${toCurrency(payments.debit)}` : '-----',
                    },
                    {
                      title: `Crédito (${
                        payments && payments.credit
                          ? (
                              ((payments.credit + payments.installmentSaleTotal) * 100) /
                              total
                            ).toFixed(2)
                          : 0
                      } %)`,
                      content: payments
                        ? `${toCurrency(payments.credit + payments.installmentSaleTotal)}`
                        : '-----',
                    },
                    {
                      title: `Boleto (${
                        payments && payments.bankSlip
                          ? ((payments.bankSlip * 100) / total).toFixed(2)
                          : 0
                      } %)`,
                      content: payments.bankSlip ? `${toCurrency(payments.bankSlip)}` : '-----',
                    },
                  ]
                : []
            }
          />
        </div>
      </div>
      <Row>
        <Col md={6}>
          <h6 className="mb-4 mt-5">Canais de venda</h6>
          <StyledPie generalSale={generalSale?.salesChannel || []} type="currency" />
        </Col>
        <Col md={6}>
          <h6 className="mb-4 mt-5">Tipo de ingresso</h6>
          <StyledPie
            backgrundColor1="#3CAFC8"
            backgrundColor2="#D8413A"
            generalSale={generalSale?.ticketTypes || []}
            type="currency"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h6 className="mb-4 mt-5">Formas de pagamento</h6>
          <StyledPie generalSale={mountPipePayments()} type="currency" />
        </Col>
        <Col md={6}>
          <h6 className="mb-4 mt-5">Setores</h6>
          <StyledPie generalSale={generalSale?.sectionsPipe || []} type="currency" />
        </Col>
      </Row>
      <hr className="mb-5 mt-5" />
      <SuperCollapse
        className={
          generalSale && generalSale.geographicRanking
            ? ' '
            : 'collapse-disabled collapse-disable-text'
        }
        title="Ranking Geográfico (Top 10 Cidades)"
        content={
          <CustomTable
            theme="secondary"
            columns={[
              {
                name: 'Cidade',
                selector: row => row.city,
                minWidth: '33%',
              },
              {
                name: 'Estado',
                selector: row => row.state,
                minWidth: '33%',
              },
              {
                name: 'Quantidade',
                selector: row => row.amount,
                width: '200px',
              },
            ]}
            data={
              generalSale && generalSale.geographicRanking
                ? generalSale?.geographicRanking
                    ?.filter(item => item.state !== 'N/A')
                    .map(item => ({
                      city: item?.city || '-----',
                      state: item?.state || '-----',
                      amount: item?.amount || 0,
                    }))
                : []
            }
            numberRowsPerPage={10}
            progressPending={false}
          />
        }
      />
      <h6 className="mb-4"></h6>
      <Card
        className="mb-5 card-no-border customTableScroll"
        style={{ border: 'none', overflow: 'auto' }}
      ></Card>
      <hr className="mb-5 mt-5" />
      <>
        {generalSale &&
          generalSale.sections &&
          generalSale.sections.length > 0 &&
          generalSale.sections.map(section => (
            <>
              <h6 className="mb-4 collapseTableText">{section.section.name}</h6>
              <Card
                className="mb-5 card-no-border customTableScroll"
                style={{ border: 'none', overflow: 'auto' }}
              >
                <div className="p-4">
                  {section.tickets &&
                    section.tickets.length > 0 &&
                    section.tickets.map(ticket => (
                      <React.Fragment key={`${ticket.ticketId}-${ticket.halfPrice}`}>
                        <div className="collapseTableText subTitleMain">
                          <label>{ticket.ticketName}</label>
                        </div>

                        <CustomTable
                          theme="secondary"
                          numberRowsPerPage={0}
                          progressPending={false}
                          columns={[
                            {
                              name: 'Lote',
                              selector: row => row.name,
                            },
                            {
                              name: 'Ingressos vendidos',
                              selector: row => row.totalSale,
                            },
                            {
                              name: 'Ingressos emitidos',
                              selector: row => row.totalIssuede,
                            },
                            {
                              name: 'Ingressos disponíveis',
                              selector: row => row.totalAvailable,
                            },
                            {
                              name: 'Cortesias',
                              selector: row => row.amountCourtesy,
                              width: '100px',
                            },
                            {
                              name: 'Valor do ingresso',
                              selector: row => row.ticketValue,
                            },
                            {
                              name: 'Valor total',
                              selector: row => row.totalValue,
                            },
                          ]}
                          data={
                            (ticket.batchs &&
                              ticket.batchs &&
                              ticket.batchs.length > 0 &&
                              ticket.batchs.map(batch => ({
                                name: batch?.name || '-----',
                                totalSale: batch?.totalSale || 0,
                                totalIssuede: batch?.totalIssued || 0,
                                totalAvailable: batch?.totalAvailable || 0,
                                amountCourtesy: batch?.amountCourtesy || 0,
                                ticketValue: toCurrency(batch?.ticketValue || 0),
                                totalValue: toCurrency(batch?.totalValue || 0),
                              }))) ||
                            []
                          }
                        />
                      </React.Fragment>
                    ))}
                </div>
                <hr className="mb-0" style={{ minWidth: '1080px' }} />
                <div
                  style={{
                    padding: '30px 40px',
                    gap: '40px',
                  }}
                  className="d-flex justify-content-center"
                >
                  <>
                    <TableFooter data={section} />
                  </>
                </div>
              </Card>
            </>
          ))}
      </>
      <>
        {generalSale &&
          generalSale.events &&
          generalSale.events.length > 0 &&
          generalSale.events.map(section => (
            <>
              <h6 className="mb-4 collapseTableText">{section.section.name}</h6>
              <Card
                className="mb-5 card-no-border customTableScroll"
                style={{ border: 'none', overflow: 'auto' }}
              >
                <div className="p-4">
                  <CustomTable
                    theme="secondary"
                    numberRowsPerPage={0}
                    progressPending={false}
                    columns={[
                      {
                        name: 'Evento',
                        selector: row => row.name,
                      },
                      {
                        name: 'Ingressos vendidos',
                        selector: row => row.totalSale,
                      },
                      {
                        name: 'Cortesias',
                        selector: row => row.amountCourtesy,
                        width: '100px',
                      },
                      {
                        name: 'Valor total',
                        selector: row => row.totalValue,
                      },
                    ]}
                    data={
                      (section.events &&
                        section.events &&
                        section.events.length > 0 &&
                        section.events.map(event => ({
                          name: event.name || '-----',
                          totalSale: event.totalSale || 0,
                          amountCourtesy: event.amountCourtesy || 0,
                          totalValue: toCurrency(event.totalValue || 0),
                        }))) ||
                      []
                    }
                  />
                </div>
                <hr className="mb-0" style={{ minWidth: '1080px' }} />
                <div
                  style={{
                    padding: '30px 40px',
                    gap: '40px',
                  }}
                  className="d-flex justify-content-center"
                >
                  <>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Total vendidos:</div>
                      <div className="text-small-black-600">{section.totalSale}</div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Cortesias:</div>
                      <div className="text-small-black-600">{section.amountCourtesy}</div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Total arrecadado:</div>
                      <div className="text-small-black-600">{`${toCurrency(
                        section.totalRaised,
                      )}`}</div>
                    </div>
                  </>
                </div>
              </Card>
            </>
          ))}
      </>
    </div>
  );
};
