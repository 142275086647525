/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Loading } from '@/components';
import api, { AxiosError } from '@/services/api';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReportControllerProps } from '../../types';
import { BleedingContent } from './ui';

interface BleedingProps {
  reportController: ReportControllerProps;
  generatePDF: () => void;
}
// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export const Bleeding: React.FC<BleedingProps> = ({ reportController, generatePDF }) => {
  const [state, setState] = useState<States>(States.default);

  const { eventId, singleEvent, bleeding, setBleeding } = reportController;

  const handleGetBleeding = async (): Promise<void> => {
    if (bleeding === undefined) {
      try {
        setState(States.loading);
        const { data } = await api.get(
          `event/report/${eventId}/bleeding?singleEvent=${singleEvent}`,
        );
        if (data) {
          setBleeding(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    handleGetBleeding();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <BleedingContent bleeding={bleeding} generatePDF={generatePDF} />
    </>
  );
};
