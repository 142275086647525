/* eslint-disable import/no-unresolved */
import React, { Fragment } from 'react';
import { Loading } from '@/components';
import { Card, Container } from 'reactstrap';
import './style.scss';
import { Link, useParams, useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { colors } from '@/styles/colors';
import EventClose from '@/model/EventClose';
import dayjs from 'dayjs';
import { ReactComponent as LocationPin } from '@/assets/images/svg/locationPinNoFill.svg';
import { CustomTable } from '@/components/Table';
import { toCurrency } from '@/helpers/masks/toCurrency';
import SuperButton from '@/components/sharedComponents/SuperButton';

interface ReportTitle {
  title: string;
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

interface HomeContainerProps {
  state: States;
  eventState?: EventClose;
  closeEvent: () => void;
}

export const HomeContainer: React.FC<HomeContainerProps> = ({ state, eventState, closeEvent }) => {
  const history = useHistory();
  const { id: eventId } = useParams<{ id: string }>();

  const mountContractorText = (): string => {
    let s = 'Contratantes: ';
    if (
      eventState &&
      eventState.header &&
      eventState.header.contractors &&
      eventState.header.contractors.length > 0
    ) {
      eventState.header.contractors.forEach((data, index) => {
        if (index > 0) {
          s += ' / ';
        }
        s += data;
      });
    }
    return s;
  };

  const ReportCards = (): JSX.Element => {
    const newReports: ReportTitle[] = [{ title: 'Receitas' }, { title: 'Despesas' }];

    return (
      <React.Fragment>
        {newReports.map((report, index) => (
          <Card
            key={`Card-Report-${index}`}
            className="report-menu-card card-no-border"
            style={{
              backgroundColor: '#FFFFFF',
              color: '#000000',

              whiteSpace: 'normal',
              textAlign: 'center',
              maxWidth: '180px',
              padding: 'initial',
              minHeight: '100px',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (report.title === 'Receitas') {
                history.push(`/financial/event-close/income/${eventId}`);
              } else if (report.title === 'Despesas') {
                history.push(`/financial/event-close/expense/${eventId}`);
              }
            }}
          >
            {report.title}
          </Card>
        ))}
      </React.Fragment>
    );
  };

  const sumTotalCollected = (): number => {
    let n = 0;
    if (eventState && eventState.generalCollections && eventState.generalCollections.length > 0) {
      eventState.generalCollections.forEach(data => {
        n += data.collection;
      });
    }
    return n;
  };

  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Container className="mainContainer" fluid={true}>
        {eventState ? (
          <>
            <div className="d-flex justify-content-between">
              <div className="pageTitle d-flex" style={{ margin: 0 }}>
                <Link to={`${process.env.PUBLIC_URL}/dashboard/event`}>
                  <ArrowLeft color={colors.black} className="arrow-left" />
                </Link>
                <h5 className="ml-3 mb-0 mt-2 pageTitle">
                  {eventState.header.name} -{' '}
                  {eventState.header.startDate
                    ? dayjs(eventState.header.startDate).format('DD/MM/YYYY')
                    : 'Sem data'}
                </h5>
              </div>
            </div>
            <div className="d-flex">
              <LocationPin style={{ transform: 'scale(0.9)' }} color={colors.black} />
              <div
                className="text-darkgray-regular"
                style={{ marginLeft: '15px', marginTop: '-3px' }}
              >
                {eventState.header.city}
              </div>
            </div>

            <div className="d-flex">
              <div className="text-darkgray-regular">{mountContractorText()}</div>
            </div>
          </>
        ) : (
          <div className="pageTitle d-flex mb-5">
            <Link to={`${process.env.PUBLIC_URL}/dashboard/event`}>
              <ArrowLeft color={colors.black} className="arrow-left" />
            </Link>
            <h5 className="ml-3 mb-0 mt-2 pageTitle">Fechamento de evento</h5>
          </div>
        )}

        <div>
          <h6 className="mb-4 collapseTableText">Arrecadação geral do evento</h6>
          <Card
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <CustomTable
              theme="secondary"
              columns={[
                {
                  name: 'Data',
                  selector: row => row.date,
                  width: '15%',
                },
                {
                  name: 'Evento',
                  selector: row => row.name,
                  width: '50%',
                },
                {
                  name: 'Ingressos',
                  selector: row => row.tickets,
                  width: '15%',
                },
                {
                  name: 'Arrecadação',
                  selector: row => row.collection,
                  width: '20%',
                },
              ]}
              data={
                eventState &&
                eventState.generalCollections &&
                eventState.generalCollections.length > 0
                  ? eventState.generalCollections.map(item => ({
                      date: dayjs(item.date).format('DD/MM/YYYY') || '-----',
                      name: item.name || '-----',
                      tickets: item.tickets || 0,
                      collection: toCurrency(item.collection) || 0,
                    }))
                  : []
              }
              numberRowsPerPage={10}
              progressPending={false}
            />
            <hr className="mb-0" style={{ minWidth: '1080px' }} />
            <div
              style={{
                padding: '30px 40px',
                gap: '40px',
              }}
              className="d-flex justify-content-center"
            >
              <>
                <div className="d-flex">
                  <div className="mr-1 text-small-gray-regular">Total arrecadação:</div>
                  <div className="text-small-black-600">{toCurrency(sumTotalCollected())}</div>
                </div>
              </>
            </div>
          </Card>
        </div>

        <div>
          <h6 className="mb-4 collapseTableText">Receitas com cartão</h6>
          <Card
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <CustomTable
              theme="secondary"
              columns={[
                {
                  name: 'Descrição',
                  selector: row => row.description,
                  width: '90%',
                },
                {
                  name: 'Valor',
                  selector: row => row.value,
                  width: '10%',
                },
              ]}
              data={
                eventState && eventState.incomes && eventState.incomes.length > 0
                  ? eventState.incomes.map(item => ({
                      description: item.description || '-----',
                      value: toCurrency(item.value) || 0,
                    }))
                  : []
              }
              numberRowsPerPage={10}
              progressPending={false}
            />
            <hr className="mb-0" style={{ minWidth: '1080px' }} />
            <div
              style={{
                padding: '30px 40px',
                gap: '40px',
              }}
              className="d-flex justify-content-center"
            >
              <>
                <div className="d-flex">
                  <div className="mr-1 text-small-gray-regular">Total de receitas:</div>
                  <div className="text-small-black-600">
                    {toCurrency(eventState?.totalIncome || 0)}
                  </div>
                </div>
              </>
            </div>
          </Card>
        </div>

        <div>
          <h6 className="mb-4 collapseTableText">Despesas pagas pela Banca do Ingresso</h6>
          <Card
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <CustomTable
              theme="secondary"
              columns={[
                {
                  name: 'Descrição',
                  selector: row => row.description,
                  width: '90%',
                },
                {
                  name: 'Valor',
                  selector: row => row.value,
                  width: '10%',
                },
              ]}
              data={
                eventState && eventState.expenses && eventState.expenses.length > 0
                  ? eventState.expenses.map(item => ({
                      description: item.description || '-----',
                      value: toCurrency(item.value) || 0,
                    }))
                  : []
              }
              numberRowsPerPage={10}
              progressPending={false}
            />
            <hr className="mb-0" style={{ minWidth: '1080px' }} />
            <div
              style={{
                padding: '30px 40px',
                gap: '40px',
              }}
              className="d-flex justify-content-center"
            >
              <>
                <div className="d-flex">
                  <div className="mr-1 text-small-gray-regular">Total de despesas:</div>
                  <div className="text-small-black-600">
                    {toCurrency(eventState?.totalExpense || 0)}
                  </div>
                </div>
              </>
            </div>
          </Card>
        </div>

        <div>
          <h6 className="mb-4 collapseTableText">Acerto final</h6>
          <Card
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <CustomTable
              theme="secondary"
              columns={[
                {
                  name: 'Descrição',
                  selector: row => row.description,
                  width: '90%',
                },
                {
                  name: 'Valor',
                  selector: row => row.value,
                  width: '10%',
                },
              ]}
              data={[
                {
                  description: 'Total de receitas',
                  value:
                    eventState && eventState.totalIncome
                      ? toCurrency(eventState.totalIncome)
                      : toCurrency(0),
                },
                {
                  description: 'Resgates',
                  value:
                    eventState && eventState.totalTransfers
                      ? toCurrency(eventState.totalTransfers)
                      : toCurrency(0),
                },
                {
                  description: 'Total de despesas',

                  value:
                    eventState && eventState.totalExpense
                      ? toCurrency(eventState.totalExpense)
                      : toCurrency(0),
                },
              ]}
              numberRowsPerPage={10}
              progressPending={false}
            />
            <hr className="mb-0" style={{ minWidth: '1080px' }} />
            <div
              style={{
                padding: '30px 40px',
                gap: '40px',
              }}
              className="d-flex justify-content-center"
            >
              <>
                <div className="d-flex">
                  <div className="mr-1 text-small-gray-regular">Saldo:</div>
                  <div className="text-small-black-600">{toCurrency(eventState?.balance || 0)}</div>
                </div>
              </>
            </div>
          </Card>
        </div>

        <div>
          <h6 className="mb-4 collapseTableText">Maquininhas</h6>
          <Card
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <CustomTable
              theme="secondary"
              columns={[
                {
                  name: 'Máquina',
                  selector: row => row.serialNumber,
                },
                {
                  name: 'Valor',
                  selector: row => row.value,
                },
                {
                  name: 'Débito',
                  selector: row => row.debit,
                },
                {
                  name: 'Crédito',
                  selector: row => row.credit,
                },
                {
                  name: 'PIX',
                  selector: row => row.pix,
                },
                {
                  name: 'Total  Maquina ',
                  selector: row => row.total,
                },
              ]}
              data={
                eventState && eventState.machines && eventState.machines.length > 0
                  ? eventState.machines.map(item => ({
                      serialNumber: item.serialNumber || '-----',
                      value: toCurrency(item.value) || 0,
                      debit: toCurrency(item.debit) || 0,
                      credit: toCurrency(item.credit) || 0,
                      pix: toCurrency(item.pix) || 0,
                      total: toCurrency(item.value) || 0,
                    }))
                  : []
              }
              numberRowsPerPage={10}
              progressPending={false}
            />
          </Card>
        </div>

        <div>
          <h6 className="mb-4 collapseTableText">Totalizador Maquininhas</h6>
          <Card
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <CustomTable
              theme="secondary"
              columns={[
                {
                  name: 'Forma de Pagamento',
                  selector: row => row.paymentType,
                },
                {
                  name: 'Valor',
                  selector: row => row.value,
                },
                {
                  name: 'Taxas',
                  selector: row => row.fee,
                },
                {
                  name: 'Valor das taxas',
                  selector: row => row.feeValue,
                },
                {
                  name: 'Valor Líquido',
                  selector: row => row.netValue,
                },
              ]}
              data={
                eventState && eventState.machineSummary && eventState.machineSummary.length > 0
                  ? eventState.machineSummary.map(item => ({
                      paymentType: item.paymentType || '-----',
                      value: toCurrency(item.value) || 0,
                      fee: toCurrency(item.fee) || 0,
                      feeValue: toCurrency(item.feeValue) || 0,
                      netValue: toCurrency(item.netValue) || 0,
                    }))
                  : []
              }
              numberRowsPerPage={10}
              progressPending={false}
            />
          </Card>
        </div>

        <hr className="mt-5 mb-4" />
        <div className="mb-5 mt-5 report-menu-container">
          <ReportCards />
        </div>
        <div className="nextPageButton">
          <SuperButton style={{ width: '278px' }} onClick={closeEvent}>
            Fechar evento
          </SuperButton>
        </div>
      </Container>
    </Fragment>
  );
};
