import React from 'react';
import { Col, Row } from 'reactstrap';
import OrderSite from '@/model/OrderSite';
import dayjs from 'dayjs';
import { updateMask } from '@/helpers/masks/cpf';
import { CustomTable } from '@/components/Table';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { TicketDataRow, ticketColumns } from '../../screens/List/ui/table';

interface CancelRequestDetailContentProps {
  order?: OrderSite;
}

export const CancelRequestDetailContent: React.FC<CancelRequestDetailContentProps> = ({
  order,
}): JSX.Element => {
  const mountDataTable = (): TicketDataRow[] => {
    const list: TicketDataRow[] = [];
    if (order && order.events && order.events.length > 0) {
      order.events.forEach(event => {
        if (event.items && event.items.length > 0) {
          event.items.forEach(item => {
            const dataRow: TicketDataRow = {
              event: event.event.name,
              ticket: item.name,
              ticketDate: dayjs(event.event.startDate).format('DD/MM/YYYY [às] HH:mm'),
              ticketValue: toCurrency(item.unitValue),
              used: item.useDate
                ? dayjs(item.useDate.setHours(item.useDate.getHours() - 3)).format(
                    'DD/MM/YYYY [às] HH:mm',
                  )
                : 'Não',
            };
            list.push(dataRow);
          });
        }
      });
    }
    return list;
  };

  const countTicketRecords = (): number => {
    let count = 0;
    if (order && order.events && order.events.length > 0) {
      order.events.forEach(data => {
        if (data.items && data.items.length > 0) {
          data.items.forEach(_item => {
            count += 1;
          });
        }
      });
    }
    return count;
  };
  return (
    <>
      {order && (
        <div>
          <Row>
            <Col md={12}>
              <label className={'input-label m-0'}>Código do pedido</label>
            </Col>
          </Row>
          <Row>
            <Col md={12}>{order.id}</Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col md={12}>
              <label className={'input-label m-0'}>Nome do comprador</label>
            </Col>
          </Row>
          <Row>
            <Col md={12}>{order.client.name}</Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col md={12}>
              <label className={'input-label m-0'}>CPF do comprador</label>
            </Col>
          </Row>
          <Row>
            <Col md={12}>{updateMask(order.client.cpf)}</Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col md={12}>
              <label className={'input-label m-0'}>Data da compra</label>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {dayjs(order.orderDate).locale('pt-br').format('DD/MM/YYYY [às] HH:mm')}
            </Col>
          </Row>

          {order.cancelRequestDate && (
            <>
              <Row style={{ marginTop: '30px' }}>
                <Col md={12}>
                  <label className={'input-label m-0'}>Data solicitação de cancelamento</label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {dayjs(order.cancelRequestDate).locale('pt-br').format('DD/MM/YYYY [às] HH:mm')}
                </Col>
              </Row>
            </>
          )}

          {order.cancelReason && (
            <>
              <Row style={{ marginTop: '30px' }}>
                <Col md={12}>
                  <label className={'input-label m-0'}>Justificativa</label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <textarea
                    style={{
                      width: '100%',
                      border: 0,
                      resize: 'none',
                      overflowY: 'scroll',
                      height: '100px',
                    }}
                    disabled={true}
                  >
                    {order.cancelReason}
                  </textarea>
                </Col>
              </Row>
            </>
          )}

          <Row style={{ marginTop: '30px' }}>
            <Col md={12}>
              <label className={'input-label m-0'}>Ingressos</label>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <CustomTable
                columns={ticketColumns}
                data={mountDataTable()}
                numberRowsPerPage={countTicketRecords()}
                progressPending={false}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
