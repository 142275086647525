/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react';
import {
  SectorProductContainer,
  States,
  FormInputName,
} from '@/features/registerEvent/screens/SectorProduct/ui';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import EventGroupSubgroup from '@/model/EventGroupSubgroup';
import api from '@/services/api';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import Event from '@/model/Event';
import ProductGroup from '@/model/ProductGroup';
import ProductSectionEvent from '@/model/SectionProductEvent';
import EventPhaseCompletion from '@/model/EventPhaseCompletion';
import { formSectorProductProps } from './types';
import { useEvent } from '../../hook/useEvent';
import { controllerEventProps } from '../SectorTicket/types';
import { FormRadioInput } from '../../components/CloneProduct';

type UrlParams = {
  id: string;
};

export interface SectorProductProps {
  phaseCompletion: EventPhaseCompletion | undefined;
  handleGetEventPhaseCompletion(): Promise<void>;
}

export const SectorProductScreen: React.FC<SectorProductProps> = ({
  phaseCompletion,
  handleGetEventPhaseCompletion,
}): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [lastStep, setLastStep] = useState<any>([]);
  const [groupOptions, setGroupOptions] = useState<ProductGroup[]>([]);
  const [sectionList, setSectionList] = useState<ProductSectionEvent[]>([]);
  const [sectorConfig, setSectorConfig] = useState<any[]>([]);
  const [visibleModalProduct, setVisibleModalProduct] = useState<boolean>(false);
  const [eventChild, setEventChild] = useState<Event[]>([]);
  const { onToggle } = useDialog();

  const params = useParams<UrlParams>();
  const { eventState, onChange: onChangeEvent } = useEvent();

  const {
    formData: formDataSectorProduct,
    formErrors: formErrorsSectorProduct,
    isFormValid: isFormValidSectorProduct,
    onChangeFormInput: onChangeFormInputSectorProduct,
  } = useForm({
    initialData: {
      isProduct: '',
    },
    validators: {
      isProduct: [validators.required],
    },
    formatters: {},
  });

  const { formData: formDataCloneProduct, onChangeFormInput } = useForm({
    initialData: {
      eventClone: '',
    },
    validators: {
      eventClone: [validators.required],
    },
  });

  const getEventData = async (eventId: string): Promise<void> => {
    const { data } = await api.get<boolean>(`/event/section-product/${eventId}/has`);
    onChangeFormInputSectorProduct(FormInputName.isProduct)(String(data));
  };

  const handleGetFirstStep = async (id: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventGroupSubgroup[]>(`/event/section-product/${id}/group`);

      if (data || []) {
        if (data && data.length > 0) {
          onChangeFormInputSectorProduct('isProduct')('true');
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };
  const handleGetLastStep = async (id: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<any>(`/event/section-product/${id}/pos`);

      if (data || []) {
        if (data && data.length > 0) {
          setLastStep(data);
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleGetGroupList = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get(`event/section-product/${params.id}/group`);

      setGroupOptions(data ?? []);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleGetSectorConfigList = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get(`/event/section-product/${params.id}/product/section`);

      setSectorConfig(data ?? []);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleGetPosSectionList = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get(`/event/section-product/${params.id}/section`);

      setSectionList(data ?? []);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleHasProduct = async (b: string): Promise<void> => {
    try {
      setState(States.loading);
      await api.patch(`/event/section-product/${params.id}/has/${b}`);
      handleGetEventPhaseCompletion();
    } finally {
      setState(States.default);
    }
  };

  const controllerFormSectorProduct: formSectorProductProps = {
    formData: formDataSectorProduct,
    formErrors: formErrorsSectorProduct,
    onChangeFormInput: onChangeFormInputSectorProduct,
    isFormValidSectorProduct,
  };

  const controllerEvent: controllerEventProps = {
    eventState,
    onChangeEvent,
    lastStep,
    groupOptions,
    handleGetGroupList,
    sectionList,
    handleGetPosSectionList,
    sectorConfig,
    handleGetSectorConfigList,
  };

  const handListEvent = async (): Promise<void> => {
    try {
      setState(States.loading);
      setVisibleModalProduct(true);
      const { data } = await api.get<Event[]>('/event/find');
      setEventChild(data ?? []);
    } finally {
      setState(States.default);
    }
  };

  const handleFetchAll = async (): Promise<void> => {
    getEventData(params.id);
    handleGetFirstStep(params.id);
    handleGetLastStep(params.id);
    handleGetGroupList();
  };

  const handlOnCloneProducts = async (): Promise<void> => {
    try {
      setState(States.loading);
      onToggle();
      const productId = formDataCloneProduct[FormRadioInput.eventClone];
      const oldEventId = params.id;
      await api.post(`/event/copy-product/${productId}/${oldEventId}`);
      await handleFetchAll();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleCloseModal = (): void => {
    setVisibleModalProduct(false);
  };

  useEffect(() => {
    handleFetchAll();
  }, []);

  return (
    <SectorProductContainer
      cloneProducts={handListEvent}
      controllerEvent={controllerEvent}
      formSectorProduct={controllerFormSectorProduct}
      phaseCompletion={phaseCompletion}
      state={state}
      onHandleHasProduct={handleHasProduct}
      handleGetEventPhaseCompletion={handleGetEventPhaseCompletion}
      visibleModalProduct={visibleModalProduct}
      CloseModalProduct={handleCloseModal}
      onCloneProducts={handlOnCloneProducts}
      onToggle={onToggle}
      eventChild={eventChild}
      formCloneProduct={formDataCloneProduct}
      onChangeFormCloneProduct={onChangeFormInput}
    />
  );
};
