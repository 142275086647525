import React from 'react';
import { ReactComponent as Success } from '@/assets/images/svg/success.svg';
import { Button } from '@/components';

interface UrlContentProps {
  url: string;
  onCopyUrl: (url: string) => void;
}

export const UrlContent: React.FC<UrlContentProps> = ({ url, onCopyUrl }) => (
  <div className="filter-modal-content">
    <div className="exclude-container">
      <Success color="#E64F49" />
      <div className="header-title-text modal__title">
        <h5 className="modal__confirmation-title">Seu Pedido foi realizado com sucesso</h5>
      </div>
      <div className="modal__confirmation-text">
        Para concluir o pagamento, click sobre o <strong>link</strong> abaixo.
      </div>
      <div className="modal__confirmation-text">
        <a href="#" onClick={() => window.open(url, '_blank')}>
          {url}
        </a>
      </div>
      <div className="modal__confirmation-text" style={{ marginTop: '30px' }}>
        <Button title="Copiar Link" onClick={() => onCopyUrl(url)} />
      </div>
    </div>
  </div>
);
