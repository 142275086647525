import React from 'react';
import { Route } from '@/navigation/Route';
import { EmailScreen } from './screens';

export const EMAIL_ROUTES = {
  itself: '/dashboard/email',
};

export const EmailNavigation = (): JSX.Element => (
  <Route exact path={EMAIL_ROUTES.itself} component={EmailScreen} isPrivateRoute />
);
