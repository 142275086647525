import React from 'react';

const SubPdvIcon = (): JSX.Element => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.49 8.01082C8.52382 8.00947 9.54673 8.22254 10.494 8.6366V9.74552C8.55515 8.73801 6.24011 8.77019 4.33019 9.83151C2.42014 10.8928 1.1699 12.8416 1.00146 15.0203H0C0.128242 13.1205 0.972792 11.3401 2.36314 10.0388C3.7535 8.73779 5.58596 8.01291 7.49017 8.0109L7.49 8.01082ZM10.9948 3.50476C10.9948 4.43413 10.6255 5.32564 9.96816 5.98292C9.31087 6.6402 8.41954 7.00936 7.49 7.00936C6.56047 7.00936 5.66913 6.64022 5.01184 5.98292C4.35456 5.32564 3.98524 4.43413 3.98524 3.50476C3.98524 2.57523 4.35454 1.68372 5.01184 1.02644C5.66913 0.369325 6.56047 0 7.49 0C8.41919 0.00100584 9.31002 0.370642 9.967 1.02759C10.6241 1.68471 10.9936 2.57553 10.9948 3.50476ZM9.99348 3.50476H9.99331C9.99331 2.84074 9.72962 2.20406 9.26007 1.73453C8.79067 1.26513 8.15403 1.00129 7.49001 1.00129C6.82598 1.00129 6.1893 1.26514 5.71994 1.73453C5.25041 2.20406 4.9867 2.84074 4.9867 3.50476C4.9867 4.16861 5.25039 4.80547 5.71994 5.27483C6.18934 5.74436 6.82598 6.00807 7.49001 6.00807C8.15368 6.0074 8.79002 5.74337 9.25938 5.27401C9.72861 4.80478 9.99262 4.16844 9.99331 3.50476H9.99348ZM11.4955 15.0201H12.4968V9.51283H11.4953L11.4955 15.0201ZM9.49275 15.0201H10.494V10.7643H9.49275V15.0201ZM15 7.38469L13.9987 6.63368L12.9974 7.38469V8.63643L13.498 8.26092V15.02H14.4993V8.26092L15 8.63643V7.38469Z"
      fill="#222222"
    />
  </svg>
);

export default SubPdvIcon;
