import { CustomTable } from '@/components/Table';
import { toCurrency } from '@/helpers/masks/toCurrency';
import EventDetailReport from '@/model/EventDetailReport';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';

interface Props {
  eventDetail: EventDetailReport | undefined;
}

export const DetailContent: React.FC<Props> = ({ eventDetail }): JSX.Element => (
  <>
    <Row className="mb-4">
      <Col md={8}>
        <div className="text-darkgray-regular">Dinheiro</div>
      </Col>
      <Col md={4} style={{ textAlign: 'right' }}>
        <div className="text-darkgray-regular">
          {eventDetail && eventDetail.payments && eventDetail.payments.money
            ? toCurrency(eventDetail.payments.money)
            : toCurrency(0)}
        </div>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <div className="text-darkgray-regular">Pix</div>
      </Col>
      <Col md={4} style={{ textAlign: 'right' }}>
        <div className="text-darkgray-regular">
          {eventDetail && eventDetail.payments && eventDetail.payments.pix
            ? toCurrency(eventDetail.payments.pix)
            : toCurrency(0)}
        </div>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <div className="text-darkgray-regular">Débito</div>
      </Col>
      <Col md={4} style={{ textAlign: 'right' }}>
        <div className="text-darkgray-regular">
          {eventDetail && eventDetail.payments && eventDetail.payments.debit
            ? toCurrency(eventDetail.payments.debit)
            : toCurrency(0)}
        </div>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <div className="text-darkgray-regular">Crédito</div>
      </Col>
      <Col md={4} style={{ textAlign: 'right' }}>
        <div className="text-darkgray-regular">
          {eventDetail && eventDetail.payments
            ? toCurrency(eventDetail.payments.credit + eventDetail.payments.installmentSaleTotal)
            : toCurrency(0)}
        </div>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <div className="text-darkgray-regular">Boleto</div>
      </Col>
      <Col md={4} style={{ textAlign: 'right' }}>
        <div className="text-darkgray-regular">
          {eventDetail && eventDetail.payments && eventDetail.payments.bankSlip
            ? toCurrency(eventDetail.payments.bankSlip)
            : toCurrency(0)}
        </div>
      </Col>
    </Row>
    <hr className="mb-4" />
    <h6 className="mb-4">Detalhamento por evento</h6>
    <Row className="mb-4">
      <Col md={12}>
        <Card
          className="mb-5 card-no-border customTableScroll"
          style={{ border: 'none', overflow: 'auto' }}
        >
          <div className="p-4">
            <CustomTable
              theme="secondary"
              numberRowsPerPage={0}
              progressPending={false}
              columns={[
                {
                  name: 'Evento',
                  selector: row => row.eventName,
                },
                {
                  name: 'Dinheiro',
                  selector: row => row.money,
                },
                {
                  name: 'Pix',
                  selector: row => row.pix,
                },
                {
                  name: 'Débito',
                  selector: row => row.debit,
                },
                {
                  name: 'Crédito',
                  selector: row => row.credit,
                  width: '100px',
                },
                {
                  name: 'Boleto',
                  selector: row => row.bankSlip,
                },
                {
                  name: 'Faturamento',
                  selector: row => row.saleValue,
                },
              ]}
              data={
                eventDetail && eventDetail.events && eventDetail.events.length > 0
                  ? eventDetail.events.map(data => {
                      const obj = {
                        eventName: data.name,
                        money: toCurrency(data.payments.money),
                        pix: toCurrency(data.payments.pix),
                        debit: toCurrency(data.payments.debit),
                        credit: toCurrency(
                          data.payments.credit + data.payments.installmentSaleTotal,
                        ),
                        // installmentSale: toCurrency(500),
                        bankSlip: toCurrency(data.payments.bankSlip),
                        // site: toCurrency(500),
                        saleValue: toCurrency(data.saleValue),
                      };
                      return obj;
                    })
                  : []
              }
            />
          </div>
        </Card>
      </Col>
    </Row>
    <hr className="mb-4" />
    <h6 className="mb-4">Detalhamento por mês</h6>
    {eventDetail &&
      eventDetail.months &&
      eventDetail.months.length > 0 &&
      eventDetail.months.map(data => (
        <Row key={data.month} className="mb-4">
          <Col md={12}>
            <h6 className="mb-4 collapseTableText">{`${data.month} de ${data.year}`}</h6>
            <Card
              className="mb-5 card-no-border customTableScroll"
              style={{ border: 'none', overflow: 'auto' }}
            >
              <div className="p-4">
                <CustomTable
                  theme="secondary"
                  numberRowsPerPage={0}
                  progressPending={false}
                  columns={[
                    {
                      name: 'Evento',
                      selector: row => row.eventName,
                    },
                    {
                      name: 'Dinheiro',
                      selector: row => row.money,
                    },
                    {
                      name: 'Pix',
                      selector: row => row.pix,
                    },
                    {
                      name: 'Débito',
                      selector: row => row.debit,
                    },
                    {
                      name: 'Crédito',
                      selector: row => row.credit,
                      width: '100px',
                    },
                    {
                      name: 'Boleto',
                      selector: row => row.bankSlip,
                    },
                    {
                      name: 'Faturamento',
                      selector: row => row.saleValue,
                    },
                  ]}
                  data={
                    data.events && data.events.length > 0
                      ? data.events.map(event => {
                          const obj = {
                            eventName: event.name,
                            money: toCurrency(event.payments.money),
                            pix: toCurrency(event.payments.pix),
                            debit: toCurrency(event.payments.debit),
                            credit: toCurrency(
                              event.payments.credit + event.payments.installmentSaleTotal,
                            ),
                            bankSlip: toCurrency(event.payments.bankSlip),
                            saleValue: toCurrency(event.saleValue),
                          };
                          return obj;
                        })
                      : []
                  }
                />
              </div>
              <hr className="mb-0" />
              <div
                style={{
                  padding: '30px 40px',
                  gap: '40px',
                }}
                className="d-flex justify-content-center"
              >
                {data.summary && (
                  <div style={{ maxWidth: '100%', overflowY: 'scroll', display: 'flex' }}>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Faturamento:</div>
                      <div className="mr-1 text-small-black-600">
                        {toCurrency(data.summary.saleValue)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Pix</div>
                      <div className="mr-1 text-small-black-600">
                        {toCurrency(data.summary.pix)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Débito:</div>
                      <div className="mr-1 text-small-black-600">
                        {toCurrency(data.summary.debit)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Crédito:</div>
                      <div className="mr-1 text-small-black-600">
                        {toCurrency(data.summary.credit + data.summary.installmentSale)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Boleto:</div>
                      <div className="mr-1 text-small-black-600">
                        {toCurrency(data.summary.bankSlip)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Dinheiro:</div>
                      <div className="text-small-black-600">{toCurrency(data.summary.money)}</div>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      ))}
  </>
);
