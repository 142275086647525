import React, { Fragment } from 'react';
import { Container, FormGroup } from 'reactstrap';
import { States } from '@/helpers/common/states';
import { Button, ButtonGroup, InputText, Loading, SelectCustom } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { FormInputName } from '../../types';

interface StateProps {
  state: States;
  formData: FormData;
  formErrors: FormErrors;
}

interface DispatchProps {
  onChangeFormInput: OnChangeFormInput;
  onSave: () => void;
}

type Props = StateProps & DispatchProps;

export const CardFeeContainer: React.FC<Props> = ({
  state,
  formData,
  onChangeFormInput,
  formErrors,
  onSave,
}): JSX.Element => {
  const optionCount = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
  ];
  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Container className="mainContainer" fluid={true}>
        <div className="container-event mb-4">
          <h5 className="mb-2 border-bottom-title mb-5">Taxas dos Ingressos</h5>

          <p style={{ fontSize: '21px', fontWeight: '500' }}>Venda física</p>

          <FormGroup className="mb-2">
            <ButtonGroup
              label="Permitir venda com cartão?"
              name="ticketPhysicalSaleAllowCreditCardPayment"
              value={formData[FormInputName.ticketPhysicalSaleAllowCreditCardPayment]}
              onChange={e =>
                onChangeFormInput(FormInputName.ticketPhysicalSaleAllowCreditCardPayment)(
                  e.target.value,
                )
              }
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              error={
                formErrors.ticketPhysicalSaleAllowCreditCardPayment &&
                formErrors.ticketPhysicalSaleAllowCreditCardPayment[0]
              }
            />
          </FormGroup>

          <FormGroup>
            <InputText
              name="ticketPhysicalSaleDebit"
              label="Débito"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.ticketPhysicalSaleDebit]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.ticketPhysicalSaleDebit)(e.target.value)
              }
              error={formErrors.ticketPhysicalSaleDebit && formErrors.ticketPhysicalSaleDebit[0]}
            />
            <InputText
              name="ticketPhysicalSaleCredit"
              label="Crédito"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.ticketPhysicalSaleCredit]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.ticketPhysicalSaleCredit)(e.target.value)
              }
              error={formErrors.ticketPhysicalSaleCredit && formErrors.ticketPhysicalSaleCredit[0]}
            />
            <InputText
              name="ticketPhysicalSalePix"
              label="PIX"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.ticketPhysicalSalePix]}
              placeholder="Ex: 1"
              onChange={e => onChangeFormInput(FormInputName.ticketPhysicalSalePix)(e.target.value)}
              error={formErrors.ticketPhysicalSalePix && formErrors.ticketPhysicalSalePix[0]}
            />
            <InputText
              name="ticketPhysicalSaleAdministrateTax"
              label="Taxa administrativa"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.ticketPhysicalSaleAdministrateTax]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.ticketPhysicalSaleAdministrateTax)(e.target.value)
              }
              error={
                formErrors.ticketPhysicalSaleAdministrateTax &&
                formErrors.ticketPhysicalSaleAdministrateTax[0]
              }
            />
          </FormGroup>
          <div className="d-flex">
            <SelectCustom
              name="ticketPhysicalSaleInstallments"
              label="Qtd parcelas"
              placeholder="Ex: 2"
              className="w-input-sm"
              value={formData[FormInputName.ticketPhysicalSaleInstallments]}
              onChange={e =>
                onChangeFormInput(FormInputName.ticketPhysicalSaleInstallments)(e?.value as string)
              }
              error={
                formErrors.ticketPhysicalSaleInstallments &&
                formErrors.ticketPhysicalSaleInstallments[0]
              }
              options={optionCount}
            />
            <span className="mt-5 mr-3 ml-3 input-label"> + </span>
            <InputText
              name="ticketPhysicalSaleFee"
              label="Juros ao mês"
              className="w-input-sm"
              addon="%"
              placeholder="Ex: 4"
              maxLength={5}
              value={formData[FormInputName.ticketPhysicalSaleFee]}
              onChange={e => onChangeFormInput(FormInputName.ticketPhysicalSaleFee)(e.target.value)}
              error={formErrors.ticketPhysicalSaleFee && formErrors.ticketPhysicalSaleFee[0]}
            />
          </div>

          <p style={{ fontSize: '21px', fontWeight: '500' }}>Venda e-commerce</p>

          <FormGroup className="mb-2">
            <ButtonGroup
              label="Permitir venda com cartão?"
              name="ticketWebsiteSaleAllowCreditCardPayment"
              value={formData[FormInputName.ticketWebsiteSaleAllowCreditCardPayment]}
              onChange={e =>
                onChangeFormInput(FormInputName.ticketWebsiteSaleAllowCreditCardPayment)(
                  e.target.value,
                )
              }
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              error={
                formErrors.ticketWebsiteSaleAllowCreditCardPayment &&
                formErrors.ticketWebsiteSaleAllowCreditCardPayment[0]
              }
            />
          </FormGroup>
          <FormGroup>
            <InputText
              name="ticketWebsiteSaleBankSlip"
              label="Boleto"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.ticketWebsiteSaleBankSlip]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.ticketWebsiteSaleBankSlip)(e.target.value)
              }
              error={
                formErrors.ticketWebsiteSaleBankSlip && formErrors.ticketWebsiteSaleBankSlip[0]
              }
            />
            <InputText
              name="ticketWebsiteSaleCredit"
              label="Crédito"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.ticketWebsiteSaleCredit]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.ticketWebsiteSaleCredit)(e.target.value)
              }
              error={formErrors.ticketWebsiteSaleCredit && formErrors.ticketWebsiteSaleCredit[0]}
            />
            <InputText
              name="ticketWebsiteSalePix"
              label="PIX"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.ticketWebsiteSalePix]}
              placeholder="Ex: 1"
              onChange={e => onChangeFormInput(FormInputName.ticketWebsiteSalePix)(e.target.value)}
              error={formErrors.ticketWebsiteSalePix && formErrors.ticketWebsiteSalePix[0]}
            />
            <InputText
              name="ticketWebsiteSaleAdministrateTax"
              label="Taxa administrativa"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.ticketWebsiteSaleAdministrateTax]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.ticketWebsiteSaleAdministrateTax)(e.target.value)
              }
              error={
                formErrors.ticketWebsiteSaleAdministrateTax &&
                formErrors.ticketWebsiteSaleAdministrateTax[0]
              }
            />
          </FormGroup>
          <div className="d-flex">
            <SelectCustom
              name="ticketWebsiteSaleInstallments"
              label="Qtd parcelas"
              placeholder="Ex: 2"
              className="w-input-sm"
              value={formData[FormInputName.ticketWebsiteSaleInstallments]}
              onChange={e =>
                onChangeFormInput(FormInputName.ticketWebsiteSaleInstallments)(e?.value as string)
              }
              error={
                formErrors.ticketWebsiteSaleInstallments &&
                formErrors.ticketWebsiteSaleInstallments[0]
              }
              options={optionCount}
            />
            <span className="mt-5 mr-3 ml-3 input-label"> + </span>
            <InputText
              name="ticketWebsiteSaleFee"
              label="Juros ao mês"
              className="w-input-sm"
              addon="%"
              placeholder="Ex: 4"
              maxLength={5}
              value={formData[FormInputName.ticketWebsiteSaleFee]}
              onChange={e => onChangeFormInput(FormInputName.ticketWebsiteSaleFee)(e.target.value)}
              error={formErrors.ticketWebsiteSaleFee && formErrors.ticketWebsiteSaleFee[0]}
            />
          </div>
        </div>

        <div className="container-event mb-4">
          <h5 className="mb-2 border-bottom-title mb-5">Taxas dos Produtos</h5>

          <p style={{ fontSize: '21px', fontWeight: '500' }}>Venda física</p>

          <FormGroup className="mb-2">
            <ButtonGroup
              label="Permitir venda com cartão?"
              name="productPhysicalSaleAllowCreditCardPayment"
              value={formData[FormInputName.productPhysicalSaleAllowCreditCardPayment]}
              onChange={e =>
                onChangeFormInput(FormInputName.productPhysicalSaleAllowCreditCardPayment)(
                  e.target.value,
                )
              }
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              error={
                formErrors.productPhysicalSaleAllowCreditCardPayment &&
                formErrors.productPhysicalSaleAllowCreditCardPayment[0]
              }
            />
          </FormGroup>

          <FormGroup>
            <InputText
              name="productPhysicalSaleDebit"
              label="Débito"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.productPhysicalSaleDebit]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.productPhysicalSaleDebit)(e.target.value)
              }
              error={formErrors.productPhysicalSaleDebit && formErrors.productPhysicalSaleDebit[0]}
            />
            <InputText
              name="productPhysicalSaleCredit"
              label="Crédito"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.productPhysicalSaleCredit]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.productPhysicalSaleCredit)(e.target.value)
              }
              error={
                formErrors.productPhysicalSaleCredit && formErrors.productPhysicalSaleCredit[0]
              }
            />
            <InputText
              name="productPhysicalSalePix"
              label="PIX"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.productPhysicalSalePix]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.productPhysicalSalePix)(e.target.value)
              }
              error={formErrors.productPhysicalSalePix && formErrors.productPhysicalSalePix[0]}
            />
            <InputText
              name="productPhysicalSaleAdministrateTax"
              label="Taxa administrativa"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.productPhysicalSaleAdministrateTax]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.productPhysicalSaleAdministrateTax)(e.target.value)
              }
              error={
                formErrors.productPhysicalSaleAdministrateTax &&
                formErrors.productPhysicalSaleAdministrateTax[0]
              }
            />
          </FormGroup>
          <div className="d-flex">
            <SelectCustom
              name="productPhysicalSaleInstallments"
              label="Qtd parcelas"
              placeholder="Ex: 2"
              className="w-input-sm"
              value={formData[FormInputName.productPhysicalSaleInstallments]}
              onChange={e =>
                onChangeFormInput(FormInputName.productPhysicalSaleInstallments)(e?.value as string)
              }
              error={
                formErrors.productPhysicalSaleInstallments &&
                formErrors.productPhysicalSaleInstallments[0]
              }
              options={optionCount}
            />
            <span className="mt-5 mr-3 ml-3 input-label"> + </span>
            <InputText
              name="productPhysicalSaleFee"
              label="Juros ao mês"
              className="w-input-sm"
              addon="%"
              placeholder="Ex: 4"
              maxLength={5}
              value={formData[FormInputName.productPhysicalSaleFee]}
              onChange={e =>
                onChangeFormInput(FormInputName.productPhysicalSaleFee)(e.target.value)
              }
              error={formErrors.productPhysicalSaleFee && formErrors.productPhysicalSaleFee[0]}
            />
          </div>

          <p style={{ fontSize: '21px', fontWeight: '500' }}>Venda e-commerce</p>

          <FormGroup className="mb-2">
            <ButtonGroup
              label="Permitir venda com cartão?"
              name="productWebsiteSaleAllowCreditCardPayment"
              value={formData[FormInputName.productWebsiteSaleAllowCreditCardPayment]}
              onChange={e =>
                onChangeFormInput(FormInputName.productWebsiteSaleAllowCreditCardPayment)(
                  e.target.value,
                )
              }
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              error={
                formErrors.productWebsiteSaleAllowCreditCardPayment &&
                formErrors.productWebsiteSaleAllowCreditCardPayment[0]
              }
            />
          </FormGroup>
          <FormGroup>
            <InputText
              name="productWebsiteSaleBankSlip"
              label="Boleto"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.productWebsiteSaleBankSlip]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.productWebsiteSaleBankSlip)(e.target.value)
              }
              error={
                formErrors.productWebsiteSaleBankSlip && formErrors.productWebsiteSaleBankSlip[0]
              }
            />
            <InputText
              name="productWebsiteSaleCredit"
              label="Crédito"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.productWebsiteSaleCredit]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.productWebsiteSaleCredit)(e.target.value)
              }
              error={formErrors.productWebsiteSaleCredit && formErrors.productWebsiteSaleCredit[0]}
            />
            <InputText
              name="productWebsiteSalePix"
              label="PIX"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.productWebsiteSalePix]}
              placeholder="Ex: 1"
              onChange={e => onChangeFormInput(FormInputName.productWebsiteSalePix)(e.target.value)}
              error={formErrors.productWebsiteSalePix && formErrors.productWebsiteSalePix[0]}
            />
            <InputText
              name="productWebsiteSaleAdministrateTax"
              label="Taxa administrativa"
              className="w-input-sm"
              addon="%"
              maxLength={5}
              value={formData[FormInputName.productWebsiteSaleAdministrateTax]}
              placeholder="Ex: 1"
              onChange={e =>
                onChangeFormInput(FormInputName.productWebsiteSaleAdministrateTax)(e.target.value)
              }
              error={
                formErrors.productWebsiteSaleAdministrateTax &&
                formErrors.productWebsiteSaleAdministrateTax[0]
              }
            />
          </FormGroup>
          <div className="d-flex">
            <SelectCustom
              name="productWebsiteSaleInstallments"
              label="Qtd parcelas"
              placeholder="Ex: 2"
              className="w-input-sm"
              value={formData[FormInputName.productWebsiteSaleInstallments]}
              onChange={e =>
                onChangeFormInput(FormInputName.productWebsiteSaleInstallments)(e?.value as string)
              }
              error={
                formErrors.productWebsiteSaleInstallments &&
                formErrors.productWebsiteSaleInstallments[0]
              }
              options={optionCount}
            />
            <span className="mt-5 mr-3 ml-3 input-label"> + </span>
            <InputText
              name="productWebsiteSaleFee"
              label="Juros ao mês"
              className="w-input-sm"
              addon="%"
              placeholder="Ex: 4"
              maxLength={5}
              value={formData[FormInputName.productWebsiteSaleFee]}
              onChange={e => onChangeFormInput(FormInputName.productWebsiteSaleFee)(e.target.value)}
              error={formErrors.productWebsiteSaleFee && formErrors.productWebsiteSaleFee[0]}
            />
          </div>
        </div>
        <hr className="mt-5" />
        <div className="footer-register-event">
          <Button title="Salvar" onClick={() => onSave()} />
        </div>
      </Container>
    </Fragment>
  );
};
