import { Button, Dialog, Loading } from '@/components';
import React, { Fragment, ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { colors } from '@/styles/colors';
import { CustomTable } from '@/components/Table';
import { ProducerReport } from '@/model/ProducerReport';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { toPercentage } from '@/helpers/common/amount';
import ProducerReportType from '@/model/ProducerReportType';
import { ActionProps } from '@/components/Dialog';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import Contractor from '@/model/Contractor';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import dayjs from 'dayjs';
import {
  expenseColumns,
  incomeColumns,
  ShouldShowModal,
  States,
  transferColumns,
} from '../../types';
import { TransferContent } from '../../components/TransferContent';

interface StateProps {
  state: States;
  producerReport?: ProducerReport;
  transfers: EventMoneyTransfer[];
  title: string | ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  formData: FormData;
  formErrors: FormErrors;
  contractors: Contractor[];
}

interface DispatchProps {
  onToggle: () => void;
  onChangeFormInput: OnChangeFormInput;
  onTransfer: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
  }) => void;
}

type Props = StateProps & DispatchProps;

export const ProducerReportContainer: React.FC<Props> = ({
  state,
  producerReport,
  transfers,
  title,
  visible,
  onToggle,
  shouldShowModal,
  onTransfer,
  formData,
  formErrors,
  contractors,
  onChangeFormInput,
  onShouldShowModal,
}): JSX.Element => {
  const renderActionDialogToCancel: ActionProps = {
    title: 'Cancelar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };
  const getType = (type: ProducerReportType): string => {
    if (type === ProducerReportType.CREDIT) {
      return 'Receita';
    }
    return 'Despesa';
  };
  const dataTableTransfers =
    transfers && transfers.length > 0
      ? transfers.map(item => ({
          contractor: item.contractor.name,
          bank: item.pixKey.bank.fullName,
          pixKey: item.pixKey.key,
          contractorUser: item.contractorUser.name,
          transferValue: toCurrency(item.transferValue),
          transferRequestDate: dayjs(item.transferRequestDate).format('DD/MM/YYYY HH:mm:ss'),
          transferred: item.transferred ? 'Transferindo' : 'Aguardando',
          transferDate: item.transferDate
            ? dayjs(item.transferDate).format('DD/MM/YYYY HH:mm:ss')
            : '---',
        }))
      : [];
  const dataTableIncomes =
    producerReport && producerReport.incomes && producerReport.incomes.length > 0
      ? producerReport.incomes.map(item => ({
          description: item.description,
          type: getType(item.type),
          baseValue: (
            <label
              style={{ color: item.type === ProducerReportType.DEBIT ? '#e64f49' : '#3caec8' }}
            >
              {toCurrency(item.baseValue)}
            </label>
          ),
        }))
      : [];

  const dataTableExpenses =
    producerReport && producerReport.expenses && producerReport.expenses.length > 0
      ? producerReport.expenses.map(item => ({
          description: item.description,
          type: getType(item.type),
          baseValue:
            item.description === 'Boleto' || item.description === 'Aluguel equipamento'
              ? // eslint-disable-next-line radix
                parseInt(`${item.baseValue}`)
              : toCurrency(item.baseValue),
          // eslint-disable-next-line no-nested-ternary
          fee: item.fee
            ? item.description === 'Boleto' || item.description === 'Aluguel equipamento'
              ? toCurrency(item.fee)
              : `${toPercentage(item.fee)} %`
            : 'N/A',
          value: (
            <label
              style={{ color: item.type === ProducerReportType.DEBIT ? '#e64f49' : '#3caec8' }}
            >
              {toCurrency(item.value)}
            </label>
          ),
        }))
      : [];
  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={'center'}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.transfer]: renderActionDialogToCancel,
          }[shouldShowModal],
          {
            [ShouldShowModal.transfer]: {
              title: 'Solicitar transferência',
              onClick: () => onTransfer(),
              disabled: Object.keys(formErrors).length === 0 && formErrors.constructor === Object,
            },
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.transfer]: (
              <TransferContent
                formData={formData}
                formErrors={formErrors}
                contractors={contractors}
                onChangeFormInput={onChangeFormInput}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between mb-5">
          <div className="pageTitle d-flex">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <ArrowLeft color={colors.black} className="arrow-left" />
            </Link>
            <span className="ml-3 mb-0 mt-2">Lançamentos</span>
          </div>
          <Button
            title="Resgatar valor"
            onClick={(): void =>
              onShouldShowModal({
                value: ShouldShowModal.transfer,
                newTitleModal: 'Resgatar valor',
              })
            }
          />
        </div>
        <h5>Totalizadores</h5>
        {producerReport && producerReport.summary && (
          <Fragment>
            <Row className="mb-4">
              <Col md={2}>
                <div className="text-darkgray-regular">Valor total arrecadado: </div>
              </Col>
              <Col md={2} style={{ textAlign: 'right' }}>
                <div className="text-primary">{toCurrency(producerReport.summary.totalSale)}</div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={2}>
                <div className="text-darkgray-regular">Dinheiro: </div>
              </Col>
              <Col md={2} style={{ textAlign: 'right' }}>
                <div className="text-danger">{toCurrency(producerReport.summary.money)}</div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={2}>
                <div className="text-darkgray-regular">Vouchers: </div>
              </Col>
              <Col md={2} style={{ textAlign: 'right' }}>
                <div className="text-danger">{toCurrency(producerReport.summary.vouchers)}</div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={2}>
                <div className="text-darkgray-regular">Descontos: </div>
              </Col>
              <Col md={2} style={{ textAlign: 'right' }}>
                <div className="text-danger">{toCurrency(producerReport.summary.discounts)}</div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={2}>
                <div className="text-darkgray-regular">Repasses: </div>
              </Col>
              <Col md={2} style={{ textAlign: 'right' }}>
                <div className="text-danger">{toCurrency(producerReport.summary.transfers)}</div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={2}>
                <div className="text-darkgray-regular">Saldo: </div>
              </Col>
              <Col md={2} style={{ textAlign: 'right' }}>
                <div
                  className={producerReport.summary.balance > 0 ? 'text-primary' : 'text-danger'}
                >
                  {toCurrency(producerReport.summary.balance)}
                </div>
              </Col>
            </Row>
          </Fragment>
        )}

        {transfers && transfers.length > 0 && (
          <Fragment>
            <h5>Resgates</h5>
            <CustomTable
              columns={transferColumns}
              data={dataTableTransfers}
              numberRowsPerPage={1000}
              progressPending={state === States.loading}
              theme="primary"
            />
          </Fragment>
        )}

        <h5>Receitas</h5>
        <CustomTable
          columns={incomeColumns}
          data={dataTableIncomes}
          numberRowsPerPage={1000}
          progressPending={state === States.loading}
          theme="primary"
        />

        <h5>Despesas</h5>
        <CustomTable
          columns={expenseColumns}
          data={dataTableExpenses}
          numberRowsPerPage={1000}
          progressPending={state === States.loading}
          theme="primary"
        />
      </Container>
    </Fragment>
  );
};
