import { toCurrency } from '@/helpers/masks/toCurrency';
import React from 'react';

export interface TableFooterProps {
  data: any;
}

export const TableFooter: React.FC<TableFooterProps> = ({ data }: any) => (
  <>
    <div className="d-flex">
      <div className="mr-1 text-small-gray-regular">Total vendidos:</div>
      <div className="text-small-black-600">{data?.totalSale}</div>
    </div>
    <div className="d-flex">
      <div className="mr-1 text-small-gray-regular">Total emitidos:</div>
      <div className="text-small-black-600">{data?.totalIssued}</div>
    </div>
    <div className="d-flex">
      <div className="mr-1 text-small-gray-regular">Total disponíveis:</div>
      <div className="text-small-black-600">{data.totalAvailable}</div>
    </div>
    <div className="d-flex">
      <div className="mr-1 text-small-gray-regular">Cortesias:</div>
      <div className="text-small-black-600">{data.amountCourtesy}</div>
    </div>
    <div className="d-flex">
      <div className="mr-1 text-small-gray-regular">Total arrecadado:</div>
      <div className="text-small-black-600">{`${toCurrency(data?.totalRaised)}`}</div>
    </div>
  </>
);
