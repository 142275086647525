import { States } from '@/helpers/common/states';
import validators from '@/helpers/validators';
import Event from '@/model/Event';
import User from '@/model/User';
import api from '@/services/api';
import React, { useEffect, useState } from 'react';
import useForm from '@/hooks/useForm';
import { useParams } from 'react-router-dom';
import UserType from '@/model/UserType';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import Entrance from '@/model/Entrance';
import { FormInput, FormInputEntrance, FormInputEventEntrance, ShouldShowModal } from '../types';
import { ValidatorAndEntrance } from './ui/ValidatorAndEntrance';

export const EventValidatorScreen: React.FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<States>(States.default);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.entrance);
  const [event, setEvent] = useState<Event>();
  const [userValidators, setValidators] = useState<User[]>([]);
  const [eventEntrances, setEventEntrances] = useState<Entrance[]>([]);
  const [entrances, setEntrances] = useState<Entrance[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const { visible, title, onChangeTitle, onToggle } = useDialog();

  const getSimpleEvent = async (eventId: string): Promise<void> => {
    try {
      const { data } = await api.get<Event>(`/event/${eventId}/simple`);
      setEvent(data);
    } catch (error) {
      window.console.error(error);
    } finally {
      setState(States.default);
    }
  };

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm } = useForm({
    initialData: {
      userId: '',
      administrator: '',
    },
    validators: {
      userId: [validators.required],
    },
  });

  const {
    formData: formDataEventEntrance,
    formErrors: formErrorsEventEntrance,
    onChangeFormInput: onChangeFormInputEventEntrance,
    isFormValid: isFormValidEventEntrance,
    resetForm: resetFormEventEntrance,
  } = useForm({
    initialData: {
      entranceId: '',
    },
    validators: {
      entranceId: [validators.required],
    },
  });

  const {
    formData: formDataEntrance,
    formErrors: formErrorsEntrance,
    onChangeFormInput: onChangeFormInputEntrance,
    isFormValid: isFormValidEntrance,
    resetForm: resetFormEntrance,
  } = useForm({
    initialData: {
      id: '',
      name: '',
    },
    validators: {
      name: [validators.required],
    },
  });

  const getValidator = async (eventId: string): Promise<void> => {
    try {
      setState(States.loading);
      const responseValidators = await api.get<User[]>(`/event/${eventId}/validator`);
      setValidators(responseValidators.data);
      const responseUsers = await api.get<User[]>('/user/find');
      const list: User[] = [];
      if (responseUsers.data && responseUsers.data.length > 0) {
        responseUsers.data.forEach(data => {
          if (data.types && data.types.length > 0) {
            let isValidator = false;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < data.types.length; i++) {
              if (data.types[i] === UserType.VALIDATOR) {
                isValidator = true;
                break;
              }
            }
            if (isValidator) {
              let found = false;
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < responseValidators.data.length; i++) {
                if (data.id === responseValidators.data[i].id) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                list.push(data);
              }
            }
          }
        });
      }
      setUsers(list);
    } catch (error) {
      window.console.error(error);
    } finally {
      setState(States.default);
    }
  };

  const getEntraces = async (eventId: string): Promise<void> => {
    try {
      setState(States.loading);
      const responseEventEntrances = await api.get<Entrance[]>(`/event/${eventId}/entrance`);
      setEventEntrances(responseEventEntrances.data);
      const responseEntrances = await api.get<Entrance[]>('/entrance/find');
      const list: Entrance[] = [];
      if (responseEntrances.data && responseEntrances.data.length > 0) {
        responseEntrances.data.forEach(data => {
          let found = false;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < responseEventEntrances.data.length; i++) {
            if (data.id === responseEventEntrances.data[i].id) {
              found = true;
              break;
            }
          }
          if (!found) {
            list.push(data);
          }
        });
      }
      setEntrances(list);
    } catch (error) {
      window.console.error(error);
    } finally {
      setState(States.default);
    }
  };

  const getEntrace = async (entranceId: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Entrance>(`/entrance/${entranceId}`);
      onChangeFormInputEntrance(FormInputEntrance.id)(data.id);
      onChangeFormInputEntrance(FormInputEntrance.name)(data.name);
    } catch (error) {
      window.console.error(error);
    } finally {
      setState(States.default);
    }
  };

  const handleOnOpenModal = async (
    value: ShouldShowModal,
    modalTitle: string,
    toggle: boolean,
    selectedEntrance?: Entrance,
  ): Promise<void> => {
    resetFormEntrance();
    onChangeTitle(modalTitle);
    setShouldShowModal(value);
    if (selectedEntrance) {
      await getEntrace(selectedEntrance.id);
    }
    if (toggle) {
      onToggle();
    }
  };

  const handleAddValidator = async (): Promise<void> => {
    if (isFormValid()) {
      const list: User[] = [];
      const dto = {
        id: formData[FormInput.userId],
      } as User;
      list.push(dto);
      try {
        setState(States.loading);
        await api.post(`/event/${id}/validator`, dto);
        resetForm();
        toast.success('Validador inserido com sucesso!');
        await getValidator(id);
        onToggle();
      } catch (error) {
        window.console.error(error);
      } finally {
        setState(States.default);
      }
    }
  };

  const handleRemoveValidator = async (validatorId: string): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(`/event/${id}/validator/${validatorId}`);
      getValidator(id);
    } catch (error) {
      window.console.error(error);
    } finally {
      toast.success('Validador removido com sucesso!');
      setState(States.default);
    }
  };

  const handleAddEntrance = async (): Promise<void> => {
    if (isFormValidEventEntrance()) {
      const dto = {
        id: formDataEventEntrance[FormInputEventEntrance.entranceId],
      } as Entrance;
      try {
        setState(States.loading);
        await api.post(`/event/${id}/entrance`, dto);
        resetFormEventEntrance();
        getEntraces(id);
        toast.success('Portaria inserida com sucesso!');
        onToggle();
      } catch (error) {
        window.console.error(error);
      } finally {
        setState(States.default);
      }
    }
  };

  const handleRemoveEntrance = async (entranceId: string): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(`/event/${id}/entrance/${entranceId}`);
      getEntraces(id);
    } catch (error) {
      window.console.error(error);
    } finally {
      toast.success('Validador removido com sucesso!');
      setState(States.default);
    }
  };

  const handleOnSaveEntrance = async (): Promise<void> => {
    if (isFormValidEntrance()) {
      const dto = {
        name: formDataEntrance[FormInputEntrance.name],
      } as Entrance;
      if (formDataEntrance[FormInputEntrance.id]) {
        dto.id = formDataEntrance[FormInputEntrance.id];
      }
      try {
        setState(States.loading);
        await api.post('/entrance', dto);
        resetFormEntrance();
        await getEntraces(id);
        if (formDataEntrance[FormInputEntrance.id]) {
          toast.success('Portaria atualizada com sucesso!');
        } else {
          toast.success('Portaria inserido com sucesso!');
        }
        handleOnOpenModal(ShouldShowModal.eventEntrance, 'Cadastrar portaria', false);
      } catch (error) {
        window.console.error(error);
      } finally {
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    getSimpleEvent(id);
    getValidator(id);
    getEntraces(id);
  }, []);
  return (
    <ValidatorAndEntrance
      state={state}
      modalTitle={title}
      visible={visible}
      shouldShowModal={shouldShowModal}
      onToggle={onToggle}
      openModal={handleOnOpenModal}
      event={event}
      validators={userValidators}
      eventEntrances={eventEntrances}
      formDataValidator={formData}
      formErrorsValidator={formErrors}
      onChangeValidator={onChangeFormInput}
      users={users}
      formDataEventEntrance={formDataEventEntrance}
      formErrorsEventEntrance={formErrorsEventEntrance}
      onChangeEventEntrance={onChangeFormInputEventEntrance}
      entrances={entrances}
      formDataEntrance={formDataEntrance}
      formErrorsEntrance={formErrorsEntrance}
      onChangeEntrance={onChangeFormInputEntrance}
      addValidator={handleAddValidator}
      removeValidator={handleRemoveValidator}
      addEntrance={handleAddEntrance}
      removeEntrance={handleRemoveEntrance}
      onSaveEntrance={handleOnSaveEntrance}
    />
  );
};
