import React from 'react';
import { ReactComponent as Success } from '@/assets/images/svg/success.svg';
import { Button } from '@/components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export const RegisterFinished: React.FC = (): JSX.Element => {
  const history = useHistory();

  return (
    <div>
      <div className="d-flex justify-content-center mb-5">
        <Success />
      </div>
      <div className="pl-5 pr-5 pb-5">
        <h5 className="text-bold-700 text-align-center">Cadastro de evento finalizado</h5>
        <div className="text-black-regular-large text-align-center">
          Você finalizou o cadastro do evento. Aguarde através do administrador do sistema a
          liberação da publicação do evento.
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          theme="dark"
          size="xl"
          onClick={() => {
            history.push('/dashboard/event/');
            toast.success('Cadastro de evento finalizado');
          }}
          title="Acompanhar liberação"
        />
      </div>
    </div>
  );
};
