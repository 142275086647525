import React from 'react';
import User from '@/model/User';
import { ReactComponent as CloseX } from '@/assets/images/svg/closeX.svg';
import { CustomTable } from '@/components/Table';
import { updateMask } from '@/helpers/masks/cpf';

interface StateProps {
  validators: User[];
}
interface DispatchProps {
  removeValidator(validatorId: string): void;
}

type Props = StateProps & DispatchProps;

export const ValidatorList: React.FC<Props> = ({ validators, removeValidator }): JSX.Element => {
  const dataTableUser = validators.map(item => ({
    id: item.id,
    name: item.name,
    login: item.cpf,
    actions: (
      <CloseX
        className="mr-2 svg-icon action-icon"
        onClick={() => {
          removeValidator(item.id);
        }}
      />
    ),
  }));

  return (
    <React.Fragment>
      {validators.length > 0 ? (
        <CustomTable
          columns={[
            {
              name: 'Nome do usuário',
              selector: row => row.name,
              width: '50%',
            },
            {
              name: 'Login',
              selector: row => updateMask(row.login),
              width: '40%',
            },
            {
              name: '',
              selector: row => row.actions,
            },
          ]}
          data={dataTableUser}
          progressPending={false}
          numberRowsPerPage={dataTableUser.length}
        />
      ) : (
        <>
          <div style={{ padding: '10px 0 20px 0', color: '#A5A5A5' }}>
            Você ainda não inseriu nenhum validador neste Evento.
          </div>
          <div style={{ color: '#A5A5A5', paddingBottom: '30px' }}>
            Aqui será exibida uma lista dos usuários validadores inseridos neste Evento.
          </div>
        </>
      )}
    </React.Fragment>
  );
};
