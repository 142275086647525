import React from 'react';

const percentIcon = (): JSX.Element => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6119 4.38931C10.5115 4.28887 10.3751 4.23242 10.2332 4.23242C10.0911 4.23242 9.95483 4.28887 9.85438 4.38931L4.39005 9.85364C4.28673 9.95349 4.22778 10.0906 4.22658 10.2344C4.22527 10.378 4.28183 10.5162 4.38347 10.6178C4.485 10.7194 4.62323 10.776 4.76683 10.7746C4.91057 10.7735 5.04773 10.7145 5.14759 10.6112L10.6119 5.14685C10.7124 5.04641 10.7688 4.91008 10.7688 4.76802C10.7688 4.62608 10.7124 4.48976 10.6119 4.38932L10.6119 4.38931Z"
      fill="currentColor"
    />
    <path
      d="M6.96431 5.35716C6.96431 4.93085 6.79499 4.52214 6.49364 4.22067C6.19218 3.91932 5.78347 3.75 5.35716 3.75C4.93085 3.75 4.52214 3.91932 4.22067 4.22067C3.91932 4.52213 3.75 4.93085 3.75 5.35716C3.75 5.78347 3.91932 6.19217 4.22067 6.49364C4.52213 6.79499 4.93085 6.96431 5.35716 6.96431C5.78347 6.96431 6.19217 6.79499 6.49364 6.49364C6.79499 6.19218 6.96431 5.78347 6.96431 5.35716ZM4.82144 5.35716C4.82144 5.2151 4.87788 5.07877 4.97833 4.97833C5.07877 4.87788 5.2151 4.82144 5.35716 4.82144C5.49922 4.82144 5.63554 4.87788 5.73599 4.97833C5.83643 5.07877 5.89287 5.2151 5.89287 5.35716C5.89287 5.49922 5.83643 5.63554 5.73599 5.73599C5.63554 5.83643 5.49922 5.89287 5.35716 5.89287C5.2151 5.89287 5.07877 5.83643 4.97833 5.73599C4.87788 5.63554 4.82144 5.49922 4.82144 5.35716Z"
      fill="currentColor"
    />
    <path
      d="M9.64231 8.03516C9.216 8.03516 8.8073 8.20448 8.50582 8.50582C8.20448 8.80729 8.03516 9.216 8.03516 9.64231C8.03516 10.0686 8.20448 10.4773 8.50582 10.7788C8.80729 11.0801 9.216 11.2495 9.64231 11.2495C10.0686 11.2495 10.4773 11.0801 10.7788 10.7788C11.0801 10.4773 11.2495 10.0686 11.2495 9.64231C11.2495 9.216 11.0801 8.8073 10.7788 8.50582C10.4773 8.20448 10.0686 8.03516 9.64231 8.03516ZM9.64231 10.178C9.50025 10.178 9.36393 10.1216 9.26348 10.0211C9.16304 9.9207 9.10659 9.78437 9.10659 9.64231C9.10659 9.50025 9.16304 9.36393 9.26348 9.26348C9.36393 9.16304 9.50025 9.10659 9.64231 9.10659C9.78437 9.10659 9.9207 9.16304 10.0211 9.26348C10.1216 9.36393 10.178 9.50025 10.178 9.64231C10.178 9.78437 10.1216 9.9207 10.0211 10.0211C9.9207 10.1216 9.78437 10.178 9.64231 10.178Z"
      fill="currentColor"
    />
    <path
      d="M7.5 0C5.51089 0 3.60334 0.79017 2.19669 2.19669C0.7902 3.60331 0 5.51111 0 7.5C0 9.48889 0.79017 11.3967 2.19669 12.8033C3.60331 14.2098 5.51111 15 7.5 15C9.48889 15 11.3967 14.2098 12.8033 12.8033C14.2098 11.3967 15 9.48889 15 7.5C14.9978 5.5115 14.2071 3.60517 12.8009 2.19914C11.3949 0.792986 9.48834 0.00220407 7.50006 6.11914e-05L7.5 0ZM7.5 13.9287C5.79504 13.9287 4.15987 13.2514 2.95435 12.0458C1.74874 10.8402 1.07144 9.20502 1.07144 7.50012C1.07144 5.79522 1.74874 4.15999 2.95435 2.95447C4.15996 1.74886 5.7951 1.07156 7.5 1.07156C9.2049 1.07156 10.8401 1.74886 12.0456 2.95447C13.2513 4.16009 13.9286 5.79522 13.9286 7.50012C13.9268 9.2045 13.2489 10.8384 12.0436 12.0439C10.8384 13.2492 9.20432 13.9271 7.49982 13.9289L7.5 13.9287Z"
      fill="currentColor"
    />
  </svg>
);

export default percentIcon;
