import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { States } from '@/helpers/common/states';
import api, { AxiosError } from '@/services/api';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import Page from '@/model/Page';
import Reprint from '@/model/Reprint';
import { ReprintContainer } from './ui';
import { FormInputName, ShouldShowModal } from '../../types';

export const ReprintScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [currentPage, setCurrentPage] = useState<Page<Reprint, Reprint>>({
    page: 1,
    pageSize: 10,
    sort: 'createdAt',
    order: 'DESC',
  } as Page<Reprint, Reprint>);
  const [modal, setModal] = useState<ShouldShowModal>(ShouldShowModal.detail);
  const [reprint, setReprint] = useState<Reprint>();
  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const getReprints = async (page: Page<Reprint, Reprint>): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Page<Reprint, Reprint>>('/support/reprint/page', page);

      if (data) {
        setCurrentPage(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnPaginationChange = async (page: number): Promise<void> => {
    getReprints({
      ...currentPage,
      page,
    });
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
  });

  const awaitTime = async (timeout: number): Promise<boolean> => {
    const value = await new Promise<boolean>((resolve, _reject) => {
      setTimeout(() => {
        resolve(true);
      }, timeout);
    });
    return value;
  };

  const handleOnGetReprint = async (
    reprintSelected: Reprint,
    showLoading: boolean,
  ): Promise<Reprint | undefined> => {
    try {
      if (showLoading) {
        setState(States.loading);
      }

      const { data } = await api.get<Reprint>(`/support/reprint/${reprintSelected.id}`);
      setReprint(data);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      if (showLoading) {
        setState(States.default);
      }
    }
    return undefined;
  };

  const handleOnCheckToken = async (reprintSelected: Reprint): Promise<void> => {
    await awaitTime(10000);
    const checkReprint = await handleOnGetReprint(reprintSelected, false);
    if (!checkReprint || !checkReprint.orders) {
      handleOnCheckToken(reprintSelected);
    }
  };

  const handleOnCreateToken = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Reprint>(`/support/reprint`);
      setReprint(data);
      handleOnCheckToken(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShouldShowModal = async (
    value: ShouldShowModal,
    newTitleModal: string | React.ReactNode,
    reprintSelected?: Reprint,
  ): Promise<void> => {
    if (reprintSelected) {
      await handleOnGetReprint(reprintSelected, true);
    }
    if (value === ShouldShowModal.token) {
      await handleOnCreateToken();
    }

    setModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnFilter = async (): Promise<void> => {
    try {
      if (isFormValidFilter()) {
        const filter = formDataFilter[FormInputName.filterSearch];
        const value = formDataFilter[FormInputName.inputSearch];

        const entity = {} as Reprint;

        if (filter === 'cpfCreated') {
          entity.cpfCreated = value.replace(/\D/g, '');
        } else if (filter === 'cpf') {
          entity.actived = value === 'true';
        }

        const newPage: Page<Reprint, Reprint> = {
          ...currentPage,
          entity,
        };
        onToggle();
        await getReprints(newPage);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnClearFilter = async (): Promise<void> => {
    resetFormFilter();
    await getReprints({
      ...currentPage,
      entity: {},
    } as Page<Reprint, Reprint>);
    onToggle();
  };

  useEffect(() => {
    getReprints(currentPage);
  }, []);
  return (
    <ReprintContainer
      state={state}
      currentPage={currentPage}
      onPaginationChange={handleOnPaginationChange}
      onShouldShowModal={handleOnShouldShowModal}
      title={title}
      visible={visible}
      onToggle={onToggle}
      modal={modal}
      formDataFilter={formDataFilter}
      formErrorsFilter={formErrorsFilter}
      onChangeFormInputFilter={onChangeFormInputFilter}
      onFilter={handleOnFilter}
      onClearFilter={handleOnClearFilter}
      reprint={reprint}
    />
  );
};
