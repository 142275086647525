/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { InputText, TextArea } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import { FormInputDocumentCategoryName } from '../../types';

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
}

interface DispatchProps {
  onChange: OnChangeFormInput;
}

type Props = StateProps & DispatchProps;

export const RegisterDocumentCategoryContent: React.FC<Props> = ({
  formData,
  formErrors,
  onChange,
}) => (
  <Form
    noValidate={true}
    onSubmit={(e): void => {
      e.preventDefault();
    }}
  >
    <Row>
      <Col md={8}>
        <FormGroup className="mb-2">
          <InputText
            name="name"
            label="Nome da categoria"
            placeholder="Informe o nome da categoria"
            maxLength={100}
            value={formData[FormInputDocumentCategoryName.name]}
            onChange={e => onChange(FormInputDocumentCategoryName.name)(e.target.value)}
            error={formErrors.title && formErrors.title[0]}
          />
        </FormGroup>
        <FormGroup className="mb-2">
          <TextArea
            name="description"
            label="Descrição"
            rows={6}
            placeholder="Digite aqui a descrição da categoria"
            value={formData[FormInputDocumentCategoryName.description]}
            onChange={e => onChange(FormInputDocumentCategoryName.description)(e.target.value)}
            error={formErrors.description && formErrors.description[0]}
          />
        </FormGroup>
      </Col>
    </Row>
  </Form>
);
