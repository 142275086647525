/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Loading } from '@/components';
import PaymentType from '@/model/PaymentType';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import EventMainPdvReport from '@/model/EventMainPdvReport';
import { SalePdvContent } from './ui';
import { ReportControllerProps } from '../../types';

interface SalePdvProps {
  reportController: ReportControllerProps;
  generatePDF: () => void;
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export const SalePdv: React.FC<SalePdvProps> = ({ reportController, generatePDF }) => {
  const [state, setState] = useState<States>(States.default);

  const { eventId, singleEvent, salePdv, setSalePdv } = reportController;

  const numberToPaymentType = (status: PaymentType) => {
    switch (status) {
      case PaymentType.CREDIT_CARD:
        return 'Crédito:';
      case PaymentType.DEBIT_CARD:
        return 'Débito:';
      case PaymentType.MONEY:
        return 'Dinheiro:';
      case PaymentType.PIX:
        return 'PIX:';
      case PaymentType.TWO_CREDIT_CARDS:
        return 'Parcelado:';
      case PaymentType.BANK_SLIP:
        return 'Boleto:';
      default:
        return 'Sem dados:';
    }
  };

  const handleGetSalePdv = async (): Promise<void> => {
    if (salePdv === undefined) {
      try {
        setState(States.loading);
        const { data } = await api.get<EventMainPdvReport[]>(
          `event/report/${eventId}/sale-pdv?singleEvent=${singleEvent}`,
        );
        if (data) {
          setSalePdv(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    handleGetSalePdv();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <SalePdvContent
        generatePDF={generatePDF}
        salePdv={salePdv}
        numberToPaymentType={numberToPaymentType}
      />
    </>
  );
};
