import React, { Fragment } from 'react';
import { States } from '@/helpers/common/states';
import { Button, InputText, Loading, SelectCustom } from '@/components';
import { Col, Container, Form, FormGroup, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { colors } from '@/styles/colors';
import Event from '@/model/Event';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import Section from '@/model/Section';
import Ticket from '@/model/Ticket';
import Bracelet from '@/model/Bracelet';
import { CustomTable } from '@/components/Table';
import dayjs from 'dayjs';
import { FormInput } from '../../types';

interface StateProps {
  state: States;
  event?: Event;
  bracelets: Bracelet[];
  events: Event[];
  sections: Section[];
  tickets: Ticket[];
  formData: FormData;
  formErrors: FormErrors;
}

interface DispatchProps {
  onChange: OnChangeFormInput;
  onChangeEvent: (eventId: string) => void;
  onChangeSection: (sectionId: string) => void;
  addBracelet(): void;
}

type Props = StateProps & DispatchProps;

export const BraceletContainer: React.FC<Props> = ({
  state,
  event,
  bracelets,
  events,
  sections,
  tickets,
  formData,
  formErrors,
  onChange,
  onChangeEvent,
  onChangeSection,
  addBracelet,
}): JSX.Element => {
  const dataTableBracelet = bracelets.map(item => ({
    event: item.event.name,
    section: item.ticket.eventSection.name,
    ticket: item.ticket.name,
    amount: item.amount,
    date: item.createdAt,
  }));
  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Container className="mainContainer" fluid={true}>
        <div className="pageTitle d-flex mb-5">
          <Link to={`${process.env.PUBLIC_URL}/dashboard/event`}>
            <ArrowLeft color={colors.black} className="arrow-left" />
          </Link>
          <h5 className="ml-3 mb-0 mt-2 pageTitle">{`Pulseiras do evento - ${event?.name}`}</h5>
        </div>
        <Form>
          <FormGroup>
            <Row className="no-gutters">
              <Col md={8}>
                <FormGroup className="mb-2">
                  <SelectCustom
                    name="eventId"
                    label="Evento"
                    placeholder="Digite ou selecione o evento"
                    value={formData[FormInput.eventId]}
                    onChange={e => {
                      onChange(FormInput.eventId)(e?.value as string);
                      onChangeEvent(e?.value as string);
                    }}
                    error={formErrors.eventId && formErrors.eventId[0]}
                    options={events.map(data => ({ label: data.name, value: data.id }))}
                    isClearable
                  />
                </FormGroup>
              </Col>
            </Row>
            {sections && sections.length > 0 && (
              <Row className="no-gutters">
                <Col md={8}>
                  <FormGroup className="mb-2">
                    <SelectCustom
                      name="sectionId"
                      label="Setor"
                      placeholder="Digite ou selecione o setor"
                      value={formData[FormInput.sectionId]}
                      onChange={e => {
                        onChange(FormInput.sectionId)(e?.value as string);
                        onChangeSection(e?.value as string);
                      }}
                      error={formErrors.sectionId && formErrors.sectionId[0]}
                      options={sections.map(data => ({ label: data.name, value: data.id }))}
                      isClearable
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}

            {tickets && tickets.length > 0 && (
              <Row className="no-gutters">
                <Col md={8}>
                  <FormGroup className="mb-2">
                    <SelectCustom
                      name="ticketId"
                      label="Ingresso"
                      placeholder="Digite ou selecione o ingresso"
                      value={formData[FormInput.ticketId]}
                      onChange={e => onChange(FormInput.ticketId)(e?.value as string)}
                      error={formErrors.ticketId && formErrors.ticketId[0]}
                      options={tickets.map(data => ({ label: data.name, value: data.id }))}
                      isClearable
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row className="no-gutters">
              <Col md={8}>
                <FormGroup className="mb-2">
                  <InputText
                    label="Quantidade"
                    placeholder="Ex: 20000"
                    name="amount"
                    value={String(formData[FormInput.amount])}
                    onChange={e => onChange(FormInput.amount)(e.target.value)}
                    error={formErrors.amount && formErrors.amount[0]}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col md={8}>
                <FormGroup className="mb-2">
                  <InputText
                    label="E-mail"
                    placeholder="xx@xx.com"
                    type="email"
                    name="email"
                    value={String(formData[FormInput.email])}
                    onChange={e => onChange(FormInput.email)(e.target.value)}
                    error={formErrors.email && formErrors.email[0]}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="footer-register-event">
              <Button title="Adicionar" onClick={() => addBracelet()} />
            </div>

            <Row className="no-gutters">
              <Col md={8}>
                <h5 className="mb-4 border-bottom-title fw-400">Pulseiras inseridas no evento</h5>
                {bracelets.length > 0 ? (
                  <CustomTable
                    columns={[
                      {
                        name: 'Evento',
                        selector: row => row.event,
                      },
                      {
                        name: 'Setor',
                        selector: row => row.section,
                      },
                      {
                        name: 'Ingresso',
                        selector: row => row.ticket,
                      },
                      {
                        name: 'Qtd.',
                        selector: row => row.amount,
                      },
                      {
                        name: 'Data',
                        selector: row => dayjs(row.date).format('DD/MM/YYYY [às] HH:mm'),
                      },
                    ]}
                    data={dataTableBracelet}
                    theme="tertiary"
                    progressPending={false}
                    numberRowsPerPage={1}
                  />
                ) : (
                  <>
                    <div style={{ padding: '10px 0 20px 0', color: '#A5A5A5' }}>
                      Você ainda não inseriu nenhuma pulseria neste Evento.
                    </div>
                    <div style={{ color: '#A5A5A5', paddingBottom: '30px' }}>
                      Aqui será exibida uma lista das pulserias inseridas neste Evento.
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </Container>
    </Fragment>
  );
};
