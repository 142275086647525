import { TableColumn } from '@/components/Table';

export interface columnsMachinesTicketSalesDetails {
  id: number;
  machineId: string;
  machine: string;
  situation: string;
  pdv: string;
  section: string;
  serial: string;
  daysofuse: string;
  value: string;
  difference: string;
  debit: string;
  credit: string;
  deductmoney: string;
  pix: string;
  totalValue: string;
}

export const columnsMachinesTicketSalesDetails: TableColumn<columnsMachinesTicketSalesDetails>[] = [
  {
    name: 'ID',
    selector: row => row.machineId,
    width: '50px',
  },
  {
    name: 'Máquina',
    selector: row => row.machine,
    width: '150px',
  },
  {
    name: 'Situação',
    selector: row => row.situation,
    width: '150px',
  },
  {
    name: 'PDV',
    selector: row => row.pdv,
    width: '150px',
  },
  {
    name: 'Setor',
    selector: row => row.section,
  },
  {
    name: 'Serial',
    selector: row => row.serial,
  },
  {
    name: 'Dias uso',
    selector: row => row.daysofuse,
  },
  {
    name: 'Valor',
    selector: row => row.value,
    width: '100px',
  },
  {
    name: 'Diferença',
    selector: row => row.difference,
    width: '150px',
  },
  {
    name: 'Débito',
    selector: row => row.debit,
    width: '100px',
  },
  {
    name: 'Crédito',
    selector: row => row.credit,
    width: '100px',
  },
  {
    name: 'Dinheiro',
    selector: row => row.deductmoney,
    width: '100px',
  },
  {
    name: 'PIX',
    selector: row => row.pix,
    width: '100px',
  },
  {
    name: 'Valor Total',
    selector: row => row.totalValue,
    width: '170px',
  },
];
