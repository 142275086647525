import React from 'react';
import { SelectCustom } from '@/components';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import ChargeSetup from '@/model/ChargeSetup';
import { OptionProps } from '@/components/Select';
import AcquiringFeeType from '@/model/AcquiringFeeType';
import { RegisterAcquiringFeeFormInput } from '../../types';

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
  chargeSetups: ChargeSetup[];
}

interface DispatchProps {
  onChangeFormInput: OnChangeFormInput;
}

type Props = StateProps & DispatchProps;

export const RegisterAcquiringFeeContent: React.FC<Props> = ({
  formData,
  formErrors,
  onChangeFormInput,
  chargeSetups,
}): JSX.Element => {
  const mountOptions = (): OptionProps[] => {
    const list: OptionProps[] = [];
    if (chargeSetups && chargeSetups.length > 0) {
      chargeSetups.forEach(data => {
        list.push({
          value: data.id,
          label: data.name,
        });
      });
    }
    return list;
  };

  const mountOptions2 = (): OptionProps[] => {
    const list: OptionProps[] = [
      {
        value: `${AcquiringFeeType.SITE}`,
        label: 'On-Line',
      },
      {
        value: `${AcquiringFeeType.POS}`,
        label: 'Física',
      },
    ];
    return list;
  };

  return (
    <Form
      noValidate={true}
      onSubmit={(e): void => {
        e.preventDefault();
      }}
    >
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectCustom
              name="chargeSetupId"
              label="Gateway"
              placeholder="..."
              value={formData[RegisterAcquiringFeeFormInput.chargeSetupId]}
              onChange={e => {
                onChangeFormInput(RegisterAcquiringFeeFormInput.chargeSetupId)(e?.value as string);
              }}
              error={formErrors.chargeSetupId && formErrors.chargeSetupId[0]}
              options={mountOptions()}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectCustom
              name="type"
              label="Origem"
              placeholder="..."
              value={formData[RegisterAcquiringFeeFormInput.type]}
              onChange={e => {
                onChangeFormInput(RegisterAcquiringFeeFormInput.type)(e?.value as string);
              }}
              error={formErrors.type && formErrors.type[0]}
              options={mountOptions2()}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
