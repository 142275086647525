import React from 'react';
import { Route } from '@/navigation/Route';
import { AcquiringFeeScreen } from './screens';

export const ACQUIRING_FEE_ROUTES = {
  itself: '/dashboard/acquiring-fee',
};

export const AcquiringFeeNavigation = (): JSX.Element => (
  <Route exact path={ACQUIRING_FEE_ROUTES.itself} component={AcquiringFeeScreen} isPrivateRoute />
);
