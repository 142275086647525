// eslint-disable-next-line no-shadow
enum UserType {
  Employee,
  PDV,
  SUB_PDV,
  POS,
  CONTRACTOR,
  VALIDATOR,
  ARTIST,
}

export default UserType;
