import React from 'react';
import { Form } from 'reactstrap';
import { Radio, InputText } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import { updateMask } from '@/helpers/masks/generalDate';

interface FilterContentProps {
  formData: FormData;
  formErrors: FormErrors;
  onChangeFormInput: OnChangeFormInput;
}

// eslint-disable-next-line no-shadow
export enum FormInputName {
  filterSearch = 'filterSearch',
  inputSearch = 'inputSearch',
}

export const FilterContent: React.FC<FilterContentProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
}) => {
  const optionFilter = [
    { label: 'Atividade', value: 'message' },
    { label: 'Responsável', value: 'personName' },
    { label: 'IP responsável', value: 'ipCreation' },
    { label: 'Data da atividade', value: 'createdAt' },
  ];

  const getPlaceHolder = (value: string): string => {
    let s = 'Informe a Atividade';
    if (value === 'message') {
      s = 'Informe a Atividade';
    } else if (value === 'personName') {
      s = 'Informe o Responsável';
    } else if (value === 'ipCreation') {
      s = 'Informe o IP responsável';
    } else if (value === 'createdAt') {
      s = 'Informe a Data da atividade';
    }
    return s;
  };

  const getMask = (filter: string, value: string): string => {
    let s = value;
    if (filter === 'createdAt') {
      s = updateMask(value);
    }
    return s;
  };

  return (
    <div className="filter-modal-content">
      <Form noValidate={true} onSubmit={(e): void => e.preventDefault()}>
        <span className="filter-name">Filtrar por:</span>
        <Radio
          options={optionFilter}
          name="filterSearch"
          style={{ fontSize: '21px' }}
          value={formData[FormInputName.filterSearch]}
          onChange={e => onChangeFormInput(FormInputName.filterSearch)(e.target.value)}
          error={formErrors.filterSearch && formErrors.filterSearch[0]}
        />
        {formData[FormInputName.filterSearch] && (
          <InputText
            name="inputSearch"
            label={
              optionFilter.find(values => formData[FormInputName.filterSearch] === values.value)
                ?.label ?? ''
            }
            placeholder={getPlaceHolder(formData[FormInputName.filterSearch])}
            maxLength={30}
            value={formData[FormInputName.inputSearch]}
            onChange={e =>
              onChangeFormInput(FormInputName.inputSearch)(
                getMask(formData[FormInputName.filterSearch], e.target.value),
              )
            }
            error={formErrors.inputSearch && formErrors.inputSearch[0]}
          />
        )}
      </Form>
    </div>
  );
};
