import React, { useEffect, useState } from 'react';
import { Button } from '@/components';
import { ValidationReport } from '@/model/ValidationReport';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { Col, Row } from 'reactstrap';
import { StyledPie } from '@/components/StyledPie';
import EventGeneralSaleChannelReport from '@/model/EventGeneralSaleChannelReport';
import SuperCollapse from '@/components/sharedComponents/SuperCollapse';
import { CustomTable } from '@/components/Table';
import EventMainReport from '@/model/EventMainReport';
import { ValidationSection } from '@/model/ValidationSection';
import { ValidationTicket } from '@/model/ValidationTicket';
import { toPercentage } from '@/helpers/common/amount';
import { StyledAreLine } from '@/components/StyleLineArea';
// import { DataList } from '@/components/DataList';

export interface ValidationContentProps {
  generatePDF: () => void;
  validation: ValidationReport | undefined;
  eventChild?: EventMainReport[];
}

export const ValidationContent: React.FC<ValidationContentProps> = ({
  validation,
  generatePDF,
  // eventChild,
}) => {
  const [summary, setSummary] = useState<ValidationSection[]>([]);
  const [braceletValidations, setBraceletValidations] = useState<ValidationSection[]>([]);
  const [percentValidatedTicket, setPercentValidatedTicket] = useState<number>(0);
  const [percentNotValidatedTicket, setPercentNotValidatedTicket] = useState<number>(0);
  const [percentValidatedBracelet, setPercentValidatedBracelet] = useState<number>(0);
  const [percentNotValidatedBracelet, setPercentNotValidatedBracelet] = useState<number>(0);

  const percentValidateds = (): void => {
    if (validation && validation.ticket.amountValidated && validation.ticket.amountNotValidated) {
      const totalTickets =
        (validation?.ticket.amountValidated || 0) + (validation?.ticket.amountNotValidated || 0);

      setPercentValidatedTicket(
        totalTickets !== 0 ? ((validation?.ticket.amountValidated || 0) / totalTickets) * 100 : 0,
      );

      setPercentNotValidatedTicket(
        totalTickets !== 0
          ? ((validation?.ticket.amountNotValidated || 0) / totalTickets) * 100
          : 0,
      );
    }

    if (
      validation &&
      validation.bracelet.amountValidated &&
      validation.bracelet.amountNotValidated
    ) {
      const totalBracelet =
        (validation?.bracelet.amountValidated || 0) +
        (validation?.bracelet.amountNotValidated || 0);

      setPercentValidatedBracelet(
        totalBracelet !== 0 ? ((validation?.ticket.amountValidated || 0) / totalBracelet) * 100 : 0,
      );

      setPercentNotValidatedBracelet(
        totalBracelet !== 0
          ? ((validation?.ticket.amountNotValidated || 0) / totalBracelet) * 100
          : 0,
      );
    }
  };

  const salesChannel: EventGeneralSaleChannelReport[] = [
    {
      name: 'Validados',
      value: validation?.ticket.amountValidated || 0,
      count: validation?.ticket.amountValidated || 0,
      percent: percentValidatedTicket || 0,
    },
    {
      name: 'Não validados',
      value: validation?.ticket.amountNotValidated || 0,
      count: validation?.ticket.amountNotValidated || 0,
      percent: percentNotValidatedTicket || 0,
    },
  ];

  const dataBracelet: EventGeneralSaleChannelReport[] = [
    {
      name: 'Validados',
      value: validation?.bracelet.amountValidated || 0,
      count: validation?.bracelet.amountValidated || 0,
      percent: percentValidatedBracelet || 0,
    },
    {
      name: 'Não validados',
      value: validation?.bracelet.amountNotValidated || 0,
      count: validation?.bracelet.amountNotValidated || 0,
      percent: percentNotValidatedBracelet || 0,
    },
  ];

  const toValidationTicket = (): void => {
    const sections: ValidationSection[] = [];
    const braceletSections: ValidationSection[] = [];
    if (validation && validation.ticket) {
      if (validation.ticket.sections && validation.ticket.sections.length > 0) {
        validation.ticket.sections.forEach(data => {
          const section: ValidationSection = {
            summary: data.summary,
            tickets: [],
          };
          if (data.tickets && data.tickets.length > 0) {
            data.tickets.forEach(ticket => {
              if (ticket.batchs && ticket.batchs.length > 0) {
                ticket.batchs.forEach(batch => {
                  const validationsTicket: ValidationTicket = {
                    ticketName: `${ticket.ticketName} - ${batch.batchName}`,
                    amountSold: batch.amountSold,
                    amountValidated: batch.amountValidated,
                    validatedPercentage: batch.validatedPercentage,
                  };
                  section.tickets.push(validationsTicket);
                });
              }
            });
          }
          sections.push(section);
        });
      }
    }
    setSummary(sections);
    if (validation && validation.bracelet) {
      if (validation.bracelet.sections && validation.bracelet.sections.length > 0) {
        validation.bracelet.sections.forEach(data => {
          const sectionsBracelet: ValidationSection = {
            summary: data.summary,
            tickets: [],
          };
          if (data.tickets && data.tickets.length > 0) {
            data.tickets.forEach(ticket => {
              if (ticket.batchs && ticket.batchs.length > 0) {
                ticket.batchs.forEach(batch => {
                  const validatedTicketBracelet: ValidationTicket = {
                    ticketName: `${ticket.ticketName} - ${batch.batchName}`,
                    amountSold: batch.amountSold,
                    amountValidated: batch.amountValidated,
                    validatedPercentage: batch.validatedPercentage,
                  };
                  sectionsBracelet.tickets.push(validatedTicketBracelet);
                });
              }
            });
          }
          braceletSections.push(sectionsBracelet);
        });
      }
    }
    setBraceletValidations(braceletSections);
  };

  useEffect(() => {
    if (validation) {
      toValidationTicket();
    }
  }, [validation]);

  useEffect(() => {
    if (validation) {
      percentValidateds();
    }
  }, [validation]);

  return (
    <>
      <div className="d-flex justify-content-between mt-5 mb-5">
        <h5>Validação</h5>
        <Button theme="outlineDark" onClick={() => generatePDF()} title="Exportar relatório" />
      </div>
      <div className="mb-5 mt-5 ">
        <div className="row">
          <div className="col-md-6">
            <h6>Validações dos ingressos</h6>
            <div className="d-flex justify-content-between">
              <div className="w-1/2">
                <div className="mb-4">Vendidos</div>
                <div className="mb-4">Validados</div>
                <div className="mb-4">Não validados</div>
                <div className="mb-4">Cancelados</div>
                <div className="mb-4">Valor</div>
              </div>
              <div className="w-1/2 mr-md-4">
                <div className="mb-4">
                  {validation && validation.ticket && validation.ticket.detail
                    ? validation.ticket.detail.sold
                    : 0}
                </div>
                <div className="mb-4">
                  {validation && validation.ticket ? validation.ticket.amountValidated : 0}
                </div>
                <div className="mb-4">
                  {validation && validation.ticket ? validation.ticket.amountNotValidated : 0}
                </div>
                <div className="mb-4">
                  {validation && validation.ticket && validation.ticket.detail
                    ? validation.ticket.detail.cancelled
                    : 0}
                </div>
                <div className="mb-4">
                  {validation && validation.ticket && validation.ticket.detail
                    ? toCurrency(validation.ticket.detail.value)
                    : toCurrency(0)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 ">
            <h6>Ingressos validados</h6>
            <Row>
              <Col md={6}>
                <div className="mb-4 ">Tipo de ingresso</div>
                <StyledPie
                  backgrundColor1="#0b8a21"
                  backgrundColor2="#a70d0d"
                  generalSale={salesChannel || []}
                  // type="currency"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <hr />
      {/* {eventChild && eventChild.length !== 0 && (
        <div
          className="customTableScroll p-4"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            gridColumnGap: '40px',
            overflow: 'auto',
            alignItems: 'end',
          }}
        >
          {eventChild.map((data, index) => (
            <DataList
              key={`${data.id}-${index}`}
              data={[
                {
                  title: 'Nome do evento',
                  content: data.name || '-----',
                },
                {
                  title: 'Vendas de hoje',
                  content: data.ticket.amountSale || '-----',
                },
                {
                  title: 'Cortesias',
                  content: data.ticket.amountCourtesy || '-----',
                },
                {
                  title: 'Vendas',
                  content: data.ticket.amountSale || '-----',
                },
                {
                  title: 'Total',
                  content: data.ticket.totalSales + data.ticket.amountCourtesy || '-----',
                },
                {
                  title: 'Faturamento',
                  content: toCurrency(data.ticket.saleValue) || '-----',
                },
                {
                  title: 'Tkt. médio',
                  content: data.ticket.averageTicket || '-----',
                },
              ]}
            />
          ))}
        </div>
      )} */}
      {summary &&
        summary.length > 0 &&
        summary.map((data, index) => (
          <React.Fragment key={`${data.summary.sectionId}-${index}`}>
            <SuperCollapse
              width={'100%'}
              title={
                <CustomTable
                  numberRowsPerPage={0}
                  progressPending={false}
                  theme="secondaryWithoutBorder"
                  columns={[
                    {
                      name: 'Setor',
                      selector: row => row.sectionName,
                      width: '30%',
                    },
                    {
                      name: 'Vendidos',
                      selector: row => row.amountSold,
                      width: '20%',
                    },
                    {
                      name: 'Cancelados',
                      selector: row => row.amountCanceled,
                      width: '20%',
                    },
                    {
                      name: '% validados',
                      selector: row => row.validatedPercentage,
                      width: '20%',
                    },
                  ]}
                  data={[
                    {
                      sectionName: data.summary.sectionName,
                      amountSold: data.summary.amountSold,
                      amountCanceled: data.summary.amountCanceled,
                      validatedPercentage: `${toPercentage(data.summary.validatedPercentage)}%`,
                    },
                  ]}
                />
              }
              content={
                <>
                  <CustomTable
                    numberRowsPerPage={0}
                    progressPending={false}
                    theme="secondaryWithoutBorder"
                    columns={[
                      {
                        name: 'Ingresso',
                        selector: row => row.ticket,
                        width: '55%',
                      },
                      {
                        name: 'Vendidos',
                        selector: row => row.amountSold,
                        width: '15%',
                      },
                      {
                        name: 'Validados',
                        selector: row => row.amountValidated,
                        width: '15%',
                      },
                      {
                        name: '% validados',
                        selector: row => row.validatedPercentage,
                        width: '15%',
                      },
                    ]}
                    data={data.tickets.map(item => ({
                      ticket: item.ticketName,
                      amountSold: item.amountSold || '---',
                      amountValidated: item.amountValidated || '---',
                      validatedPercentage: `${toPercentage(item.validatedPercentage)}` || '---',
                    }))}
                  />
                </>
              }
            />
          </React.Fragment>
        ))}
      {validation && validation.ticket && validation.ticket.dates && (
        <div className="row">
          {validation.ticket.dates.map((data, index) => (
            <React.Fragment key={`ticket-${data.date}-${index}`}>
              <div className="col-md-4">
                <hr />
                <h6 className={index > 0 ? 'mt-4' : 'mt-1'}>Horário de entrada</h6>
                <StyledAreLine
                  data={data.hours}
                  dataSetName={'Horas'}
                  color={'#FF5733'}
                  tableTitle={data.date}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {validation &&
        validation.bracelet &&
        validation.bracelet.dates &&
        validation.bracelet.dates.length > 0 && (
          <React.Fragment>
            <div className="mb-5 mt-5 ">
              <div className="row">
                <div className="col-md-6">
                  <h6>Validações das Pulseias</h6>
                  <div className="d-flex justify-content-between">
                    <div className="w-1/2">
                      <div className="mb-4">Geradas</div>
                      <div className="mb-4">Validados</div>
                    </div>
                    <div className="w-1/2 mr-md-4">
                      <div className="mb-4">
                        {validation && validation.bracelet && validation.bracelet.detail
                          ? validation.bracelet.detail.sold
                          : 0}
                      </div>
                      <div className="mb-4">
                        {validation && validation.bracelet
                          ? validation.bracelet.amountValidated
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <h6>Pulserias validadas</h6>
                  <Row>
                    <Col md={6}>
                      {/* <div className="mb-4 ">Tipo de ingresso</div> */}
                      <StyledPie
                        backgrundColor1="#0b8a21"
                        backgrundColor2="#a70d0d"
                        generalSale={dataBracelet || []}
                        // type="currency"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {validation && validation.bracelet && validation.bracelet.dates && (
              <div className="row">
                {validation.bracelet.dates.map((data, index) => (
                  <React.Fragment key={`ticket-${data.date}-${index}`}>
                    <div className="col-md-4">
                      <hr />
                      <h6 className={index > 0 ? 'mt-4' : 'mt-1'}>Horário de entrada</h6>
                      <StyledAreLine
                        data={data.hours}
                        dataSetName={'Horas'}
                        color={'#FF5733'}
                        tableTitle={data.date}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
            {braceletValidations &&
              braceletValidations.length > 0 &&
              braceletValidations.map((data, index) => (
                <SuperCollapse
                  key={`${data.summary.sectionId}-${index}`}
                  width={'100%'}
                  title={
                    <CustomTable
                      numberRowsPerPage={0}
                      progressPending={false}
                      theme="secondaryWithoutBorder"
                      columns={[
                        {
                          name: 'Setor',
                          selector: row => row.sectionName,
                          width: '30%',
                        },
                        {
                          name: 'Gerados',
                          selector: row => row.amountSold,
                          width: '20%',
                        },
                        {
                          name: 'Cancelados',
                          selector: row => row.amountCanceled,
                          width: '20%',
                        },
                        {
                          name: '% validados',
                          selector: row => row.validatedPercentage,
                          width: '20%',
                        },
                      ]}
                      data={[
                        {
                          sectionName: data.summary.sectionName,
                          amountSold: data.summary.amountSold,
                          amountCanceled: data.summary.amountCanceled,
                          validatedPercentage: `${toPercentage(data.summary.validatedPercentage)}%`,
                        },
                      ]}
                    />
                  }
                  content={
                    <>
                      <CustomTable
                        numberRowsPerPage={0}
                        progressPending={false}
                        theme="secondaryWithoutBorder"
                        columns={[
                          {
                            name: 'Ingresso',
                            selector: row => row.ticket,
                            width: '55%',
                          },
                          {
                            name: 'Vendidos',
                            selector: row => row.amountSold,
                            width: '15%',
                          },
                          {
                            name: 'Validados',
                            selector: row => row.amountValidated,
                            width: '15%',
                          },
                          {
                            name: '% validados',
                            selector: row => row.validatedPercentage,
                            width: '15%',
                          },
                        ]}
                        data={data.tickets.map(item => ({
                          ticket: item.ticketName,
                          amountSold: item.amountSold || '---',
                          amountValidated: item.amountValidated || '---',
                          validatedPercentage: `${toPercentage(item.validatedPercentage)}` || '---',
                        }))}
                      />
                    </>
                  }
                />
              ))}
          </React.Fragment>
        )}
    </>
  );
};
