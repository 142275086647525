/* eslint-disable import/no-unresolved */
import React, { Fragment } from 'react';
import { ButtonGroup, InputText, SelectCustom } from '@/components';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { updateMask as updateMaskCash } from '@/helpers/masks/cashNumber';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import EventSection from '@/model/EventSection';
import { OptionProps } from '@/components/Select';
import { posStatesProps } from '../../types';

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

// eslint-disable-next-line no-shadow
export enum FormInputName {
  name = 'name',
  rechargePrepaidCard = 'rechargePrepaidCard',
  chargePrepaidCard = 'chargePrepaidCard',
  rechargeSection = 'rechargeSection',
  rechargeProduct = 'rechargeProduct',
  waiter = 'waiter',
  commission = 'commission',
}

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
  posStates: posStatesProps;
  showRecharge: boolean;
  eventSections: EventSection[];
}

interface DispatchProps {
  onChangeRecharge: (value: string) => void;
  onChangeFormInput: OnChangeFormInput;
}

type Props = StateProps & DispatchProps;

export const PosContent: React.FC<Props> = ({
  formData,
  formErrors,
  onChangeFormInput,
  posStates,
  onChangeRecharge,
  showRecharge,
  eventSections,
}) => {
  const mountProductOptions = (): OptionProps[] => {
    const options: OptionProps[] = [];
    if (eventSections && eventSections.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < eventSections.length; i++) {
        if (eventSections[i].section.id === formData[FormInputName.rechargeSection]) {
          if (eventSections[i].products && eventSections[i].products.length > 0) {
            eventSections[i].products.forEach(data => {
              options.push({
                value: data.id,
                label: data.name,
              });
            });
          }
          break;
        }
      }
    }
    return options;
  };
  return (
    <Fragment>
      <Form
        noValidate={true}
        onSubmit={(e): void => {
          e.preventDefault();
        }}
      >
        <Row>
          <Col md={6}>
            <FormGroup className="mb-2">
              <SelectCustom
                name="name"
                label="POS"
                placeholder="Digite ou selecione a POS"
                disabled={!!posStates.pos}
                value={formData[FormInputName.name]}
                onChange={e => onChangeFormInput(FormInputName.name)(e?.value as string)}
                error={formErrors.name && formErrors.name[0]}
                options={posStates.posOptions
                  .map(pos => ({
                    value: posStates.posList.find(posList => posList.pos.id === pos.id)
                      ? undefined
                      : pos.id,
                    label: posStates.posList.find(posList => posList.pos.name === pos.name)
                      ? undefined
                      : pos.name,
                  }))
                  .filter(option => option.value !== undefined && option.label !== undefined)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="mb-2">
              <ButtonGroup
                label="Efetua recarga Pré Pago"
                name="rechargePrepaidCard"
                value={formData[FormInputName.rechargePrepaidCard]}
                onChange={e => onChangeRecharge(e.target.value)}
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                error={formErrors.rechargePrepaidCard && formErrors.rechargePrepaidCard[0]}
              />
            </FormGroup>
          </Col>
        </Row>
        {showRecharge && (
          <Fragment>
            <Row>
              <Col md={6}>
                <FormGroup className="mb-2">
                  <ButtonGroup
                    label="Cobrar cartão Pré Pago"
                    name="chargePrepaidCard"
                    value={formData[FormInputName.chargePrepaidCard]}
                    onChange={e =>
                      onChangeFormInput(FormInputName.chargePrepaidCard)(e.target.value)
                    }
                    options={[
                      { value: true, label: 'Sim' },
                      { value: false, label: 'Não' },
                    ]}
                    error={formErrors.chargePrepaidCard && formErrors.chargePrepaidCard[0]}
                  />
                </FormGroup>
              </Col>
            </Row>
            {formData[FormInputName.chargePrepaidCard] === 'true' && (
              <Fragment>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-2">
                      <SelectCustom
                        name="rechargeSection"
                        label="Setor Pré Pago"
                        placeholder="Digite ou selecione o Setor"
                        value={formData[FormInputName.rechargeSection]}
                        onChange={e =>
                          onChangeFormInput(FormInputName.rechargeSection)(e?.value as string)
                        }
                        error={formErrors.rechargeSection && formErrors.rechargeSection[0]}
                        options={eventSections.map(section => ({
                          value: section.section.id,
                          label: section.section.name,
                        }))}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {formData[FormInputName.rechargeSection] && (
                  <Row>
                    <Col md={6}>
                      <FormGroup className="mb-2">
                        <SelectCustom
                          name="rechargeProduct"
                          label="Produto Pré Pago"
                          placeholder="Digite ou selecione o Produto"
                          value={formData[FormInputName.rechargeProduct]}
                          onChange={e =>
                            onChangeFormInput(FormInputName.rechargeProduct)(e?.value as string)
                          }
                          error={formErrors.rechargeProduct && formErrors.rechargeProduct[0]}
                          options={mountProductOptions()}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </Fragment>
            )}
          </Fragment>
        )}

        <Row>
          <Col md={6}>
            <FormGroup className="mb-2">
              <InputText
                name="waiter"
                label="Porcentagem do garçom"
                addon="%"
                maxLength={5}
                placeholder="0"
                className="w-input-sm"
                value={formData[FormInputName.waiter]}
                onChange={e =>
                  onChangeFormInput(FormInputName.waiter)(updateMaskCash(e?.target?.value))
                }
                error={formErrors.waiter && formErrors.waiter[0]}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="mb-2">
              <InputText
                name="commission"
                label="Porcentagem de comissão"
                placeholder="0"
                addon="%"
                maxLength={5}
                className="w-input-sm"
                value={formData[FormInputName.commission]}
                onChange={e =>
                  onChangeFormInput(FormInputName.commission)(
                    updateMaskCash(e?.target?.value) as string,
                  )
                }
                error={formErrors.commission && formErrors.commission[0]}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};
