import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import { Button, Loading } from '@/components';
import { ActionProps, Dialog } from '@/components/Dialog';
import { States } from '@/helpers/common/states';
import Page from '@/model/Page';
import React, { Fragment } from 'react';
import { ShouldShowModal } from '@/features/log/types';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { FilterContent } from '@/features/log/components/FilterContent';
import { DetailContent } from '@/features/log/components/DetailContent';
import { Container, Label } from 'reactstrap';
import FilterVector from '@/assets/images/svg/FilterVector';
import { CustomTable } from '@/components/Table';
import Pagination from '@/components/Utils/Pagination';
import dayjs from 'dayjs';

import Log from '@/model/Log';
import { columns } from './table';

interface StateProps {
  state: States;
  page: Page<Log, Log>;
  title: string | React.ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  logState?: Log;
  formDataFilter: FormData;
  formErrorsFilter: FormErrors;
}

interface DispatchProps {
  clearFilter: () => void;
  onToggle: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
    log,
    isEdit,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    log?: Log;
    isEdit?: boolean;
  }) => void;
  onFilter: () => void;
  onChangeFormInputFilter: OnChangeFormInput;
  onExport: () => void;
  onPaginationChange: (pageNumber: number) => void;
}

type Props = StateProps & DispatchProps;

export const LogContainer: React.FC<Props> = ({
  clearFilter,
  onToggle,
  state,
  page,
  onShouldShowModal,
  title,
  visible,
  shouldShowModal,
  onFilter,
  logState,
  formDataFilter,
  formErrorsFilter,
  onChangeFormInputFilter,
  onExport,
  onPaginationChange,
}) => {
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => clearFilter(),
    theme: 'noneBorder',
  };

  const dataTable =
    page.list && page.list.length > 0
      ? page.list.map(item => ({
          message: `${item.className}: ${item.message}`,
          personName: item.person && item.person.name ? item.person.name : 'Não informado',
          ipCreation: item.ipCreation,
          createdAt: dayjs(item.createdAt).format('DD/MM/YYYY [às] HH:mm'),
          actions: (
            <Fragment>
              <Detail
                className="mr-4 svg-icon action-icon"
                onClick={(): void => {
                  onToggle();
                  onShouldShowModal({
                    value: ShouldShowModal.detail,
                    newTitleModal: 'Detalhes',
                    log: item,
                    isEdit: true,
                  });
                }}
              />
            </Fragment>
          ),
        }))
      : [];

  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={shouldShowModal !== ShouldShowModal.filter}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
            [ShouldShowModal.detail]: {},
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: {
              title: 'Aplicar',
              onClick: () => onFilter(),
            },
            [ShouldShowModal.detail]: {},
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formDataFilter}
                formErrors={formErrorsFilter}
                onChangeFormInput={onChangeFormInputFilter}
              />
            ),
            [ShouldShowModal.detail]: <DetailContent log={logState as Log} />,
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <div className="pageTitle" style={{ display: 'grid' }}>
            <Label>Logs</Label>
          </div>
          <div className="button-filter-container">
            <Button theme="outlineDark" title="Exportar logs" onClick={() => onExport()} />
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onToggle();
                  onShouldShowModal({
                    value: ShouldShowModal.filter,
                    newTitleModal: '',
                  });
                }}
              >
                <FilterVector />
              </div>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={dataTable}
          numberRowsPerPage={page.pageSize}
          progressPending={state === States.loading}
          theme="primary"
        />
        <Pagination
          currentPage={page.page}
          totalCount={page.total}
          pageSize={page.pageSize}
          onPageChange={pageNumber => onPaginationChange(pageNumber)}
          total={page.total}
        />
      </Container>
    </Fragment>
  );
};
