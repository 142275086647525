/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import { Loading } from '@/components';
import EventReportPayment from '@/model/EventReportPayment';
import { ReportControllerProps } from '../../types';
import { SaleProdutContent } from './ui';

interface SaleDateProps {
  payments: EventReportPayment;
  reportController: ReportControllerProps;
  generatePDF: () => void;
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export const SaleProduct: React.FC<SaleDateProps> = ({
  payments,
  reportController,
  generatePDF,
}) => {
  const [state, setState] = useState<States>(States.default);

  const { eventId, singleEvent, saleProduct, setSaleProduct } = reportController;

  const handleGetSaleProduct = async (): Promise<void> => {
    if (saleProduct === undefined) {
      try {
        setState(States.loading);
        const { data } = await api.get(
          `event/report/${eventId}/sale-product?singleEvent=${singleEvent}`,
        );
        if (data) {
          setSaleProduct(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    handleGetSaleProduct();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <SaleProdutContent payments={payments} saleProduct={saleProduct} generatePDF={generatePDF} />
    </>
  );
};
