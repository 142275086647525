import React, { useEffect, useState } from 'react';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import Support from '@/model/Support';
import useForm from '@/hooks/useForm';
import { AxiosError } from 'axios';
import api from '@/services/api';
import { toast } from 'react-toastify';
import OrderSite from '@/model/OrderSite';
import { useDialog } from '@/hooks/useDialog';
import MessageType from '@/model/MessageType';
import { SupportMessage } from '@/model/SupportMessage';
import { useParams } from 'react-router-dom';
import SupportStatus from '@/model/SupportStatus';
import { CancelContent } from '@/features/cancelRequest/components/CancelContent';
import { ReverseContent } from '@/features/reverse/components/ReverseContent';
import { FormUpdateSupportStatus, ShouldShowModalReverse, States, UrlParams } from '../../types';
import { ChatUI } from './ui';
import { ConfirmUpdate } from '../../components/ConfirmUpdate';

export const ChatScreen: React.FC = (): JSX.Element => {
  const params = useParams<UrlParams>();
  const [state, setState] = useState<States>(States.default);
  const [support, setSupport] = useState<Support>();
  const [connected, setConnected] = useState<boolean>(false);
  const confirmUpdate = useConfirmDelete();
  const [modal, setModal] = useState<ShouldShowModalReverse>(ShouldShowModalReverse.detail);
  const [currentPage, setCurrentPage] = useState<OrderSite[]>([]);
  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const [order, setOrder] = useState<OrderSite>();
  const confirmDelete = useConfirmDelete();

  const {
    formData: formDataStatus,
    formErrors: formErrorsStatus,
    onChangeFormInput: onChangeFormInputRegisterStatus,
  } = useForm({
    initialData: {
      status: '',
    },
  });

  const handleGetTypeStatus = (statusType: SupportStatus): string => {
    let value = '';
    if (statusType === SupportStatus.CANCELED) {
      value = 'Cancelado';
    } else if (statusType === SupportStatus.CLOSED) {
      value = 'Fechado';
    } else if (statusType === SupportStatus.IN_PROGRESS) {
      value = 'Em Atendimento';
    } else if (statusType === SupportStatus.NEW) {
      value = 'Novo';
    } else if (statusType === SupportStatus.REOPENED) {
      value = 'Reaberto';
    } else if (statusType === SupportStatus.RESOLVED) {
      value = 'Resolvido';
    } else if (statusType === SupportStatus.WAITING) {
      value = 'Aguardando';
    } else if (statusType === SupportStatus.WAITING_FOR_CLIENT) {
      value = 'Aguradando Cliente';
    }
    return value;
  };

  const getOrderClient = async (cpf: string): Promise<void> => {
    try {
      const { data } = await api.get<OrderSite[]>(`/support/call/order/${cpf}`);
      if (data) {
        setCurrentPage(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnFetchSupport = async (): Promise<void> => {
    try {
      //   setState(States.loading);
      const { data } = await api.get<Support>(`/support/call/protocol/${params.id}`);
      setSupport(data);
      if (data) {
        getOrderClient(data.cpf);
      }
      onChangeFormInputRegisterStatus(FormUpdateSupportStatus.status)(
        handleGetTypeStatus(data.supportStatus ? data.supportStatus : SupportStatus.WAITING),
      );
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm, setErrors } = useForm({
    initialData: {
      message: '',
      file: '',
    },
  });

  const handleOnChangeFileInput = (target: EventTarget & HTMLInputElement): void => {
    if (target && target.files) {
      const file = target.files[0];
      if (file && file.type.match('image.*|application.pdf')) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            onChangeFormInput('message')(file.name);
            onChangeFormInput('file')(base64);
          }
        };
      } else {
        setErrors({ message: ['O formato deve ser .jpg, .jpeg ou .png'] });
      }
    } else {
      setErrors({
        message: ['Não foi possível carregar o arquivo'],
      });
    }
  };

  const handleOnClearImage = (): void => {
    resetForm();
  };

  const handleOnSendMessage = async (): Promise<void> => {
    if (isFormValid()) {
      try {
        // setState(States.loading);

        const payload = {
          comment: formData.file ? formData.file : formData.message,
          messageType: formData.file ? MessageType.FILE : MessageType.TEXT,
        } as SupportMessage;

        const { data } = await api.post<Support>(`/support/call/message/${params.id}`, payload);
        setSupport(data);
        resetForm();
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status !== 401) {
          toast.error(err.message);
        }
      } finally {
        setState(States.default);
      }
    }
  };
  const handleOnClose = (): void => confirmUpdate.hide();

  const handleOnChangeStatus = async (status: SupportStatus): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.patch<Support>(
        `/support/call/protocol/${params.id}/status/${status}`,
      );

      toast.success('Status alterado com sucesso!');
      handleOnClose();
      setSupport(data);
      resetForm();
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const objDiv = document.getElementById('divMessagesChat');
  if (objDiv) {
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  const connectToWebSocket = (protocolId: string): void => {
    const socket = new WebSocket(
      `wss://api.bancadoingresso.com.br/api/websocket/v1/chat/support/${protocolId}/false`,
    );
    socket.onopen = (): void => {
      setConnected(true);
    };
    socket.onmessage = (m: MessageEvent<string>) => {
      if (m.data === 'new message') {
        handleOnFetchSupport();
      }
    };
    socket.onclose = () => {
      connectToWebSocket(protocolId);
    };
    socket.onerror = () => {
      connectToWebSocket(protocolId);
    };
  };

  const getStatusType = (statusText: string): SupportStatus => {
    switch (statusText) {
      case 'Cancelado':
        return SupportStatus.CANCELED;
      case 'Fechado':
        return SupportStatus.CLOSED;
      case 'Em Atendimento':
        return SupportStatus.IN_PROGRESS;
      case 'Novo':
        return SupportStatus.NEW;
      case 'Reaberto':
        return SupportStatus.REOPENED;
      case 'Resolvido':
        return SupportStatus.RESOLVED;
      case 'Aguardando':
        return SupportStatus.WAITING;
      case 'Aguradando Cliente':
        return SupportStatus.WAITING_FOR_CLIENT;
      default:
        return SupportStatus.WAITING;
    }
  };

  const handleOnUpdateStatusConfirm = (): void => {
    confirmUpdate.show({
      title: '',
      children: <ConfirmUpdate />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero alterar',
          onClick: (): Promise<void> =>
            handleOnChangeStatus(getStatusType(formDataStatus[FormUpdateSupportStatus.status])),
        },
      ],
    });
  };

  const handleOnShouldShowModal = async (
    value: ShouldShowModalReverse,
    newTitleModal: string | React.ReactNode,
    orderSelected?: OrderSite,
  ): Promise<void> => {
    if (orderSelected) {
      try {
        setState(States.loading);
        const { data } = await api.get<OrderSite>(`/support/reverse/${orderSelected.id}`);

        setOrder(data);
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }

    setModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnConfirmCancelOrder = async (orderSelected: OrderSite): Promise<void> => {
    try {
      setState(States.loading);
      await api.patch(`/support/reverse/cancel/${orderSelected.id}`);
      toast.success('Pedido cancelado com sucesso!');
      handleOnClose();
      getOrderClient(orderSelected.client.cpf);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnConfirmReverseOrder = async (orderSelected: OrderSite): Promise<void> => {
    try {
      setState(States.loading);
      await api.patch(`/support/reverse/${orderSelected.id}`);
      toast.success('Pedido extornado com sucesso!');
      handleOnClose();
      getOrderClient(orderSelected.client.cpf);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShowCancelOrder = (orderSelected: OrderSite): void => {
    confirmDelete.show({
      title: '',
      children: <CancelContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero cancelar',
          onClick: (): Promise<void> => handleOnConfirmCancelOrder(orderSelected),
        },
      ],
    });
  };

  const handleOnShowReverseOrder = (orderSelected: OrderSite): void => {
    confirmDelete.show({
      title: '',
      children: <ReverseContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero extornar',
          onClick: (): Promise<void> => handleOnConfirmReverseOrder(orderSelected),
        },
      ],
    });
  };

  useEffect(() => {
    if (params.id) {
      handleOnFetchSupport();
    }
  }, [params]);

  useEffect(() => {
    if (support && !connected) {
      connectToWebSocket(support.id);
    }
  }, [support]);

  return (
    <ChatUI
      state={state}
      support={support}
      formData={formData}
      formErrors={formErrors}
      formDataStatus={formDataStatus}
      formErrorsStatus={formErrorsStatus}
      onChangeInput={onChangeFormInput}
      onChangeFileInput={handleOnChangeFileInput}
      onClearImage={handleOnClearImage}
      onSendMessage={handleOnSendMessage}
      onChangeFormInput={onChangeFormInputRegisterStatus}
      onUpdateStatusConfirm={handleOnUpdateStatusConfirm}
      currentPage={currentPage}
      onShouldShowModal={handleOnShouldShowModal}
      onShowCancelOrder={handleOnShowCancelOrder}
      onShowReverseOrder={handleOnShowReverseOrder}
      onToggle={onToggle}
      title={title}
      visible={visible}
      order={order}
      modal={modal}
    />
  );
};
