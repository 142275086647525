/* eslint-disable @typescript-eslint/explicit-function-return-type */
import api from '@/services/api';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FinalSettlementContent } from './ui';

export const FinalSettlementScreen = () => {
  const [finalSettlement, setFinalSettlement] = useState();
  const { id: eventId } = useParams<{ id: string }>();

  const getFinalSettlement = async () => {
    try {
      const { data } = await api.get(`/event/close/${eventId}/final-settlement`);
      setFinalSettlement(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFinalSettlement();
  }, []);

  return (
    <div className="finalSettlement">
      <FinalSettlementContent data={finalSettlement} eventId={eventId} />
    </div>
  );
};
