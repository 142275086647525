import { Button } from '@/components';
import { DataList } from '@/components/DataList';
import { CustomTable } from '@/components/Table';
import SuperCollapse from '@/components/sharedComponents/SuperCollapse';
import { toCurrency } from '@/helpers/masks/toCurrency';
import EventMainDateReport from '@/model/EventMainDateReport';
import React from 'react';

export interface SaleDateContentProps {
  generatePDF: () => void;
  saleDate: EventMainDateReport | undefined;
}

export const SaleDateContent: React.FC<SaleDateContentProps> = ({ saleDate, generatePDF }) => (
  <>
    <div className="d-flex justify-content-between">
      <h5>Vendas por data</h5>
      <Button theme="outlineDark" onClick={() => generatePDF()} title="Exportar relatório" />
    </div>
    <div
      className="customTableScroll p-4"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        gridColumnGap: '40px',
        overflow: 'auto',
        alignItems: 'end',
      }}
    >
      <DataList
        data={
          saleDate
            ? [
                {
                  title: 'Vendidos:',
                  content: saleDate.amountSold ? `${saleDate.amountSold}` : '-----',
                },
                {
                  title: 'Cortesias:',
                  content: saleDate.amountCourtesy ? saleDate.amountCourtesy : '-----',
                },
                {
                  title: 'Faturamento:',
                  content: saleDate.billingValue ? `${toCurrency(saleDate.billingValue)}` : '-----',
                },
                {
                  title: 'Descontos:',
                  content: saleDate.discounts ? `${toCurrency(saleDate.discounts)}` : '-----',
                },
                {
                  title: 'Faturamento com descontos:',
                  content: saleDate.billingValue ? `${toCurrency(saleDate.billingValue)}` : '-----',
                },
              ]
            : []
        }
      />
    </div>
    {saleDate &&
      saleDate.dates &&
      saleDate.dates.map((data, index) => (
        <React.Fragment key={`Date-${index}-${data.date}`}>
          <SuperCollapse
            width={'100%'}
            title={
              <CustomTable
                numberRowsPerPage={0}
                progressPending={false}
                theme="secondaryWithoutBorder"
                columns={[
                  {
                    name: 'Data',
                    selector: row => row.date,
                    width: '25%',
                  },
                  {
                    name: 'Tot Vendidos',
                    selector: row => row.amountSold,
                    width: '25%',
                  },
                  {
                    name: 'Cortesias',
                    selector: row => row.amountCourtesy,
                    width: '25%',
                  },
                  {
                    name: 'Valor total',
                    selector: row => row.value,
                    width: '25%',
                  },
                ]}
                data={
                  data.date
                    ? [
                        {
                          date: data.date,
                          amountSold: data.amountSold,
                          amountCourtesy: data.amountCourtesy,
                          value: toCurrency(data.value),
                        },
                      ]
                    : []
                }
              />
            }
            content={
              <>
                {data.events &&
                  data.events.length > 0 &&
                  data.events.map(event => (
                    <>
                      <h6>{event.eventName}</h6>
                      <CustomTable
                        key={event.eventId}
                        numberRowsPerPage={0}
                        progressPending={false}
                        theme="secondaryWithoutBorder"
                        columns={[
                          {
                            name: 'Ingresso',
                            selector: row => row.name,
                            width: '55%',
                          },
                          {
                            name: 'Vendidos',
                            selector: row => row.amountSold,
                            width: '15%',
                          },
                          {
                            name: 'Cancelados',
                            selector: row => row.amountCancel,
                            width: '15%',
                          },
                          {
                            name: 'Valor',
                            selector: row => row.value,
                            width: '15%',
                          },
                        ]}
                        data={
                          event.details && event.details.length > 0
                            ? event.details.map(detail => ({
                                name: `${detail.ticket.name} - ${detail.batch.name}`,
                                amountSold: detail.amountSold || '---',
                                amountCancel: detail.amountCancel || '---',
                                ticketsCancel: detail.value || '---',
                                value: toCurrency(detail.value) || '---',
                              }))
                            : []
                        }
                      />
                    </>
                  ))}
              </>
            }
          />
        </React.Fragment>
      ))}
  </>
);
