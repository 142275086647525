import { States } from '@/helpers/common/states';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import Log from '@/model/Log';
import Page from '@/model/Page';
import Person from '@/model/Person';
import api from '@/services/api';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FormInputFilterName, ShouldShowModal } from '../../types';
import { LogContainer } from './ui';

export const LogScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [page, setPage] = useState<Page<Log, Log>>({
    page: 1,
    pageSize: 10,
    sort: 'createdAt',
    order: 'DESC',
  });
  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.detail);
  const [logState, setLogState] = useState<Log>();

  const getPage = async (currentPage: Page<Log, Log>): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Page<Log, Log>>('/log/page', currentPage);
      setPage(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
  });

  const handleClearFilter = async (): Promise<void> => {
    resetFormFilter();
    const newPage = {
      page: 1,
      pageSize: 10,
      sort: 'createdAt',
      order: 'DESC',
    } as Page<Log, Log>;
    await getPage(newPage);
    onToggle();
  };

  const handleOnShouldShowModal = ({
    value,
    newTitleModal,
    log: logSelected,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    log?: Log;
  }): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    if (value === ShouldShowModal.detail) {
      if (logSelected) {
        setLogState(logSelected);
      }
    }
  };

  const handleOnFilter = async (): Promise<void> => {
    try {
      if (isFormValidFilter()) {
        const newPage: Page<Log, Log> = {
          ...page,
        };
        const entity: Log = {} as Log;
        if (formDataFilter[FormInputFilterName.filterSearch] === 'message') {
          entity.message = formDataFilter[FormInputFilterName.inputSearch];
        } else if (formDataFilter[FormInputFilterName.filterSearch] === 'personName') {
          const person = { name: formDataFilter[FormInputFilterName.inputSearch] } as Person;
          entity.person = person;
        } else if (formDataFilter[FormInputFilterName.filterSearch] === 'ipCreation') {
          entity.ipCreation = formDataFilter[FormInputFilterName.inputSearch];
        } else if (formDataFilter[FormInputFilterName.filterSearch] === 'createdAt') {
          const split = formDataFilter[FormInputFilterName.inputSearch].split('/');
          entity.createdAt = dayjs(`${split[2]}-${split[1]}-${split[0]}`).toDate();
        }

        newPage.page = 1;
        newPage.pageSize = 10;
        newPage.entity = entity;
        await getPage(newPage);
        onToggle();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnPaginationChange = async (pageNumber: number): Promise<void> => {
    try {
      if (isFormValidFilter()) {
        const newPage: Page<Log, Log> = {
          ...page,
          page: pageNumber,
        };
        await getPage(newPage);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnExport = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<string>('/log/export', page);
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  useEffect(() => {
    getPage(page);
  }, []);
  return (
    <LogContainer
      clearFilter={handleClearFilter}
      onToggle={onToggle}
      state={state}
      page={page}
      onShouldShowModal={handleOnShouldShowModal}
      title={title}
      visible={visible}
      shouldShowModal={shouldShowModal}
      onFilter={handleOnFilter}
      logState={logState}
      formDataFilter={formDataFilter}
      formErrorsFilter={formErrorsFilter}
      onChangeFormInputFilter={onChangeFormInputFilter}
      onExport={handleOnExport}
      onPaginationChange={handleOnPaginationChange}
    />
  );
};
