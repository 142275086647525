import { Loading } from '@/components';
import { DataList } from '@/components/DataList';
import Event from '@/model/Event';
import dayjs from 'dayjs';
import React from 'react';
import { Container } from 'reactstrap';
import { toPercentage } from '@/helpers/common/amount';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { formatToCPF } from 'brazilian-values';
import { States } from '../../types';

interface StateProps {
  state: States;
  event?: Event;
}

export const FinishedEventDetailContainer: React.FC<StateProps> = ({
  state,
  event,
}): JSX.Element => {
  const getEventType = (type: number): string => {
    switch (type) {
      case 0:
        return 'Mono';
      case 1:
        return 'Pai';
      case 2:
        return 'Filho';
      default:
        return '--';
    }
  };
  // Confirmação de dados
  const dataConfirmation = [
    {
      title: 'Nome do evento:',
      content: event?.name || '--',
    },
    {
      title: 'Tipo do evento:',
      content: getEventType(Number(event?.eventType)),
    },
    {
      title: 'Eventos filhos:',
      content: event?.childs?.map(child => child.name) || '--',
    },
    {
      title: 'Evento pai:',
      content: event?.fatherEvent?.name || '--',
    },
    {
      title: 'Cidade / Estado:',
      content:
        event?.address.city && event.address.state
          ? `${event?.address.city} / ${event?.address.state}`
          : '--',
    },
    {
      title: 'Data e hora início:',
      content:
        event?.startDate !== undefined
          ? `${dayjs(event?.startDate).format('DD/MM/YYYY')} - ${
              String(event?.startDate).split('T')[1].slice(0, 5) ?? ''
            }`
          : '--',
    },
    {
      title: 'Data e hora fim:',
      content:
        event?.startDate !== undefined
          ? `${dayjs(event?.endDate).format('DD/MM/YYYY')} - ${
              String(event?.endDate).split('T')[1].slice(0, 5) ?? ''
            }`
          : '--',
    },
    {
      title: 'Empresa ou contratante:',
      content: event?.contractors[0].name || '--',
    },
  ];
  return (
    <>
      <Loading isVisible={state === States.loading} />
      <Container className="mainContainer" fluid={true}>
        <div className="container-event">
          <h5 className="mb-2 border-bottom-title mb-5">Confirmação de dados</h5>
        </div>
        <div style={{ overflow: 'auto' }}>
          <DataList data={dataConfirmation} />
        </div>
        {event && event.tickets && event.tickets.length > 0 && (
          <>
            <h5 className="mb-2 border-bottom-title mb-5 container-event">Setores e Ingressos</h5>
            {event.tickets.map((item, index) => (
              <div style={{ overflow: 'auto' }} key={index}>
                <div className="mb-4">
                  <div className="mb-2 text-darkgray-regular">Nome do setor:</div>
                  <div>{item?.name || '--'}</div>
                </div>
                <div className="d-grid">
                  {item.batchs?.map(batch => (
                    <div
                      key={index}
                      className="mb-4"
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                        columnGap: '30px',
                      }}
                    >
                      <DataList
                        data={[
                          {
                            title: 'Lote:',
                            content: batch.name || '--',
                          },
                          {
                            title: 'Início da venda:',
                            content: `${dayjs(batch?.startDate).format('DD/MM/YYYY')} - ${
                              String(batch?.startDate).split('T')[1].slice(0, 5) ?? ''
                            }`,
                          },
                          {
                            title: 'Fim da venda:',
                            content: `${dayjs(batch?.startDate).format('DD/MM/YYYY')} - ${
                              String(batch?.startDate).split('T')[1].slice(0, 5) ?? ''
                            }`,
                          },
                          {
                            title: '% comissão:',
                            content: batch.commission ? `${toPercentage(batch.commission)}%` : '--',
                          },
                          {
                            title: 'Qtd de ingresso:',
                            content: batch.amount || '--',
                          },
                          {
                            title: 'Qtd cortesia:',
                            content: item?.amountCourtesy || '--',
                          },
                          {
                            title: 'Qtd meia:',
                            content: item.amountHalfPrice || '--',
                          },
                          {
                            title: 'Valor unitário:',
                            content: toCurrency(batch.unitValue) || '--',
                          },
                          {
                            title: <div style={{ fontWeight: '700' }}>Valor total:</div>,
                            content:
                              (
                                <div style={{ fontWeight: '700' }}>
                                  {toCurrency(batch.totalValue)}
                                </div>
                              ) || '--',
                          },
                        ]}
                      />
                    </div>
                  ))}
                </div>
                {event.tickets && event.tickets.length - 1 !== index && (
                  <div
                    key={index}
                    className="mb-5 mt-5"
                    style={{ borderBottom: 'solid 1px #D9D9D9' }}
                  />
                )}
              </div>
            ))}
          </>
        )}

        {event && event.sectionproductsAndCombos && event.sectionproductsAndCombos.length > 0 && (
          <>
            <h5 className="mb-2 border-bottom-title mb-5 container-event">Setores e Produtos</h5>
            {event.sectionproductsAndCombos.map((section, indexSector) => (
              <div
                style={{ overflow: 'auto' }}
                key={`${indexSector}-sectionproductsAndCombos-${section.section.id}`}
              >
                {indexSector > 0 ? <hr style={{ margin: '25px 0px 30px 0px' }} /> : null}
                <div className="text-darkgray-regular">Nome do setor:</div>
                <div className="mb-4">{section.section.name}</div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                    columnGap: '30px',
                  }}
                >
                  <DataList
                    data={[
                      {
                        title: 'Grupo:',
                        content:
                          section.products.find(item => item?.group?.name)?.group.name || '--',
                        width: '100%',
                      },
                      {
                        title: 'Subgrupo:',
                        content:
                          section.products.find(item => item?.subgroup?.name)?.subgroup.name ||
                          '--',
                      },
                    ]}
                  />
                </div>
                <div
                  className="mb-4"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                    columnGap: '30px',
                  }}
                >
                  {section.products && section.products.length > 0 && (
                    <DataList
                      data={[
                        {
                          title: 'Produto',
                          content: section.products.map(item => item.name) || '--',
                        },
                        {
                          title: 'Qtd',
                          content: section.products.map(item => item.amount) || '--',
                        },
                        {
                          title: 'Valor un',
                          content:
                            section.products.map(item => toCurrency(item.totalValue)) || '--',
                        },
                        {
                          title: 'Total estimado',
                          content: section.products.map(
                            item => toCurrency(item.totalValue * item.amount) || '--',
                          ),
                        },
                        {
                          title: 'Tx Deb',
                          content: section.products.map(item =>
                            item.physicalSale?.debit
                              ? `${toPercentage(item.physicalSale?.debit)}%`
                              : '--',
                          ),
                        },
                        {
                          title: 'Tx Cred',
                          content: section.products.map(item =>
                            item.physicalSale?.credit
                              ? `${toPercentage(item.physicalSale?.credit)}%`
                              : '--',
                          ),
                        },
                        {
                          title: 'Tx Pix ',
                          content: section.products.map(item =>
                            item.physicalSale?.pix
                              ? `${toPercentage(item.physicalSale?.pix)}%`
                              : '--',
                          ),
                        },
                        {
                          title: 'Tx Admin',
                          content: section.products.map(item =>
                            item.physicalSale?.administrateTax
                              ? `${toPercentage(item.physicalSale?.administrateTax)}%`
                              : '--',
                          ),
                        },
                        {
                          title: 'Parcelas',
                          content: section.products.map(item =>
                            item.physicalSale?.installments
                              ? item.physicalSale?.installments
                              : '--',
                          ),
                        },
                        {
                          title: 'Jur mês',
                          content: section.products.map(item =>
                            item.physicalSale?.fee
                              ? `${toPercentage(item.physicalSale?.fee)}%`
                              : '--',
                          ),
                        },
                      ]}
                    />
                  )}

                  {section.combos && section.combos.length > 0 && (
                    <DataList
                      data={[
                        {
                          title: 'Combos',
                          content: section?.combos?.map(item => item.name) || '--',
                        },
                        {
                          title: 'Qtd',
                          content: section?.combos?.map(item => item.amount) || '--',
                        },
                        {
                          title: 'Valor un',
                          content:
                            section?.combos?.map(item => toCurrency(item.totalValue)) || '--',
                        },
                        {
                          title: 'Total estimado',
                          content:
                            section?.combos?.map(item =>
                              toCurrency(item.totalValue * item.amount),
                            ) || '--',
                        },
                        {
                          title: 'Tx Deb',
                          content: section?.combos?.map(item =>
                            item.physicalSale?.debit
                              ? `${toPercentage(item.physicalSale?.debit)}%`
                              : '--',
                          ),
                        },
                        {
                          title: 'Tx Cred',
                          content: section?.combos?.map(item =>
                            item.physicalSale?.credit
                              ? `${toPercentage(item.physicalSale?.credit)}%`
                              : '--',
                          ),
                        },
                        {
                          title: 'Tx Pix ',
                          content: section?.combos?.map(item =>
                            item.physicalSale?.pix
                              ? `${toPercentage(item.physicalSale?.pix)}%`
                              : '--',
                          ),
                        },
                        {
                          title: 'Tx Admin',
                          content: section?.combos?.map(item =>
                            item.physicalSale?.administrateTax
                              ? `${toPercentage(item.physicalSale?.administrateTax)}%`
                              : '--',
                          ),
                        },
                        {
                          title: 'Parcelas',
                          content: section?.combos?.map(item =>
                            item.physicalSale?.installments
                              ? item.physicalSale?.installments
                              : '--',
                          ),
                        },
                        {
                          title: 'Jur mês',
                          content: section?.combos?.map(item =>
                            item.physicalSale?.fee
                              ? `${toPercentage(item.physicalSale?.fee)}%`
                              : '--',
                          ),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            ))}
          </>
        )}

        {event && event.pdvs && event.pdvs.length > 0 && (
          <>
            <h5 className="mb-2 border-bottom-title mb-5 container-event">PDV’s</h5>
            {event.pdvs.map((item, index) => (
              <div style={{ overflow: 'auto' }} key={index}>
                <div className="mb-4">
                  <div className="text-darkgray-regular">Nome do PDV</div>
                  <div>{item.pdv.name}</div>
                </div>

                {item.poss && item.poss.length > 0 ? (
                  item.poss.map(pos => (
                    <div
                      key={pos?.pos?.id}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                        columnGap: '30px',
                      }}
                    >
                      <DataList
                        data={[
                          {
                            title: 'Nome da POS:',
                            content: pos?.pos?.name,
                          },
                          {
                            title: 'Nº de série:',
                            content: pos?.pos?.serialNumber,
                          },
                          {
                            title: '% do Garçom:',
                            content: `${toPercentage(pos?.waiter)}%`,
                          },
                        ]}
                      />
                    </div>
                  ))
                ) : (
                  <div className="mb-4">
                    <div className="text-darkgray-regular">Nome da POS:</div>
                    <div>Nenhuma POS cadastrada</div>
                  </div>
                )}

                <DataList
                  data={[
                    {
                      title: 'Usuários do PDV:',
                      content:
                        item?.pdv?.users?.length > 0
                          ? item?.pdv?.users?.map(
                              user => `${formatToCPF(user?.cpf)} (${user?.name})`,
                            )
                          : ['Nenhum usuario cadastrado'],
                    },
                  ]}
                />
                <DataList
                  data={[
                    {
                      title: 'SubPDV’s:',
                      content:
                        item.subPdvs && item.subPdvs.length > 0
                          ? item.subPdvs.map(subPdv => subPdv.subPdv.name)
                          : ['Nenhum subpdv cadastrado'],
                    },
                  ]}
                />
                {event?.pdvs && index === event.pdvs.length - 1 ? null : <hr />}
              </div>
            ))}
          </>
        )}
      </Container>
    </>
  );
};
