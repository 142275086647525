import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

export const ConfirmUpdate: React.FC = (): JSX.Element => (
  <React.Fragment>
    <div className="exclude-container">
      <FaExclamationTriangle color="#E64F49" style={{ fontSize: 40 }} />
      <div className="header-title-text modal__title">
        <h5 className="modal__confirmation-title">Você tem certeza que quer atualizar o status?</h5>
      </div>
    </div>
  </React.Fragment>
);
