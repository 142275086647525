import { Button, Dialog, InputText, Loading } from '@/components';
import { ActionProps } from '@/components/Dialog';
import { CustomTable } from '@/components/Table';
import { States } from '@/helpers/common/states';
import Event from '@/model/Event';
import Ticket from '@/model/Ticket';
import { colors } from '@/styles/colors';
import React, { Fragment } from 'react';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import { Col, Container, FormGroup, Row } from 'reactstrap';
import { ReactComponent as CloseX } from '@/assets/images/svg/closeX.svg';
import { ReactComponent as ItemConfig } from '@/assets/images/svg/ItemConfig.svg';
import { ReactComponent as Pen } from '@/assets/images/svg/pen.svg';
import Section from '@/model/Section';
import EventMap from '@/model/EventMap';
import EventMapTicket from '@/model/EventMapTicket';
import { ShowMap } from '../../components/ShowMap';
import {
  FormInputName,
  FormInputRegister,
  MapControler,
  OnShouldShowModalProps,
  ShouldShowModal,
} from '../../types';
import { ConfigureSector } from '../../components/ConfigureSector';
import { TicketContent } from '../../components/TicketContent';

interface StateProps {
  state: States;
  event: Event;
  eventMap: EventMap;
  mapControler: MapControler;
  title: React.ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  configureSectorControler: MapControler;
  sectionId?: string;
  sections: Section[];
  sectionTickets: Ticket[];
  ticketControler: MapControler;
  mapTickets: EventMapTicket[];
  eventKey?: string;
}

interface DispatchProps {
  onToggle: () => void;
  onShouldShowModal: (props: OnShouldShowModalProps) => void;
  back: () => void;
  save: () => void;
  onCancel: () => void;
  openSection: (sectionId: string) => void;
  saveSection: () => void;
  removeSection: (sectionId: string) => void;
  openTicket: (sectionId: string, ticketId?: string | undefined) => void;
  saveTicket: (sectionId: string) => void;
  removeTicket: (sectionId: string, ticketId: string) => void;
}

type EventMapContainerProps = StateProps & DispatchProps;

export const EventMapContainer: React.FC<EventMapContainerProps> = ({
  state,
  event,
  eventMap,
  mapControler,
  title,
  visible,
  onToggle,
  shouldShowModal,
  onShouldShowModal,
  back,
  save,
  removeSection,
  configureSectorControler,
  sectionId,
  sections,
  mapTickets,
  sectionTickets,
  onCancel,
  openSection,
  saveSection,
  ticketControler,
  openTicket,
  saveTicket,
  removeTicket,
  eventKey,
}) => {
  const renderActionDialogToCancel: ActionProps = {
    title: 'Cancelar',
    onClick: () => onCancel(),
    theme: 'noneBorder',
  };

  const dataTicket =
    eventMap && eventMap.sections && eventMap.sections.length > 0
      ? eventMap &&
        eventMap.sections &&
        eventMap.sections.map(item => ({
          id: item.id,
          name: item.name,
          actions: (
            <div className="d-flex m-2 ml-4">
              <Pen className="mr-4 svg-icon action-icon" onClick={() => openSection(item.id)} />
              <ItemConfig
                className="mr-4 svg-icon action-icon"
                onClick={() => openTicket(item.id)}
              />
              <CloseX
                className="mr-2 svg-icon action-icon svg-icon-trash"
                onClick={() => removeSection(item.id)}
              />
            </div>
          ),
        }))
      : [];
  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.map]: renderActionDialogToCancel,
            [ShouldShowModal.confg]: renderActionDialogToCancel,
            [ShouldShowModal.ticket]: renderActionDialogToCancel,
          }[shouldShowModal],
          {
            [ShouldShowModal.map]: {},
            [ShouldShowModal.confg]: {
              title:
                configureSectorControler.formData[FormInputRegister.id] &&
                configureSectorControler.formData[FormInputRegister.id].trim().length > 0
                  ? 'Salvar'
                  : 'Cadastrar nova Categoria',
              onClick: (): void => saveSection(),
            },
            [ShouldShowModal.ticket]: {
              title:
                sectionId && sectionId.trim().length > 0 ? 'Salvar' : 'Cadastrar novo Ingresso',
              onClick: (): void => saveTicket(sectionId as string),
            },
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.map]: (
              <ShowMap
                eventKey={eventKey as string}
                workspaceKey={mapControler.formData[FormInputName.publicWorkspaceKey]}
                eventMap={eventMap}
              />
            ),
            [ShouldShowModal.confg]: (
              <ConfigureSector
                configureSectorControler={configureSectorControler}
                sections={sections}
              />
            ),
            [ShouldShowModal.ticket]: (
              <TicketContent
                sectionId={sectionId}
                eventMap={eventMap}
                ticketControler={ticketControler}
                tickets={sectionTickets}
                mapTickets={mapTickets}
                onShouldShowModal={onShouldShowModal}
                openTicket={openTicket}
                removeTicket={removeTicket}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="pageTitle d-flex mb-5">
          <Link to={`${process.env.PUBLIC_URL}/dashboard/event`}>
            <ArrowLeft color={colors.black} className="arrow-left" />
          </Link>
          <h5 className="ml-3 mb-0 mt-2 pageTitle">{`Mapa do evento - ${event?.name}`}</h5>
        </div>
        <h5 className="mb-2 border-bottom-title mb-5">Chaves</h5>
        <Row>
          <Col md={8}>
            <FormGroup className="mb-2">
              <InputText
                name="secretWorkspaceKey"
                label="Secret workspace key"
                placeholder="Digite a secret do evento do Seats.io. Ex: 2b97722e-350f-4c7e-82dc-18682535105c"
                value={mapControler.formData[FormInputName.secretWorkspaceKey]}
                onChange={e =>
                  mapControler.onChangeFormInput(FormInputName.secretWorkspaceKey)(e.target.value)
                }
                error={
                  mapControler.formErrors.secretWorkspaceKey &&
                  mapControler.formErrors.secretWorkspaceKey[0]
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup className="mb-2">
              <InputText
                name="publicWorkspaceKey"
                label="Public workspace key"
                placeholder="Digite o key publica do Workspace. Ex: 1b423395-161d-4ef1-a06b-6a7a73d67795"
                value={mapControler.formData[FormInputName.publicWorkspaceKey]}
                onChange={e =>
                  mapControler.onChangeFormInput(FormInputName.publicWorkspaceKey)(e.target.value)
                }
                error={
                  mapControler.formErrors.publicWorkspaceKey &&
                  mapControler.formErrors.publicWorkspaceKey[0]
                }
              />
            </FormGroup>
          </Col>
        </Row>

        <hr />
        <div className="footer-register-event">
          <Button title="Voltar" theme="noneBorder" onClick={() => back()} />
          <Button title="Salvar" onClick={() => save()} />
        </div>
        <h5 className="mb-2 border-bottom-title mb-5">Categorias</h5>
        <Row>
          <Col md={8}>
            {eventMap && eventMap.sections && eventMap.sections.length > 0 ? (
              <CustomTable
                columns={[
                  {
                    name: 'Categoria',
                    selector: row => row.name,
                  },
                  {
                    name: '',
                    selector: row => row.actions,
                    maxWidth: '160px',
                  },
                ]}
                data={dataTicket}
                theme="tertiary"
                progressPending={false}
                numberRowsPerPage={1}
              />
            ) : (
              <>
                <div style={{ padding: '10px 0 20px 0', color: '#A5A5A5' }}>
                  Você ainda não inseriu nenhum Setor.
                </div>
                <div style={{ color: '#A5A5A5', paddingBottom: '30px' }}>
                  Aqui será exibida uma lista setores inseridos neste Evento.
                </div>
              </>
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <div
            className="link-green"
            onClick={() =>
              onShouldShowModal({
                value: ShouldShowModal.confg,
                newTitleModal: 'Configurar Categoria',
              })
            }
          >
            + Adcionar Categoria
          </div>
        </div>
      </Container>
    </Fragment>
  );
};
