/* eslint-disable @typescript-eslint/no-use-before-define */
import { DeleteContent } from '@/components/DeleteContent';
import { States } from '@/helpers/common/states';
import validators from '@/helpers/validators';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import Document from '@/model/Document';
import DocumentCategory from '@/model/DocumentCategory';
import Page from '@/model/Page';
import User from '@/model/User';
import api from '@/services/api';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  FormInputDocumentCategoryName,
  FormInputDocumentName,
  FormInputFilterName,
  ShouldShowModal,
} from '../../types';
import { DocumentContainer } from './ui';

export const DocumentScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [page, setPage] = useState<Page<Document, Document>>({
    page: 1,
    pageSize: 10,
    sort: 'bindingDate',
    order: 'DESC',
  });
  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(
    ShouldShowModal.registerDocument,
  );
  const [documentState, setDocumentState] = useState<Document>();
  const [categories, setCategories] = useState<DocumentCategory[]>([]);
  const [userList, setUserList] = useState<User[]>([]);
  const [documentUsers, setDocumentUsers] = useState<User[]>([]);

  const confirmDelete = useConfirmDelete();

  const getPage = async (currentPage: Page<Document, Document>): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Page<Document, Document>>('/document/page', currentPage);
      setPage(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const getCategories = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<DocumentCategory[]>('/document-category/find');
      const response = await api.get<User[]>('/user/find');
      if (response.data && response.data.length > 0) {
        const list: User[] = [];
        response.data.forEach(user => {
          let found = false;
          if (documentUsers && documentUsers.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < documentUsers.length; i++) {
              if (user.id === documentUsers[i].id) {
                found = true;
                break;
              }
            }
          }
          if (!found) {
            list.push(user);
          }
        });
        setUserList(list);
      }
      setCategories(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
  });

  const {
    formData: formDataDocument,
    formErrors: formErrorsDocument,
    onChangeFormInput: onChangeFormInputDocument,
    isFormValid: isFormValidDocument,
    resetForm: resetFormDocument,
    setFormErrors: setFormErrorsDocument,
    // setErrors: setErrorsDocument,
  } = useForm({
    initialData: {
      title: '',
      description: '',
      fileURL: '',
      fileName: '',
      category: '',
      user: '',
    },
    validators: {
      title: [validators.required],
      description: [validators.required],
      fileURL: [validators.required],
      category: [validators.required],
    },
  });

  const {
    formData: formDataDocumentCategory,
    formErrors: formErrorsDocumentCategory,
    onChangeFormInput: onChangeFormInputDocumentCategory,
    isFormValid: isFormValidDocumentCategory,
    resetForm: resetFormDocumentCategory,
  } = useForm({
    initialData: {
      title: '',
      description: '',
    },
    validators: {
      name: [validators.required],
      description: [validators.required],
    },
  });

  const handleClearFilter = async (): Promise<void> => {
    resetFormFilter();
    const newPage = { page: 1, pageSize: 10, sort: 'bindingDate', order: 'DESC' } as Page<
      Document,
      Document
    >;
    await getPage(newPage);
    onToggle();
  };

  const handleOnDownload = (fileUrl: string): void => {
    const link = document.createElement('a');
    link.download = fileUrl.split('/').pop() as string;
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOnShouldShowModal = ({
    value,
    newTitleModal,
    document: documentSelected,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    document?: Document;
    isEdit?: boolean;
  }): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);

    if (value === ShouldShowModal.registerDocument) {
      getCategories();
      if (documentSelected) {
        setDocumentState(documentSelected);
        setDocumentUsers(documentSelected.users);
        onChangeFormInputDocument(FormInputDocumentName.title)(documentSelected.title);
        onChangeFormInputDocument(FormInputDocumentName.description)(documentSelected.description);
        onChangeFormInputDocument(FormInputDocumentName.fileName)(
          documentSelected.fileURL.split('/').pop() as string,
        );
        onChangeFormInputDocument(FormInputDocumentName.fileURL)(documentSelected.fileURL);
        onChangeFormInputDocument(FormInputDocumentName.category)(documentSelected.category.id);
      }
    }
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnConfirmDeleteDocument = async (selectedDocument: Document): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(`/document/${selectedDocument.id}`);
      toast.success('Documento excluído com sucesso!');
      handleOnClose();
      getPage(page);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShowDeleteDocument = (selectedDocument: Document): void => {
    confirmDelete.show({
      title: '',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnConfirmDeleteDocument(selectedDocument),
        },
      ],
    });
  };

  const handleOnFilter = async (): Promise<void> => {
    try {
      if (isFormValidFilter()) {
        const newPage: Page<Document, Document> = {
          ...page,
        };
        const entity: Document = {} as Document;
        if (formDataFilter[FormInputFilterName.filterSearch] === 'name') {
          entity.title = formDataFilter[FormInputFilterName.inputSearch];
        } else if (formDataFilter[FormInputFilterName.filterSearch] === 'category') {
          const category: DocumentCategory = {} as DocumentCategory;
          category.name = formDataFilter[FormInputFilterName.inputSearch];
          entity.category = category;
        } else if (formDataFilter[FormInputFilterName.filterSearch] === 'bindingDate') {
          const split = formDataFilter[FormInputFilterName.inputSearch].split('/');
          entity.bindingDate = dayjs(`${split[2]}-${split[1]}-${split[0]}`).toDate();
        }
        newPage.page = 1;
        newPage.pageSize = 10;
        newPage.entity = entity;
        await getPage(newPage);
        onToggle();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnSaveDocument = async (): Promise<void> => {
    if (isFormValidDocument()) {
      if (documentUsers && documentUsers.length > 0) {
        try {
          setState(States.loading);
          const users: User[] = [];
          documentUsers.forEach(data => {
            users.push({ id: data.id } as User);
          });
          const payload = {
            title: formDataDocument[FormInputDocumentName.title],
            description: formDataDocument[FormInputDocumentName.description],
            fileURL: formDataDocument[FormInputDocumentName.fileURL],
            category: { id: formDataDocument[FormInputDocumentName.category] } as DocumentCategory,
            users,
          } as Document;
          if (documentState && documentState.id) {
            payload.id = documentState.id;
            await api.put('/document', payload);
            toast.success('Documento atualizado com sucesso!');
          } else {
            await api.post('/document', payload);
            toast.success('Documento inserido com sucesso!');
          }
          resetFormDocument();
          const newDocumentUsers: User[] = [];
          const newUserList: User[] = [];
          documentUsers.forEach(data => {
            newUserList.push(data);
          });
          setDocumentUsers(newDocumentUsers);

          userList.forEach(data => {
            newUserList.push(data);
          });

          const userSorted = newUserList.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          setUserList(userSorted);
          onToggle();
          getPage(page);
        } catch (error) {
          const err = error as AxiosError;
          toast.error(err.message);
        } finally {
          setState(States.default);
        }
      } else {
        setFormErrorsDocument({
          [FormInputDocumentName.user]: ['É necessário vincular usuário(s)'],
        });
      }
    }
  };

  const handleOnPaginationChange = async (pageNumber: number): Promise<void> => {
    try {
      if (isFormValidFilter()) {
        const newPage: Page<Document, Document> = {
          ...page,
          page: pageNumber,
        };
        await getPage(newPage);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      onChangeFormInputDocument(FormInputDocumentName.fileName)(e.target.files[0].name);
      const reader = new FileReader();
      reader.onload = () => {
        onChangeFormInputDocument(FormInputDocumentName.fileURL)(reader.result as string);
      };
      reader.onerror = (error: ProgressEvent<FileReader>) => {
        window.console.error('Error: ', error);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleOnSaveDocumentCategory = async (): Promise<void> => {
    if (isFormValidDocumentCategory()) {
      try {
        setState(States.loading);
        const payload = {
          name: formDataDocumentCategory[FormInputDocumentCategoryName.name],
          description: formDataDocumentCategory[FormInputDocumentCategoryName.description],
        } as DocumentCategory;
        await api.post('/document-category', payload);
        toast.success('Categoria incluida com sucesso!');
        resetFormDocumentCategory();
        handleOnShouldShowModal({
          value: ShouldShowModal.registerDocument,
          newTitleModal: documentState?.id
            ? `Documento ${documentState.title}`
            : 'Upload de documento',
          document: documentState,
          isEdit: true,
        });
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  const handleOnAddUser = (userId: string): void => {
    const newDocumentUsers: User[] = [];
    const newUserList: User[] = [];
    userList.forEach(data => {
      if (data.id !== userId) {
        newUserList.push(data);
      } else {
        newDocumentUsers.push(data);
      }
    });
    documentUsers.forEach(data => {
      newDocumentUsers.push(data);
    });
    const documentUsersSorted = newDocumentUsers.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setDocumentUsers(documentUsersSorted);
    setUserList(newUserList);
  };

  const handleOnDeleteUser = (user: User): void => {
    const newDocumentUsers: User[] = [];
    const newUserList: User[] = [];
    documentUsers.forEach(data => {
      if (data.id !== user.id) {
        newDocumentUsers.push(data);
      } else {
        newUserList.push(data);
      }
    });
    setDocumentUsers(newDocumentUsers);

    userList.forEach(data => {
      newUserList.push(data);
    });

    const userSorted = newUserList.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setUserList(userSorted);
  };

  useEffect(() => {
    getPage(page);
  }, []);
  return (
    <DocumentContainer
      clearFilter={handleClearFilter}
      onToggle={onToggle}
      state={state}
      page={page}
      onDownload={handleOnDownload}
      onShouldShowModal={handleOnShouldShowModal}
      onShowDeleteDocument={handleOnShowDeleteDocument}
      title={title}
      visible={visible}
      shouldShowModal={shouldShowModal}
      onFilter={handleOnFilter}
      documentState={documentState}
      onSaveDocument={handleOnSaveDocument}
      formErrorsDocument={formErrorsDocument}
      formDataFilter={formDataFilter}
      formErrorsFilter={formErrorsFilter}
      onChangeFormInputFilter={onChangeFormInputFilter}
      formDataDocument={formDataDocument}
      onChangeFormInputDocument={onChangeFormInputDocument}
      categories={categories}
      userList={userList}
      documentUsers={documentUsers}
      onPaginationChange={handleOnPaginationChange}
      onChangeFile={handleOnChangeFile}
      onSaveDocumentCategory={handleOnSaveDocumentCategory}
      formErrorsDocumentCategory={formErrorsDocumentCategory}
      onChangeFormInputDocumentCategory={onChangeFormInputDocumentCategory}
      formDataDocumentCategory={formDataDocumentCategory}
      onAddUser={handleOnAddUser}
      onDeleteUser={handleOnDeleteUser}
    />
  );
};
