import React from 'react';
import { Container } from 'reactstrap';
import dayjs from 'dayjs';
import { Button, Dialog, Loading } from '@/components';
import { CustomTable } from '@/components/Table';
import { States } from '@/helpers/common/states';
import { updateMask } from '@/helpers/masks/cpf';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import { ActionProps } from '@/components/Dialog';
import FilterVector from '@/assets/images/svg/FilterVector';
import { FilterContent } from '@/features/reverse/components/FilterContent';
import { ShouldShowModal } from '@/features/reprint/types';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import Page from '@/model/Page';
import Pagination from '@/components/Utils/Pagination';
import Reprint from '@/model/Reprint';
import { ReprintDetailContent } from '@/features/reprint/components/ReprintDetailContent';
import { TokenContent } from '@/features/reprint/components/TokenContent';
import { DataRow, columns } from './table';

interface StateProps {
  state: States;
  currentPage: Page<Reprint, Reprint>;
  title: string | React.ReactNode;
  visible: boolean;
  modal: ShouldShowModal;
  formDataFilter: FormData;
  formErrorsFilter: FormErrors;
  token?: string;
  reprint?: Reprint;
}

interface DispatchProps {
  onPaginationChange: (page: number) => void;
  onShouldShowModal: (
    value: ShouldShowModal,
    newTitleModal: string | React.ReactNode,
    reprintSelected?: Reprint,
  ) => void;
  onToggle: () => void;
  onChangeFormInputFilter: OnChangeFormInput;
  onFilter: () => void;
  onClearFilter: () => void;
}

type Props = StateProps & DispatchProps;

export const ReprintContainer: React.FC<Props> = ({
  state,
  currentPage,
  onPaginationChange,
  onShouldShowModal,
  title,
  visible,
  onToggle,
  modal,
  formDataFilter,
  formErrorsFilter,
  onChangeFormInputFilter,
  onFilter,
  onClearFilter,
  reprint,
}): JSX.Element => {
  const mountDataTable = (): DataRow[] => {
    const list: DataRow[] = [];
    if (currentPage && currentPage.list && currentPage.list.length > 0) {
      currentPage.list.forEach(data => {
        const dataRow: DataRow = {
          cpfCreated: updateMask(data.cpfCreated),
          token: data.token,
          createdAt: dayjs(data.createdAt).format('DD/MM/YYYY [às] HH:mm'),
          used: data.cpfUsed ? 'SIM' : 'NÃO',
          actions: (
            <React.Fragment>
              <Detail
                className={'mr-4 svg-icon action-icon'}
                onClick={() => onShouldShowModal(ShouldShowModal.detail, 'Pedido', data)}
              />
            </React.Fragment>
          ),
        };
        list.push(dataRow);
      });
    }
    return list;
  };

  const renderActionDialogToFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => onClearFilter(),
    theme: 'noneBorder',
  };

  const renderActionDialogToCancel: ActionProps = {
    title: 'Fechar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };

  return (
    <React.Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={'center'}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToFilter,
            [ShouldShowModal.token]: {},
            [ShouldShowModal.detail]: renderActionDialogToCancel,
          }[modal],
          {
            [ShouldShowModal.filter]: {
              title: 'Filtrar',
              onClick: () => onFilter(),
            },
            [ShouldShowModal.token]: {},
            [ShouldShowModal.detail]: {
              title: 'OK',
            },
          }[modal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formDataFilter}
                formErrors={formErrorsFilter}
                onChangeFormInput={onChangeFormInputFilter}
              />
            ),
            [ShouldShowModal.token]: <TokenContent reprint={reprint} />,
            [ShouldShowModal.detail]: <ReprintDetailContent reprint={reprint} />,
          }[modal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <h5 className="pageTitle">Reimprimir Ingresso</h5>
          <div className="button-filter-container">
            <Button
              size="md"
              title="+ Gerar novo Token"
              onClick={(): void => {
                onShouldShowModal(ShouldShowModal.token, 'Gerar novo Token');
              }}
            />
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onShouldShowModal(ShouldShowModal.filter, '');
                }}
              >
                <FilterVector />
              </div>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={mountDataTable()}
          numberRowsPerPage={currentPage.total || 0}
          progressPending={state === States.loading}
        />
        <Pagination
          currentPage={currentPage.page}
          totalCount={currentPage.total}
          pageSize={currentPage.pageSize}
          onPageChange={(page: number) => onPaginationChange(page)}
          total={currentPage.total}
        />
      </Container>
    </React.Fragment>
  );
};
