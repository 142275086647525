import React, { Fragment } from 'react';
import { Loading } from '@/components';
import { Container } from 'reactstrap';
import './style.scss';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { colors } from '@/styles/colors';
import { CustomTable } from '@/components/Table';
// import dayjs from 'dayjs';
import { FooterCustom } from '@/components/FooterCustom';
import EventClosePosticketSalePayment from '@/model/EventClosePosticketSalePayment';
import PosStatus from '@/model/PosStatus';
import PaymentType from '@/model/PaymentType';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { toPercentage } from '@/helpers/common/amount';
import { columnsMachinesReportDetails } from './table';

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

interface MachinesReportProps {
  state: States;
  eventLocation: any;
  machinesReportList: any[];
  machinesReportFooter: any;
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  infoMachinesReport = 'infoMachinesReport',
}

export const MachinesReportContainer: React.FC<MachinesReportProps> = ({
  state,
  eventLocation,
  machinesReportList,
  machinesReportFooter,
}) => {
  const { id: eventId } = useParams<{ id: string }>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const numberToPaymentType = (status: PaymentType) => {
    switch (status) {
      case PaymentType.CREDIT_CARD:
        return 'Crédito:';
      case PaymentType.DEBIT_CARD:
        return 'Débito:';
      case PaymentType.MONEY:
        return 'Dinheiro:';
      case PaymentType.PIX:
        return 'PIX:';
      case PaymentType.TWO_CREDIT_CARDS:
        return 'Dois cartões:';
      default:
        return 'Sem dados:';
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const numberToStatus = (status: PosStatus) => {
    switch (status) {
      case PosStatus.USE:
        return (
          <div className="filterActive" style={{ backgroundColor: '#7AD81B10' }}>
            <div style={{ color: '#7AD81B' }}>Em uso</div>
          </div>
        );
      case PosStatus.INACTIVE:
        return (
          <div className="filterActive" style={{ backgroundColor: '#E54F4910' }}>
            <div style={{ color: '#E54F49' }}>Inativa</div>
          </div>
        );
      case PosStatus.RESERVED:
        return (
          <div className="filterActive" style={{ backgroundColor: '#F6D93E10' }}>
            <div style={{ color: '#F6D93E' }}>Reservada</div>
          </div>
        );
      case PosStatus.STOCK:
        return (
          <div className="filterActive" style={{ backgroundColor: '#3CAFC810' }}>
            <div style={{ color: '#3CAFC8' }}>Em estoque</div>
          </div>
        );
      default:
        return 'Sem dados';
    }
  };

  const dataTableMachinesReport = machinesReportList?.map((item: any, index) => ({
    id: index,
    machineId: index < 10 ? `0${index + 1}` : index + 1,
    machine: item?.posName,
    situation: numberToStatus(item?.posStatus),
    pdv: item?.pdvName === 'N/A' ? '-----' : item?.pdvName,
    section: item?.sectionName === 'N/A' ? '-----' : item?.sectionName,
    serial: item?.serialNumber,
    daysofuse: item?.daysUse,
    value: toCurrency(item?.value),
    difference: toCurrency(item?.difference),
    debit: toCurrency(item?.debit),
    credit: toCurrency(item?.credit),
    deductmoney: toCurrency(item?.money),
    pix: toCurrency(item?.pix),
    totalValue: toCurrency(item?.totalValue),
  }));

  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Container className="mainContainer" fluid={true}>
        <div className="pageTitle d-flex mb-5">
          <Link to={`${process.env.PUBLIC_URL}/financial/event-close/machines/${eventId}`}>
            <ArrowLeft color={colors.black} className="arrow-left" />
          </Link>
          <h5 className="ml-3 mb-0 mt-2 pageTitle">
            Relatório PagSeguro {eventLocation?.event?.name && `- ${eventLocation?.event?.name}`}
          </h5>
        </div>
        <CustomTable
          columns={columnsMachinesReportDetails}
          data={dataTableMachinesReport}
          numberRowsPerPage={1000}
          theme="primary"
          progressPending={state === States.loading}
        />
      </Container>
      <FooterCustom
        data={
          machinesReportFooter?.payments?.map((item: EventClosePosticketSalePayment) => ({
            title: numberToPaymentType(item?.paymentType),
            value: toCurrency(item?.paymentValue),
            fee: `${toPercentage(item?.fee)}%`,
            feeValue: toCurrency(item?.feeValue),
            netAmount: toCurrency(item?.netAmount),
          })) || [
            {
              title: 'Sem dados',
              value: null,
            },
          ]
        }
        theme="secondary"
        summaryData={toCurrency(machinesReportFooter.totalNetAmount)}
      />
    </Fragment>
  );
};
