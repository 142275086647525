/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable arrow-body-style */
import { Button, ButtonGroup, InputFile, InputText, SelectCustom } from '@/components';
import { CustomTable } from '@/components/Table';
import { OnChangeFormInput, FormData, FormErrors } from '@/hooks/useForm';
import React, { Fragment } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import Event from '@/model/Event';
import EventCourtesy from '@/model/EventCourtesy';
import Section from '@/model/Section';
import Tickets from '@/model/Tickets';
import { ReactComponent as Trash } from '@/assets/images/svg/lixeira.svg';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import { columns } from './table';

export interface DataRow {
  description: string;
  client: number;
  ticket: string;
  amount: string;
  actions: string;
}

// eslint-disable-next-line no-shadow
export enum FormInputName {
  description = 'description',
  section = 'section',
  ticket = 'ticket',
  loadFile = 'loadFile',
  amount = 'amount',
  name = 'name',
  cpf = 'cpf',
  birthDate = 'birthDate',
  cellPhone = 'cellPhone',
  email = 'email',
  motherName = 'motherName',
  file = 'file',
  sendEmail = 'sendEmail',
}

interface StateProps {
  event: Event;
  courtesys: EventCourtesy[];
  sections: Section[];
  tickets: Tickets[];
  formData: FormData;
  formErrors: FormErrors;
  disableName: boolean;
  disableEmail: boolean;
  disablePhone: boolean;
  disableBirthDate: boolean;
  disableMotherName: boolean;
  file: string;
}

interface DispatchProps {
  onChange: OnChangeFormInput;
  listTickets: (eventId: string, sectionId: string) => void;
  onCheckCpf: (cpf: string) => void;
  onAdd: (eventId: string) => Promise<void>;
  onGet: (eventId: string, courtesyId: string) => Promise<void>;
  onDelete: (eventId: string, courtesyId: string) => Promise<void>;
  onChangeFileInput: (inputName: string) => (file: File | undefined) => void;
}

type Props = StateProps & DispatchProps;

export const RegisterCourtesy: React.FC<Props> = ({
  courtesys,
  onGet,
  onDelete,
  event,
  formData,
  onChange,
  formErrors,
  disableName,
  disableEmail,
  disablePhone,
  disableBirthDate,
  disableMotherName,
  onCheckCpf,
  sections,
  listTickets,
  tickets,
  onAdd,
  onChangeFileInput,
  file,
}) => {
  const dataTableVoucher = courtesys.map(item => ({
    description: item.description,
    client: item.client ? item.client.name : undefined,
    ticket: item.ticket.name,
    amount: `${item.amount}`,
    actions: (
      <Fragment>
        <Detail
          className="svg-icon action-icon"
          onClick={async () => {
            onGet(event.id, item.id);
          }}
        />
        <Trash
          style={{ marginLeft: '10px' }}
          className="svg-icon action-icon"
          onClick={async () => {
            onDelete(event.id, item.id);
          }}
        />
      </Fragment>
    ),
  }));

  return (
    <>
      <Form>
        <Row>
          <Col md={8}>
            <FormGroup>
              <InputText
                label="Descrição da cortesia"
                placeholder="Digite a descrição da cortesia"
                name="description"
                value={formData[FormInputName.description]}
                onChange={e => onChange(FormInputName.description)(e.target.value)}
                error={formErrors.description && formErrors.description[0]}
              />
            </FormGroup>
            <FormGroup>
              <SelectCustom
                name="section"
                label="Setor"
                placeholder="Digite ou selecione o setor "
                onChange={e => {
                  onChange(FormInputName.section)(e?.value as string);
                  listTickets(event.id, e?.value as string);
                }}
                error={formErrors.section && formErrors.section[0]}
                value={formData[FormInputName.section]}
                options={sections.map(item => ({
                  value: item?.id,
                  label: item?.name,
                }))}
              />
            </FormGroup>
            {tickets && tickets.length > 0 && (
              <FormGroup className="mb-2">
                <SelectCustom
                  name="ticket"
                  label="Ingresso"
                  placeholder="Digite ou selecione o ingresso"
                  onChange={e => {
                    onChange(FormInputName.ticket)(e?.value as string);
                  }}
                  error={formErrors.ticket && formErrors.ticket[0]}
                  value={formData[FormInputName.ticket]}
                  options={tickets.map(item => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                />
              </FormGroup>
            )}
            <FormGroup className="mb-2">
              <ButtonGroup
                label="Efetuar carga de arquivo?"
                name="loadFile"
                value={formData[FormInputName.loadFile]}
                onChange={e => {
                  onChange(FormInputName.loadFile)(e.target.value as string);
                }}
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                error={formErrors.loadFile && formErrors.loadFile[0]}
              />
            </FormGroup>
            {formData[FormInputName.loadFile] === 'true' ? (
              <FormGroup className="mb-2">
                <InputFile
                  name="imageDescriptionUrl"
                  label={<span>Arquivo (CSV)</span>}
                  accept=".csv"
                  fileName={file}
                  onChange={e =>
                    onChangeFileInput(FormInputName.file)(
                      (e.target as HTMLInputElement)?.files?.[0],
                    )
                  }
                  error={formErrors.imageDescriptionUrl && formErrors.imageDescriptionUrl[0]}
                />
              </FormGroup>
            ) : (
              <FormGroup className="mb-2">
                <InputText
                  label="Quantidade de cortesia"
                  placeholder="Ex: 20000"
                  name="amount"
                  value={String(formData[FormInputName.amount])}
                  onChange={e => onChange(FormInputName.amount)(e.target.value)}
                  error={formErrors.amount && formErrors.amount[0]}
                />
              </FormGroup>
            )}
          </Col>
        </Row>
        {formData[FormInputName.loadFile] === 'false' && (
          <>
            <Row>
              <Col md={8}>
                <FormGroup className="mb-2">
                  <InputText
                    label="CPF do(a) favorecido(a)"
                    placeholder="Ex: 123.456.789-00"
                    name="cpf"
                    value={formData[FormInputName.cpf]}
                    onChange={e => onChange(FormInputName.cpf)(e.target.value)}
                    onBlur={e => onCheckCpf(e.target.value)}
                    error={formErrors.cpf && formErrors.cpf[0]}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <FormGroup className="mb-2">
                  <InputText
                    label="Nome do(a) favorecido(a)"
                    placeholder="Ex: Maria Almeida"
                    name="name"
                    value={formData[FormInputName.name]}
                    disabled={disableName}
                    onChange={e => onChange(FormInputName.name)(e.target.value)}
                    error={formErrors.name && formErrors.name[0]}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <InputText
                  name="birthDate"
                  label="Data de Nascimento"
                  placeholder="00/00/0000"
                  value={formData[FormInputName.birthDate]}
                  disabled={disableBirthDate}
                  onChange={e => onChange(FormInputName.birthDate)(e.target.value)}
                  error={formErrors.birthDate && formErrors.birthDate[0]}
                />
              </Col>
              <Col md={4}>
                <FormGroup className="mb-2">
                  <InputText
                    label="Celular do(a) favorecido(a)"
                    placeholder="Ex: (00) 0 0000-0000"
                    name="cellPhone"
                    value={formData[FormInputName.cellPhone]}
                    disabled={disablePhone}
                    onChange={e => onChange(FormInputName.cellPhone)(e.target.value)}
                    error={formErrors.cellPhone && formErrors.cellPhone[0]}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <FormGroup className="mb-2">
                  <InputText
                    label="E-mail do(a) favorecido(a)"
                    placeholder="Ex: maria.almeida@email.com"
                    name="email"
                    value={formData[FormInputName.email]}
                    disabled={disableEmail}
                    onChange={e => onChange(FormInputName.email)(e.target.value)}
                    error={formErrors.email && formErrors.email[0]}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <FormGroup className="mb-2">
                  <InputText
                    label="Nome da mãe do(a) favorecido(a)"
                    placeholder="Ex: Maria Almeida"
                    name="motherName"
                    value={formData[FormInputName.motherName]}
                    disabled={disableMotherName}
                    onChange={e => onChange(FormInputName.motherName)(e.target.value)}
                    error={formErrors.motherName && formErrors.motherName[0]}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col md={8}>
            <FormGroup className="mb-2">
              <ButtonGroup
                label="Enviar por e-mail?"
                name="sendEmail"
                value={formData[FormInputName.sendEmail]}
                onChange={e => {
                  onChange(FormInputName.sendEmail)(e.target.value as string);
                }}
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                error={formErrors.sendEmail && formErrors.sendEmail[0]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button className="mb-4" title="Gerar cortesia" onClick={() => onAdd(event.id)} />
            </div>
            <div className="border-bottom-divider"></div>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col md={6}>
          <h5 className="mb-4 border-bottom-title fw-400 mt-5">Cortesias gerados</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          {courtesys.length > 0 ? (
            <>
              <CustomTable
                columns={columns}
                data={dataTableVoucher}
                numberRowsPerPage={10}
                theme="secondaryThemeVoucher"
                progressPending={false}
              />
            </>
          ) : (
            <>
              <div style={{ padding: '10px 0 20px 0', color: '#A5A5A5' }}>
                Você ainda não gerou nenhuma cortesia.
              </div>
              <div style={{ color: '#A5A5A5', paddingBottom: '30px' }}>
                Aqui será exibida uma lista das cortesias geradas.
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
