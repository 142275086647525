import { TableColumn } from 'react-data-table-component';

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
  detail = 'detail',
}

export interface DataRow {
  event: string;
  contractor: string;
  bank: string;
  pixKey: string;
  contractorUser: string;
  transferValue: string;
  transferRequestDate: string;
  transferred: string;
  transferDate: string;
  action: string;
}

// eslint-disable-next-line no-shadow
export enum FormInput {
  filterSearch = 'filterSearch',
  inputSearch = 'inputSearch',
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Evento',
    selector: row => row.event,
    minWidth: '200px',
  },
  {
    name: 'Produtor',
    selector: row => row.contractor,
    minWidth: '200px',
  },
  {
    name: 'Banco',
    selector: row => row.bank,
    minWidth: '80px',
  },
  {
    name: 'Chave',
    selector: row => row.pixKey,
    minWidth: '150px',
  },
  {
    name: 'Solicitante',
    selector: row => row.contractorUser,
    minWidth: '200px',
  },
  {
    name: 'Valor',
    selector: row => row.transferValue,
    width: '120px',
  },
  {
    name: 'Data solicitação',
    selector: row => row.transferRequestDate,
    width: '170px',
  },
  {
    name: 'Ações',
    selector: row => row.action,
    width: '170px',
  },
];
