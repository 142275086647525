// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModalReverse {
  detail = 'detail',
}

// eslint-disable-next-line no-shadow
export enum FormInputFilter {
  filterSearch = 'filterSearch',
  inputSearch = 'inputSearch',
}

export interface DataRow {
  id: string;
  client: string;
  document: string;
  protocol: string;
  chatDate: string;
  type: string;
  status: string;
  isNewMessage: any;
}

export type UrlParams = {
  id: string;
};

// eslint-disable-next-line no-shadow
export enum FormInputMessagem {
  message = 'message',
  file = 'file',
}

// eslint-disable-next-line no-shadow
export enum FormUpdateSupportStatus {
  status = 'status',
}

export interface DataColumn {
  id: string;
  name: string;
}
