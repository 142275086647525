import React from 'react';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import EventMap from '@/model/EventMap';
import ChartPrice from '@/model/ChartPrice';
import EventMapSection from '@/model/EventMapSection';
import ChartTicketType from '@/model/ChartTicketType';
import ChartObject from '@/model/ChartObject';
import ChartSelectedTicketType from '@/model/ChartSelectedTicketType';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { SelectedObject } from '@/model/SelectedObject';

interface StateProps {
  eventMap?: EventMap;
  eventKey?: string;
  selectedObjects: SelectedObject[];
}

interface DispatchProps {
  onObjectSelected: (object: ChartObject, selectedTicketType: ChartSelectedTicketType) => void;
  onObjectDeselected: (object: ChartObject, selectedTicketType: ChartSelectedTicketType) => void;
}

type Props = StateProps & DispatchProps;

export const MapContent: React.FC<Props> = ({
  eventMap,
  eventKey,
  selectedObjects,
  onObjectSelected,
  onObjectDeselected,
}): JSX.Element => {
  const mountPricing = (): ChartPrice[] => {
    const list: ChartPrice[] = [];
    if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
      const sections = eventMap.sections as EventMapSection[];
      sections.forEach(data => {
        if (data.tickets && data.tickets.length > 0) {
          const dto: ChartPrice = {
            category: data.name,
            ticketTypes: [],
          };

          data.tickets.forEach(ticket => {
            let price = Number(ticket.ticket.unitValue);
            if (ticket.ticket.fee && Number(ticket.ticket.fee) > 0) {
              price = Number(ticket.ticket.unitValue) * Number(ticket.ticket.fee);
            }
            price = Number(price.toFixed(2));
            const ticketType: ChartTicketType = {
              category: data.name,
              ticketType: ticket.ticket.name,
              label: ticket.ticket.name,
              description: `${data.name} - ${ticket.ticket.name}`,
              price,
            };
            (dto.ticketTypes as ChartTicketType[]).push(ticketType);
          });
          list.push(dto);
        }
      });
    }

    return list;
  };

  return (
    <>
      {eventMap && eventKey && (
        <SeatsioSeatingChart
          workspaceKey={eventMap.publicWorkspaceKey}
          event={eventKey}
          pricing={mountPricing()}
          selectedObjects={selectedObjects}
          priceFormatter={(price: number) => toCurrency(price)}
          region="sa"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onObjectSelected={(object: ChartObject, selectedTicketType: ChartSelectedTicketType) =>
            onObjectSelected(object, selectedTicketType)
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onObjectDeselected={(object: ChartObject, selectedTicketType: ChartSelectedTicketType) =>
            onObjectDeselected(object, selectedTicketType)
          }
          language="pt"
        />
      )}
    </>
  );
};
