import { TableColumn } from '@/components/Table';
import { DataRow } from '.';

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Descrição da cortesia',
    selector: row => row.description,
    minWidth: '150px',
  },
  {
    name: 'Usuário',
    selector: row => row.client,
    minWidth: '150px',
  },
  {
    name: 'Setor/Lote',
    selector: row => row.ticket,
    minWidth: '150px',
  },
  {
    name: 'Quantidade',
    selector: row => row.amount,
    minWidth: '80px',
  },
  {
    name: '',
    selector: row => row.actions,
    width: '100px',
    style: {
      cursor: 'pointer',
    },
  },
];
