/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Loading } from '@/components';
import api from '@/services/api';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ValidationReport } from '@/model/ValidationReport';
// import EventMainReport from '@/model/EventMainReport';
import { ReportControllerProps } from '../../types';
import { ValidationContent } from './ui';

interface ValidationProps {
  reportController: ReportControllerProps;
  // eventChild?: EventMainReport;
  generatePDF: () => void;
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export const Validation: React.FC<ValidationProps> = ({
  reportController,
  generatePDF,
  // eventChild,
}) => {
  const [state, setState] = useState<States>(States.default);

  const [validation, setValidation] = useState<ValidationReport | undefined>(undefined);

  const { eventId, singleEvent } = reportController;

  const handleGetValidation = async (): Promise<void> => {
    if (validation === undefined) {
      try {
        setState(States.loading);
        const { data } = await api.get(
          `event/report/${eventId}/validation?singleEvent=${singleEvent}`,
        );
        if (data) {
          setValidation(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    handleGetValidation();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <ValidationContent
        validation={validation}
        generatePDF={generatePDF}
        // eventChild={eventChild}
      />
    </>
  );
};
