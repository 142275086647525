import React, { ChangeEvent } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { ButtonGroup, InputText, SelectCustom, Switch } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import eye from '@/assets/images/login/eye.png';
import closeEye from '@/assets/images/login/closeEye.png';
import { convertToBoolean } from '@/helpers/common/convertToBoolean';
import { EmailTypeSelect, FormInputEmail } from '../../types';

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
  togglePassword: boolean;
}

interface DispatchProps {
  change: OnChangeFormInput;
  onActivateAndInactivate(e: ChangeEvent<HTMLInputElement>): void;
  setTogglePassword: (b: boolean) => void;
}

type Props = StateProps & DispatchProps;

export const SaveEmail: React.FC<Props> = (props: Props) => {
  const mountEmailType = (): EmailTypeSelect[] => {
    const list: EmailTypeSelect[] = [
      { id: '0', name: 'Pagamento' },
      { id: '1', name: 'Contato' },
      { id: '2', name: 'Não responda' },
    ];

    return list;
  };
  return (
    <Form
      noValidate={true}
      onSubmit={(e): void => {
        e.preventDefault();
      }}
    >
      <Row>
        <Col md={8}>
          <h5 className="mb-5 border-bottom-title">Informações básicas</h5>
          <FormGroup className="mb-2">
            <InputText
              name="name"
              label="Nome do email"
              placeholder="Ex: Contatos"
              maxLength={100}
              value={props.formData[FormInputEmail.name]}
              onChange={e => props.change(FormInputEmail.name)(e.target.value)}
              error={props.formErrors.name && props.formErrors.name[0]}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <Switch
            name="status"
            label={`Email ${
              convertToBoolean(props.formData[FormInputEmail.actived]) ? 'ativo' : 'inativo'
            }`}
            onChange={e => props.onActivateAndInactivate(e)}
            checked={convertToBoolean(props.formData[FormInputEmail.actived])}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectCustom
              name="type"
              label="Tipo"
              placeholder="Digite ou selecione o tipo"
              onChange={e => props.change(FormInputEmail.type)(e?.value as string)}
              error={props.formErrors.module && props.formErrors.module[0]}
              value={props.formData[FormInputEmail.type]}
              options={mountEmailType().map(item => ({ value: item.id, label: item.name }))}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="host"
              label="Host do email"
              placeholder="Ex: smtp.gmail.com"
              maxLength={100}
              value={props.formData[FormInputEmail.host]}
              onChange={e => props.change(FormInputEmail.host)(e.target.value)}
              error={props.formErrors.host && props.formErrors.host[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="port"
              label="Porta do email"
              placeholder="Ex: 465"
              maxLength={4}
              value={props.formData[FormInputEmail.port]}
              onChange={e => props.change(FormInputEmail.port)(e.target.value)}
              error={props.formErrors.port && props.formErrors.port[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <ButtonGroup
              label="Possui TLS?"
              name="tls"
              value={props.formData[FormInputEmail.tls]}
              onChange={e => props.change(FormInputEmail.tls)(e.target.value)}
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              error={props.formErrors.tls && props.formErrors.tls[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <ButtonGroup
              label="Possui SSL?"
              name="ssl"
              value={props.formData[FormInputEmail.ssl]}
              onChange={e => props.change(FormInputEmail.ssl)(e.target.value)}
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              error={props.formErrors.ssl && props.formErrors.ssl[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="userName"
              label="Email"
              placeholder="Ex: xxx@bancadoingresso.com.br"
              maxLength={100}
              value={props.formData[FormInputEmail.userName]}
              onChange={e => props.change(FormInputEmail.userName)(e.target.value)}
              error={props.formErrors.userName && props.formErrors.userName[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="password"
              label="Senha"
              type={props.togglePassword ? 'text' : 'password'}
              placeholder="Ex: 1234abcd"
              value={props.formData[FormInputEmail.password]}
              onChange={e => props.change(FormInputEmail.password)(e.target.value)}
              error={props.formErrors.password && props.formErrors.password[0]}
              renderForward={
                <div
                  className="show-hide"
                  onClick={() => props.setTogglePassword(!props.togglePassword)}
                  style={{ cursor: 'pointer' }}
                >
                  {props.togglePassword ? (
                    <img
                      className="d-flex justify-content-center align-items-center passwordIcon"
                      src={eye}
                    />
                  ) : (
                    <img
                      className="d-flex justify-content-center align-items-center passwordIcon"
                      src={closeEye}
                    />
                  )}
                </div>
              }
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
