import React from 'react';
import { Route } from '@/navigation/Route';
import { ReverseScreen } from './screens/List';

export const REVERSE_ROUTES = {
  itself: '/support/reverse',
};

export const ReverseNavigation = (): JSX.Element => (
  <Route exact path={REVERSE_ROUTES.itself} component={ReverseScreen} isPrivateRoute />
);
