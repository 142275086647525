// eslint-disable-next-line no-shadow
enum SupportType {
  CANCELLATION,
  PAYMENTS,
  TICKET,
  ACCOUNT,
  OTHERS,
}

export default SupportType;
