import { CustomTable } from '@/components/Table';
import { toCurrency } from '@/helpers/masks/toCurrency';
import Canceled from '@/model/Canceled';
import dayjs from 'dayjs';
import React from 'react';
import { Card } from 'reactstrap';

export interface CancellationsUIProps {
  canceled?: Canceled;
}

export const CancellationsUI: React.FC<CancellationsUIProps> = ({ canceled }): JSX.Element => (
  <>
    <div className="justify-content-between mt-5 mb-5">
      <h5>Cancelamentos</h5>
      {canceled &&
        canceled.canceledTickets.length > 0 &&
        canceled.canceledTickets.map((data, index) => (
          <Card
            key={index + data.eventName}
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <div className="p-4">
              <CustomTable
                numberRowsPerPage={0}
                progressPending={false}
                theme="secondary"
                columns={[
                  {
                    name: 'Motivo',
                    selector: row => row.reason,
                    width: '20%',
                  },
                  {
                    name: 'Setor',
                    selector: row => row.section,
                    width: '15%',
                  },
                  {
                    name: 'Ingresso',
                    selector: row => row.ticket,
                    width: '12%',
                  },
                  {
                    name: 'Pagamento',
                    selector: row => row.payment,
                    width: '12%',
                  },
                  {
                    name: 'Valor',
                    selector: row => row.amount,
                    width: '7%',
                  },
                  {
                    name: 'Solicitação',
                    selector: row => row.cancel_request_date,
                    width: '10%',
                  },
                  {
                    name: 'Avaliação',
                    selector: row => row.cancel_date,
                    width: '7%',
                  },
                  {
                    name: 'POS',
                    selector: row => row.pos,
                    width: '8%',
                  },
                  {
                    name: 'Usuário',
                    selector: row => row.user,
                    width: '10%',
                  },
                ]}
                data={
                  data.cancelTicket &&
                  data.cancelTicket.map(ticket => ({
                    reason: ticket.cancelReason,
                    section: ticket.sectionName,
                    ticket: ticket.eventTicketName,
                    payment: ticket.paymentType,
                    amount: toCurrency(ticket.totalValue),
                    cancel_request_date:
                      ticket.cancelRequestDate != null
                        ? dayjs(ticket.cancelRequestDate).format('DD/MM/YYYY')
                        : 'Não informado',
                    cancel_date: dayjs(ticket.cancelDate).format('DD/MM/YYYY'),
                    pos: ticket.posSerialNumber === null ? 'Site' : ticket.posSerialNumber,
                    user: ticket.userName,
                  }))
                }
              />
            </div>
          </Card>
        ))}
      {canceled &&
        canceled.canceledProducts.length > 0 &&
        canceled.canceledProducts.map((data, index) => (
          <Card
            key={index + data.eventName}
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <div className="p-4">
              <CustomTable
                numberRowsPerPage={0}
                progressPending={false}
                theme="secondary"
                columns={[
                  {
                    name: 'Produto',
                    selector: row => row.nameProduct,
                    width: '20%',
                  },
                  {
                    name: 'Valor',
                    selector: row => row.totalValue,
                    width: '10%',
                  },
                  {
                    name: 'Pagamento',
                    selector: row => row.payment,
                    width: '10%',
                  },
                  {
                    name: 'Cancelamento',
                    selector: row => row.cancelDate,
                    width: '14%',
                  },
                  {
                    name: 'Usuário',
                    selector: row => row.userName,
                    width: '`17%',
                  },
                  {
                    name: 'POS',
                    selector: row => row.pos,
                    width: '12%',
                  },
                  {
                    name: 'Setor',
                    selector: row => row.sectionProductName,
                    width: '12%',
                  },
                ]}
                data={
                  data.canceledProduct &&
                  data.canceledProduct.map(products => ({
                    nameProduct: products.nameProduct,
                    totalValue: toCurrency(products.totalValue),
                    payment: products.paymentType,
                    cancelDate: dayjs(products.cancelDate).format('DD/MM/YYYY'),
                    userName: products.userName,
                    pos: products.posSerialNumber === null ? 'Site' : products.posSerialNumber,
                    sectionProductName: products.sectionProductName,
                  }))
                }
              />
            </div>
          </Card>
        ))}
    </div>
  </>
);
