// eslint-disable-next-line no-shadow
export enum FormInputName {
  ticketPhysicalSaleId = 'ticketPhysicalSaleId',
  ticketPhysicalSaleAllowCreditCardPayment = 'ticketPhysicalSaleAllowCreditCardPayment',
  ticketPhysicalSaleDebit = 'ticketPhysicalSaleDebit',
  ticketPhysicalSaleCredit = 'ticketPhysicalSaleCredit',
  ticketPhysicalSalePix = 'ticketPhysicalSalePix',
  ticketPhysicalSaleAdministrateTax = 'ticketPhysicalSaleAdministrateTax',
  ticketPhysicalSaleInstallments = 'ticketPhysicalSaleInstallments',
  ticketPhysicalSaleFee = 'ticketPhysicalSaleFee',
  ticketWebsiteSaleId = 'ticketWebsiteSaleId',
  ticketWebsiteSaleAllowCreditCardPayment = 'ticketWebsiteSaleAllowCreditCardPayment',
  ticketWebsiteSaleBankSlip = 'ticketWebsiteSaleBankSlip',
  ticketWebsiteSaleCredit = 'ticketWebsiteSaleCredit',
  ticketWebsiteSalePix = 'ticketWebsiteSalePix',
  ticketWebsiteSaleAdministrateTax = 'ticketWebsiteSaleAdministrateTax',
  ticketWebsiteSaleInstallments = 'ticketWebsiteSaleInstallments',
  ticketWebsiteSaleFee = 'ticketWebsiteSaleFee',
  productPhysicalSaleId = 'productPhysicalSaleId',
  productPhysicalSaleAllowCreditCardPayment = 'productPhysicalSaleAllowCreditCardPayment',
  productPhysicalSaleDebit = 'productPhysicalSaleDebit',
  productPhysicalSaleCredit = 'productPhysicalSaleCredit',
  productPhysicalSalePix = 'productPhysicalSalePix',
  productPhysicalSaleAdministrateTax = 'productPhysicalSaleAdministrateTax',
  productPhysicalSaleInstallments = 'productPhysicalSaleInstallments',
  productPhysicalSaleFee = 'productPhysicalSaleFee',
  productWebsiteSaleId = 'productWebsiteSaleId',
  productWebsiteSaleAllowCreditCardPayment = 'productWebsiteSaleAllowCreditCardPayment',
  productWebsiteSaleBankSlip = 'productWebsiteSaleBankSlip',
  productWebsiteSaleCredit = 'productWebsiteSaleCredit',
  productWebsiteSalePix = 'productWebsiteSalePix',
  productWebsiteSaleAdministrateTax = 'productWebsiteSaleAdministrateTax',
  productWebsiteSaleInstallments = 'productWebsiteSaleInstallments',
  productWebsiteSaleFee = 'productWebsiteSaleFee',
}
