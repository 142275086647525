import React from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { InputText } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import { FormInputEntrance } from '../../types';

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
}

interface DispatchProps {
  onChange: OnChangeFormInput;
}

type Props = StateProps & DispatchProps;

export const RegisterEntranceContent: React.FC<Props> = ({ formData, formErrors, onChange }) => (
  <Form
    noValidate={true}
    onSubmit={(e): void => {
      e.preventDefault();
    }}
  >
    <Row>
      <Col md={8}>
        <FormGroup>
          <InputText
            name="name"
            label="Nome da portaria"
            placeholder="Digite o nome da portaria"
            value={formData[FormInputEntrance.name]}
            onChange={e => onChange(FormInputEntrance.name)(e.target.value)}
            error={formErrors.name && formErrors.name[0]}
          />
        </FormGroup>
      </Col>
    </Row>
  </Form>
);
