// eslint-disable-next-line no-shadow
enum PaymentType {
  CREDIT_CARD,
  TWO_CREDIT_CARDS,
  DEBIT_CARD,
  PIX,
  BANK_SLIP,
  MONEY,
  COURTESY,
}
export default PaymentType;
