import { TableColumn } from '@/components/Table';
import { DataRowDocument, DataRowUser } from '@/features/document/types';

export const columnsDocument: TableColumn<DataRowDocument>[] = [
  {
    name: 'Nome do documento',
    selector: row => row.title,
    minWidth: '200px',
  },
  {
    name: 'Categoria',
    selector: row => row.category,
  },
  {
    name: 'Responsável',
    selector: row => row.bindingUser,
  },
  {
    name: 'Data do upload',
    selector: row => row.bindingDate,
  },
  {
    name: 'Ações',
    selector: row => row.actions,
    maxWidth: '140px',
  },
];

export const columnsUser: TableColumn<DataRowUser>[] = [
  {
    name: 'Nome do usuário',
    selector: row => row.name,
  },
  {
    name: 'Ação',
    selector: row => row.action,
    maxWidth: '70px',
  },
];
