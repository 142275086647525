import { States } from '@/helpers/common/states';
import validators from '@/helpers/validators';
import Event from '@/model/Event';
import api from '@/services/api';
import React, { useEffect, useState } from 'react';
import useForm from '@/hooks/useForm';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Section from '@/model/Section';
import Ticket from '@/model/Ticket';
import Bracelet from '@/model/Bracelet';
import { BraceletContainer } from './ui';
import { FormInput } from '../types';

export const BraceletScreen: React.FC = (): JSX.Element => {
  const { eventId } = useParams<{ eventId: string }>();
  const [state, setState] = useState<States>(States.default);
  const [bracelets, setBracelets] = useState<Bracelet[]>([]);
  const [event, setEvent] = useState<Event>();
  const [events, setEvents] = useState<Event[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [tickets, setTickets] = useState<Ticket[]>([]);

  const getBracelets = async (): Promise<void> => {
    const braceletsResponse = await api.get<Bracelet[]>(`/bracelet/${eventId}`);
    setBracelets(braceletsResponse.data);
  };

  const getSimpleEvent = async (): Promise<void> => {
    try {
      setState(States.loading);
      await getBracelets();
      const { data } = await api.get<Event>(`/event/${eventId}/simple`);
      const list: Event[] = [];
      list.push(data);
      const responseChilds = await api.get<Event[]>(`/event/${eventId}/child`);
      if (responseChilds.data && responseChilds.data.length > 0) {
        responseChilds.data.forEach(child => {
          list.push(child);
        });
      }
      setEvent(data);
      setEvents(list);
    } catch (error) {
      window.console.error(error);
    } finally {
      setState(States.default);
    }
  };

  const { formData, formErrors, onChangeFormInput, isFormValid } = useForm({
    initialData: {
      eventId: '',
      sectionId: '',
      ticketId: '',
      amount: '',
      email: '',
    },
    validators: {
      eventId: [validators.required],
      sectionId: [validators.required],
      ticketId: [validators.required],
      amount: [validators.required, validators.greaterThanOrEqual(1)],
      email: [validators.required, validators.email],
    },
  });

  const handleOnChangeEvent = async (id: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Section[]>(`/event/courtesy/${id}/section/get`);
      setSections(data);
      setTickets([]);
      onChangeFormInput(FormInput.sectionId)('');
      onChangeFormInput(FormInput.ticketId)('');
    } catch (error) {
      window.console.error(error);
    } finally {
      setState(States.default);
    }
  };

  const handleOnChangeSection = async (id: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Ticket[]>(
        `/event/courtesy/${formData[FormInput.eventId]}/section/${id}/ticket`,
      );
      setTickets(data);
      onChangeFormInput(FormInput.ticketId)('');
    } catch (error) {
      window.console.error(error);
    } finally {
      setState(States.default);
    }
  };

  const handleAddBracelet = async (): Promise<void> => {
    if (isFormValid()) {
      try {
        setState(States.loading);
        const dto = {
          ticket: { id: formData[FormInput.ticketId] } as Ticket,
          amount: Number(formData[FormInput.amount]),
          email: formData[FormInput.email],
        } as Bracelet;
        await api.post('/bracelet', dto);
        await getBracelets();
      } catch (error) {
        window.console.error(error);
      } finally {
        toast.success('Pulseira(s) inserida(s) com sucesso!');
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    getSimpleEvent();
  }, [eventId]);
  return (
    <BraceletContainer
      state={state}
      event={event}
      bracelets={bracelets}
      events={events}
      sections={sections}
      tickets={tickets}
      formData={formData}
      formErrors={formErrors}
      onChange={onChangeFormInput}
      onChangeEvent={handleOnChangeEvent}
      onChangeSection={handleOnChangeSection}
      addBracelet={handleAddBracelet}
    />
  );
};
