import { Button, Dialog, Loading } from '@/components';
import { States } from '@/helpers/common/states';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import User from '@/model/User';
import React from 'react';
import { ActionProps } from '@/components/Dialog';
import { Container, Label } from 'reactstrap';
import Event from '@/model/Event';
import SuperCollapse from '@/components/sharedComponents/SuperCollapse';
import UserIcon from '@/assets/images/svg/User';
import Entrance from '@/model/Entrance';
import { ValidatorList } from './ValidatorList';
import { FormInput, ShouldShowModal } from '../../types';
import { RegisterValidatorContent } from '../components/RegisterValidatorContent';
import { RegisterEntranceContent } from '../components/RegisterEntranceContent';
import { EntranceList } from './EntranceList';
import { RegisterEventEntranceContent } from '../components/RegisterEventEntranceContent';

interface StateProps {
  state: States;
  modalTitle: React.ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  event?: Event;
  validators: User[];
  eventEntrances: Entrance[];
  formDataValidator: FormData;
  formErrorsValidator: FormErrors;
  users: User[];
  formDataEventEntrance: FormData;
  formErrorsEventEntrance: FormErrors;
  entrances: Entrance[];
  formDataEntrance: FormData;
  formErrorsEntrance: FormErrors;
}

interface DispatchProps {
  onToggle(): void;
  openModal(value: ShouldShowModal, modalTitle: string, toggle: boolean, entrance?: Entrance): void;
  onChangeValidator: OnChangeFormInput;
  onChangeEventEntrance: OnChangeFormInput;
  onChangeEntrance: OnChangeFormInput;
  addValidator(): void;
  removeValidator(validatorId: string): void;
  addEntrance(): void;
  removeEntrance(entranceId: string): void;
  onSaveEntrance(): void;
}

type Props = StateProps & DispatchProps;

export const ValidatorAndEntrance: React.FC<Props> = ({
  state,
  modalTitle,
  visible,
  onToggle,
  shouldShowModal,
  openModal,
  addValidator,
  formDataValidator,
  formErrorsValidator,
  users,
  onChangeValidator,
  addEntrance,
  formDataEventEntrance,
  formErrorsEventEntrance,
  entrances,
  onChangeEventEntrance,
  onChangeEntrance,
  formDataEntrance,
  formErrorsEntrance,
  onSaveEntrance,
  event,
  validators,
  removeValidator,
  eventEntrances,
  removeEntrance,
}): JSX.Element => {
  const renderActionDialogToCancel: ActionProps = {
    title: 'Cancelar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };
  const renderActionDialogToBack: ActionProps = {
    title: 'Voltar',
    onClick: (): void => openModal(ShouldShowModal.eventEntrance, 'Cadastrar portaria', false),
    theme: 'noneBorder',
  };
  return (
    <React.Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={modalTitle}
        visible={visible}
        onClose={onToggle}
        position="center"
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.validator]: renderActionDialogToCancel,
            [ShouldShowModal.eventEntrance]: renderActionDialogToCancel,
            [ShouldShowModal.entrance]: renderActionDialogToBack,
          }[shouldShowModal],
          {
            [ShouldShowModal.validator]: {
              title: 'Cadastrar validador',
              onClick: addValidator,
              disabled: formDataValidator[FormInput.userId] === '',
            },
            [ShouldShowModal.eventEntrance]: {
              title: 'Cadastrar portaria',
              onClick: addEntrance,
            },
            [ShouldShowModal.entrance]: {
              title: 'Cadastrar portaria',
              onClick: onSaveEntrance,
            },
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.validator]: (
              <RegisterValidatorContent
                formData={formDataValidator}
                formErrors={formErrorsValidator}
                users={users}
                onChange={onChangeValidator}
              />
            ),
            [ShouldShowModal.eventEntrance]: (
              <RegisterEventEntranceContent
                formData={formDataEventEntrance}
                formErrors={formErrorsEventEntrance}
                entrances={entrances}
                onChange={onChangeEventEntrance}
                openModal={openModal}
              />
            ),
            [ShouldShowModal.entrance]: (
              <RegisterEntranceContent
                formData={formDataEntrance}
                formErrors={formErrorsEntrance}
                onChange={onChangeEntrance}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <div className="pageTitle" style={{ display: 'grid' }}>
            <Label>{`Validadores do evento - ${event?.name}`}</Label>
          </div>
          <div className="button-filter-container">
            <Button
              theme="outlineDark"
              size="md"
              title="+ Cadastrar validador"
              style={{ marginRight: 25 }}
              onClick={(): void =>
                openModal(ShouldShowModal.validator, 'Cadastrar validador', true)
              }
            />
            <Button
              size="md"
              title="+ Cadastrar portaria"
              onClick={(): void =>
                openModal(ShouldShowModal.eventEntrance, 'Cadastrar portaria', true)
              }
            />
          </div>
        </div>
        <div>
          <SuperCollapse
            title="Validadores cadastrados"
            className="action-icon"
            content={
              validators && validators.length > 0 ? (
                <ValidatorList validators={validators} removeValidator={removeValidator} />
              ) : (
                'Nenhum usuário cadastrado. Aqui será exibida uma lista dos usuários cadastrados'
              )
            }
            leftIcon={UserIcon()}
            count={validators.length}
          />
          <SuperCollapse
            title="Portarias cadastradas"
            className="action-icon"
            content={
              validators && validators.length > 0 ? (
                <EntranceList entrances={eventEntrances} removeEntrance={removeEntrance} />
              ) : (
                'Nenhum usuário cadastrado. Aqui será exibida uma lista dos usuários cadastrados'
              )
            }
            leftIcon={UserIcon()}
            count={eventEntrances.length}
          />
        </div>
      </Container>
    </React.Fragment>
  );
};
