import EventGeneralReport from '@/model/EventGeneralReport';
import EventMainBleedingReport from '@/model/EventMainBleedingReport';
import EventMainDateReport from '@/model/EventMainDateReport';
import EventMainPdvReport from '@/model/EventMainPdvReport';
import EventMainSubPdvReport from '@/model/EventMainSubPdvReport';
import EventMainTerminalReport from '@/model/EventMainTerminalReport';
import EventMainTrackingReport from '@/model/EventMainTrackingReport';
import EventProductReport from '@/model/EventProductReport';
import EventTrackingReport from '@/model/EventTrackingReport';
import EventValidationMainReport from '@/model/EventValidationMainReport';
import Page from '@/model/Page';

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  detail = 'detail',
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export interface ReportControllerProps {
  eventId: string;
  singleEvent: boolean;
  generalSale: EventGeneralReport | undefined;
  salePdv: EventMainPdvReport[] | undefined;
  saleDate: EventMainDateReport | undefined;
  saleSubpdv: EventMainSubPdvReport[] | undefined;
  bleeding: EventMainBleedingReport | undefined;
  tracking: EventMainTrackingReport | undefined;
  trackingPagination: Page<EventTrackingReport, EventTrackingReport>;
  saleProduct: EventProductReport | undefined;
  validation: EventValidationMainReport | undefined;
  terminal: EventMainTerminalReport | undefined;
  setGeneralSale: (value: EventGeneralReport) => void;
  setSalePdv: (value: EventMainPdvReport[]) => void;
  setSaleDate: (value: EventMainDateReport) => void;
  setSaleSubpdv: (value: EventMainSubPdvReport[]) => void;
  setBleeding: (value: EventMainBleedingReport | undefined) => void;
  setTracking: (value: EventMainTrackingReport | undefined) => void;
  setTrackingPagination: (value: Page<EventTrackingReport, EventTrackingReport>) => void;
  onPageTracking: (page: number) => void;
  setSaleProduct: (value: EventProductReport | undefined) => void;
  setValidation: (value: EventValidationMainReport | undefined) => void;
  setTerminal: (value: EventMainTerminalReport | undefined) => void;
}
