import React, { useState } from 'react';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import ChartPrice from '@/model/ChartPrice';
import EventMap from '@/model/EventMap';
import EventMapSection from '@/model/EventMapSection';
import ChartTicketType from '@/model/ChartTicketType';

interface StateProps {
  eventKey: string;
  workspaceKey: string;
  eventMap: EventMap;
}

export const ShowMap: React.FC<StateProps> = ({
  eventKey,
  workspaceKey,
  eventMap,
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedObject, setSelectedObject] = useState<string[]>([]);
  const mountPricing = (): ChartPrice[] => {
    const list: ChartPrice[] = [];
    if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
      const sections = eventMap.sections as EventMapSection[];
      sections.forEach(data => {
        if (data.tickets && data.tickets.length > 0) {
          const dto: ChartPrice = {
            category: data.name,
            ticketTypes: [],
          };

          data.tickets.forEach(ticket => {
            let price = Number(ticket.ticket.unitValue);
            if (ticket.ticket.fee && Number(ticket.ticket.fee) > 0) {
              price = Number(ticket.ticket.unitValue) * Number(ticket.ticket.fee);
            }
            price = Number(price.toFixed(2));
            const ticketType: ChartTicketType = {
              category: data.name,
              ticketType: ticket.ticket.name,
              label: ticket.ticket.name,
              description: `${data.name} - ${ticket.ticket.name}`,
              price,
            };
            (dto.ticketTypes as ChartTicketType[]).push(ticketType);
          });
          list.push(dto);
        }
      });
    }

    return list;
  };
  return (
    <>
      {eventMap.publicWorkspaceKey && eventKey && (
        <SeatsioSeatingChart
          workspaceKey={workspaceKey}
          event={eventKey}
          pricing={mountPricing()}
          // selectedSeats={selectedObject}
          selectedObjects={selectedObject}
          priceFormatter={(price: string) => `R$${price}`}
          region="sa"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onObjectSelected={(object: any, selectedTicketType: unknown) =>
            window.console.log('object:', object, 'selectedTicketType', selectedTicketType)
          }
          onObjectDeselected={(obj: unknown) => window.console.log(obj)}
        />
      )}
    </>
  );
};
