import React from 'react';
import { Route } from '@/navigation/Route';
import { BraceletScreen } from './screens';

export const EVENTS_BRACELET_ROUTES = {
  itself: '/dashboard/event/bracelet/:eventId',
};

export const BraceletNavigation = (): JSX.Element => (
  <Route exact path={EVENTS_BRACELET_ROUTES.itself} component={BraceletScreen} isPrivateRoute />
);
