import React from 'react';
import { Col, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { updateMask } from '@/helpers/masks/cpf';
import Reprint from '@/model/Reprint';

interface ReprintDetailContentProps {
  reprint?: Reprint;
}

export const ReprintDetailContent: React.FC<ReprintDetailContentProps> = ({
  reprint,
}): JSX.Element => (
  <>
    {reprint && (
      <div>
        <Row>
          <Col md={12}>
            <label className={'input-label m-0'}>Usuário</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{updateMask(reprint.cpfCreated)}</Col>
        </Row>
        <Row>
          <Col md={12}>
            <label className={'input-label m-0'}>Token</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{reprint.token}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Data</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {dayjs(reprint.createdAt).locale('pt-br').format('DD/MM/YYYY [às] HH:mm')}
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Utilizado</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{reprint.actived ? 'SIM' : 'NÃO'}</Col>
        </Row>
        {reprint.cpfUsed && (
          <>
            <Row style={{ marginTop: '30px' }}>
              <Col md={12}>
                <label className={'input-label m-0'}>CPF POS</label>
              </Col>
            </Row>
            <Row>
              <Col md={12}>{updateMask(reprint.cpfUsed)}</Col>
            </Row>
          </>
        )}

        {reprint.usedDate && (
          <>
            <Row style={{ marginTop: '30px' }}>
              <Col md={12}>
                <label className={'input-label m-0'}>Data utilização</label>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {dayjs(reprint.usedDate).locale('pt-br').format('DD/MM/YYYY [às] HH:mm')}
              </Col>
            </Row>
          </>
        )}
      </div>
    )}
  </>
);
