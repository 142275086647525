import { InputFile } from '@/components';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { FormData, FormErrors } from '@/hooks';
import { FormInputAttachment } from '../../types';

interface StateProps {
  data?: EventMoneyTransfer;
  formData: FormData;
  formErrors: FormErrors;
}

interface DispatchProps {
  onChangeFileInput: (e: EventTarget & HTMLInputElement) => void;
}

type Props = StateProps & DispatchProps;

export const AttachmentContent: React.FC<Props> = ({
  data,
  formData,
  formErrors,
  onChangeFileInput,
}): JSX.Element => (
  <>
    {data && (
      <div>
        <Row>
          <Col md={12}>
            <label className={'input-label m-0'}>Evento</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{data.event.name}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Produtor</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{data.contractor.name}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Banco</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{data.pixKey.bank.fullName}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Chave</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{data.pixKey.key}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={6}>
            <FormGroup className="mb-2">
              <InputFile
                name="attachment"
                label="Conprovante"
                fileName={formData[FormInputAttachment.attachmentName]}
                onChange={e => onChangeFileInput(e.target)}
                error={formErrors.imageBluetooth && formErrors.imageBluetooth[0]}
              />
            </FormGroup>
          </Col>
          {data.attachmentUrl && (
            <Col md={6}>
              <Detail
                className={'mr-4 svg-icon action-icon mt-5'}
                onClick={() => window.open(data.attachmentUrl)}
              />
            </Col>
          )}
        </Row>
      </div>
    )}
  </>
);
