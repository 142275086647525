import { ReactComponent as Trash } from '@/assets/images/svg/lixeira.svg';
import React, { Fragment } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { InputFile, InputText, SelectCustom, TextArea } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import DocumentCategory from '@/model/DocumentCategory';
import Document from '@/model/Document';
import { ArrowLeft } from 'react-feather';
import { colors } from '@/styles/colors';
import User from '@/model/User';
import { CustomTable } from '@/components/Table';
import { FormInputDocumentName, ShouldShowModal } from '../../types';
import { columnsUser } from '../../screens/List/ui/table';

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
  categories: DocumentCategory[];
  documentState?: Document;
  userList: User[];
  documentUsers: User[];
}

interface DispatchProps {
  onChange: OnChangeFormInput;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
    document,
    isEdit,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    document?: Document;
    isEdit?: boolean;
  }) => void;
  onAddUser: (userId: string) => void;
  onDeleteUser: (user: User) => void;
}

type Props = StateProps & DispatchProps;

export const RegisterContent: React.FC<Props> = ({
  formData,
  formErrors,
  onChange,
  onChangeFile,
  categories,
  onShouldShowModal,
  documentState,
  userList,
  documentUsers,
  onAddUser,
  onDeleteUser,
}) => {
  const dataTableUser = documentUsers.map(item => ({
    name: item.name,
    action: (
      <Fragment>
        <Trash
          className="mr-2 svg-icon action-icon svg-icon-trash"
          onClick={() => {
            onDeleteUser(item);
          }}
        />
      </Fragment>
    ),
  }));
  return (
    <Form
      noValidate={true}
      onSubmit={(e): void => {
        e.preventDefault();
      }}
    >
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputFile
              name="fileURL"
              label="Arquivo"
              fileName={formData[FormInputDocumentName.fileName]}
              onChange={e => {
                onChangeFile(e);
              }}
              accept="image/png, image/jpeg, image/gif, text/html, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
              error={formErrors?.fileURL && formErrors.fileURL[0]}
            />
          </FormGroup>
          <FormGroup className="mb-2">
            <InputText
              name="title"
              label="Título do documento"
              placeholder="Informe o título do documento"
              maxLength={100}
              value={formData[FormInputDocumentName.title]}
              onChange={e => onChange(FormInputDocumentName.title)(e.target.value)}
              error={formErrors.title && formErrors.title[0]}
            />
          </FormGroup>
          <FormGroup className="mb-2">
            <TextArea
              name="description"
              label="Descrição"
              rows={6}
              placeholder="Digite aqui a descrição do arquivo"
              value={formData[FormInputDocumentName.description]}
              onChange={e => onChange(FormInputDocumentName.description)(e.target.value)}
              error={formErrors.description && formErrors.description[0]}
            />
          </FormGroup>
          <FormGroup className="mb-2">
            <SelectCustom
              name="category"
              label="Categoria"
              placeholder="Selecione ou digite a categoria do arquivo"
              onChange={e => onChange(FormInputDocumentName.category)(e?.value as string)}
              error={formErrors.category && formErrors.category[0]}
              value={formData[FormInputDocumentName.category]}
              options={categories?.map(item => ({
                value: item?.id,
                label: item?.name,
              }))}
            />
            <div
              style={{ margin: '0 0', cursor: 'pointer' }}
              className="subpdv-register-buttom"
              onClick={() => {
                onShouldShowModal({
                  value: ShouldShowModal.registerDocumentCategory,
                  newTitleModal: (
                    <div className="d-flex">
                      <div
                        className="m-auto"
                        onClick={() => {
                          onShouldShowModal({
                            value: ShouldShowModal.registerDocument,
                            newTitleModal: documentState?.id
                              ? `Documento ${documentState.title}`
                              : 'Upload de documento',
                            document: documentState,
                            isEdit: true,
                          });
                        }}
                      >
                        <ArrowLeft color={colors.black} width="30" height="30" className="m-auto" />
                      </div>
                      <h5 className="header-title-text modal__title ml-3 mb-0">
                        Adicionar categoria
                      </h5>
                    </div>
                  ),
                  document: documentState,
                });
              }}
            >
              + adicionar Categoria
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <>
            <label className="input-label">Permissão de usuário</label>
            <FormGroup className="mb-2">
              <SelectCustom
                name="user"
                label="Selecione os usuários que terão acesso a esse arquivo"
                placeholder="Selecione ou digite o usuário"
                onChange={e => onAddUser(e?.value as string)}
                error={formErrors.user && formErrors.user[0]}
                value={formData[FormInputDocumentName.user]}
                options={userList.map(item => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </FormGroup>
          </>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          {documentUsers.length > 0 ? (
            <>
              <CustomTable
                columns={columnsUser}
                data={dataTableUser}
                theme="secondary"
                progressPending={false}
                numberRowsPerPage={10}
              />
            </>
          ) : (
            <>
              <div style={{ padding: '10px 0 20px 0', color: '#A5A5A5' }}>
                Você ainda não adicionou nenhum usuário
              </div>
              <div style={{ color: '#A5A5A5', paddingBottom: '30px' }}>
                Aqui será exibida uma lista usuários inseridos.
              </div>
            </>
          )}
        </Col>
      </Row>
    </Form>
  );
};
