import React from 'react';
import { Route } from '@/navigation/Route';
import { RedemptionFinishedScreen } from './screens';

export const REDEMPTION_FINISHED_ROUTES = {
  itself: '/financial/redemption-finished',
};

export const RedemptionFinishedNavigation = (): JSX.Element => (
  <Route
    exact
    path={REDEMPTION_FINISHED_ROUTES.itself}
    component={RedemptionFinishedScreen}
    isPrivateRoute
  />
);
