/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import api from '@/services/api';
import { Loading } from '@/components';
import EventMainDateReport from '@/model/EventMainDateReport';
import { SaleDateContent } from './ui';
import { ReportControllerProps } from '../../types';

interface SaleDateProps {
  reportController: ReportControllerProps;
  generatePDF: () => void;
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export const SaleDate: React.FC<SaleDateProps> = ({ reportController, generatePDF }) => {
  const [state, setState] = useState<States>(States.default);

  const { saleDate, setSaleDate, eventId } = reportController;

  const handleGetSaleDate = async (): Promise<void> => {
    if (saleDate === undefined) {
      try {
        setState(States.loading);
        const { data } = await api.get<EventMainDateReport>(
          `event/report/${eventId}/sale-date?singleEvent=${reportController.singleEvent}`,
        );
        if (data) {
          setSaleDate(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    handleGetSaleDate();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <SaleDateContent generatePDF={generatePDF} saleDate={saleDate} />
    </>
  );
};
