import React from 'react';
import Event from '@/model/Event';
import { Radio } from '@/components';
import { FormData, OnChangeFormInput } from '@/hooks/useForm';

export interface CloneProductScreenProps {
  eventChild: Event[];
  formCloneProduct: FormData;
  onChangeFormInput: OnChangeFormInput;
}

// eslint-disable-next-line no-shadow
export enum FormRadioInput {
  eventClone = 'eventClone',
}

export const CloneProductScreen: React.FC<CloneProductScreenProps> = ({
  eventChild,
  formCloneProduct,
  onChangeFormInput,
}): JSX.Element => {
  const radioOptions = eventChild.map(event => ({
    value: event.id,
    label: event.name,
  }));

  return (
    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
      <Radio
        name={FormRadioInput.eventClone}
        label="Selecione o Evento para clonar"
        options={radioOptions}
        onChange={e => onChangeFormInput(FormRadioInput.eventClone)(e.target.value)}
        value={formCloneProduct[FormRadioInput.eventClone]}
        wrapperClass="custom-wrapper-class"
        className="custom-radio-class"
      />
    </div>
  );
};
