import React from 'react';

const ComboIcon = (): JSX.Element => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1348 -3.05176e-05H3.86498C3.7378 -3.05176e-05 3.63481 0.102959 3.63481 0.230141V7.27565L0.230172 7.2758C0.102994 7.2758 0 7.37879 0 7.50597V14.7758C0 14.903 0.10299 15.006 0.230172 15.006H14.7698C14.897 15.006 15 14.903 15 14.7758V7.4939C15 7.36672 14.897 7.26373 14.7698 7.26373H11.3591L11.359 0.224452C11.359 0.0971215 11.2621 0.000215205 11.1349 0.000215205L11.1348 -3.05176e-05ZM6.51244 0.454378H8.4813V2.10221L7.65738 1.27829C7.56657 1.18747 7.42721 1.18747 7.33624 1.27829L6.51232 2.10221L6.51244 0.454378ZM2.87749 7.72382H4.85242V9.37165L4.02851 8.54773C3.93769 8.45691 3.79833 8.45691 3.70736 8.54773L2.88345 9.37165L2.8836 7.72382H2.87749ZM7.26969 14.5392H0.454346V7.72382H2.4232L2.42335 9.91688C2.42335 10.0077 2.47781 10.0926 2.5627 10.1289C2.64759 10.1653 2.7445 10.147 2.81113 10.0804L3.85913 9.03241L4.90714 10.0804C4.94958 10.1229 5.01013 10.147 5.07067 10.147C5.10095 10.147 5.13122 10.141 5.15556 10.1289C5.24045 10.0926 5.29491 10.0138 5.29491 9.91687V7.71786H7.26376V14.539L7.26969 14.5392ZM4.0893 7.26972V0.454378H6.05815V2.64732C6.05815 2.73814 6.11262 2.82303 6.1975 2.85939C6.28239 2.89575 6.3793 2.87749 6.44593 2.81086L7.49393 1.76285L8.54194 2.81086C8.58438 2.8533 8.64493 2.87749 8.70547 2.87749C8.73575 2.87749 8.76602 2.8714 8.79036 2.85939C8.87525 2.82303 8.92971 2.74423 8.92971 2.64732V0.454376H10.8986V7.26972L4.0893 7.26972ZM12.3464 10.1472C12.3767 10.1472 12.4069 10.1411 12.4313 10.1291C12.5162 10.0928 12.5706 10.014 12.5706 9.91707V7.71805H14.5395V14.5334H7.72413V7.72389H9.69298V9.91695C9.69298 10.0078 9.74744 10.0927 9.83233 10.129C9.91722 10.1654 10.0141 10.1471 10.0808 10.0805L11.1288 9.03248L12.1768 10.0805C12.2314 10.1229 12.2858 10.1471 12.3464 10.1471L12.3464 10.1472ZM12.1162 7.72397V9.3718L11.2923 8.54789C11.2015 8.45707 11.0621 8.45707 10.9711 8.54789L10.1472 9.3718V7.72397H12.1162Z"
      fill="#222222"
    />
    <rect
      x="2.48805"
      y="10.6322"
      width="2.80403"
      height="0.701007"
      rx="0.350503"
      fill="#222222"
      stroke="#C4C4C4"
      strokeWidth="0.155779"
    />
    <rect
      x="9.7732"
      y="10.6322"
      width="2.80403"
      height="0.701007"
      rx="0.350503"
      fill="#222222"
      stroke="#C4C4C4"
      strokeWidth="0.155779"
    />
    <rect
      x="6.11305"
      y="3.38859"
      width="2.80403"
      height="0.701007"
      rx="0.350503"
      fill="#222222"
      stroke="#C4C4C4"
      strokeWidth="0.155779"
    />
  </svg>
);

export default ComboIcon;
