import { CustomTable } from '@/components/Table';
import SuperCollapse from '@/components/sharedComponents/SuperCollapse';
import DiscountCoupon from '@/model/DiscountCoupon';
import { EventCourtesyReport } from '@/model/EventCourtesyReport';
import Voucher from '@/model/Voucher';
import React from 'react';
import { Card } from 'reactstrap';

interface VoucherCourtesyUIProps {
  voucherList: Voucher[];
  couponsList: DiscountCoupon[];
  courtesyList?: EventCourtesyReport;
}

export const VoucherCourtesyUI: React.FC<VoucherCourtesyUIProps> = ({
  voucherList,
  couponsList,
  courtesyList,
}): JSX.Element => (
  <div className="justify-content-between mt-5 mb-5">
    <h5>Voucher e Courtesy</h5>
    {voucherList && voucherList.length > 0 && (
      <div>
        <h6 className="mt-4">Voucher</h6>
        {voucherList.map((data, index) => (
          <Card
            key={index} // Adicionei uma chave única para cada item na lista
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <div className="p-4">
              <CustomTable
                numberRowsPerPage={0}
                progressPending={false}
                theme="secondary"
                columns={[
                  {
                    name: 'Voucher',
                    selector: row => row.descript,
                    width: '30%',
                  },
                  {
                    name: 'Usuário',
                    selector: row => row.user,
                    width: '20%',
                  },
                  {
                    name: 'valor',
                    selector: row => row.value,
                    width: '20%',
                  },
                  {
                    name: 'Codigo',
                    selector: row => row.code,
                    width: '20%',
                  },
                ]}
                data={[
                  {
                    descript: data.description,
                    user: data.user,
                    value: data.value,
                    code: data.value,
                  },
                ]}
              />
            </div>
          </Card>
        ))}
      </div>
    )}
    {couponsList && couponsList.length > 0 && (
      <div>
        <h6 className="mt-4">Coupons</h6>
        {couponsList.map((data, index) => (
          <Card
            key={index} // Adicionei uma chave única para cada item na lista
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <div className="p-4">
              <CustomTable
                numberRowsPerPage={0}
                progressPending={false}
                theme="secondary"
                columns={[
                  {
                    name: 'Descrição',
                    selector: row => row.name,
                    width: '30%',
                  },
                  {
                    name: 'Quantidade',
                    selector: row => row.amount,
                    width: '20%',
                  },
                  {
                    name: 'Valor',
                    selector: row => row.value,
                    width: '20%',
                  },
                  {
                    name: 'Código',
                    selector: row => row.code,
                    width: '20%',
                  },
                ]}
                data={[
                  {
                    name: data.name,
                    amount: data.amount,
                    value: data.discount,
                    code: data.code,
                  },
                ]}
              />
            </div>
          </Card>
        ))}
      </div>
    )}

    {courtesyList && courtesyList.employees.length > 0 && (
      <div>
        <h6 className="mt-4">Courtesias</h6>
        {courtesyList.employees.map((data, index) => (
          <Card
            key={index} // Adicionei uma chave única para cada item na lista
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            <div className="p-4">
              <CustomTable
                numberRowsPerPage={0}
                progressPending={false}
                theme="secondary"
                columns={[
                  {
                    name: 'Descrição',
                    selector: row => row.descript,
                    width: '30%',
                  },
                  {
                    name: 'Setor',
                    selector: row => row.section,
                    width: '20%',
                  },
                  {
                    name: 'Ingresso',
                    selector: row => row.ticket,
                    width: '20%',
                  },
                  {
                    name: 'QTD',
                    selector: row => row.quantity,
                    width: '20%',
                  },
                ]}
                data={data.courtesys.map(item => ({
                  descript: item.description,
                  section: item.ticket.eventSection.name || '---',
                  ticket: item.ticket.name || '---',
                  quantity: item.amount || '---',
                }))}
              />
            </div>
          </Card>
        ))}
      </div>
    )}
    {courtesyList && courtesyList.contractors.length > 0 && (
      <div>
        <h6 className="mt-4">Contrato</h6>
        {courtesyList.contractors.map((data, index) => (
          <SuperCollapse
            key={`${data.user}-${index}`}
            width={'100%'}
            title={
              <CustomTable
                numberRowsPerPage={0}
                progressPending={false}
                theme="secondary"
                columns={[
                  {
                    name: 'Funcionário',
                    selector: row => row.employees,
                    width: '30%',
                  },
                  // {
                  //   name: 'Usuário',
                  //   selector: row => row.user,
                  //   width: '20%',
                  // },
                  // {
                  //   name: 'valor',
                  //   selector: row => row.value,
                  //   width: '20%',
                  // },
                  // {
                  //   name: 'Codigo',
                  //   selector: row => row.code,
                  //   width: '20%',
                  // },
                ]}
                data={[
                  {
                    descript: data.user,
                    // user: data.user,
                    // value: data.value,
                    // code: data.value,
                  },
                ]}
              />
            }
            content={
              <>
                <CustomTable
                  numberRowsPerPage={0}
                  progressPending={false}
                  theme="secondary"
                  columns={[
                    {
                      name: 'Descrição',
                      selector: row => row.descript,
                      width: '55%',
                    },
                    {
                      name: 'Setor',
                      selector: row => row.section,
                      width: '15%',
                    },
                    {
                      name: 'Ingresso',
                      selector: row => row.ticket,
                      width: '15%',
                    },
                    {
                      name: 'QTD',
                      selector: row => row.quantity,
                      width: '15%',
                    },
                  ]}
                  data={data.courtesys.map(item => ({
                    descript: item.description,
                    section: item.ticket.eventSection.name || '---',
                    ticket: item.ticket.name || '---',
                    quantity: item.amount || '---',
                  }))}
                />
              </>
            }
          />
        ))}
      </div>
    )}

    {courtesyList && courtesyList.pdvs.length > 0 && (
      <div>
        <h6 className="mt-4">Pdvs</h6>
        {courtesyList.pdvs.map((data, index) => (
          <SuperCollapse
            key={`${data.pdvId}-${index}`}
            width={'100%'}
            title={
              <CustomTable
                numberRowsPerPage={0}
                progressPending={false}
                theme="secondary"
                columns={[
                  {
                    name: 'POS',
                    selector: row => row.employees,
                    width: '30%',
                  },
                  // {
                  //   name: 'Usuário',
                  //   selector: row => row.user,
                  //   width: '20%',
                  // },
                  // {
                  //   name: 'valor',
                  //   selector: row => row.value,
                  //   width: '20%',
                  // },
                  // {
                  //   name: 'Codigo',
                  //   selector: row => row.code,
                  //   width: '20%',
                  // },
                ]}
                data={[
                  {
                    descript: data.pdvName,
                    // user: data.user,
                    // value: data.value,
                    // code: data.value,
                  },
                ]}
              />
            }
            content={
              <>
                <CustomTable
                  numberRowsPerPage={0}
                  progressPending={false}
                  theme="secondary"
                  columns={[
                    {
                      name: 'POS',
                      selector: row => row.pos,
                      width: '55%',
                    },
                    {
                      name: 'Evento',
                      selector: row => row.event,
                      width: '15%',
                    },
                    {
                      name: 'Setor',
                      selector: row => row.section,
                      width: '15%',
                    },
                    {
                      name: 'Ingresso',
                      selector: row => row.ticket,
                      width: '15%',
                    },
                    {
                      name: 'Data',
                      selector: row => row.date,
                      width: '15%',
                    },
                  ]}
                  data={data.tickets.map(item => ({
                    pos: item.serialNumber,
                    event: item.eventName || '---',
                    section: item.sectionName || '---',
                    ticket: item.ticketName || '---',
                    date: item.courtesyDate || '---',
                  }))}
                />
              </>
            }
          />
        ))}
      </div>
    )}
  </div>
);
