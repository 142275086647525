import React, { useEffect, useState } from 'react';
import Page from '@/model/Page';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import { EventMoneyTransferAttachment } from '@/model/EventMoneyTransferAttachment';
import { FormInput, FormInputAttachment, ShouldShowModal, States } from '../types';
import { RedemptionFinishedContainer } from './ui';

export const RedemptionFinishedScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [page, setPage] = useState<Page<EventMoneyTransfer, EventMoneyTransfer>>({
    page: 1,
    pageSize: 10,
    entity: {
      transferred: true,
    } as EventMoneyTransfer,
    sort: 'transferDate',
    order: 'DESC',
  });
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const [eventMoneyTransfer, setEventMoneyTransfer] = useState<EventMoneyTransfer>();

  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const handleOnPage = async (
    pageData: Page<EventMoneyTransfer, EventMoneyTransfer>,
  ): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Page<EventMoneyTransfer, EventMoneyTransfer>>(
        '/event-money-transfer/page/requests',
        pageData,
      );
      if (data) {
        setPage(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const {
    formData: formDataAttachment,
    formErrors: formErrorsAttachment,
    onChangeFormInput: onChangeFormInputAttachment,
    isFormValid: isFormValidAttachment,
    resetForm: resetFormAttachment,
    setErrors: setErrorsAttachment,
  } = useForm({
    initialData: {
      id: '',
      attachment: '',
      attachmentName: '',
    },
    validators: {
      id: [validators.required],
      attachment: [validators.required],
      attachmentName: [validators.required],
    },
  });

  const handleOnGet = async (id: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventMoneyTransfer>(`/event-money-transfer/${id}`);
      if (data) {
        setEventMoneyTransfer(data);
        onChangeFormInputAttachment(FormInputAttachment.id)(data.id);
        if (data.attachmentUrl) {
          onChangeFormInputAttachment(FormInputAttachment.attachment)(data.attachmentUrl);
          const split = data.attachmentUrl.split('/');
          onChangeFormInputAttachment(FormInputAttachment.attachmentName)(split[split.length - 1]);
        } else {
          onChangeFormInputAttachment(FormInputAttachment.attachment)('');
          onChangeFormInputAttachment(FormInputAttachment.attachmentName)('');
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    id,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    id?: string;
  }): Promise<void> => {
    if ((value === ShouldShowModal.detail || value === ShouldShowModal.attachment) && id) {
      await handleOnGet(id);
      if (value === ShouldShowModal.attachment) {
        onChangeFormInputAttachment(FormInputAttachment.id)(id);
      }
    }

    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
    validators: {
      inputSearch: [validators.required],
    },
  });

  const handleOnClearFilter = async (): Promise<void> => {
    resetForm();
    const newPage: Page<EventMoneyTransfer, EventMoneyTransfer> = {
      page: 1,
      pageSize: 10,
      sort: 'transferRequestDate',
      order: 'ASC',
    };
    await handleOnPage(newPage);
  };

  const handleOnFilter = async (): Promise<void> => {
    if (isFormValid()) {
      const newPage: Page<EventMoneyTransfer, EventMoneyTransfer> = {
        page: 1,
        pageSize: 10,
        entity: {
          transferred: formData[FormInput.inputSearch] === 'true',
        } as EventMoneyTransfer,
        sort: 'startDate',
        order: 'DESC',
        total: 1,
      };
      onToggle();

      await handleOnPage(newPage);
    }
  };

  const handleOnChangeFileInput = (target: EventTarget & HTMLInputElement): void => {
    if (target && target.files) {
      const file = target.files[0];
      if (file && file.type.match('image.*|application.pdf')) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            onChangeFormInputAttachment('attachmentName')(file.name);
            onChangeFormInputAttachment('attachment')(base64);
          }
        };
      } else {
        setErrorsAttachment({ message: ['O formato deve ser .jpg, .jpeg ou .png'] });
      }
    } else {
      setErrorsAttachment({
        message: ['Não foi possível carregar o arquivo'],
      });
    }
  };

  const handleOnAddAttachment = async (): Promise<void> => {
    if (isFormValidAttachment()) {
      try {
        setState(States.loading);
        const payload: EventMoneyTransferAttachment = {
          id: formDataAttachment[FormInputAttachment.id],
          attachment: formDataAttachment[FormInputAttachment.attachment],
        };
        await api.put('/event-money-transfer/', payload);
        resetFormAttachment();
        onToggle();
        handleOnClearFilter();
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status !== 401) {
          toast.error(err.message);
        }
      } finally {
        setState(States.default);
      }
    }
  };

  const handleOnPaginationChange = async (newPageNumber: number): Promise<void> => {
    const newPage: Page<EventMoneyTransfer, EventMoneyTransfer> = {
      ...page,
      page: newPageNumber,
    };
    await handleOnPage(newPage);
  };
  useEffect(() => {
    handleOnPage(page);
  }, []);
  return (
    <RedemptionFinishedContainer
      state={state}
      title={title}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      onShouldShowModal={handleOnShouldShowModal}
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      onClearFilter={handleOnClearFilter}
      onFilter={handleOnFilter}
      page={page}
      eventMoneyTransfer={eventMoneyTransfer}
      formDataAttachment={formDataAttachment}
      formErrorsAttachment={formErrorsAttachment}
      onChangeFileInput={handleOnChangeFileInput}
      onAddAttachment={handleOnAddAttachment}
      onPaginationChange={handleOnPaginationChange}
    />
  );
};
