import React from 'react';
import { Route } from '@/navigation/Route';
import { DocumentScreen } from '@/features/document/screens/List';

export const DOCUMENT_ROUTES = {
  itself: '/dashboard/document',
};

export const DocumentNavigation = (): JSX.Element => (
  <Route exact path={DOCUMENT_ROUTES.itself} component={DocumentScreen} isPrivateRoute />
);
