import React, { useEffect, useState } from 'react';
import { Button } from '@/components';
import { toCurrency } from '@/helpers/masks/toCurrency';
import EventProductReport from '@/model/EventProductReport';
import EventProductDetailReport from '@/model/EventProductDetailReport';
import { CustomTable } from '@/components/Table';
import { Card } from 'reactstrap';
// import { ReportFooter } from '@/components/ReportFooter';
import EventReportPayment from '@/model/EventReportPayment';
import { DataList } from '@/components/DataList';

/*
interface FooterData {
  title: string;
  value: string;
}
*/

export interface SaleSubPdvContentProps {
  generatePDF: () => void;
  payments: EventReportPayment;
  saleProduct: EventProductReport | undefined;
}

export const SaleProdutContent: React.FC<SaleSubPdvContentProps> = ({
  payments,
  saleProduct,
  generatePDF,
}) => {
  const [totalSold, setTotalSold] = useState<number>(0);
  const [totalValue, setTotalValue] = useState<number>(0);
  // const [footerData, setFooterData] = useState<FooterData[]>([]);
  const mountFooterData = (): void => {
    let ttotalSold = 0;
    const ttotalValue = payments
      ? payments.money + payments.pix + payments.debit + payments.credit + payments.bankSlip
      : 0;

    if (saleProduct && saleProduct.general && saleProduct.general.length > 0) {
      saleProduct.general.forEach(data => {
        ttotalSold += data.productsSold;
      });
    }

    setTotalSold(ttotalSold);
    setTotalValue(ttotalValue);
  };

  useEffect(() => {
    mountFooterData();
  }, [saleProduct]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5>Vendas por Produtos</h5>
        <Button theme="outlineDark" onClick={() => generatePDF()} title="Exportar relatório" />
      </div>
      <div>
        <h6 className="mb-2 mt-4" style={{ paddingLeft: '1.5rem' }}>
          Vendas totais dos produtos no evento
        </h6>
        <hr />
        <div
          className="customTableScroll p-4"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            gridColumnGap: '40px',
            overflow: 'auto',
            alignItems: 'end',
          }}
        >
          <DataList
            data={
              payments
                ? [
                    {
                      title: 'Vendidos:',
                      content: totalSold ? `${totalSold}` : '-----',
                    },
                    {
                      title: 'Faturamento:',
                      content: totalValue ? `${toCurrency(totalValue)}` : '-----',
                    },
                    {
                      title: `Crédito (${
                        payments && payments.credit
                          ? ((payments.credit * 100) / totalValue).toFixed(2)
                          : 0
                      } %)`,
                      content: payments ? `${toCurrency(payments.credit)}` : '-----',
                    },
                    {
                      title: `Débito (${
                        payments && payments.debit
                          ? ((payments.debit * 100) / totalValue).toFixed(2)
                          : 0
                      } %)`,
                      content: payments.debit ? `${toCurrency(payments.debit)}` : '-----',
                    },
                    {
                      title: `Pix (${
                        payments && payments.pix
                          ? ((payments.pix * 100) / totalValue).toFixed(2)
                          : 0
                      } %)`,
                      content: payments.pix ? `${toCurrency(payments.pix)}` : '-----',
                    },
                    {
                      title: `Dinheiro (${
                        payments && payments.money
                          ? ((payments.money * 100) / totalValue).toFixed(2)
                          : 0
                      } %)`,
                      content: payments.money ? `${toCurrency(payments.money)}` : '-----',
                    },
                    {
                      title: `Boleto (${
                        payments && payments.bankSlip
                          ? ((payments.bankSlip * 100) / totalValue).toFixed(2)
                          : 0
                      } %)`,
                      content: payments.bankSlip ? `${toCurrency(payments.bankSlip)}` : '-----',
                    },
                  ]
                : []
            }
          />
        </div>
      </div>
      {saleProduct && saleProduct.general && saleProduct.general.length > 0 && (
        <>
          <h6 className="mb-4 collapseTableText">Vendas geral por produto</h6>
          <hr />
          <Card
            className="mb-5 card-no-border customTableScroll"
            style={{ border: 'none', overflow: 'auto' }}
          >
            {saleProduct && saleProduct.general && saleProduct.general.length > 0 && (
              <div className="p-4">
                <CustomTable
                  theme="secondary"
                  numberRowsPerPage={0}
                  progressPending={false}
                  columns={[
                    {
                      name: 'Produto/Combo',
                      selector: row => row.productName,
                      width: '40%',
                    },
                    {
                      name: 'Vendidos',
                      selector: row => row.productSold,
                      width: '15%',
                    },
                    {
                      name: 'Cortesias',
                      selector: row => row.productCourtesy,
                      width: '15%',
                    },
                    {
                      name: 'Valor do produto',
                      selector: row => row.productValue,
                      width: '15%',
                    },
                    {
                      name: 'Arrecadado',
                      selector: row => row.totalValue,
                      width: '15%',
                    },
                  ]}
                  data={saleProduct.general.map(data => ({
                    productName: data.productName,
                    productSold: data.productsSold,
                    productCourtesy: data.productsCourtesy,
                    productValue: toCurrency(data.productValue),
                    totalValue: toCurrency(data.totalValue),
                  }))}
                />
              </div>
            )}
          </Card>
        </>
      )}

      {saleProduct && saleProduct.sections && saleProduct.sections.length > 0 && (
        <>
          <h6 className="mb-4 collapseTableText">Vendas de produtos por setor</h6>
          <hr />
          {saleProduct.sections.map(data => (
            <>
              <h6 className="mb-4 mt-5 collapseTableText">{data.name}</h6>
              <Card
                className="mb-5 card-no-border customTableScroll"
                style={{ border: 'none', overflow: 'auto' }}
              >
                <div className="p-4">
                  <CustomTable
                    theme="secondary"
                    numberRowsPerPage={0}
                    progressPending={false}
                    columns={[
                      {
                        name: 'Produto/Combo',
                        selector: row => row.productName,
                      },
                      {
                        name: 'Vendidos',
                        selector: row => row.productSold,
                      },
                      {
                        name: 'Cortesias',
                        selector: row => row.productCourtesy,
                      },
                      {
                        name: 'Estoque inicial',
                        selector: row => row.initialInventory,
                      },
                      {
                        name: 'Estoque disponível',
                        selector: row => row.availableStock,
                      },
                      {
                        name: 'Valor',
                        selector: row => row.productValue,
                      },
                      {
                        name: 'Arrecadado',
                        selector: row => row.totalValue,
                      },
                    ]}
                    data={
                      data?.details?.map((details: EventProductDetailReport) => ({
                        productName: details?.productName,
                        productSold: details?.productsSold,
                        productCourtesy: details?.productsCourtesy,
                        initialInventory: details?.initialInventory,
                        availableStock: details?.availableStock,
                        productValue: toCurrency(details?.productValue),
                        totalValue: toCurrency(details?.totalValue),
                      })) || []
                    }
                  />
                </div>
                <hr className="mb-0" style={{ minWidth: '1080px' }} />
                <div
                  style={{
                    padding: '30px 40px',
                    gap: '40px',
                  }}
                  className="d-flex justify-content-center"
                >
                  <>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Total vendidos:</div>
                      <div className="text-small-black-600">{data?.summary?.totalSold}</div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Cortesias:</div>
                      <div className="text-small-black-600">{data?.summary?.totalCourtesy}</div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Estoque total disponível:</div>
                      <div className="text-small-black-600">
                        {data?.summary?.totalStockAvailable}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Taxas:</div>
                      <div className="text-small-black-600">
                        {toCurrency(data?.summary?.totalFee)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Total arrecadado:</div>
                      <div className="text-small-black-600">
                        {toCurrency(data?.summary?.totalCollected)}
                      </div>
                    </div>
                  </>
                </div>
              </Card>
            </>
          ))}
        </>
      )}

      {saleProduct && saleProduct.pdvs && saleProduct.pdvs.length > 0 && (
        <>
          <h6 className="mb-4 collapseTableText">Vendas de produtos por PDV</h6>
          <hr />
          {saleProduct.pdvs.map(data => (
            <>
              <h6 className="mb-4 mt-5 collapseTableText">{data.name}</h6>
              <Card
                className="mb-5 card-no-border customTableScroll"
                style={{ border: 'none', overflow: 'auto' }}
              >
                <div className="p-4">
                  <CustomTable
                    theme="secondary"
                    numberRowsPerPage={0}
                    progressPending={false}
                    columns={[
                      {
                        name: 'Produto/Combo',
                        selector: row => row.productName,
                      },
                      {
                        name: 'Vendidos',
                        selector: row => row.productSold,
                      },
                      {
                        name: 'Cortesias',
                        selector: row => row.productCourtesy,
                      },
                      {
                        name: 'Estoque inicial',
                        selector: row => row.initialInventory,
                      },
                      {
                        name: 'Estoque disponível',
                        selector: row => row.availableStock,
                      },
                      {
                        name: 'Valor',
                        selector: row => row.productValue,
                      },
                      {
                        name: 'Arrecadado',
                        selector: row => row.totalValue,
                      },
                    ]}
                    data={
                      data?.details?.map((details: EventProductDetailReport) => ({
                        productName: details?.productName,
                        productSold: details?.productsSold,
                        productCourtesy: details?.productsCourtesy,
                        initialInventory: details?.initialInventory,
                        availableStock: details?.availableStock,
                        productValue: toCurrency(details?.productValue),
                        totalValue: toCurrency(details?.totalValue),
                      })) || []
                    }
                  />
                </div>
                <hr className="mb-0" style={{ minWidth: '1080px' }} />
                <div
                  style={{
                    padding: '30px 40px',
                    gap: '40px',
                  }}
                  className="d-flex justify-content-center"
                >
                  <>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Total vendidos:</div>
                      <div className="text-small-black-600">{data?.summary?.totalSold}</div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Cortesias:</div>
                      <div className="text-small-black-600">{data?.summary?.totalCourtesy}</div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Estoque total disponível:</div>
                      <div className="text-small-black-600">
                        {data?.summary?.totalStockAvailable}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Taxas:</div>
                      <div className="text-small-black-600">
                        {toCurrency(data?.summary?.totalFee)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Total arrecadado:</div>
                      <div className="text-small-black-600">
                        {toCurrency(data?.summary?.totalCollected)}
                      </div>
                    </div>
                  </>
                </div>
              </Card>
            </>
          ))}
        </>
      )}

      {saleProduct && saleProduct.machines && saleProduct.machines.length > 0 && (
        <>
          <h6 className="mb-4 collapseTableText">Vendas de produtos por Maquininha</h6>
          <hr />
          {saleProduct.machines.map(data => (
            <>
              <h6 className="mb-4 mt-5 collapseTableText">{data.name}</h6>
              <Card
                className="mb-5 card-no-border customTableScroll"
                style={{ border: 'none', overflow: 'auto' }}
              >
                <div className="p-4">
                  <CustomTable
                    theme="secondary"
                    numberRowsPerPage={0}
                    progressPending={false}
                    columns={[
                      {
                        name: 'Produto/Combo',
                        selector: row => row.productName,
                      },
                      {
                        name: 'Vendidos',
                        selector: row => row.productSold,
                      },
                      {
                        name: 'Cortesias',
                        selector: row => row.productCourtesy,
                      },
                      {
                        name: 'Estoque inicial',
                        selector: row => row.initialInventory,
                      },
                      {
                        name: 'Estoque disponível',
                        selector: row => row.availableStock,
                      },
                      {
                        name: 'Valor',
                        selector: row => row.productValue,
                      },
                      {
                        name: 'Arrecadado',
                        selector: row => row.totalValue,
                      },
                    ]}
                    data={
                      data?.details?.map((details: EventProductDetailReport) => ({
                        productName: details?.productName,
                        productSold: details?.productsSold,
                        productCourtesy: details?.productsCourtesy,
                        initialInventory: details?.initialInventory,
                        availableStock: details?.availableStock,
                        productValue: toCurrency(details?.productValue),
                        totalValue: toCurrency(details?.totalValue),
                      })) || []
                    }
                  />
                </div>
                <hr className="mb-0" style={{ minWidth: '1080px' }} />
                <div
                  style={{
                    padding: '30px 40px',
                    gap: '40px',
                  }}
                  className="d-flex justify-content-center"
                >
                  <>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Total vendidos:</div>
                      <div className="text-small-black-600">{data?.summary?.totalSold}</div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Cortesias:</div>
                      <div className="text-small-black-600">{data?.summary?.totalCourtesy}</div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Estoque total disponível:</div>
                      <div className="text-small-black-600">
                        {data?.summary?.totalStockAvailable}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Taxas:</div>
                      <div className="text-small-black-600">
                        {toCurrency(data?.summary?.totalFee)}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Total arrecadado:</div>
                      <div className="text-small-black-600">
                        {toCurrency(data?.summary?.totalCollected)}
                      </div>
                    </div>
                  </>
                </div>
              </Card>
            </>
          ))}
        </>
      )}
      {/*
      <ReportFooter data={footerData} />
      */}
    </>
  );
};
