import React from 'react';
import { ReactComponent as Warning } from '@/assets/images/svg/warning.svg';

export const ReactivateContent: React.FC = (): JSX.Element => (
  <React.Fragment>
    <div className="exclude-container">
      <Warning color="#E64F49" />
      <div className="header-title-text modal__title">
        <h5 className="modal__confirmation-title">
          Você tem certeza que quer reativar esse pedido?
        </h5>
      </div>
      <div className="modal__confirmation-text">
        Ao reativar o pedido, os ingressos ficarão disponíveis para serem impressos e transferidos.
      </div>
    </div>
  </React.Fragment>
);
