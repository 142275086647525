/* eslint-disable no-nested-ternary */
import { DataList } from '@/components/DataList';
import SuperCollapse from '@/components/sharedComponents/SuperCollapse';
import { CustomTable } from '@/components/Table';
import empty from '@/assets/images/other-images/imgvazio.svg';
import { ReactComponent as LocationPin } from '@/assets/images/svg/locationPinNoFill.svg';
import { colors } from '@/styles/colors';
import React from 'react';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import { Card, Container } from 'reactstrap';
import dayjs from 'dayjs';
import UserType from '@/model/UserType';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { Dialog } from '@/components';
import { ActionProps } from '@/components/Dialog';
import EventMainReport from '@/model/EventMainReport';
import EventDetailReport from '@/model/EventDetailReport';
import { getItem } from '@/helpers/common/localStorage';
import { REACT_APP_USER } from '@/utils/config';
import { AuthUser } from '@/model/AuthUser';
import { GeneralSale } from '../../components/GeneralSale';
import { SaleDate } from '../../components/SaleDate';
import { SalePdv } from '../../components/SalePdv';
/*
import { SaleSubPdv } from '../../components/SaleSubPdv';
*/
import { Bleeding } from '../../components/Bleeding';

import { Tracking } from '../../components/Tracking';
/*
import { Terminal } from '../../components/Terminal';
import { Validation } from '../../components/Validation';
*/
import { ReportControllerProps, ShouldShowModal } from '../../types';
import { SaleProduct } from '../../components/SaleProduct';
import { DetailContent } from '../../components/DetailContent';
import { Validation } from '../../components/Validation';
import { VoucherCourtesyScreen } from '../../components/VoucherCourtesy';
import { CancellationsScreen } from '../../components/Cancellations';

interface ReportTitle {
  title: string;
}

export interface ReportsContentProps {
  event: EventMainReport;
  shouldShowModal: ShouldShowModal;
  visible: boolean;
  eventChild: EventMainReport[];
  reportController: ReportControllerProps;
  userType: UserType;
  eventDetail: EventDetailReport | undefined;
  onChildPress: (eventSelected: string, eventName: string) => void;
  onShowModal: () => void;
  generatePDF: () => void;
  onToggle: () => void;
}

export const ReportsContent: React.FC<ReportsContentProps> = ({
  event,
  shouldShowModal,
  visible,
  eventChild,
  userType,
  reportController,
  eventDetail,
  onChildPress,
  onShowModal,
  generatePDF,
  onToggle,
}) => {
  const userTypeRender =
    userType === UserType.PDV
      ? 'Vendas por PDV'
      : userType === UserType.SUB_PDV
      ? 'Vendas por SubPDV'
      : '';

  const [reportContent, setReportContent] = React.useState(userTypeRender);

  const reports = [
    {
      title: 'Vendas gerais ingresso',
    },
    {
      title: 'Vendas por data',
    },
    {
      title: 'Vendas por PDV',
    },
    /*
    {
      title: 'Vendas por SubPDV',
    }, */
    {
      title: 'Sangria',
    },
    {
      title: 'Rastreamento',
    },
    {
      title: 'Vendas por Produtos',
    },

    {
      title: 'Validação',
    },
    {
      title: 'Voucher e Cortesias',
    },
    {
      title: 'Cancelamentos',
    },
  ];

  const reportsArtist = [
    {
      title: 'Vendas gerais ingresso',
    },
    {
      title: 'Vendas por data',
    },
    {
      title: 'Vendas por PDV',
    },
    /*
    {
      title: 'Vendas por SubPDV',
    }, */
    {
      title: 'Rastreamento',
    },
    {
      title: 'Validação',
    },
    {
      title: 'Voucher e Cortesias',
    },
    {
      title: 'Cancelamentos',
    },
  ];

  const reportsPdv = [
    {
      title: 'Vendas por PDV',
    },
    {
      title: 'Vendas por SubPDV',
    },
  ];

  const reportsSubPdv = [
    {
      title: 'Vendas por SubPDV',
    },
  ];

  const renderActionDialogToCancel: ActionProps = {
    title: 'Fechar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };

  const user = getItem(String(REACT_APP_USER)) as AuthUser;

  const ReportCards = (): JSX.Element => {
    let newReports: ReportTitle[] = [];
    if (userType === UserType.Employee || userType === UserType.CONTRACTOR) {
      newReports = reports;
    } else if (userType === UserType.ARTIST) {
      newReports = reportsArtist;
    } else if (userType === UserType.PDV) {
      newReports = reportsPdv;
    } else if (userType === UserType.SUB_PDV) {
      newReports = reportsSubPdv;
    }
    return (
      <React.Fragment>
        {newReports.map((report, index) => (
          <Card
            key={`Card-Report-${index}`}
            className="report-menu-card card-no-border"
            style={{
              backgroundColor: report.title === reportContent ? '#3CAFC8' : '#FFFFFF',
              color: report.title === reportContent ? '#FFFFFF' : '#000000',

              whiteSpace: 'normal',
              textAlign: 'center',
              maxWidth: '180px',
              padding: 'initial',
              minHeight: '100px',
              justifyContent: 'center',
            }}
            onClick={() => {
              setReportContent(report.title);
            }}
          >
            {report.title}
          </Card>
        ))}
      </React.Fragment>
    );
  };

  return (
    <>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between">
          <div className="pageTitle d-flex" style={{ margin: 0 }}>
            <Link to={`${process.env.PUBLIC_URL}/dashboard/event`}>
              <ArrowLeft color={colors.black} className="arrow-left" />
            </Link>
            <h5 className="ml-3 mb-0 mt-2 pageTitle">
              {event.name} - {event.date ? dayjs(event.date).format('DD/MM/YYYY') : 'Sem data'}
            </h5>
          </div>
        </div>
        <div className="d-flex">
          <LocationPin style={{ transform: 'scale(0.9)' }} color={colors.black} />
          <div
            className="text-darkgray-regular"
            style={{ marginLeft: '15px', marginTop: '-3px' }}
          >{`${event.city}/${event.state}`}</div>
        </div>

        {event.imageDetailUrl && (
          <img
            style={{
              borderRadius: '10px',
              transform: 'scaleY(0.8)',
              maxWidth: '100%',
              minWidth: '100%',
              // maxHeight: '42px',
            }}
            src={event.imageDetailUrl ? event.imageDetailUrl : empty}
          />
        )}
        <h6 className="mb-2">Vendas de ingressos</h6>
        <div
          className="mb-2 customTableScroll"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            gridColumnGap: '40px',
            overflow: 'auto',
            alignItems: 'end',
          }}
        >
          <DataList
            data={
              user.userType === UserType.Employee && event.ticket
                ? [
                    {
                      title: 'Vendas de hoje',
                      content: event.ticket.amountSale || '-----',
                    },
                    {
                      title: 'Cortesias',
                      content: event.ticket.amountCourtesy || '-----',
                    },
                    {
                      title: 'Descontos',
                      content: event.ticket.discount
                        ? `${toCurrency(event.ticket.discount)}`
                        : '-----',
                    },
                    {
                      title: 'Vendas:',
                      content: event.ticket.totalSales || '-----',
                    },
                    {
                      title: 'Vendas + Cortesias:',
                      content: event.ticket.totalSales + event.ticket.amountCourtesy || '-----',
                    },
                    {
                      title: 'Taxas:',
                      content: event.ticket.fees ? `${toCurrency(event.ticket.fees)}` : '-----',
                    },
                    {
                      title: 'Faturamento:',
                      content: event.ticket.saleValue
                        ? `${toCurrency(event.ticket.saleValue)}`
                        : '-----',
                    },
                    {
                      title: 'Ticket médio:',
                      content: event.ticket.averageTicket
                        ? `${toCurrency(event.ticket.averageTicket)}`
                        : '-----',
                    },
                  ]
                : [
                    {
                      title: 'Vendas de hoje',
                      content: event.ticket.amountSale || '-----',
                    },
                    {
                      title: 'Cortesias',
                      content: event.ticket.amountCourtesy || '-----',
                    },
                    {
                      title: 'Descontos',
                      content: event.ticket.discount || '-----',
                    },
                    {
                      title: 'Vendas:',
                      content: event.ticket.totalSales || '-----',
                    },
                    {
                      title: 'Vendas + Cortesias:',
                      content: event.ticket.totalSales + event.ticket.amountCourtesy || '-----',
                    },
                    {
                      title: 'Faturamento:',
                      content: event.ticket.saleValue
                        ? `${toCurrency(event.ticket.saleValue)}`
                        : '-----',
                    },
                    {
                      title: 'Ticket médio:',
                      content: event.ticket.averageTicket
                        ? `${toCurrency(event.ticket.averageTicket)}`
                        : '-----',
                    },
                  ]
            }
          />
        </div>
        {event.product && event.product.saleValue > 0 && (
          <>
            <h6 className="mb-2">Vendas de produtos</h6>
            <div
              className="mb-2 customTableScroll"
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                gridColumnGap: '40px',
                overflow: 'auto',
                alignItems: 'end',
              }}
            >
              <DataList
                data={
                  event.product
                    ? [
                        {
                          title: 'Qtd. vendas',
                          content: event.product.amountSale || '-----',
                        },
                        {
                          title: 'Cortesias',
                          content: event.product.amountCourtesy || '-----',
                        },
                        {
                          title: 'Descontos',
                          content: event.product.discount || '-----',
                        },
                        {
                          title: 'Taxas',
                          content: toCurrency(event.product.fee) || '-----',
                        },
                        {
                          title: 'Faturamento S/ Taxas:',
                          content: event.product.saleValue
                            ? `${toCurrency(
                                Number(event.product.saleValue) - Number(event.product.fee),
                              )}`
                            : '-----',
                        },
                        {
                          title: 'Faturamento C/ Taxas:',
                          content: event.product.saleValue
                            ? `${toCurrency(event.product.saleValue)}`
                            : '-----',
                        },
                        {
                          title: 'Ticket médio:',
                          content: event.product.averageTicket
                            ? `${toCurrency(event.product.averageTicket)}`
                            : '-----',
                        },
                      ]
                    : []
                }
              />
            </div>
          </>
        )}

        {eventChild && eventChild.length > 0 && (
          <SuperCollapse
            className={eventChild ? ' ' : 'collapse-disabled collapse-disable-text'}
            title="Eventos diários"
            content={
              <CustomTable
                numberRowsPerPage={0}
                progressPending={false}
                theme="secondaryWithoutBorder"
                columns={[
                  {
                    name: '',
                    selector: row => row.image,
                    width: '120px',
                  },
                  {
                    name: 'Nome do evento',
                    selector: row => row.name,
                  },
                  {
                    name: 'Venda',
                    selector: row => row.venda,
                  },
                  {
                    name: 'Cortesia',
                    selector: row => row.cortesia,
                  },
                  {
                    name: 'Total de vendas',
                    selector: row => row.totalVendas,
                  },
                  {
                    name: 'Faturamento',
                    selector: row => row.arrecadacao,
                  },
                  {
                    name: 'Ticket médio',
                    selector: row => row.ticketMedio,
                  },
                ]}
                data={
                  eventChild.map &&
                  eventChild.map(child => ({
                    image: (
                      <div
                        onClick={() => onChildPress(child.id, child.name)}
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          style={{
                            borderRadius: '10px',
                            transform: 'scaleY(0.8)',
                            maxHeight: '96px',
                          }}
                          width={120}
                          src={child.imageUrl ? child.imageUrl : empty}
                        />
                      </div>
                    ),
                    name: (
                      <div
                        onClick={() => onChildPress(child.id, child.name)}
                        style={{ cursor: 'pointer' }}
                      >
                        {child.name || '---'}
                      </div>
                    ),
                    venda: (
                      <div
                        onClick={() => onChildPress(child.id, child.name)}
                        style={{ cursor: 'pointer' }}
                      >
                        {child.ticket.totalSales !== undefined &&
                        child.product.amountSale !== undefined
                          ? child.ticket.totalSales + child.product.amountSale
                          : '---'}
                      </div>
                    ),
                    cortesia: (
                      <div
                        onClick={() => onChildPress(child.id, child.name)}
                        style={{ cursor: 'pointer' }}
                      >
                        {child.ticket.amountCourtesy !== undefined &&
                        child.product.amountCourtesy !== undefined
                          ? child.ticket.amountCourtesy + child.product.amountCourtesy
                          : '---'}
                      </div>
                    ),
                    totalVendas: (
                      <div
                        onClick={() => onChildPress(child.id, child.name)}
                        style={{ cursor: 'pointer' }}
                      >
                        {child.ticket.totalSales !== undefined &&
                        child.product.amountSale !== undefined
                          ? child.ticket.totalSales +
                            child.product.amountSale +
                            child.ticket.amountCourtesy +
                            child.product.amountCourtesy
                          : '---'}
                      </div>
                    ),
                    arrecadacao: (
                      <div
                        onClick={() => onChildPress(child.id, child.name)}
                        style={{ cursor: 'pointer' }}
                      >
                        {child.ticket.totalSales !== undefined &&
                        child.product.saleValue !== undefined
                          ? toCurrency(child.ticket.saleValue + child.product.saleValue)
                          : '---'}
                      </div>
                    ),
                    ticketMedio: (
                      <div
                        onClick={() => onChildPress(child.id, child.name)}
                        style={{ cursor: 'pointer' }}
                      >
                        {child.ticket.averageTicket || child.product.averageTicket
                          ? toCurrency(
                              (child.ticket.averageTicket || 0) +
                                (child.product.averageTicket || 0),
                            )
                          : '---'}
                      </div>
                    ),
                  }))
                }
              />
            }
          />
        )}

        <hr />
        <div className="mb-5 mt-5 report-menu-container">
          <ReportCards />
        </div>
        <hr className="mb-5" />
        {reportContent === 'Vendas gerais ingresso' && (
          <GeneralSale
            payments={event.ticket.payment}
            reportController={reportController}
            generatePDF={generatePDF}
            onShowModal={onShowModal}
          />
        )}
        {reportContent === 'Vendas por data' && (
          <SaleDate reportController={reportController} generatePDF={generatePDF} />
        )}
        {reportContent === 'Vendas por PDV' && (
          <SalePdv generatePDF={generatePDF} reportController={reportController} />
        )}
        {/* reportContent === 'Vendas por SubPDV' && (
          <SaleSubPdv generatePDF={generatePDF} reportController={reportController} />
        ) */}
        {reportContent === 'Sangria' && (
          <Bleeding generatePDF={generatePDF} reportController={reportController} />
        )}
        {reportContent === 'Rastreamento' && (
          <Tracking generatePDF={generatePDF} reportController={reportController} />
        )}

        {reportContent === 'Vendas por Produtos' && (
          <SaleProduct
            payments={event.product.payment}
            generatePDF={generatePDF}
            reportController={reportController}
          />
        )}

        {reportContent === 'Validação' && (
          <Validation generatePDF={generatePDF} reportController={reportController} />
        )}

        {reportContent === 'Voucher e Cortesias' && (
          <VoucherCourtesyScreen reportController={reportController} />
        )}

        {reportContent === 'Cancelamentos' && (
          <CancellationsScreen reportController={reportController} />
        )}
      </Container>
      <Dialog
        title="Vendas por tipo de pagamento"
        visible={visible}
        onClose={onToggle}
        position={'center'}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.detail]: renderActionDialogToCancel,
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.detail]: <DetailContent eventDetail={eventDetail} />,
          }[shouldShowModal]
        }
      </Dialog>
      {/* <ReportFooter data={footerData} hasFooter={hasFooter} /> */}
    </>
  );
};
