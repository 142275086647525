import React from 'react';
import { InputText, SelectCustom } from '@/components';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { OptionProps } from '@/components/Select';
import CardBrand from '@/model/CardBrand';
import { Plus, X } from 'react-feather';
import { AcquiringBrandFee } from '@/model/AcquiringBrandFee';
import PaymentType from '@/model/PaymentType';
import { RegisterBrandFormInput } from '../../types';

interface StateProps {
  paymentType: PaymentType;
  formData: FormData;
  formErrors: FormErrors;
  brands: AcquiringBrandFee[];
}

interface DispatchProps {
  onChangeFormInput: OnChangeFormInput;
  changeBrandFeeInstallments: (index: number, value: string) => void;
  changeBrandFeeValue: (index: number, value: string) => void;
  addBrandFee: () => void;
  removeBrandFee: (index: number) => void;
}

type Props = StateProps & DispatchProps;

export const RegisterBrandContent: React.FC<Props> = ({
  paymentType,
  formData,
  formErrors,
  onChangeFormInput,
  brands,
  changeBrandFeeInstallments,
  changeBrandFeeValue,
  addBrandFee,
  removeBrandFee,
}): JSX.Element => {
  const mountOptions = (): OptionProps[] => {
    const list: OptionProps[] = [
      {
        value: `${CardBrand.AMEX}`,
        label: 'AMEX',
      },
      {
        value: `${CardBrand.BANRICOMPRAS}`,
        label: 'BANRICOMPRAS',
      },
      {
        value: `${CardBrand.CABAL}`,
        label: 'CABAL',
      },
      {
        value: `${CardBrand.ELO}`,
        label: 'ELO',
      },
      {
        value: `${CardBrand.HIPERCARD}`,
        label: 'HIPERCARD',
      },
      {
        value: `${CardBrand.MASTERCARD}`,
        label: 'MASTERCARD',
      },
      {
        value: `${CardBrand.VISA}`,
        label: 'VISA',
      },
    ];
    return list;
  };
  return (
    <Form
      noValidate={true}
      onSubmit={(e): void => {
        e.preventDefault();
      }}
    >
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectCustom
              name="cardBrand"
              label="Bandeira"
              placeholder="..."
              value={formData[RegisterBrandFormInput.cardBrand]}
              onChange={e => {
                onChangeFormInput(RegisterBrandFormInput.cardBrand)(e?.value as string);
              }}
              error={formErrors.cardBrand && formErrors.cardBrand[0]}
              options={mountOptions()}
            />
          </FormGroup>
        </Col>
      </Row>
      {paymentType === PaymentType.DEBIT_CARD && (
        <Row>
          <Col md={8}>
            <FormGroup className="mb-2">
              <InputText
                className={!formData[RegisterBrandFormInput.valueFee] ? 'input__disabled' : ''}
                name="valueFee"
                label="Valor da taxa"
                placeholder="R$40,00 ou 50%"
                maxLength={5}
                value={formData[RegisterBrandFormInput.valueFee]}
                onChange={e => {
                  onChangeFormInput(RegisterBrandFormInput.valueFee)(e.target.value);
                }}
                error={formErrors.valueFee && formErrors.valueFee[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      {(paymentType === PaymentType.CREDIT_CARD ||
        paymentType === PaymentType.TWO_CREDIT_CARDS) && (
        <Row>
          <Col md={8}>
            <table>
              <thead>
                <tr>
                  <th>
                    <label className="input-label">Parcela</label>
                  </th>
                  <th>
                    <label className="input-label" style={{ marginLeft: '1.0rem !important' }}>
                      Valor da taxa
                    </label>
                  </th>
                  <th></th>
                </tr>
              </thead>
              {brands &&
                brands.length > 0 &&
                brands.map((data, index) => (
                  <tr key={`${index}-brand`}>
                    <td>
                      <InputText
                        className={'mr-2'}
                        name="installment"
                        placeholder="1"
                        maxLength={2}
                        type="number"
                        value={`${data.installments}`}
                        onChange={e => changeBrandFeeInstallments(index, e.target.value)}
                      />
                    </td>
                    <td>
                      <InputText
                        className={'ml-2'}
                        name="valueFee"
                        placeholder="50%"
                        maxLength={5}
                        value={`${data.valueFee}`}
                        onChange={e => changeBrandFeeValue(index, e.target.value)}
                        addon={'%'}
                      />
                    </td>
                    <td>
                      <div style={{ marginLeft: '15px' }}>
                        {index > 0 ? (
                          <X
                            className="svg-icon action-icon"
                            onClick={() => removeBrandFee(index)}
                          />
                        ) : (
                          <Plus className="svg-icon action-icon" onClick={() => addBrandFee()} />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </table>
          </Col>
        </Row>
      )}
    </Form>
  );
};
