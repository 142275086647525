/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { EventContainer, States, ShouldShowModal } from '@/features/events/screens/list/ui';
import EventStatus from '@/model/EventStatus';
import { colors } from '@/styles/colors';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import { AxiosError } from 'axios';
import { FormInputName as FormInputNameToFilter } from '@/features/contractor/components/FilterContent';
import { unmask } from '@/helpers/masks/decimalNumber';
import { toast } from 'react-toastify';
import api from '@/services/api';
import Event from '@/model/Event';
import Voucher from '@/model/Voucher';
import validators from '@/helpers/validators';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import EventCourtesy from '@/model/EventCourtesy';
import Section from '@/model/Section';
import Tickets from '@/model/Tickets';
import { updateMask as updateMaskCPF } from '@/helpers/masks/cpf';
import { updateMask as updateMaskPhone } from '@/helpers/masks/mobilePhone';
import { updateMask as updateMaskDate } from '@/helpers/masks/generalDate';
import Client from '@/model/Client';
import { ArrowLeft } from 'react-feather';
import { REACT_APP_USER } from '@/utils/config';
import { updateMask as updateMaskOnlyNumber } from '@/helpers/masks/onlyNumber';
import CartEventTicketUserData from '@/model/CartEventTicketUserData';
import dayjs from 'dayjs';
import { EventRequestParams, EventResponse } from '../../types';
import { FormInputName as FormInputNameCourtesy } from '../../components/RegisterCourtesy';
import { FormInputName } from '../../components/RegisterVoucher';
import { DeleteContent } from '../../components/DeleteContent';
import { CloneContent } from '../../components/CloneContent';

export interface PayloadEvent {
  name: string;
  eventStatus?: EventStatus;
}
export interface PayloadVoucher {
  description: string;
  value: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const EventScreen: React.FC = () => {
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const [state, setState] = useState<States>(States.default);
  const [menuList, setMenuList] = useState<string[]>([]);
  const [listEvent, setListEvent] = useState<Event[]>([]);
  const [voucher, setVoucher] = useState<Voucher[]>([]);
  const [event, setEvent] = useState<Event>();
  const [fullListEvent, setFullListEvent] = useState<Event[]>([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [currentPage, setCurrentPage] = useState<EventRequestParams>({
    page: 1,
    pageSize: +pagination,
    sort: 'startDate',
    order: 'DESC',
    total: 1,
  });
  const [courtesys, setCourtesys] = useState<EventCourtesy[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [tickets, setTickets] = useState<Tickets[]>([]);
  const [courtesy, setCourtesy] = useState<EventCourtesy>({} as EventCourtesy);
  const [disableName, setDisableName] = useState<boolean>(false);
  const [disableEmail, setDisableEmail] = useState<boolean>(false);
  const [disablePhone, setDisablePhone] = useState<boolean>(false);
  const [disableBirthDate, setDisableBirthDate] = useState<boolean>(false);
  const [disableMotherName, setDisableMotherName] = useState<boolean>(false);
  const [file, setFile] = useState<string>('');

  const confirmDelete = useConfirmDelete();
  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const user = JSON.parse(localStorage.getItem(String(REACT_APP_USER)) ?? '{}');
  const { userType } = user;

  const paginationSelect = [
    { value: 10, label: '10 por página' },
    { value: 20, label: '20 por página' },
    { value: 50, label: '50 por página' },
    { value: 100, label: '100 por página' },
  ];

  const changeColorColumn = (status: EventStatus): string => {
    let color = colors.grey;
    switch (status) {
      case EventStatus.DRAFT:
        color = colors.lightBlue;
        break;
      case EventStatus.PENDING:
        color = colors.darkRed;
        break;
      case EventStatus.ACTIVE:
        color = colors.green;
        break;
      case EventStatus.REFUSED:
        color = colors.yellow;
        break;
      case EventStatus.BLOCKED:
        color = colors.red;
        break;
      case EventStatus.FINISHED:
        color = colors.lightGreen;
        break;
      case EventStatus.INACTIVE:
        color = colors.orange;
        break;
      case EventStatus.COMPLETED:
        color = colors.grey;
        break;
      default:
        color = colors.grey;
        break;
    }
    return color;
  };

  const {
    formData: formDataVoucher,
    formErrors: formErrorsVoucher,
    onChangeFormInput: onChangeFormInputVoucher,
    isFormValid: isFormValidVoucher,
    resetForm: resetFormVoucher,
  } = useForm({
    initialData: {
      description: '',
      value: '',
    },
    validators: {
      description: [validators.required],
      value: [validators.required],
    },
  });

  const {
    formData: formDataCourtesy,
    formErrors: formErrorsCourtesy,
    onChangeFormInput: onChangeFormInputCourtesy,
    isFormValid: isFormValidCourtesy,
    resetForm: resetFormCourtesy,
  } = useForm({
    initialData: {
      description: '',
      section: '',
      ticket: '',
      loadFile: 'false',
      amount: '',
      name: '',
      cpf: '',
      birthDate: '',
      cellPhone: '',
      email: '',
      motherName: '',
      file: '',
      sendEmail: 'false',
    },
    validators: {
      description: [validators.required],
      section: [validators.required],
      ticket: [validators.required],
      loadFile: [validators.required],
      sendEmail: [validators.required],
    },
    formatters: {
      cpf: updateMaskCPF,
      cellPhone: updateMaskPhone,
      amount: updateMaskOnlyNumber,
      birthDate: updateMaskDate,
    },
  });

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
    formatters: {},
  });

  const handleOnMountEventMenu = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<string[]>('/event/menu');
      if (data && data.length > 0) {
        setMenuList(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const handleFetch = async (values: EventRequestParams): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<EventResponse>('/event/page', values);

      if (data) {
        setListEvent(data?.list ?? []);

        setCurrentPage(currentPageState => ({
          ...currentPageState,
          ...data,
        }));
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const handleFetchAll = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Event[]>('/event/find');
      setFullListEvent(data ?? []);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };
  const handleFetchVoucher = async (eventSelected: Event): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Voucher[]>(`/event/${eventSelected?.id}/voucher`);
      setVoucher(data ?? []);
      resetFormVoucher();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };
  const handleFetchVoucherModal = async (eventSelected: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Voucher[]>(`/event/${eventSelected}/voucher`);
      setVoucher(data ?? []);
      resetFormVoucher();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnReleaseEvent = async (eventSelected: Event): Promise<void> => {
    try {
      const payload: PayloadEvent = {
        name: eventSelected.name,
      };
      await api.patch<Event>(`/event/${eventSelected.id}/active`, payload);

      toast.success('Evento Liberado!');
      handleFetch(currentPage);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnRefuseEvent = async (eventSelected: Event): Promise<void> => {
    try {
      const payload: PayloadEvent = {
        name: eventSelected.name,
      };
      await api.patch<Event>(`/event/${eventSelected.id}/refused`, payload);

      toast.success('Evento Recusado!');
      handleFetch(currentPage);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnPaginationChange = async (page: number): Promise<void> => {
    handleFetch({
      ...currentPage,
      page,
    });
  };

  const listCourtesys = async (eventId: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventCourtesy[]>(`/event/courtesy/${eventId}`);
      setCourtesys(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const listSections = async (eventId: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Section[]>(`/event/courtesy/${eventId}/section/get`);
      setSections(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleListTickets = async (eventId: string, sectionId: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Tickets[]>(
        `/event/courtesy/${eventId}/section/${sectionId}/ticket`,
      );
      setTickets(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShouldShowModal = ({
    value,
    newTitleModal,
    event: eventSelected,
    notToggle,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event?: Event | any;
    notToggle?: boolean;
  }): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    if (value === ShouldShowModal.voucher) {
      resetFormVoucher();
      handleFetchVoucher(eventSelected);
      setEvent(eventSelected);
    } else if (value === ShouldShowModal.courtesy) {
      setEvent(eventSelected);
      setSections([]);
      setTickets([]);
      resetFormCourtesy();
      listCourtesys(eventSelected?.id as string);
      listSections(eventSelected?.id as string);
    }
    if (!notToggle) {
      onToggle();
    }
  };

  const handleOnSaveVoucher = async (eventSelected: Event): Promise<void> => {
    try {
      if (isFormValidVoucher()) {
        await api.post(`/event/${eventSelected?.id}/voucher`, {
          description: formDataVoucher[FormInputName.description],
          value: unmask(formDataVoucher[FormInputName.value]),
        });
        toast.success('Voucher gerado');
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnConfirmDeleteVoucher = async (
    eventSelected: string,
    voucherSelected: string,
  ): Promise<void> => {
    try {
      await api.put(`/event/${eventSelected}/voucher/${voucherSelected}/disable`);
      toast.success('Voucher excluído');
      handleOnClose();
      handleFetch(currentPage);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  // eslint-disable-next-line no-shadow
  const handleOnShowDeleteProduct = (eventState: string, voucher: string): void => {
    confirmDelete.show({
      title: '',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: async (): Promise<void> => {
            await handleOnConfirmDeleteVoucher(eventState, voucher);
            await handleFetchVoucherModal(eventState);
          },
        },
      ],
    });
  };

  const clearFilter = async (): Promise<void> => {
    resetFormFilter();
    await handleFetch({
      ...currentPage,
      entity: {},
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);
    onToggle();
  };
  const clearFilterStatus = async (): Promise<void> => {
    resetFormFilter();
    await handleFetch({
      ...currentPage,
      entity: {},
    } as any);
  };

  const handleOnFilter = async (): Promise<void> => {
    try {
      if (isFormValidFilter()) {
        const payload =
          {
            name: {
              entity: {
                name: formDataFilter[FormInputNameToFilter.inputSearch],
              },
            },
          }[formDataFilter[FormInputNameToFilter.filterSearch]] || {};

        onToggle();
        await handleFetch({
          ...currentPage,
          page: +pagination,
          ...payload,
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnFilterStatus = async (status: number): Promise<void> => {
    try {
      if (isFormValidFilter()) {
        const payload = {
          entity: {
            eventStatus: status,
          },
        };

        await handleFetch({
          ...currentPage,
          ...payload,
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const copyToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text);
    toast.success('Código copiado');
  };

  const handleCloneEvent = async (eventId: string): Promise<void> => {
    try {
      setState(States.loading);
      handleOnClose();
      await api.post(`event/copy/${eventId}`);
      handleFetch({ ...currentPage, ...pagination });
      handleFetchAll();
      toast.success('Evento clonado com sucesso!');
    } catch {
      toast.warn('Evento clonando, este processo pode demorar alguns minutos!');
    } finally {
      setState(States.default);
    }
  };

  // eslint-disable-next-line no-shadow
  const handleOnShowCloneEvent = (eventId: string): void => {
    let eventTitle = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < listEvent.length; i++) {
      if (listEvent[i].id === eventId) {
        eventTitle = listEvent[i].name;
        break;
      }
    }
    confirmDelete.show({
      title: '',
      children: <CloneContent title={eventTitle} />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero clonar',
          onClick: async () => {
            handleCloneEvent(eventId);
          },
        },
      ],
    });
  };

  const handleOnCheckCpf = async (cpf: string): Promise<void> => {
    setDisableName(false);
    setDisableEmail(false);
    setDisablePhone(false);
    setDisableBirthDate(false);
    setDisableMotherName(false);
    try {
      setState(States.loading);
      const { data } = await api.get<CartEventTicketUserData>(`/order/cpf/${cpf}`);
      if (data.name) {
        if (data.name) {
          setDisableName(true);
          onChangeFormInputCourtesy(FormInputNameCourtesy.name)(data.name);
        }
        if (data.email) {
          setDisableEmail(true);
          onChangeFormInputCourtesy(FormInputNameCourtesy.email)(data.email);
        }
        if (data.phone) {
          setDisablePhone(true);
          onChangeFormInputCourtesy(FormInputNameCourtesy.cellPhone)(data.phone);
        }
        if (data.birthDate) {
          setDisableBirthDate(true);
          onChangeFormInputCourtesy(FormInputNameCourtesy.birthDate)(
            data.birthDate ? dayjs(data.birthDate).format('DD/MM/YYYY') : '',
          );
        }
        if (data.motherName) {
          setDisableMotherName(true);
          onChangeFormInputCourtesy(FormInputNameCourtesy.motherName)(
            data.motherName ? data.motherName : '',
          );
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnAddCourtesy = async (eventId: string): Promise<void> => {
    if (isFormValidCourtesy()) {
      try {
        setState(States.loading);
        const birthDateSplit = formDataCourtesy[FormInputNameCourtesy.birthDate].split('/');
        const birthDateString = `${birthDateSplit[2]}-${birthDateSplit[1]}-${birthDateSplit[0]}T12:00:00.000Z`;
        let client: Client | undefined;
        if (
          formDataCourtesy[FormInputNameCourtesy.cpf] &&
          formDataCourtesy[FormInputNameCourtesy.cpf].trim().length > 0
        ) {
          client = {
            name: formDataCourtesy[FormInputNameCourtesy.name],
            cpf: formDataCourtesy[FormInputNameCourtesy.cpf],
            cellPhone: formDataCourtesy[FormInputNameCourtesy.cellPhone],
            email: formDataCourtesy[FormInputNameCourtesy.email],
            birthDate: new Date(birthDateString),
            motherName: formDataCourtesy[FormInputNameCourtesy.motherName],
          } as Client;
        }
        const payload = {
          description: formDataCourtesy[FormInputNameCourtesy.description],
          ticket: { id: formDataCourtesy[FormInputNameCourtesy.ticket] } as Tickets,
          loadFile: formDataCourtesy[FormInputNameCourtesy.loadFile] === 'true',
          amount: Number(formDataCourtesy[FormInputNameCourtesy.amount]),
          client,
          file: formDataCourtesy[FormInputNameCourtesy.file],
          sendEmail: formDataCourtesy[FormInputNameCourtesy.sendEmail] === 'true',
        } as EventCourtesy;
        await api.post(`event/courtesy/${eventId}`, payload);
        resetFormCourtesy();
        setSections([]);
        setFile('');
        toast.success('Cadastro efetuado com sucesso!');
        listCourtesys(eventId);
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  const handleOnGetCourtesy = async (eventId: string, courtesyId: string): Promise<void> => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < courtesys.length; i++) {
      if (courtesys[i].id === courtesyId) {
        setCourtesy(courtesys[i]);
        break;
      }
    }
    handleOnShouldShowModal({
      value: ShouldShowModal.courtesyDetail,
      newTitleModal: (
        <div
          className="d-flex"
          style={{
            cursor: 'pointer',
          }}
        >
          <div
            className="m-auto"
            onClick={() => {
              handleOnShouldShowModal({
                value: ShouldShowModal.courtesy,
                newTitleModal: 'Cadastrar cortesia nominal',
                event,
                notToggle: true,
              });
            }}
          >
            <ArrowLeft color={colors.black} width="30" height="30" className="m-auto" />
          </div>
          <h5 className="header-title-text modal__title ml-3 mb-0">Detalhes</h5>
        </div>
      ),
      event,
      notToggle: true,
    });
  };

  const handleDeleteCourtesy = async (eventId: string, courtesyId: string): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(`event/courtesy/${eventId}/${courtesyId}`);
      listCourtesys(eventId);
      toast.success('Cortesia excluída');
      handleOnClose();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnDeleteCourtesy = async (eventId: string, courtesyId: string): Promise<void> => {
    confirmDelete.show({
      title: '',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: () => handleDeleteCourtesy(eventId, courtesyId),
        },
      ],
    });
  };

  const handleOnChangeFileInput =
    (inputName: string) =>
    (selectedFile: File | undefined): void => {
      if (selectedFile && selectedFile.type === 'text/csv') {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFile(selectedFile.name);
            onChangeFormInputCourtesy(inputName)(base64);
          }
        };
      } else {
        toast.error('Extensão do arquivo inválida');
      }
    };

  const handleDownloadPdf = async (id: string, name: string): Promise<void> => {
    try {
      setState(States.loading);

      const { data } = await api.get<string>(`order/${id}/printlist`);
      if (data) {
        const base64WithoutPrefix = data.startsWith('data:application/pdf;base64,')
          ? data.substring('data:application/pdf;base64,'.length)
          : data;

        const bytes = atob(base64WithoutPrefix);
        const out = new Uint8Array(bytes.length);

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < bytes.length; i++) {
          out[i] = bytes.charCodeAt(i);
        }
        const blob = new Blob([out], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `ticket-list-${name}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message || 'Erro ao baixar o PDF');
    } finally {
      setState(States.default);
    }
  };

  useEffect(() => {
    handleOnMountEventMenu();
    handleFetch({ ...currentPage, ...pagination });
    handleFetchAll();
  }, [pagination]);

  return (
    <EventContainer
      state={state}
      menuList={menuList}
      voucherState={voucher}
      listEvent={listEvent}
      paginationSelect={paginationSelect}
      changeColorColumn={changeColorColumn}
      shouldShowModal={shouldShowModal}
      title={title}
      visible={visible}
      onToggle={onToggle}
      formDataFilter={formDataFilter}
      formErrorsFilter={formErrorsFilter}
      onChangeFormInputFilter={onChangeFormInputFilter}
      onShouldShowModal={handleOnShouldShowModal}
      onPaginationChange={handleOnPaginationChange}
      currentPage={currentPage}
      pagination={pagination}
      setPagination={setPagination}
      onFilter={handleOnFilter}
      handleOnFilterStatus={handleOnFilterStatus}
      clearFilter={clearFilter}
      fullListEvent={fullListEvent}
      onReleaseEvent={handleOnReleaseEvent}
      onRefuseEvent={handleOnRefuseEvent}
      clearFilterStatus={clearFilterStatus}
      handleOnSaveVoucher={handleOnSaveVoucher}
      eventState={event}
      handleFetchVoucher={handleFetchVoucher}
      onChangeFormInputVoucher={onChangeFormInputVoucher}
      formDataVoucher={formDataVoucher}
      formErrorsVoucher={formErrorsVoucher}
      copyToClipboard={copyToClipboard}
      isFormValidVoucher={isFormValidVoucher}
      handleOnShowDeleteProduct={handleOnShowDeleteProduct}
      onClone={handleOnShowCloneEvent}
      courtesys={courtesys}
      sections={sections}
      tickets={tickets}
      formDataCourtesy={formDataCourtesy}
      formErrorsCourtesy={formErrorsCourtesy}
      onChangeCourtesy={onChangeFormInputCourtesy}
      listTickets={handleListTickets}
      disableName={disableName}
      disableEmail={disableEmail}
      disablePhone={disablePhone}
      disableBirthDate={disableBirthDate}
      disableMotherName={disableMotherName}
      onCheckCpf={handleOnCheckCpf}
      onAddCourtesy={handleOnAddCourtesy}
      onGetCourtesy={handleOnGetCourtesy}
      onDeleteCourtesy={handleOnDeleteCourtesy}
      courtesy={courtesy}
      userType={userType}
      onChangeFileInput={handleOnChangeFileInput}
      file={file}
      ListOnline={handleDownloadPdf}
    />
  );
};
