import React, { useEffect, useState } from 'react';
import { States } from '@/helpers/common/states';
import validators from '@/helpers/validators';
import useForm from '@/hooks/useForm';
import api from '@/services/api';
import CompanyParameter from '@/model/CompanyParameter';
import CardFees from '@/model/CardFees';
import { toast } from 'react-toastify';
import { unmask, updateMask } from '@/helpers/masks/cashNumber';
import { CardFeeContainer } from './ui';
import { FormInputName } from '../types';

export const CardFeeScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState(States.default);
  const { formData, formErrors, onChangeFormInput, isFormValid } = useForm({
    initialData: {
      ticketPhysicalSaleId: '',
      ticketPhysicalSaleAllowCreditCardPayment: 'true',
      ticketPhysicalSaleDebit: '',
      ticketPhysicalSaleCredit: '',
      ticketPhysicalSalePix: '',
      ticketPhysicalSaleAdministrateTax: '',
      ticketPhysicalSaleInstallments: '',
      ticketPhysicalSaleFee: '',
      ticketWebsiteSaleId: '',
      ticketWebsiteSaleAllowCreditCardPayment: 'true',
      ticketWebsiteSaleDebit: '',
      ticketWebsiteSaleCredit: '',
      ticketWebsiteSalePix: '',
      ticketWebsiteSaleAdministrateTax: '',
      ticketWebsiteSaleInstallments: '',
      ticketWebsiteSaleFee: '',
      productPhysicalSaleId: '',
      productPhysicalSaleAllowCreditCardPayment: 'true',
      productPhysicalSaleDebit: '',
      productPhysicalSaleCredit: '',
      productPhysicalSalePix: '',
      productPhysicalSaleAdministrateTax: '',
      productPhysicalSaleInstallments: '',
      productPhysicalSaleFee: '',
      productWebsiteSaleId: '',
      productWebsiteSaleAllowCreditCardPayment: 'true',
      productWebsiteSaleDebit: '',
      productWebsiteSaleCredit: '',
      productWebsiteSalePix: '',
      productWebsiteSaleAdministrateTax: '',
      productWebsiteSaleInstallments: '',
      productWebsiteSaleFee: '',
    },
    validators: {
      ticketPhysicalSaleAllowCreditCardPayment: [validators.required],
      ticketPhysicalSaleDebit: [validators.required, validators.between(0.1, 99.99)],
      ticketPhysicalSaleCredit: [validators.required, validators.between(1, 99.99)],
      ticketPhysicalSalePix: [validators.required, validators.between(1, 99.999)],
      ticketPhysicalSaleAdministrateTax: [validators.required, validators.between(1, 99.99)],
      ticketPhysicalSaleInstallments: [validators.required, validators.between(0, 24)],
      ticketPhysicalSaleFee: [validators.required, validators.between(1, 99.99)],
      ticketWebsiteSaleAllowCreditCardPayment: [validators.required],
      ticketWebsiteSaleBankSlip: [validators.required, validators.between(0.1, 99.99)],
      ticketWebsiteSaleCredit: [validators.required, validators.between(1, 99.99)],
      ticketWebsiteSalePix: [validators.required, validators.between(1, 99.99)],
      ticketWebsiteSaleAdministrateTax: [validators.required, validators.between(1, 99.99)],
      ticketWebsiteSaleInstallments: [validators.required, validators.between(0, 24)],
      ticketWebsiteSaleFee: [validators.required, validators.between(1, 99.99)],
      productPhysicalSaleAllowCreditCardPayment: [validators.required],
      productPhysicalSaleDebit: [validators.required, validators.between(0.1, 99.99)],
      productPhysicalSaleCredit: [validators.required, validators.between(1, 99.99)],
      productPhysicalSalePix: [validators.required, validators.between(1, 99.999)],
      productPhysicalSaleAdministrateTax: [validators.required, validators.between(1, 99.99)],
      productPhysicalSaleInstallments: [validators.required, validators.between(0, 24)],
      productPhysicalSaleFee: [validators.required, validators.between(1, 99.99)],
      productWebsiteSaleAllowCreditCardPayment: [validators.required],
      productWebsiteSaleBankSlip: [validators.required, validators.between(0.1, 99.99)],
      productWebsiteSaleCredit: [validators.required, validators.between(1, 99.99)],
      productWebsiteSalePix: [validators.required, validators.between(1, 99.99)],
      productWebsiteSaleAdministrateTax: [validators.required, validators.between(1, 99.99)],
      productWebsiteSaleInstallments: [validators.required, validators.between(0, 24)],
      productWebsiteSaleFee: [validators.required, validators.between(1, 99.99)],
    },
    formatters: {
      ticketPhysicalSaleDebit: updateMask,
      ticketPhysicalSaleCredit: updateMask,
      ticketPhysicalSalePix: updateMask,
      ticketPhysicalSaleAdministrateTax: updateMask,
      ticketPhysicalSaleFee: updateMask,
      ticketWebsiteSaleBankSlip: updateMask,
      ticketWebsiteSaleCredit: updateMask,
      ticketWebsiteSalePix: updateMask,
      ticketWebsiteSaleAdministrateTax: updateMask,
      ticketWebsiteSaleFee: updateMask,
      productPhysicalSaleDebit: updateMask,
      productPhysicalSaleCredit: updateMask,
      productPhysicalSalePix: updateMask,
      productPhysicalSaleAdministrateTax: updateMask,
      productPhysicalSaleFee: updateMask,
      productWebsiteSaleBankSlip: updateMask,
      productWebsiteSaleCredit: updateMask,
      productWebsiteSalePix: updateMask,
      productWebsiteSaleAdministrateTax: updateMask,
      productWebsiteSaleFee: updateMask,
    },
  });

  const handleOnGetCardFee = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<CompanyParameter>('/company/parameter/fee');
      if (data.ticketPhysicalSale) {
        onChangeFormInput(FormInputName.ticketPhysicalSaleId)(String(data.ticketPhysicalSale.id));
        onChangeFormInput(FormInputName.ticketPhysicalSaleAllowCreditCardPayment)(
          String(data.ticketPhysicalSale.allowCreditCardPayment),
        );
        onChangeFormInput(FormInputName.ticketPhysicalSaleDebit)(
          String(Number(data.ticketPhysicalSale.debit * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.ticketPhysicalSaleCredit)(
          String(Number(data.ticketPhysicalSale.credit * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.ticketPhysicalSalePix)(
          String(Number(data.ticketPhysicalSale.pix * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.ticketPhysicalSaleAdministrateTax)(
          String(Number(data.ticketPhysicalSale.administrateTax * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.ticketPhysicalSaleInstallments)(
          String(data.ticketPhysicalSale.installments),
        );
        onChangeFormInput(FormInputName.ticketPhysicalSaleFee)(
          String(Number(data.ticketPhysicalSale.fee * 100).toFixed(0)),
        );
      }
      if (data.ticketWebsiteSale) {
        onChangeFormInput(FormInputName.ticketWebsiteSaleId)(String(data.ticketWebsiteSale.id));
        onChangeFormInput(FormInputName.ticketWebsiteSaleAllowCreditCardPayment)(
          String(data.ticketWebsiteSale.allowCreditCardPayment),
        );
        onChangeFormInput(FormInputName.ticketWebsiteSaleBankSlip)(
          String(Number(data.ticketWebsiteSale.bankSlip * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.ticketWebsiteSaleCredit)(
          String(Number(data.ticketWebsiteSale.credit * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.ticketWebsiteSalePix)(
          String(Number(data.ticketWebsiteSale.pix * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.ticketWebsiteSaleAdministrateTax)(
          String(Number(data.ticketWebsiteSale.administrateTax * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.ticketWebsiteSaleInstallments)(
          String(data.ticketWebsiteSale.installments),
        );
        onChangeFormInput(FormInputName.ticketWebsiteSaleFee)(
          String(Number(data.ticketWebsiteSale.fee * 100).toFixed(0)),
        );
      }
      if (data.productPhysicalSale) {
        onChangeFormInput(FormInputName.productPhysicalSaleId)(String(data.productPhysicalSale.id));
        onChangeFormInput(FormInputName.productPhysicalSaleAllowCreditCardPayment)(
          String(data.productPhysicalSale.allowCreditCardPayment),
        );
        onChangeFormInput(FormInputName.productPhysicalSaleDebit)(
          String(Number(data.productPhysicalSale.debit * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.productPhysicalSaleCredit)(
          String(Number(data.productPhysicalSale.credit * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.productPhysicalSalePix)(
          String(Number(data.productPhysicalSale.pix * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.productPhysicalSaleAdministrateTax)(
          String(Number(data.productPhysicalSale.administrateTax * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.productPhysicalSaleInstallments)(
          String(data.productPhysicalSale.installments),
        );
        onChangeFormInput(FormInputName.productPhysicalSaleFee)(
          String(Number(data.productPhysicalSale.fee * 100).toFixed(0)),
        );
      }
      if (data.productWebsiteSale) {
        onChangeFormInput(FormInputName.productWebsiteSaleId)(String(data.productWebsiteSale.id));
        onChangeFormInput(FormInputName.productWebsiteSaleAllowCreditCardPayment)(
          String(data.productWebsiteSale.allowCreditCardPayment),
        );
        onChangeFormInput(FormInputName.productWebsiteSaleBankSlip)(
          String(Number(data.productWebsiteSale.bankSlip * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.productWebsiteSaleCredit)(
          String(Number(data.productWebsiteSale.credit * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.productWebsiteSalePix)(
          String(Number(data.productWebsiteSale.pix * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.productWebsiteSaleAdministrateTax)(
          String(Number(data.productWebsiteSale.administrateTax * 100).toFixed(0)),
        );
        onChangeFormInput(FormInputName.productWebsiteSaleInstallments)(
          String(data.productWebsiteSale.installments),
        );
        onChangeFormInput(FormInputName.productWebsiteSaleFee)(
          String(Number(data.productWebsiteSale.fee * 100).toFixed(0)),
        );
      }
    } finally {
      setState(States.default);
    }
  };

  const mountTicketPhysicalSale = (): CardFees => {
    const physicalSale = {
      allowCreditCardPayment: Boolean(
        formData[FormInputName.ticketPhysicalSaleAllowCreditCardPayment],
      ),
      debit: +unmask(formData[FormInputName.ticketPhysicalSaleDebit]),
      credit: +unmask(formData[FormInputName.ticketPhysicalSaleCredit]),
      pix: +unmask(formData[FormInputName.ticketPhysicalSalePix]),
      administrateTax: +unmask(formData[FormInputName.ticketPhysicalSaleAdministrateTax]),
      installments: Number(formData[FormInputName.ticketPhysicalSaleInstallments]),
      fee: +unmask(formData[FormInputName.ticketPhysicalSaleFee]),
    } as CardFees;
    if (
      formData[FormInputName.ticketPhysicalSaleId] &&
      formData[FormInputName.ticketPhysicalSaleId].trim().length > 0
    ) {
      physicalSale.id = formData[FormInputName.ticketPhysicalSaleId];
    }
    return physicalSale;
  };

  const mountTicketWebsiteSale = (): CardFees => {
    const websiteSale = {
      allowCreditCardPayment: Boolean(
        formData[FormInputName.ticketWebsiteSaleAllowCreditCardPayment],
      ),
      credit: +unmask(formData[FormInputName.ticketWebsiteSaleCredit]),
      bankSlip: +unmask(formData[FormInputName.ticketWebsiteSaleBankSlip]),
      pix: +unmask(formData[FormInputName.ticketWebsiteSalePix]),
      administrateTax: +unmask(formData[FormInputName.ticketWebsiteSaleAdministrateTax]),
      installments: Number(formData[FormInputName.ticketWebsiteSaleInstallments]),
      fee: +unmask(formData[FormInputName.ticketWebsiteSaleFee]),
    } as CardFees;
    if (
      formData[FormInputName.ticketWebsiteSaleId] &&
      formData[FormInputName.ticketWebsiteSaleId].trim().length > 0
    ) {
      websiteSale.id = formData[FormInputName.ticketWebsiteSaleId];
    }
    return websiteSale;
  };

  const mountProductPhysicalSale = (): CardFees => {
    const physicalSale = {
      allowCreditCardPayment: Boolean(
        formData[FormInputName.productPhysicalSaleAllowCreditCardPayment],
      ),
      debit: +unmask(formData[FormInputName.productPhysicalSaleDebit]),
      credit: +unmask(formData[FormInputName.productPhysicalSaleCredit]),
      pix: +unmask(formData[FormInputName.productPhysicalSalePix]),
      administrateTax: +unmask(formData[FormInputName.productPhysicalSaleAdministrateTax]),
      installments: Number(formData[FormInputName.productPhysicalSaleInstallments]),
      fee: +unmask(formData[FormInputName.productPhysicalSaleFee]),
    } as CardFees;
    if (
      formData[FormInputName.productPhysicalSaleId] &&
      formData[FormInputName.productPhysicalSaleId].trim().length > 0
    ) {
      physicalSale.id = formData[FormInputName.productPhysicalSaleId];
    }
    return physicalSale;
  };

  const mountProductWebsiteSale = (): CardFees => {
    const websiteSale = {
      allowCreditCardPayment: Boolean(
        formData[FormInputName.productWebsiteSaleAllowCreditCardPayment],
      ),
      credit: +unmask(formData[FormInputName.productWebsiteSaleCredit]),
      bankSlip: +unmask(formData[FormInputName.productWebsiteSaleBankSlip]),
      pix: +unmask(formData[FormInputName.productWebsiteSalePix]),
      administrateTax: +unmask(formData[FormInputName.productWebsiteSaleAdministrateTax]),
      installments: Number(formData[FormInputName.productWebsiteSaleInstallments]),
      fee: +unmask(formData[FormInputName.productWebsiteSaleFee]),
    } as CardFees;
    if (
      formData[FormInputName.productWebsiteSaleId] &&
      formData[FormInputName.productWebsiteSaleId].trim().length > 0
    ) {
      websiteSale.id = formData[FormInputName.productWebsiteSaleId];
    }
    return websiteSale;
  };

  const handleOnSave = async (): Promise<void> => {
    if (isFormValid()) {
      try {
        setState(States.loading);
        const dto: CompanyParameter = {
          ticketPhysicalSale: mountTicketPhysicalSale(),
          ticketWebsiteSale: mountTicketWebsiteSale(),
          productPhysicalSale: mountProductPhysicalSale(),
          productWebsiteSale: mountProductWebsiteSale(),
        };

        await api.post<void>('/company/parameter/fee', dto);

        toast.success('Taxas configuradas com sucesso!');
        handleOnGetCardFee();
      } finally {
        setState(States.default);
      }
    }
  };

  useEffect(() => {
    handleOnGetCardFee();
  }, []);

  return (
    <CardFeeContainer
      state={state}
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      onSave={handleOnSave}
    />
  );
};
