import { ReactComponent as Download } from '@/assets/images/svg/download.svg';
import { ReactComponent as Pen } from '@/assets/images/svg/pen.svg';
import { ReactComponent as Trash } from '@/assets/images/svg/lixeira.svg';
import { Button, Loading } from '@/components';
import { ActionProps, Dialog } from '@/components/Dialog';
import { States } from '@/helpers/common/states';
import Document from '@/model/Document';
import Page from '@/model/Page';
import React, { Fragment } from 'react';
import { ShouldShowModal } from '@/features/document/types';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { FilterContent } from '@/features/document/components/FilterContent';
import { RegisterContent } from '@/features/document/components/RegisterContent';
import DocumentCategory from '@/model/DocumentCategory';
import { Container, Label } from 'reactstrap';
import FilterVector from '@/assets/images/svg/FilterVector';
import { CustomTable } from '@/components/Table';
import Pagination from '@/components/Utils/Pagination';
import { RegisterDocumentCategoryContent } from '@/features/document/components/RegisterDocumentCategoryContent';
import User from '@/model/User';
import dayjs from 'dayjs';

import { columnsDocument } from './table';

interface StateProps {
  state: States;
  page: Page<Document, Document>;
  title: string | React.ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  documentState?: Document;
  formDataDocument: FormData;
  formErrorsDocument: FormErrors;
  formDataFilter: FormData;
  formErrorsFilter: FormErrors;
  categories: DocumentCategory[];
  userList: User[];
  documentUsers: User[];
  formDataDocumentCategory: FormData;
  formErrorsDocumentCategory: FormErrors;
}

interface DispatchProps {
  clearFilter: () => void;
  onToggle: () => void;
  onDownload: (fileUrl: string) => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
    document,
    isEdit,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    document?: Document;
    isEdit?: boolean;
  }) => void;
  onShowDeleteDocument: (selectedDocument: Document) => void;
  onFilter: () => void;
  onSaveDocument: () => void;
  onChangeFormInputFilter: OnChangeFormInput;
  onChangeFormInputDocument: OnChangeFormInput;
  onPaginationChange: (pageNumber: number) => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSaveDocumentCategory: () => void;
  onChangeFormInputDocumentCategory: OnChangeFormInput;
  onAddUser: (userId: string) => void;
  onDeleteUser: (user: User) => void;
}

type Props = StateProps & DispatchProps;

export const DocumentContainer: React.FC<Props> = ({
  clearFilter,
  onToggle,
  state,
  page,
  onDownload,
  onShouldShowModal,
  onShowDeleteDocument,
  title,
  visible,
  shouldShowModal,
  onFilter,
  documentState,
  onSaveDocument,
  formErrorsDocument,
  formDataFilter,
  formErrorsFilter,
  onChangeFormInputFilter,
  formDataDocument,
  onChangeFormInputDocument,
  categories,
  userList,
  documentUsers,
  onPaginationChange,
  onChangeFile,
  onSaveDocumentCategory,
  formDataDocumentCategory,
  formErrorsDocumentCategory,
  onChangeFormInputDocumentCategory,
  onAddUser,
  onDeleteUser,
}) => {
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => clearFilter(),
    theme: 'noneBorder',
  };

  const renderActionDialogToCancel: ActionProps = {
    title: 'Cancelar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };

  const renderActionDialogToCancelDocumentCategory: ActionProps = {
    title: 'Cancelar',
    onClick: () =>
      onShouldShowModal({
        value: ShouldShowModal.registerDocument,
        newTitleModal: documentState?.id
          ? `Documento ${documentState.title}`
          : 'Upload de documento',
        document: documentState,
        isEdit: true,
      }),
    theme: 'noneBorder',
  };

  const dataTableDocument =
    page.list && page.list.length > 0
      ? page.list.map(item => ({
          title: item.title,
          category: item.category.name,
          bindingUser: item.bindingUser.name,
          bindingDate: dayjs(item.bindingDate).format('DD/MM/YYYY [às] HH:mm'),
          actions: (
            <Fragment>
              <Download
                height={15}
                className="svg-icon action-icon mr-3"
                onClick={() => onDownload(item.fileURL)}
              />
              <Pen
                className="mr-4 svg-icon action-icon"
                onClick={(): void => {
                  onToggle();
                  onShouldShowModal({
                    value: ShouldShowModal.registerDocument,
                    newTitleModal: `Documento  ${item.title}`,
                    document: item,
                    isEdit: true,
                  });
                }}
              />
              <Trash
                className="mr-2 svg-icon action-icon svg-icon-trash"
                onClick={() => {
                  onShowDeleteDocument(item);
                }}
              />
            </Fragment>
          ),
        }))
      : [];

  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={shouldShowModal !== ShouldShowModal.filter}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
            [ShouldShowModal.registerDocument]: renderActionDialogToCancel,
            [ShouldShowModal.registerDocumentCategory]: renderActionDialogToCancelDocumentCategory,
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: {
              title: 'Aplicar',
              onClick: () => onFilter(),
            },
            [ShouldShowModal.registerDocument]: {
              title: documentState?.id ? 'Salvar' : 'Upload',
              onClick: () => onSaveDocument(),
              disabled:
                Object.keys(formErrorsDocument).length === 0 &&
                formErrorsDocument.constructor === Object,
            },
            [ShouldShowModal.registerDocumentCategory]: {
              title: documentState?.id ? 'Salvar' : 'Cadastrar nova Categoria',
              onClick: () => onSaveDocumentCategory(),
              disabled:
                Object.keys(formErrorsDocumentCategory).length === 0 &&
                formErrorsDocumentCategory.constructor === Object,
            },
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formDataFilter}
                formErrors={formErrorsFilter}
                onChangeFormInput={onChangeFormInputFilter}
              />
            ),
            [ShouldShowModal.registerDocument]: (
              <RegisterContent
                formData={formDataDocument}
                formErrors={formErrorsDocument}
                onChange={onChangeFormInputDocument}
                categories={categories}
                userList={userList}
                documentUsers={documentUsers}
                onChangeFile={onChangeFile}
                onShouldShowModal={onShouldShowModal}
                documentState={documentState}
                onAddUser={onAddUser}
                onDeleteUser={onDeleteUser}
              />
            ),
            [ShouldShowModal.registerDocumentCategory]: (
              <RegisterDocumentCategoryContent
                formData={formDataDocumentCategory}
                formErrors={formErrorsDocumentCategory}
                onChange={onChangeFormInputDocumentCategory}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <div className="pageTitle" style={{ display: 'grid' }}>
            <Label>Documentos</Label>
          </div>
          <div className="button-filter-container">
            <Button
              title="+ Upload de documento"
              onClick={(): void => {
                onToggle();
                onShouldShowModal({
                  value: ShouldShowModal.registerDocument,
                  newTitleModal: 'Upload de documento',
                });
              }}
            />
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onToggle();
                  onShouldShowModal({
                    value: ShouldShowModal.filter,
                    newTitleModal: '',
                  });
                }}
              >
                <FilterVector />
              </div>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columnsDocument}
          data={dataTableDocument}
          numberRowsPerPage={page.pageSize}
          progressPending={state === States.loading}
          theme="primary"
        />
        <Pagination
          currentPage={page.page}
          totalCount={page.total}
          pageSize={page.pageSize}
          onPageChange={pageNumber => onPaginationChange(pageNumber)}
          total={page.total}
        />
      </Container>
    </Fragment>
  );
};
