/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-key */
/* eslint-disable import/no-unresolved */
import React, { Fragment } from 'react';
import { Button, Dialog, Loading } from '@/components';
import { Col, Container, Row } from 'reactstrap';
import Event from '@/model/Event';
import EventStatus from '@/model/EventStatus';
import { DataConfirmation } from '../components/DataConfirmation';
import { SectorTicket } from '../components/SectorTicket';
import { SectorProduct } from '../components/SectorProduct';
import { SectorPdv } from '../components/SectorPdv';
import { RegisterFinished } from '../components/RegisterFinished';
import { ConfirmationProducer } from '../components/Producer';

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

interface ConfirmationEventContainerProps {
  state: States;
  event: Event | undefined;
  ticket: any;
  visible: boolean;
  setVisible: (value: boolean) => void;
  shouldShowModal: ShouldShowModal;
  ontoggle(): void;
  eventState: any;
  onChangeEvent: (value: any) => void;
  validate: () => void;
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  registerFinished = 'registerFinished',
}

export const ConfirmationEventContainer: React.FC<ConfirmationEventContainerProps> = ({
  state,
  event,
  ticket,
  visible,
  eventState,
  onChangeEvent,
  setVisible,
  validate,
}) => (
  <Fragment>
    <Loading isVisible={state === States.loading} />

    <Dialog size="lg" title="" visible={visible} onClose={() => setVisible(false)} actions={[]}>
      <RegisterFinished />
    </Dialog>
    <Container className="mainContainer" fluid={true}>
      <Row>
        <Col md={12}>
          {event?.id ? (
            <>
              <DataConfirmation event={event} />
              {ticket.tickets && ticket.tickets.length > 0 && <SectorTicket ticket={ticket} />}
              {event.sectionproductsAndCombos && event.sectionproductsAndCombos.length > 0 && (
                <SectorProduct event={event} />
              )}
              {event.pdvs && event.pdvs.length > 0 && <SectorPdv event={event} />}
              {event.producer && <ConfirmationProducer event={event} />}
            </>
          ) : (
            <h5>Evento não encontrado</h5>
          )}
        </Col>
      </Row>
      <hr />
      <div className="footer-register-event">
        <Button
          title="Voltar"
          theme="noneBorder"
          onClick={() => {
            onChangeEvent({ ...eventState, currentStep: eventState.currentStep - 1 });
          }}
        />
        {event && event.eventStatus === EventStatus.DRAFT && (
          <Button
            title="Finalizar cadastro do evento"
            onClick={() => {
              validate();
            }}
          />
        )}
      </div>
    </Container>
  </Fragment>
);
