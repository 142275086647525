import React from 'react';

const UserIcon = (): JSX.Element => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.22744 0C4.39805 0 2.90606 1.49184 2.90606 3.32137C2.90606 5.15076 4.3979 6.64275 6.22744 6.64275C8.05682 6.64275 9.54881 5.15076 9.54881 3.32137C9.54881 1.49199 8.05697 0 6.22744 0ZM6.22744 0.83025C7.60807 0.83025 8.71837 1.94055 8.71837 3.32119C8.71837 4.70182 7.60807 5.81212 6.22744 5.81212C4.8468 5.81212 3.7365 4.70182 3.7365 3.32119C3.7365 1.94055 4.8468 0.83025 6.22744 0.83025ZM6.22744 7.05769C2.79141 7.05769 0 9.7522 0 13.1035V13.7004H0.83025V13.1035C0.83025 10.2119 3.22333 7.88801 6.22736 7.88801C9.23132 7.88801 11.6245 10.2118 11.6245 13.1035V13.7004H12.4547V13.1035C12.4547 9.75216 9.66331 7.05769 6.22729 7.05769H6.22744Z"
      fill="black"
    />
  </svg>
);

export default UserIcon;
