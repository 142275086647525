import OrderSite from '@/model/OrderSite';
import { TableColumn } from 'react-data-table-component';

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  map = 'map',
  qrCode = 'qrCode',
  ticket = 'ticket',
  url = 'url',
  detail = 'detail',
}

// eslint-disable-next-line no-shadow
export enum FormInputName {
  eventId = 'eventId',
  sectionId = 'sectionId',
  code = 'code',
  paymentForm = 'paymentForm',
  showUserData = 'showUserData',
}

// eslint-disable-next-line no-shadow
export enum UserDataFormInputName {
  name = 'name',
  document = 'document',
  email = 'email',
  phone = 'phone',
  birthDate = 'birthDate',
  motherName = 'motherName',
}

export interface OnShouldShowModalProps {
  value: ShouldShowModal;
  newTitleModal: string | React.ReactNode;
  orderSelected?: OrderSite;
  eventKey?: string;
}

export interface DataRow {
  client: string;
  orderId: string;
  orderDate: string;
  orderValue: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transaction: any;
  paymentStatus: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any;
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Cliente',
    selector: row => row.client,
    width: '300px',
  },
  {
    name: 'Pedido',
    selector: row => row.orderId,
    width: '300px',
  },
  {
    name: 'Data',
    selector: row => row.orderDate,
    width: '170px',
  },
  {
    name: 'Valor',
    selector: row => row.orderValue,
    width: '100px',
  },
  {
    name: 'Transação',
    selector: row => row.transaction,
    width: '340px',
  },
  {
    name: 'Status',
    selector: row => row.paymentStatus,
    width: '200px',
  },
  {
    name: 'Ações',
    selector: row => row.actions,
  },
];

export interface DataColumn {
  id: string;
  name: string;
}

export interface TicketDataRow {
  event: string;
  ticket: string;
  ticketDate: string;
  ticketValue: string;
  clientName: string;
  clientCPF: string;
  used: string;
}

export const ticketColumns: TableColumn<TicketDataRow>[] = [
  {
    name: 'Evento',
    selector: row => row.event,
    width: '300px',
  },
  {
    name: 'Ingresso',
    selector: row => row.ticket,
    width: '300px',
  },
  {
    name: 'Data',
    selector: row => row.ticketDate,
    width: '170px',
  },
  {
    name: 'Valor',
    selector: row => row.ticketValue,
    width: '100px',
  },
  {
    name: 'Client',
    selector: row => row.clientName,
    width: '330px',
  },
  {
    name: 'CPF',
    selector: row => row.clientCPF,
    width: '150px',
  },
  {
    name: 'Utilizado',
    selector: row => row.used,
    width: '170px',
  },
];
