import React from 'react';
import { Col, Row } from 'reactstrap';
import EventCourtesy from '@/model/EventCourtesy';
import { updateMask as updateMaskCPF } from '@/helpers/masks/cpf';
import { updateMask as updateMaskPhone } from '@/helpers/masks/mobilePhone';

interface StateProps {
  courtesy: EventCourtesy;
}

export const DetailCourtesy: React.FC<StateProps> = ({ courtesy }) => (
  <>
    <Row className="mb-4">
      <Col md={8}>
        <label className="input-label">Descrição da cortesia</label>
        <br />
        <label>{courtesy.description}</label>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <label className="input-label">Usuário</label>
        <br />
        <label>{courtesy.client ? courtesy.client.name : ''}</label>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <label className="input-label">Ingresso</label>
        <br />
        <label>{courtesy.ticket.name}</label>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <label className="input-label">Quantidade</label>
        <br />
        <label>{courtesy.amount}</label>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <label className="input-label">Nome do(a) favorecido(a)</label>
        <br />
        <label>{courtesy.client ? courtesy.client.name : ''}</label>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <label className="input-label">CPF do(a) favorecido(a)</label>
        <br />
        <label>{updateMaskCPF(courtesy.client ? courtesy.client.cpf : '')}</label>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <label className="input-label">Celular do(a) favorecido(a)</label>
        <br />
        <label>{updateMaskPhone(courtesy.client ? courtesy.client.cellPhone : '')}</label>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <label className="input-label">E-mail do(a) favorecido(a)</label>
        <br />
        <label>{courtesy.client ? courtesy.client.email : ''}</label>
      </Col>
    </Row>
  </>
);
