import React, { useEffect, useState } from 'react';
import { AcquiringFee } from '@/model/AcquiringFee';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import validators from '@/helpers/validators';
import useForm from '@/hooks/useForm';
import ChargeSetup from '@/model/ChargeSetup';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { AcquiringFeePaymentType } from '@/model/AcquiringFeePaymentType';
import { updateMask, unmask as unmaskCash } from '@/helpers/masks/cashNumber';
import { AcquiringBrandFee } from '@/model/AcquiringBrandFee';
import { AcquiringFeePaymentTypeBrand } from '@/model/AcquiringFeePaymentTypeBrand';
import { AcquiringFeePaymentTypeBrandFee } from '@/model/AcquiringFeePaymentTypeBrandFee';
import PaymentType from '@/model/PaymentType';
import { AcquiringFeeContainer } from './ui';
import {
  RegisterAcquiringFeeFormInput,
  RegisterBrandFormInput,
  RegisterPaymentTypeFormInput,
  ShouldShowModal,
  States,
} from '../types';
import { DeleteContent } from '../components/DeleteContent';

export const AcquiringFeeScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [acquiringFees, setAcquiringFees] = useState<AcquiringFee[]>([]);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(
    undefined as unknown as ShouldShowModal,
  );
  const [acquiringFee, setAcquiringFee] = useState<AcquiringFee>();
  const [chargeSetups, setChargeSetups] = useState<ChargeSetup[]>([]);
  const [brands, setBrands] = useState<AcquiringBrandFee[]>([
    { installments: 0, valueFee: 0 } as AcquiringBrandFee,
  ]);
  const [typePayment, setTypePayment] = useState<PaymentType>(PaymentType.MONEY);

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const handleOnFetch = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<AcquiringFee[]>('/acquiring-fee/find');
      if (data) {
        setAcquiringFees(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const {
    formData: formDataRegisterAcquiringFee,
    formErrors: formErrorsRegisterAcquiringFee,
    onChangeFormInput: onChangeFormInputRegisterAcquiringFee,
    isFormValid: isFormValidRegisterAcquiringFee,
    resetForm: resetFormRegisterAcquiringFee,
  } = useForm({
    initialData: {
      chargeSetupId: '',
      type: '',
    },
    validators: {
      chargeSetupId: [validators.required],
      type: [validators.required],
    },
  });

  const handleOnSaveAcquiringFee = async (): Promise<void> => {
    try {
      setState(States.loading);
      if (isFormValidRegisterAcquiringFee()) {
        const payload: AcquiringFee = {
          chargeSetup: {
            id: formDataRegisterAcquiringFee[RegisterAcquiringFeeFormInput.chargeSetupId],
          } as ChargeSetup,
          type: Number(formDataRegisterAcquiringFee[RegisterAcquiringFeeFormInput.type]),
        } as AcquiringFee;
        if (acquiringFee && acquiringFee.id) {
          payload.id = acquiringFee.id;
        }
        await api.post('/acquiring-fee', payload);
        resetFormRegisterAcquiringFee();
        onToggle();
        handleOnFetch();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnFetchAcquiringFee = async (id?: string): Promise<void> => {
    try {
      setState(States.loading);
      const response = await api.get<ChargeSetup[]>('/charge-setup/find');
      if (response && response.data) {
        setChargeSetups(response.data);
      }
      if (id) {
        const { data } = await api.get<AcquiringFee>(`/acquiring-fee/${id}`);
        if (data) {
          setAcquiringFee(data);
          onChangeFormInputRegisterAcquiringFee(RegisterAcquiringFeeFormInput.chargeSetupId)(
            data.chargeSetup.id,
          );
          onChangeFormInputRegisterAcquiringFee(RegisterAcquiringFeeFormInput.type)(`${data.type}`);
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnConfirmDelete = async (id: string): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(`/acquiring-fee/${id}`);
      confirmDelete.hide();
      handleOnFetch();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShowDelete = (id: string): void => {
    confirmDelete.show({
      title: '',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => confirmDelete.hide(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnConfirmDelete(id),
        },
      ],
    });
  };

  const {
    formData: formDataRegisterPaymentType,
    formErrors: formErrorsRegisterPaymentType,
    onChangeFormInput: onChangeFormInputRegisterPaymentType,
    isFormValid: isFormValidRegisterPaymentType,
    resetForm: resetFormRegisterPaymentType,
  } = useForm({
    initialData: {
      id: '',
      acquiringFeeId: '',
      paymentType: '',
      feeType: '',
      valueFee: '',
    },
    validators: {
      acquiringFeeId: [validators.required],
      paymentType: [validators.required],
    },
  });

  const handleOnFetchPaymentType = async (id?: string, paymentType?: string): Promise<void> => {
    try {
      setState(States.loading);
      if (id) {
        const { data } = await api.get<AcquiringFeePaymentType>(
          `/acquiring-fee/${id}/payment-type/${paymentType}`,
        );
        if (data) {
          onChangeFormInputRegisterPaymentType(RegisterPaymentTypeFormInput.id)(data.id);
          onChangeFormInputRegisterPaymentType(RegisterPaymentTypeFormInput.acquiringFeeId)(id);
          onChangeFormInputRegisterPaymentType(RegisterPaymentTypeFormInput.paymentType)(
            `${data.paymentType}`,
          );
          onChangeFormInputRegisterPaymentType(RegisterPaymentTypeFormInput.feeType)(
            data.feeType ? `${data.feeType}` : '',
          );
          onChangeFormInputRegisterPaymentType(RegisterPaymentTypeFormInput.valueFee)(
            data.valueFee ? updateMask(`${Number(data.valueFee).toFixed(2)}`) : '',
          );
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnConfirmDeleteBrande = async (
    acquiringfeeId: string,
    paymentTypeId: string,
    brandId: string,
  ): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(
        `/acquiring-fee/${acquiringfeeId}/payment-type/${paymentTypeId}/brand/${brandId}`,
      );
      confirmDelete.hide();
      handleOnFetch();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShowDeleteBrand = (
    acquiringfeeId: string,
    paymentTypeId: string,
    brandId: string,
  ): void => {
    confirmDelete.show({
      title: '',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => confirmDelete.hide(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> =>
            handleOnConfirmDeleteBrande(acquiringfeeId, paymentTypeId, brandId),
        },
      ],
    });
  };

  const {
    formData: formDataRegisterBrand,
    formErrors: formErrorsRegisterBrand,
    onChangeFormInput: onChangeFormInputRegisterBrand,
    isFormValid: isFormValidRegisterBrand,
    resetForm: resetFormRegisterBrand,
  } = useForm({
    initialData: {
      id: '',
      acquiringFeeId: '',
      paymentTypeId: '',
      cardBrand: '',
      valueFee: '',
    },
    validators: {
      acquiringFeeId: [validators.required],
      paymentTypeId: [validators.required],
      cardBrand: [validators.required],
    },
    formatters: {
      valueFee: updateMask,
    },
  });

  const handleOnFetchBrand = async (
    id?: string,
    paymentType?: string,
    brandId?: string,
  ): Promise<void> => {
    try {
      setState(States.loading);
      if (id) {
        const { data } = await api.get<AcquiringFeePaymentTypeBrand>(
          `/acquiring-fee/${id}/payment-type/${paymentType}/brand/${brandId}`,
        );
        if (data) {
          onChangeFormInputRegisterBrand(RegisterBrandFormInput.id)(data.id);
          onChangeFormInputRegisterBrand(RegisterBrandFormInput.acquiringFeeId)(id);
          onChangeFormInputRegisterBrand(RegisterBrandFormInput.paymentTypeId)(
            paymentType as string,
          );
          onChangeFormInputRegisterBrand(RegisterBrandFormInput.cardBrand)(
            data.cardBrand !== undefined ? `${data.cardBrand}` : '',
          );

          if (data.valueFee && data.valueFee > 0) {
            onChangeFormInputRegisterBrand(RegisterBrandFormInput.valueFee)(
              updateMask(Number(data.valueFee).toFixed(2)),
            );
          }

          if (data.fees && data.fees.length > 0) {
            const newBrands: AcquiringFeePaymentTypeBrandFee[] = [];
            data.fees.forEach(fee => {
              const newFee = {
                ...fee,
                valueFee: updateMask(`${Number(fee.valueFee).toFixed(2)}`) as unknown as number,
              };
              newBrands.push(newFee);
            });
            setBrands(newBrands);
          }
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    id,
    paymentType,
    brandId,
    type,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    id?: string;
    paymentType?: string;
    brandId?: string;
    type?: PaymentType;
  }): Promise<void> => {
    if (value === ShouldShowModal.registerAcquiringFee) {
      await handleOnFetchAcquiringFee(id);
    } else if (value === ShouldShowModal.registerPaymentType) {
      onChangeFormInputRegisterPaymentType(RegisterPaymentTypeFormInput.acquiringFeeId)(
        id as string,
      );
      if (paymentType) {
        await handleOnFetchPaymentType(id, paymentType);
      }
    } else if (value === ShouldShowModal.registerBrand) {
      setTypePayment(type as PaymentType);
      onChangeFormInputRegisterBrand(RegisterBrandFormInput.acquiringFeeId)(id as string);
      onChangeFormInputRegisterBrand(RegisterBrandFormInput.paymentTypeId)(paymentType as string);
      if (brandId) {
        await handleOnFetchBrand(id, paymentType, brandId);
      }
    }
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnSavePaymentType = async (): Promise<void> => {
    try {
      setState(States.loading);
      if (isFormValidRegisterPaymentType()) {
        const feeType = formDataRegisterPaymentType[RegisterPaymentTypeFormInput.feeType];
        const valueFee = formDataRegisterPaymentType[RegisterPaymentTypeFormInput.valueFee];
        const payload: AcquiringFeePaymentType = {
          paymentType: Number(
            formDataRegisterPaymentType[RegisterPaymentTypeFormInput.paymentType],
          ),
          feeType: feeType && Number(feeType) >= 0 ? Number(feeType) : undefined,
          valueFee: valueFee ? Number(unmaskCash(valueFee)) : undefined,
        } as AcquiringFeePaymentType;
        const id = formDataRegisterPaymentType[RegisterPaymentTypeFormInput.id];
        if (id && id.trim().length > 0) {
          payload.id = id;
        }
        const acquiringFeeId =
          formDataRegisterPaymentType[RegisterPaymentTypeFormInput.acquiringFeeId];
        await api.post(`/acquiring-fee/${acquiringFeeId}/payment-type`, payload);
        resetFormRegisterPaymentType();
        onToggle();
        handleOnFetch();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnConfirmDeletePaymentType = async (
    acquiringFeeId: string,
    id: string,
  ): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(`/acquiring-fee/${acquiringFeeId}/payment-type/${id}`);
      confirmDelete.hide();
      handleOnFetch();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShowDeletePaymentType = (acquiringFeeId: string, id: string): void => {
    confirmDelete.show({
      title: '',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => confirmDelete.hide(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnConfirmDeletePaymentType(acquiringFeeId, id),
        },
      ],
    });
  };

  const handleOnSaveBrand = async (): Promise<void> => {
    try {
      if (brands.length === 0) {
        throw new Error('Fabor informar pelo menos uma taxa/parcela!');
      }
      setState(States.loading);
      if (isFormValidRegisterBrand()) {
        const id = formDataRegisterBrand[RegisterBrandFormInput.id];
        const acquiringFeeId = formDataRegisterBrand[RegisterBrandFormInput.acquiringFeeId];
        const paymentTypeId = formDataRegisterBrand[RegisterBrandFormInput.paymentTypeId];
        const cardBrand = formDataRegisterBrand[RegisterBrandFormInput.cardBrand];
        let valueFee: number | undefined;
        if (typePayment === PaymentType.DEBIT_CARD) {
          const valueFeeString = formDataRegisterBrand[RegisterBrandFormInput.valueFee];
          if (valueFeeString) {
            valueFee = Number(unmaskCash(valueFeeString));
          }
        }
        const fees: AcquiringFeePaymentTypeBrandFee[] = [];
        brands.forEach(data => {
          fees.push({
            installments: Number(data.installments),
            valueFee: data.valueFee ? Number(unmaskCash(`${data.valueFee}`)) : 0,
          } as AcquiringFeePaymentTypeBrandFee);
        });

        const payload: AcquiringFeePaymentTypeBrand = {
          cardBrand: cardBrand && Number(cardBrand) >= 0 ? Number(cardBrand) : undefined,
          valueFee,
          fees,
        } as AcquiringFeePaymentTypeBrand;
        if (id && id.trim().length > 0) {
          payload.id = id;
        }
        await api.post(`/acquiring-fee/${acquiringFeeId}/payment-type/${paymentTypeId}`, payload);
        resetFormRegisterBrand();
        onToggle();
        handleOnFetch();
        setBrands([
          { installments: 0, valueFee: updateMask('0') as unknown as number } as AcquiringBrandFee,
        ]);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnChangeBrandFeeInstallments = (index: number, value: string): void => {
    const list: AcquiringBrandFee[] = [];
    if (brands && brands.length > 0) {
      brands.forEach((data, i) => {
        if (index === i) {
          const newData = {
            ...data,
            installments: value as unknown as number,
          };
          list.push(newData);
        } else {
          list.push(data);
        }
      });
      setBrands(list);
    }
  };

  const handleOnChangeBrandFeeValue = (index: number, value: string): void => {
    const list: AcquiringBrandFee[] = [];
    if (brands && brands.length > 0) {
      brands.forEach((data, i) => {
        if (index === i) {
          const newData = {
            ...data,
            valueFee: updateMask(value) as unknown as number,
          };
          list.push(newData);
        } else {
          list.push(data);
        }
      });
      setBrands(list);
    }
  };
  const handleOnAddBrandFee = (): void => {
    const list: AcquiringBrandFee[] = [];
    if (brands && brands.length > 0) {
      brands.forEach(data => {
        list.push(data);
      });
      list.push({
        installments: 0,
        valueFee: updateMask('0') as unknown as number,
      } as AcquiringBrandFee);
    }
    setBrands(list);
  };

  const handleOnRemoveBrandFee = (index: number): void => {
    const list: AcquiringBrandFee[] = [];
    if (brands && brands.length > 0) {
      brands.forEach((data, i) => {
        if (index !== i) {
          list.push(data);
        }
      });
      setBrands(list);
    }
  };

  useEffect(() => {
    handleOnFetch();
  }, []);
  return (
    <AcquiringFeeContainer
      state={state}
      title={title as string}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      acquiringFee={acquiringFee}
      formDataRegisterAcquiringFee={formDataRegisterAcquiringFee}
      formErrorsRegisterAcquiringFee={formErrorsRegisterAcquiringFee}
      onChangeFormInputRegisterAcquiringFee={onChangeFormInputRegisterAcquiringFee}
      chargeSetups={chargeSetups}
      acquiringFees={acquiringFees}
      onSaveAcquiringFee={handleOnSaveAcquiringFee}
      onShouldShowModal={handleOnShouldShowModal}
      formDataRegisterPaymentType={formDataRegisterPaymentType}
      formErrorsRegisterPaymentType={formErrorsRegisterPaymentType}
      onChangeFormInputRegisterPaymentType={onChangeFormInputRegisterPaymentType}
      onSavePaymentType={handleOnSavePaymentType}
      onShowDelete={handleOnShowDelete}
      onShowDeletePaymentType={handleOnShowDeletePaymentType}
      paymentType={typePayment}
      formDataRegisterBrand={formDataRegisterBrand}
      formErrorsRegisterBrand={formErrorsRegisterBrand}
      onChangeFormInputRegisterBrand={onChangeFormInputRegisterBrand}
      onSaveBrand={handleOnSaveBrand}
      brands={brands}
      changeBrandFeeInstallments={handleOnChangeBrandFeeInstallments}
      changeBrandFeeValue={handleOnChangeBrandFeeValue}
      addBrandFee={handleOnAddBrandFee}
      removeBrandFee={handleOnRemoveBrandFee}
      onShowDeleteBrand={handleOnShowDeleteBrand}
    />
  );
};
