// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
  detail = 'detail',
}

// eslint-disable-next-line no-shadow
export enum FormInputFilterName {
  filterSearch = 'filterSearch',
  inputSearch = 'inputSearch',
}

export interface DataRow {
  message: string;
  personName: string;
  ipCreation: string;
  createdAt: string;
  actions: string;
}
