import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import dayjs from 'dayjs';

interface ReverseDetailContentProps {
  data?: EventMoneyTransfer;
}

export const DetailContent: React.FC<ReverseDetailContentProps> = ({ data }): JSX.Element => (
  <>
    {data && (
      <div>
        <Row>
          <Col md={12}>
            <label className={'input-label m-0'}>Evento</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{data.event.name}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Produtor</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{data.contractor.name}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Banco</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{data.pixKey.bank.fullName}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Chave</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{data.pixKey.key}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Solicitante</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{data.contractorUser.name}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Data solicitação</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{dayjs(data.transferRequestDate).format('DD/MM/YYYY HH:mm:ss')}</Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <label className={'input-label m-0'}>Status</label>
          </Col>
        </Row>
        <Row>
          <Col md={12}>{data.transferred ? 'Transferindo' : 'Aguardando'}</Col>
        </Row>
        {data.transferDate && (
          <Fragment>
            <Row style={{ marginTop: '30px' }}>
              <Col md={12}>
                <label className={'input-label m-0'}>Data transferência</label>
              </Col>
            </Row>
            <Row>
              <Col md={12}>{dayjs(data.transferDate).format('DD/MM/YYYY HH:mm:ss')}</Col>
            </Row>
          </Fragment>
        )}
        {data.transferUser && (
          <Fragment>
            <Row style={{ marginTop: '30px' }}>
              <Col md={12}>
                <label className={'input-label m-0'}>Usuário</label>
              </Col>
            </Row>
            <Row>
              <Col md={12}>{data.transferUser.name}</Col>
            </Row>
          </Fragment>
        )}
      </div>
    )}
  </>
);
