import { CustomTable } from '@/components/Table';
import React from 'react';
import { Col, Row } from 'reactstrap';
import Reprint from '@/model/Reprint';
import { DataRowToken, tokenColumns } from '../../screens/List/ui/table';

interface TokenContentProps {
  reprint?: Reprint;
}

export const TokenContent: React.FC<TokenContentProps> = ({ reprint }): JSX.Element => {
  const countRecords = (): number => {
    let count = 0;
    if (reprint && reprint.orders && reprint.orders.length > 0) {
      reprint.orders.forEach(order => {
        if (order.items && order.items.length > 0) {
          count += order.items.length;
        }
      });
    }
    return count;
  };
  const mountDataTable = (): DataRowToken[] => {
    if (reprint && reprint.orders && reprint.orders.length > 0) {
      const list: DataRowToken[] = [];
      reprint.orders.forEach(order => {
        if (order.items && order.items.length > 0) {
          order.items.forEach(data => {
            list.push({
              orderId: order.id,
              ticket: data.name,
              sequence: `${data.sequence || 0}`,
            });
          });
        }
      });
      return list;
    }
    return [];
  };

  return (
    <div>
      <Row>
        <Col md={12}>
          <h5 className="modal__confirmation-title" style={{ textAlign: 'center' }}>
            Informe o token de impressão ao PDV e aguarde o retorno da reimpressão
          </h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className={`loader-wrapper`}
            style={{ height: 'auto', width: 'auto', position: 'inherit', background: 'bottom' }}
          >
            <div className="loader-index">
              <span></span>
            </div>
            <svg>
              <defs></defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="11" result="blur"></feGaussianBlur>
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                  result="goo"
                >
                  {' '}
                </feColorMatrix>
              </filter>
            </svg>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h3 className="modal__confirmation-title text-danger" style={{ textAlign: 'center' }}>
            {reprint?.token}
          </h3>
        </Col>
      </Row>
      {reprint && reprint.orders && reprint.orders.length > 0 && (
        <Row>
          <Col md={12}>
            <CustomTable
              columns={tokenColumns}
              data={mountDataTable()}
              numberRowsPerPage={countRecords()}
              progressPending={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
