import CustomError from '../../../model/CustomError';
import Page from '../../../model/Page';
import Event from '../../../model/Event';
import EventFind from '../../../model/EventFind';
import EventGeneralInformation from '../../../model/EventGeneralInformation';
import EventTicketMainConfiguration from '../../../model/EventTicketMainConfiguration';
import TicketPayment from '../../../model/TicketPayment';
import EventTicketGeneralSettings from '../../../model/EventTicketGeneralSettings';
import EventProduct from '../../../model/EventProduct';
import EventProductCombo from '../../../model/EventProductCombo';
import EventSection from '../../../model/EventSection';
import EventPos from '../../../model/EventPos';
import EventPdv from '../../../model/EventPdv';

/**
 * Action types
 */

// eslint-disable-next-line no-shadow
export enum EventTypes {
  LIST_EVENT_REQUEST = '@event/LIST_EVENT_REQUEST',
  LIST_EVENT_SUCCCES = '@event/LIST_EVENT_SUCCCES',
  LIST_EVENT_FAILURE = '@event/LIST_EVENT_FAILURE',
  GET_EVENT_REQUEST = '@event/GET_EVENT_REQUEST',
  GET_EVENT_SUCCCES = '@event/GET_EVENT_SUCCCES',
  GET_EVENT_FAILURE = '@event/GET_EVENT_FAILURE',
  GET_ALL_EVENT_REQUEST = '@event/GET_ALL_EVENT_REQUEST',
  GET_ALL_EVENT_SUCCCES = '@event/GET_EVENT_SUCCCES',
  GET_ALL_EVENT_FAILURE = '@event/GET_EVENT_FAILURE',

  GENERAL_INFORMATION_EVENT_REQUEST = '@event/GENERAL_INFORMATION_EVENT_REQUEST',
  GENERAL_INFORMATION_EVENT_SUCCCES = '@event/GENERAL_INFORMATION_EVENT_SUCCCES',
  GENERAL_INFORMATION_EVENT_FAILURE = '@event/GENERAL_INFORMATION_EVENT_FAILURE',

  TICKET_MAIN_CONFIGURATION_EVENT_REQUEST = '@event/TICKET_MAIN_CONFIGURATION_EVENT_REQUEST',
  TICKET_MAIN_CONFIGURATION_EVENT_SUCCCES = '@event/TICKET_MAIN_CONFIGURATION_EVENT_SUCCCES',
  TICKET_MAIN_CONFIGURATION_EVENT_FAILURE = '@event/TICKET_MAIN_CONFIGURATION_EVENT_FAILURE',
  TICKET_PAYMENT_EVENT_REQUEST = '@event/TICKET_PAYMENT_EVENT_REQUEST',
  TICKET_PAYMENT_EVENT_SUCCCES = '@event/TICKET_PAYMENT_EVENT_SUCCCES',
  TICKET_PAYMENT_EVENT_FAILURE = '@event/TICKET_PAYMENT_EVENT_FAILURE',
  TICKET_GENERAL_SETTINGS_EVENT_REQUEST = '@event/TICKET_GENERAL_SETTINGS_EVENT_REQUEST',
  TICKET_GENERAL_SETTINGS_EVENT_SUCCCES = '@event/TICKET_GENERAL_SETTINGS_EVENT_SUCCCES',
  TICKET_GENERAL_SETTINGS_EVENT_FAILURE = '@event/TICKET_GENERAL_SETTINGS_EVENT_FAILURE',

  SECTION_PRODUCT_PRODUCT_EVENT_REQUEST = '@event/SECTION_PRODUCT_PRODUCT_EVENT_REQUEST',
  SECTION_PRODUCT_PRODUCT_EVENT_SUCCCES = '@event/SECTION_PRODUCT_PRODUCT_EVENT_SUCCCES',
  SECTION_PRODUCT_PRODUCT_EVENT_FAILURE = '@event/SECTION_PRODUCT_PRODUCT_EVENT_FAILURE',
  SECTION_PRODUCT_COMBO_EVENT_REQUEST = '@event/SECTION_PRODUCT_COMBO_EVENT_REQUEST',
  SECTION_PRODUCT_COMBO_EVENT_SUCCCES = '@event/SECTION_PRODUCT_COMBO_EVENT_SUCCCES',
  SECTION_PRODUCT_COMBO_EVENT_FAILURE = '@event/SECTION_PRODUCT_COMBO_EVENT_FAILURE',
  SECTION_PRODUCT_COMBO_SECTION_EVENT_REQUEST = '@event/SECTION_PRODUCT_COMBO_SECTION_EVENT_REQUEST',
  SECTION_PRODUCT_COMBO_SECTION_EVENT_SUCCCES = '@event/SECTION_PRODUCT_COMBO_SECTION_EVENT_SUCCCES',
  SECTION_PRODUCT_COMBO_SECTION_EVENT_FAILURE = '@event/SECTION_PRODUCT_COMBO_SECTION_EVENT_FAILURE',
  SECTION_PRODUCT_POS_EVENT_REQUEST = '@event/SECTION_PRODUCT_POS_EVENT_REQUEST',
  SECTION_PRODUCT_POS_EVENT_SUCCCES = '@event/SECTION_PRODUCT_POS_EVENT_SUCCCES',
  SECTION_PRODUCT_POS_EVENT_FAILURE = '@event/SECTION_PRODUCT_POS_EVENT_FAILURE',

  PDV_MAIN_EVENT_REQUEST = '@event/PDV_MAIN_EVENT_REQUEST',
  PDV_MAIN_EVENT_SUCCCES = '@event/PDV_MAIN_EVENT_SUCCCES',
  PDV_MAIN_EVENT_FAILURE = '@event/PDV_MAIN_EVENT_FAILURE',
  PDV_TICKET_EVENT_REQUEST = '@event/PDV_TICKET_EVENT_REQUEST',
  PDV_TICKET_EVENT_SUCCCES = '@event/PDV_TICKET_EVENT_SUCCCES',
  PDV_TICKET_EVENT_FAILURE = '@event/PDV_TICKET_EVENT_FAILURE',
  PDV_PRODUCT_EVENT_REQUEST = '@event/PDV_PRODUCT_EVENT_REQUEST',
  PDV_PRODUCT_EVENT_SUCCCES = '@event/PDV_PRODUCT_EVENT_SUCCCES',
  PDV_PRODUCT_EVENT_FAILURE = '@event/PDV_PRODUCT_EVENT_FAILURE',
  PDV_SUBPDV_EVENT_REQUEST = '@event/PDV_SUBPDV_EVENT_REQUEST',
  PDV_SUBPDV_EVENT_SUCCCES = '@event/PDV_SUBPDV_EVENT_SUCCCES',
  PDV_SUBPDV_EVENT_FAILURE = '@event/PDV_SUBPDV_EVENT_FAILURE',
}

/**
 * Data types
 */

export interface EventDataType {
  page: Page<EventFind, Event>;
  eventGeneralInformation: EventGeneralInformation;
  ticketMainConfigurations: EventTicketMainConfiguration[];
  ticketPayments: TicketPayment[];
  ticketGeneralSettings: EventTicketGeneralSettings[];
  products: EventProduct[];
  combos: EventProductCombo[];
  sectionproductsAndCombos: EventSection[];
  poss: EventPos[];
  pdvs: EventPdv[];
  list: Event[];
}

/**
 * State type
 */
export interface EventState {
  readonly data: EventDataType;
  readonly loading: boolean;
  readonly error: CustomError | undefined;
}
