import React, { useEffect, useState } from 'react';
import { /* useHistory, */ useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api, { AxiosError } from '@/services/api';
import EventMainPdvReport from '@/model/EventMainPdvReport';
import EventMainSubPdvReport from '@/model/EventMainSubPdvReport';
import EventMainBleedingReport from '@/model/EventMainBleedingReport';
import EventProductReport from '@/model/EventProductReport';
import EventMainTerminalReport from '@/model/EventMainTerminalReport';
import EventValidationMainReport from '@/model/EventValidationMainReport';
import { REACT_APP_USER } from '@/utils/config';
import { Loading } from '@/components';
import EventMainReport from '@/model/EventMainReport';
import EventGeneralReport from '@/model/EventGeneralReport';
import { useDialog } from '@/hooks/useDialog';
import EventDetailReport from '@/model/EventDetailReport';
import EventMainDateReport from '@/model/EventMainDateReport';
import EventMainTrackingReport from '@/model/EventMainTrackingReport';
import Page from '@/model/Page';
import EventTrackingReport from '@/model/EventTrackingReport';
import { ReportsContent } from './ui';
import { ReportControllerProps, ShouldShowModal, States } from '../types';

export type UrlParams = {
  id: string;
  single: string;
};

export const ReportsScreen: React.FC = () => {
  const params = useParams<UrlParams>();
  const [state, setState] = useState<States>(States.default);
  const [event, setEvent] = useState<EventMainReport>({} as EventMainReport);
  const [eventChild, setEventChild] = useState<EventMainReport[]>([]);
  const [eventDetail, setEventDetail] = useState<EventDetailReport>();
  const [generalSale, setGeneralSale] = useState<EventGeneralReport>();
  const [salePdv, setSalePdv] = useState<EventMainPdvReport[]>();
  const [saleDate, setSaleDate] = useState<EventMainDateReport>();
  const [saleSubpdv, setSaleSubpdv] = useState<EventMainSubPdvReport[]>();
  const [bleeding, setBleeding] = useState<EventMainBleedingReport>();
  const [tracking, setTracking] = useState<EventMainTrackingReport>();
  const [trackingPagination, setTrackingPagination] = useState<
    Page<EventTrackingReport, EventTrackingReport>
  >({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [saleProduct, setSaleProduct] = useState<EventProductReport>();
  const [validation, setValidation] = useState<EventValidationMainReport>();
  const [terminal, setTerminal] = useState<EventMainTerminalReport>();
  const { visible, onToggle } = useDialog();

  const user = JSON.parse(localStorage.getItem(String(REACT_APP_USER)) ?? '{}');
  const { userType } = user;
  const shouldShowModal = ShouldShowModal.detail;

  // const history = useHistory();

  const handleGetReport = async (): Promise<void> => {
    const singleEvent = params.single === 'true';
    try {
      setState(States.loading);
      const { data } = await api.get<EventMainReport>(
        `event/report/${params.id}?singleEvent=${singleEvent}`,
      );
      if (data) {
        setEvent(data);
        if (data.childs && data.childs.length > 0) {
          setEventChild(data.childs);
        }
      }
    } catch (error) {
      toast.error('Evento sem relatórios disponíveis');
    } finally {
      setState(States.default);
    }
  };

  const handleOnChildPress = async (eventSelected: string, eventName: string): Promise<void> => {
    if (eventSelected !== event.id || eventName === event.name) {
      window?.open(
        `${window.location.origin}/dashboard/event/reports/${eventSelected}/true`,
        '_blank',
      );

      /*
      history.push(`/dashboard/event/reports/${eventSelected}/true`);

      try {
        setState(States.loading);
        setEvent({} as EventMainReport);
        setEventChild([]);
        setEventDetail(undefined);
        setGeneralSale(undefined);
        setSalePdv(undefined);
        setSaleDate(undefined);
        setSaleSubpdv(undefined);
        setBleeding(undefined);
        setTracking(undefined);
        setTrackingPagination({
          page: 1,
          pageSize: 10,
          total: 0,
        });
        setSaleProduct(undefined);
        setValidation(undefined);
        setTerminal(undefined);
        const { data } = await api.get<EventMainReport>(
          `event/report/${eventSelected}?singleEvent=true`,
        );
        if (data) {
          setEvent(data);
          if (data.childs && data.childs.length > 0) {
            setEventChild(data.childs);
          }
        }
      } catch (error) {
        toast.error('Evento sem relatórios disponíveis');
      } finally {
        setState(States.default);
      }
      */
    }
  };

  const handleGetDetailReport = async (): Promise<void> => {
    try {
      setState(States.loading);
      const singleEvent = params.single === 'true';
      const { data } = await api.get<EventDetailReport>(
        `event/report/${params.id}/detail?singleEvent=${singleEvent}`,
      );
      if (data) {
        setEventDetail(data);
      } else {
        setEventDetail(undefined);
      }
    } catch (error) {
      toast.error('Evento sem relatórios disponíveis');
    } finally {
      setState(States.default);
    }
  };

  const onPageTracking = async (page: number): Promise<void> => {
    try {
      const singleEvent = params.single === 'true';
      setState(States.loading);
      const newPage = {
        ...trackingPagination,
        page,
      };
      const { data } = await api.post<Page<EventTrackingReport, EventTrackingReport>>(
        `event/report/${params.id}/tracking?singleEvent=${singleEvent}`,
        newPage,
      );

      if (data) {
        setTrackingPagination(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const reportController: ReportControllerProps = {
    eventId: params.id,
    singleEvent: params.single === 'true',
    generalSale,
    salePdv,
    saleDate,
    saleSubpdv,
    bleeding,
    tracking,
    trackingPagination,
    saleProduct,
    validation,
    terminal,
    setGeneralSale,
    setSalePdv,
    setSaleDate,
    setSaleSubpdv,
    setBleeding,
    setTracking,
    setTrackingPagination,
    onPageTracking,
    setSaleProduct,
    setValidation,
    setTerminal,
  };

  const handleOnShowModal = async (): Promise<void> => {
    if (!visible) {
      await handleGetDetailReport();
    }
    onToggle();
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const generatePDF = () => {
    window.print();
  };

  useEffect(() => {
    handleGetReport();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      {event && event.ticket && (
        <ReportsContent
          event={event}
          shouldShowModal={shouldShowModal}
          visible={visible}
          reportController={reportController}
          eventChild={eventChild}
          userType={userType}
          eventDetail={eventDetail}
          onChildPress={handleOnChildPress}
          onShowModal={handleOnShowModal}
          generatePDF={generatePDF}
          onToggle={onToggle}
        />
      )}
    </>
  );
};
