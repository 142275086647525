import { DataList } from '@/components/DataList';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { colors } from '@/styles/colors';
import React from 'react';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

export interface FinalSettlementProps {
  eventId: string;
  data: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const FinalSettlementContent: React.FC<FinalSettlementProps> = ({ eventId, data }) => (
  <Container className="mainContainer" fluid={true}>
    <div className="pageTitle d-flex justify-content-between mb-5">
      <div className="d-flex">
        <Link to={`${process.env.PUBLIC_URL}/financial/event-close/${eventId}`}>
          <ArrowLeft color={colors.black} className="arrow-left" />
        </Link>
        <h5 className="ml-3 mb-0 pageTitle">Acerto final - </h5>
      </div>
    </div>
    <div>
      <h6 className="mt-2 border-bottom-title-500 mb-5">Acerto final - Máquinas</h6>
      <div className="mb-5">
        <DataList
          data={[
            {
              title: 'Total sistema Banca',
              content: toCurrency(data?.pos?.total),
            },
            {
              title: 'Total API PagSeguro',
              content: toCurrency(data?.pos?.totalPagSeguro),
            },
            {
              title: 'Diferença',
              content: toCurrency(data?.pos?.difference),
            },
          ]}
        />
      </div>
    </div>
    <div className="mt-5">
      <h6 className="mt-2 border-bottom-title-500 mb-5">Acerto final - Evento</h6>
      <div>
        <DataList
          data={[
            {
              title: 'Total receitas',
              content: toCurrency(data?.event?.totalIncome),
            },
            {
              title: 'Total despesas',
              content: toCurrency(data?.event?.totalExpense),
            },
            {
              title: 'Saldo',
              content: toCurrency(data?.event?.balance),
            },
          ]}
        />
      </div>
    </div>
  </Container>
);
