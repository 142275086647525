import React from 'react';
import { Route } from '@/navigation/Route';
import { EventMapScreen } from './screens';

export const EVENTS_MAP_ROUTES = {
  itself: '/dashboard/event/map/:id',
};

export const EventMapNavigation = (): JSX.Element => (
  <Route exact path={EVENTS_MAP_ROUTES.itself} component={EventMapScreen} isPrivateRoute />
);
