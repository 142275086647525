import React from 'react';

const POSIcon = (): JSX.Element => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.9128 10.2709H7.65032C7.86282 10.2709 8.00453 10.1292 8.00453 9.91669V6.48116C8.00453 6.26866 7.86282 6.12695 7.65032 6.12695L1.9128 6.12707C1.7003 6.12707 1.55859 6.26878 1.55859 6.48128V9.91681C1.55859 10.1293 1.7003 10.2709 1.9128 10.2709V10.2709ZM2.26701 6.83567H7.2963L7.29618 9.56273H2.2672L2.26701 6.83567Z"
      fill="#222222"
    />
    <path
      d="M2.79544 11.3333H1.76827C1.55577 11.3333 1.41406 11.475 1.41406 11.6875C1.41406 11.9 1.55577 12.0417 1.76827 12.0417H2.79532C3.00782 12.0417 3.14953 11.9 3.14953 11.6875C3.14953 11.475 3.00794 11.3333 2.79544 11.3333Z"
      fill="#222222"
    />
    <path
      d="M5.31515 11.3333H4.25265C4.04015 11.3333 3.89844 11.475 3.89844 11.6875C3.89844 11.9 4.04015 12.0417 4.25265 12.0417H5.31515C5.52765 12.0417 5.66936 11.9 5.66936 11.6875C5.66936 11.475 5.4922 11.3333 5.31515 11.3333Z"
      fill="#222222"
    />
    <path
      d="M7.79141 11.3333H6.76436C6.55186 11.3333 6.41016 11.475 6.41016 11.6875C6.41016 11.9 6.55186 12.0417 6.76436 12.0417H7.79141C8.00391 12.0417 8.14562 11.9 8.14562 11.6875C8.1455 11.475 8.00391 11.3333 7.79141 11.3333Z"
      fill="#222222"
    />
    <path
      d="M2.79544 13.1044H1.76827C1.55577 13.1044 1.41406 13.2461 1.41406 13.4586C1.41406 13.6711 1.55577 13.8128 1.76827 13.8128H2.79532C3.00782 13.8128 3.14953 13.6711 3.14953 13.4586C3.14953 13.2814 3.00794 13.1044 2.79544 13.1044Z"
      fill="#222222"
    />
    <path
      d="M5.31515 13.1044H4.25265C4.04015 13.1044 3.89844 13.2461 3.89844 13.4586C3.89844 13.6711 4.04015 13.8128 4.25265 13.8128L5.31515 13.8127C5.52765 13.8127 5.66936 13.671 5.66936 13.4585C5.66936 13.2814 5.4922 13.1044 5.31515 13.1044V13.1044Z"
      fill="#222222"
    />
    <path
      d="M7.79141 13.1044H6.76436C6.55186 13.1044 6.41016 13.2461 6.41016 13.4586C6.41016 13.6711 6.55186 13.8128 6.76436 13.8128L7.79141 13.8127C8.00391 13.8127 8.14562 13.671 8.14562 13.4585C8.1455 13.2814 8.00391 13.1044 7.79141 13.1044V13.1044Z"
      fill="#222222"
    />
    <path
      d="M2.79544 14.9106H1.76827C1.55577 14.9106 1.41406 15.0524 1.41406 15.2649C1.41406 15.4774 1.55577 15.6191 1.76827 15.6191H2.79532C3.00782 15.6191 3.14953 15.4774 3.14953 15.2649C3.14953 15.0522 3.00794 14.9106 2.79544 14.9106Z"
      fill="#222222"
    />
    <path
      d="M5.31515 14.9106H4.25265C4.04015 14.9106 3.89844 15.0524 3.89844 15.2649C3.89844 15.4774 4.04015 15.6191 4.25265 15.6191L5.31515 15.6189C5.52765 15.6189 5.66936 15.4772 5.66936 15.2647C5.66936 15.0522 5.4922 14.9106 5.31515 14.9106V14.9106Z"
      fill="#222222"
    />
    <path
      d="M7.79141 14.9106H6.76436C6.55186 14.9106 6.41016 15.0524 6.41016 15.2649C6.41016 15.4774 6.55186 15.6191 6.76436 15.6191L7.79141 15.6189C8.00391 15.6189 8.14562 15.4772 8.14562 15.2647C8.1455 15.0522 8.00391 14.9106 7.79141 14.9106V14.9106Z"
      fill="#222222"
    />
    <path
      d="M16.6457 6.02081H9.56241V3.89581C9.56241 3.29376 9.10195 2.8333 8.49991 2.8333H7.4374V0.354208C7.4374 0.141708 7.2957 0 7.0832 0H2.47922C2.26672 0 2.12501 0.141708 2.12501 0.354208V2.83342L1.0625 2.83331C0.460459 2.83331 0 3.29376 0 3.89581V15.9376C0 16.5397 0.460459 17.0001 1.0625 17.0001H8.50003C9.10207 17.0001 9.56253 16.5397 9.56253 15.9376V15.1584H16.6458C16.8583 15.1584 17 15.0167 17 14.8042V6.37521C17 6.19816 16.8583 6.021 16.6458 6.021L16.6457 6.02081ZM2.83312 0.708295H6.72887V4.63956H2.83312V0.708295ZM8.85418 15.9376C8.85418 16.1501 8.71247 16.2918 8.49997 16.2918H1.06244C0.849942 16.2918 0.708235 16.1501 0.708235 15.9376V3.89581C0.708235 3.68331 0.849942 3.5416 1.06244 3.5416H2.12495V4.63956H1.91245C1.69995 4.63956 1.55824 4.78127 1.55824 4.99377C1.55824 5.20627 1.69995 5.34798 1.91245 5.34798H7.64997C7.86247 5.34798 8.00418 5.20627 8.00418 4.99377C8.00418 4.78127 7.86247 4.63956 7.64997 4.63956H7.43747V3.54172H8.49997C8.71247 3.54172 8.85418 3.68343 8.85418 3.89593V15.9376ZM16.2917 14.4501H9.56241V6.72935H16.2917V14.4501Z"
      fill="#222222"
    />
    <path
      d="M10.6237 12.6438H14.7675C14.98 12.6438 15.1217 12.5021 15.1217 12.2896C15.1217 12.0771 14.98 11.9354 14.7675 11.9354L10.6237 11.9355C10.4112 11.9355 10.2695 12.0773 10.2695 12.2898C10.2695 12.5021 10.4112 12.6438 10.6237 12.6438V12.6438Z"
      fill="#222222"
    />
    <path
      d="M12.2878 9.24382H14.7316C14.9441 9.24382 15.0858 9.10211 15.0858 8.88961C15.0858 8.67711 14.9441 8.5354 14.7316 8.5354H12.2878C12.0753 8.5354 11.9336 8.67711 11.9336 8.88961C11.9337 9.10211 12.1108 9.24382 12.2878 9.24382Z"
      fill="#222222"
    />
  </svg>
);

export default POSIcon;
