import { InputText, SelectCustom } from '@/components';
import Section from '@/model/Section';
import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { FormInputRegister, MapControler } from '../../types';

interface ConfigureSectorProps {
  configureSectorControler: MapControler;
  sections: Section[];
}

export const ConfigureSector: React.FC<ConfigureSectorProps> = ({
  configureSectorControler,
  sections,
}): JSX.Element => (
  <div>
    <Row>
      <Col md={8}>
        <FormGroup className="mb-2">
          <SelectCustom
            name="section"
            label="Setor"
            placeholder="Digite ou selecione o setor"
            value={configureSectorControler.formData[FormInputRegister.section]}
            onChange={e =>
              configureSectorControler.onChangeFormInput(FormInputRegister.section)(
                e?.value as string,
              )
            }
            error={
              configureSectorControler.formErrors.section &&
              configureSectorControler.formErrors.section[0]
            }
            options={sections.map(section => ({
              label: section.name,
              value: section.id,
            }))}
            isClearable
          />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col md={8}>
        <FormGroup className="mb-2">
          <InputText
            name="name"
            label="Nome da Categoria"
            placeholder="Digite o nome da Categoria no Seats.io"
            value={configureSectorControler.formData[FormInputRegister.name]}
            onChange={e =>
              configureSectorControler.onChangeFormInput(FormInputRegister.name)(e.target.value)
            }
            error={
              configureSectorControler.formErrors.name &&
              configureSectorControler.formErrors.name[0]
            }
          />
        </FormGroup>
      </Col>
    </Row>
  </div>
);
