import React from 'react';
import { Route } from '@/navigation/Route';
import { ArtistScreen } from '@/features/artist/screens/List';

export const ARTIST_ROUTES = {
  itself: '/dashboard/artist',
};

export const ArtistNavigation = (): JSX.Element => (
  <Route exact path={ARTIST_ROUTES.itself} component={ArtistScreen} isPrivateRoute />
);
