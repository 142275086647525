import React, { useEffect, useState } from 'react';
import api from '@/services/api';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { VoucherAndCourtesy } from '@/model/VoucherAndCourtesy';
import { States } from '@/helpers/common/states';
import { Loading } from '@/components';
import { EventCourtesyReport } from '@/model/EventCourtesyReport';
import DiscountCoupon from '@/model/DiscountCoupon';
import Voucher from '@/model/Voucher';

import { VoucherCourtesyUI } from './ui';
import { ReportControllerProps } from '../../types';

interface VoucherCourtesyScreenProps {
  reportController: ReportControllerProps;
}

export const VoucherCourtesyScreen: React.FC<VoucherCourtesyScreenProps> = ({
  reportController,
}): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [voucherList, setVoucherList] = useState<Voucher[]>([]);
  const [couponsList, setCouponsList] = useState<DiscountCoupon[]>([]);
  const [courtesyList, setCourtesyList] = useState<EventCourtesyReport>();

  const handleFetchVoucherAndCourtesies = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<VoucherAndCourtesy>(
        `event/report/${reportController.eventId}/courtesy-voucher?singleEvent=${reportController.singleEvent}`,
      );
      if (data) {
        setCourtesyList(data.courtesies);
        setCouponsList(data.discountCoupons);
        setVoucherList(data.voucher);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  useEffect(() => {
    handleFetchVoucherAndCourtesies();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <VoucherCourtesyUI
        voucherList={voucherList}
        couponsList={couponsList}
        courtesyList={courtesyList}
      />
    </>
  );
};
