import React, { useEffect, useState } from 'react';
import { States } from '@/helpers/common/states';
import Support from '@/model/Support';
import Page from '@/model/Page';
import SupportStatus from '@/model/SupportStatus';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import { useDialog } from '@/hooks/useDialog';
import { updateMask as updateMaskProtocol } from '@/helpers/masks/protocol';
import { updateMask as updateMaskCPF } from '@/helpers/masks/cpf';
import { useHistory } from 'react-router-dom';
import { SupportUI } from './ui';
import { FormInputFilter, ShouldShowModal } from '../../types';

export const SupportScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const [currentPage, setCurrentPage] = useState<Page<Support, Support>>({
    page: 1,
    pageSize: 10,
    sort: 'startDate',
    order: 'DESC',
  });

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const history = useHistory();

  const handleOnPage = async (page: Page<Support, Support>): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Page<Support, Support>>('/support/call', page);
      if (data) {
        setCurrentPage(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const noFormat = (value: string): string => value;

  const getFilterFormater = (value: string): string => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (formDataFilter[FormInputFilter.filterSearch]) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (formDataFilter[FormInputFilter.filterSearch] === 'protocol') {
        return updateMaskProtocol(value);
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (formDataFilter[FormInputFilter.filterSearch] === 'cpf') {
        return updateMaskCPF(value);
      }
      return noFormat(value);
    }
    return updateMaskProtocol(value);
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
    validators: {
      filterSearch: [validators.required],
      inputSearch: [validators.required],
    },
    formatters: {
      inputSearch: getFilterFormater,
    },
  });

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
  }): Promise<void> => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnGoToChat = (id: string): void => {
    history.push(`/chat/${id}`);
  };

  const handleOnClearFilter = async (): Promise<void> => {
    resetFormFilter();
    const newPage: Page<Support, Support> = {
      page: 1,
      pageSize: 10,
      sort: 'startDate',
      order: 'ASC',
    };
    onToggle();
    await handleOnPage(newPage);
  };

  const handleOnFilter = async (): Promise<void> => {
    if (isFormValidFilter()) {
      const entity = {} as Support;
      if (formDataFilter[FormInputFilter.filterSearch] === 'protocol') {
        entity.protocol = formDataFilter[FormInputFilter.inputSearch];
      } else if (formDataFilter[FormInputFilter.filterSearch] === 'name') {
        entity.name = formDataFilter[FormInputFilter.inputSearch];
      } else if (formDataFilter[FormInputFilter.filterSearch] === 'cpf') {
        entity.cpf = formDataFilter[FormInputFilter.inputSearch];
      } else if (formDataFilter[FormInputFilter.filterSearch] === 'e-mail') {
        entity.email = formDataFilter[FormInputFilter.inputSearch];
      } else if (formDataFilter[FormInputFilter.filterSearch] === 'status') {
        if (formDataFilter[FormInputFilter.inputSearch] === 'NEW') {
          entity.supportStatus = SupportStatus.NEW;
        } else if (formDataFilter[FormInputFilter.inputSearch] === 'WAITING') {
          entity.supportStatus = SupportStatus.WAITING;
        } else if (formDataFilter[FormInputFilter.inputSearch] === 'RESOLVED') {
          entity.supportStatus = SupportStatus.RESOLVED;
        } else if (formDataFilter[FormInputFilter.inputSearch] === 'CLOSED') {
          entity.supportStatus = SupportStatus.CLOSED;
        } else if (formDataFilter[FormInputFilter.inputSearch] === 'REOPENED') {
          entity.supportStatus = SupportStatus.REOPENED;
        } else if (formDataFilter[FormInputFilter.inputSearch] === 'CANCELED') {
          entity.supportStatus = SupportStatus.CANCELED;
        } else if (formDataFilter[FormInputFilter.inputSearch] === 'IN_PROGRESS') {
          entity.supportStatus = SupportStatus.IN_PROGRESS;
        } else if (formDataFilter[FormInputFilter.inputSearch] === 'WAITING_FOR_CLIENT') {
          entity.supportStatus = SupportStatus.WAITING_FOR_CLIENT;
        }
      }
      const newPage: Page<Support, Support> = {
        page: 1,
        pageSize: 10,
        entity,
        sort: 'startDate',
        order: 'DESC',
        total: 1,
      };
      onToggle();

      await handleOnPage(newPage);
    }
  };

  const handleOnPagination = (pageNumber: number): void => {
    const newPage: Page<Support, Support> = {
      ...currentPage,
      page: pageNumber,
    };
    handleOnPage(newPage);
  };

  useEffect(() => {
    handleOnPage(currentPage);
  }, []);

  return (
    <SupportUI
      state={state}
      title={title}
      visible={visible}
      shouldShowModal={shouldShowModal}
      onToggle={onToggle}
      onGoToChat={handleOnGoToChat}
      onShouldShowModal={handleOnShouldShowModal}
      formData={formDataFilter}
      formErrors={formErrorsFilter}
      onChangeFormInput={onChangeFormInputFilter}
      onFilter={handleOnFilter}
      onClearFilter={handleOnClearFilter}
      currentPage={currentPage}
      onPagination={handleOnPagination}
    />
  );
};
