import React from 'react';
import { Button, DropdonwFlags } from '@/components';
import { toCurrency } from '@/helpers/masks/toCurrency';
import PaymentType from '@/model/PaymentType';
import { updateMask as updateMaskCpfCnpj } from '@/helpers/masks/cpfCnpj';
import EventTrackingReport from '@/model/EventTrackingReport';
import { CustomTable, TableColumn } from '@/components/Table';
import dayjs from 'dayjs';
import EventMainTrackingReport from '@/model/EventMainTrackingReport';
import Page from '@/model/Page';
import Pagination from '@/components/Utils/Pagination';
import PaymentStatus from '@/model/PaymentStatus';
import { DataList } from '@/components/DataList';
import { DataColumn } from '@/features/reverse/types';

export interface DataRowContractor {
  id: string;
  name: string;
  document: string;
  telephone: string;
  companyType: number;
  actions: string;
}

export interface TrackingContenttProps {
  generatePDF: () => void;
  tracking: EventMainTrackingReport | undefined;
  pagination: Page<EventTrackingReport, EventTrackingReport>;
  onPage: (page: number) => void;
}

export const TrackingContent: React.FC<TrackingContenttProps> = ({
  tracking,
  generatePDF,
  pagination,
  onPage,
}) => {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const convertNumberToStatus = (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.APPROVED:
        return (
          <div className="filterActive" style={{ backgroundColor: '#7AD81B10' }}>
            <div style={{ color: '#7AD81B' }}>Aprovado</div>
          </div>
        );
      case PaymentStatus.DISAPPROVED:
        return (
          <div className="filterActive" style={{ backgroundColor: '#E54F4910' }}>
            <div style={{ color: '#E54F49' }}>Recusado</div>
          </div>
        );
      case PaymentStatus.AWAITING_PAYMENT:
        return (
          <div className="filterActive" style={{ backgroundColor: '#F6D93E10' }}>
            <div style={{ color: '#F6D93E' }}>Aguardando Pagamento</div>
          </div>
        );
      case PaymentStatus.CANCELED:
        return (
          <div className="filterActive" style={{ backgroundColor: '#E54F4910' }}>
            <div style={{ color: '#3CAFC8' }}>Cancelado</div>
          </div>
        );
      case PaymentStatus.CANCELLATION_ANALYSIS:
        return (
          <div className="filterActive" style={{ backgroundColor: '#E54F4910' }}>
            <div style={{ color: '#3CAFC8' }}>Analisando solicitação de cancelamento</div>
          </div>
        );
      default:
        return (
          <div className="filterActive" style={{ backgroundColor: '#F6D93E10' }}>
            <div style={{ color: '#F6D93E' }}>Pendente</div>
          </div>
        );
    }
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const convertPaymentTypeToString = (types: PaymentType[]) => {
    if (types) {
      if (types.length === 1) {
        switch (types[0]) {
          case PaymentType.CREDIT_CARD:
            return 'Crédito';
          case PaymentType.TWO_CREDIT_CARDS:
            return 'Dois cartões';
          case PaymentType.DEBIT_CARD:
            return 'Débito';
          case PaymentType.PIX:
            return 'Pix';
          case PaymentType.BANK_SLIP:
            return 'Boleto';
          case PaymentType.MONEY:
            return 'Dinheiro';
          case PaymentType.COURTESY:
            return 'Cortesia';
          default:
            return 'Outros';
        }
      } else {
        const dataColumnProfiles: DataColumn[] = [];
        types.forEach((data, index) => {
          dataColumnProfiles.push({
            id: `${index}`,
            name: convertPaymentTypeToString([data]) as string,
          });
        });
        return <DropdonwFlags pointerClass={true} dataColumn={dataColumnProfiles} />;
      }
    } else {
      return '';
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const mountTransaction = (transactions: string[]) => {
    if (transactions) {
      if (transactions.length === 1) {
        return transactions[0];
      }
      const dataColumnProfiles: DataColumn[] = [];
      transactions.forEach((data, index) => {
        dataColumnProfiles.push({
          id: `${index}`,
          name: data as string,
        });
      });
      return <DropdonwFlags pointerClass={true} dataColumn={dataColumnProfiles} />;
    }
    return '';
  };

  const columns: TableColumn<EventTrackingReport>[] = [
    {
      name: 'Cód',
      selector: row => row.id,
    },
    {
      name: 'PDV',
      selector: row => row.pdvName,
    },
    {
      name: 'Setor e ingresso',
      selector: row => `${row.sectionName} - ${row.ticketName}`,
    },
    {
      name: 'CPF',
      selector: row => (row.cpf ? updateMaskCpfCnpj(row.cpf) : '-----'),
    },
    {
      name: 'Venda',
      selector: row => dayjs(row.saleDate).format('DD/MM/YYYY [às] HH:mm'),
    },
    {
      name: 'Status',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selector: row => convertNumberToStatus(row.status) as unknown as PaymentStatus,
    },
    {
      name: 'Forma de pag',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selector: row => (row.types ? convertPaymentTypeToString(row.types) : '') as string,
    },
    {
      name: 'Valor',
      selector: row => toCurrency(row.value),
    },
    {
      name: 'Transação',
      selector: row => (row.transactions ? mountTransaction(row.transactions) : '') as string,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5>Rastreamento</h5>
        <Button theme="outlineDark" onClick={() => generatePDF()} title="Exportar relatório" />
      </div>
      <div
        className="customTableScroll p-4"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          gridColumnGap: '40px',
          overflow: 'auto',
          alignItems: 'end',
        }}
      >
        <DataList
          data={
            tracking
              ? [
                  {
                    title: 'Vendidos:',
                    content: tracking.amountSold ? `${tracking.amountSold}` : '-----',
                  },
                  {
                    title: 'Cortesias:',
                    content: tracking.amountCourtesy ? tracking.amountCourtesy : '-----',
                  },
                  {
                    title: 'Faturamento:',
                    content: tracking.billingValue
                      ? `${toCurrency(tracking.billingValue)}`
                      : '-----',
                  },
                  {
                    title: 'Descontos:',
                    content: tracking.discounts ? `${toCurrency(tracking.discounts)}` : '-----',
                  },
                  {
                    title: 'Faturamento com descontos:',
                    content: tracking.billingValue
                      ? `${toCurrency(tracking.billingValue)}`
                      : '-----',
                  },
                ]
              : []
          }
        />
      </div>
      <div className="mt-5">
        <CustomTable
          progressPending={false}
          columns={columns}
          data={pagination.list || []}
          numberRowsPerPage={pagination.total || 0}
        />
        <Pagination
          currentPage={pagination.page}
          totalCount={pagination.total}
          pageSize={pagination.pageSize}
          onPageChange={page => onPage(page)}
          total={pagination.total}
        />
      </div>
    </>
  );
};
