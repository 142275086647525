import React from 'react';

const UserGroupIcon = (): JSX.Element => (
  <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5006 0C12.1831 0 11.1086 1.07442 11.1086 2.39204C11.1086 3.70956 12.183 4.78409 13.5006 4.78409C14.8181 4.78409 15.8926 3.70956 15.8926 2.39204C15.8926 1.07453 14.8182 0 13.5006 0ZM13.5006 0.597944C14.4949 0.597944 15.2946 1.39758 15.2946 2.39191C15.2946 3.38624 14.4949 4.18588 13.5006 4.18588C12.5063 4.18588 11.7066 3.38624 11.7066 2.39191C11.7066 1.39758 12.5063 0.597944 13.5006 0.597944ZM13.5006 5.08293C11.026 5.08293 9.01562 7.02351 9.01562 9.43709V9.86696H9.61357V9.43709C9.61357 7.35457 11.3371 5.68092 13.5006 5.68092C15.664 5.68092 17.3875 7.35454 17.3875 9.43709V9.86696H17.9855V9.43709C17.9855 7.02348 15.9751 5.08293 13.5005 5.08293H13.5006Z"
      fill="black"
    />
    <path
      d="M13.5006 11.1943C12.1831 11.1943 11.1086 12.2688 11.1086 13.5864C11.1086 14.9039 12.183 15.9784 13.5006 15.9784C14.8181 15.9784 15.8926 14.9039 15.8926 13.5864C15.8926 12.2689 14.8182 11.1943 13.5006 11.1943ZM13.5006 11.7923C14.4949 11.7923 15.2946 12.5919 15.2946 13.5862C15.2946 14.5806 14.4949 15.3802 13.5006 15.3802C12.5063 15.3802 11.7066 14.5806 11.7066 13.5862C11.7066 12.5919 12.5063 11.7923 13.5006 11.7923ZM13.5006 16.2773C11.026 16.2773 9.01562 18.2178 9.01562 20.6314V21.0613H9.61357V20.6314C9.61357 18.5489 11.3371 16.8753 13.5006 16.8753C15.664 16.8753 17.3875 18.5489 17.3875 20.6314V21.0613H17.9855V20.6314C17.9855 18.2178 15.9751 16.2773 13.5005 16.2773H13.5006Z"
      fill="black"
    />
    <path
      d="M4.48498 6.24121C3.16746 6.24121 2.09294 7.31563 2.09294 8.63325C2.09294 9.95077 3.16736 11.0253 4.48498 11.0253C5.8025 11.0253 6.87702 9.95077 6.87702 8.63325C6.87702 7.31574 5.8026 6.24121 4.48498 6.24121ZM4.48498 6.83915C5.47931 6.83915 6.27895 7.63879 6.27895 8.63312C6.27895 9.62745 5.47931 10.4271 4.48498 10.4271C3.49065 10.4271 2.69101 9.62745 2.69101 8.63312C2.69101 7.63879 3.49065 6.83915 4.48498 6.83915ZM4.48498 11.3241C2.01037 11.3241 0 13.2647 0 15.6783V16.1082H0.597944V15.6783C0.597944 13.5958 2.32143 11.9221 4.48493 11.9221C6.64837 11.9221 8.37191 13.5958 8.37191 15.6783V16.1082H8.96985V15.6783C8.96985 13.2647 6.95949 11.3241 4.48487 11.3241H4.48498Z"
      fill="black"
    />
    <path
      d="M22.5143 6.24121C21.1968 6.24121 20.1222 7.31563 20.1222 8.63325C20.1222 9.95077 21.1967 11.0253 22.5143 11.0253C23.8318 11.0253 24.9063 9.95077 24.9063 8.63325C24.9063 7.31574 23.8319 6.24121 22.5143 6.24121ZM22.5143 6.83915C23.5086 6.83915 24.3082 7.63879 24.3082 8.63312C24.3082 9.62745 23.5086 10.4271 22.5143 10.4271C21.5199 10.4271 20.7203 9.62745 20.7203 8.63312C20.7203 7.63879 21.5199 6.83915 22.5143 6.83915ZM22.5143 11.3241C20.0397 11.3241 18.0293 13.2647 18.0293 15.6783V16.1082H18.6272V15.6783C18.6272 13.5958 20.3507 11.9221 22.5142 11.9221C24.6777 11.9221 26.4012 13.5958 26.4012 15.6783V16.1082H26.9991V15.6783C26.9991 13.2647 24.9888 11.3241 22.5142 11.3241H22.5143Z"
      fill="black"
    />
  </svg>
);

export default UserGroupIcon;
