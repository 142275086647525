import React, { useEffect, useState } from 'react';
import Event from '@/model/Event';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { FinishedEventDetailContainer } from './ui';
import { States, UrlParams } from '../types';

export const FinishedEventDetailScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [event, setEvent] = useState<Event>();

  const params = useParams<UrlParams>();

  const handleOnFetch = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Event>(`/event/${params.id}`);
      if (data) {
        setEvent(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };
  useEffect(() => {
    handleOnFetch();
  }, []);
  return <FinishedEventDetailContainer state={state} event={event} />;
};
