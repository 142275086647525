import React from 'react';
import { Col, Row } from 'reactstrap';
import avatar from '@/assets/images/avatar/avatar.svg';
import Log from '@/model/Log';
import dayjs from 'dayjs';

interface StateProps {
  log: Log;
}

export const DetailContent: React.FC<StateProps> = ({ log }) => (
  <>
    <Row className="mb-4">
      <Col md={8}>
        <div className="media profile-media" title={log.person ? log.person.name : 'Não informado'}>
          <img
            className="user-img"
            src={log.person && log.person.imageUrl ? log.person.imageUrl : avatar}
            alt=""
          />
          <div className="media-body profile-name">
            <span>{log.person ? log.person.name : 'Não informado'}</span>
            <div className="profile-user" style={{ marginTop: '5px' }}>
              {dayjs(log.createdAt).format('DD/MM/YYYY [às] HH:mm')} <i className=""></i>
            </div>
          </div>
        </div>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col md={8}>
        <label className="input-label">{log.message}</label>
      </Col>
    </Row>
  </>
);
