import React from 'react';
import { Route } from '@/navigation/Route';
import { ReprintScreen } from './screens/List';

export const REPRINT_ROUTES = {
  itself: '/support/reprint',
};

export const ReprintNavigation = (): JSX.Element => (
  <Route exact path={REPRINT_ROUTES.itself} component={ReprintScreen} isPrivateRoute />
);
