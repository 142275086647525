import React from 'react';

const TicketsIcon = (): JSX.Element => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.63672 1L5.20692 1L5.20692 1.34709C5.20692 2.11805 4.58935 2.73562 3.81839 2.73562C3.04744 2.73562 2.42987 2.11805 2.42987 1.34709L2.42987 1L7.00951e-05 1L6.94507e-05 15.5792L2.42987 15.5792L2.42987 15.2321C2.42987 14.4611 3.04744 13.8436 3.81839 13.8436C4.58935 13.8436 5.20692 14.4611 5.20692 15.2321L5.20692 15.5792L7.63672 15.5792L7.63672 1ZM6.94253 1.69418L6.94253 11.0665L6.24835 11.0665L6.24835 11.7607L6.94253 11.7607L6.94253 14.8848L5.83065 14.8848C5.6585 13.9095 4.84142 13.1492 3.81839 13.1492C2.79512 13.1492 1.97805 13.9092 1.80613 14.8848L0.694254 14.8848L0.694254 11.7607L1.38844 11.7607L1.38844 11.0665L0.694254 11.0665L0.694255 1.69418L1.80613 1.69418C1.97805 2.66976 2.79512 3.4298 3.81839 3.4298C4.84142 3.4298 5.65849 2.66951 5.83066 1.69418L6.94253 1.69418ZM5.55401 11.0665L4.16549 11.0665L4.16549 11.7607L5.55401 11.7607L5.55401 11.0665ZM3.4713 11.0665L2.08278 11.0665L2.08278 11.7607L3.4713 11.7607L3.4713 11.0665Z"
      fill="#222222"
    />
    <path
      d="M18.1504 2.8291L15.7744 2.24668L15.6867 2.58539C15.4919 3.33773 14.7319 3.79236 13.978 3.60757C13.2241 3.42277 12.7763 2.67208 12.9711 1.91974L13.0589 1.58103L10.6829 0.998612L6.99848 15.2258L9.37447 15.8082L9.46218 15.4695C9.65702 14.7171 10.417 14.2625 11.1709 14.4473C11.9247 14.6321 12.3726 15.3828 12.1777 16.1351L12.09 16.4738L14.466 17.0563L18.1504 2.8291ZM17.2961 3.34013L14.9276 12.4862L14.2488 12.3198L14.0734 12.9972L14.7522 13.1636L13.9627 16.2123L12.8754 15.9457C12.9535 14.9527 12.3467 14.0149 11.3463 13.7697C10.3457 13.5244 9.35468 14.0703 8.94002 14.9811L7.85277 14.7146L8.64228 11.6659L9.32109 11.8323L9.49652 11.1549L8.81771 10.9885L11.1863 1.84243L12.2735 2.10894C12.1951 3.10217 12.802 4.03971 13.8026 4.28499C14.8029 4.53021 15.7941 3.98412 16.2089 3.07361L17.2961 3.34013ZM13.5698 12.1533L12.2121 11.8205L12.0366 12.4979L13.3944 12.8308L13.5698 12.1533ZM11.5333 11.6541L10.1755 11.3213L10.0001 11.9987L11.3578 12.3315L11.5333 11.6541Z"
      fill="#222222"
    />
  </svg>
);

export default TicketsIcon;
