import React from 'react';
import { Route } from '@/navigation/Route';
import { CreateSaleScreen } from './screens';

export const CREATE_SALE_ROUTES = {
  itself: '/dashboard/create-sale',
};

export const CreateSaleNavigation = (): JSX.Element => (
  <Route exact path={CREATE_SALE_ROUTES.itself} component={CreateSaleScreen} isPrivateRoute />
);
