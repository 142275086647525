import React from 'react';
import { ReactComponent as Success } from '@/assets/images/svg/success.svg';
import TicketPdf from '@/model/TicketPdf';

interface TicketContentProps {
  tickets: TicketPdf[];
}

export const TicketContent: React.FC<TicketContentProps> = ({ tickets }) => (
  <div className="filter-modal-content">
    <div className="exclude-container">
      <Success color="#E64F49" />
      <div className="header-title-text modal__title">
        <h5 className="modal__confirmation-title">Seu Pedido foi realizado com sucesso</h5>
      </div>
      <div className="modal__confirmation-text" style={{ marginBottom: '20px' }}>
        Para baixar seu(s) ingresso(s), click sobre o <strong>ingresso</strong>.
      </div>
      {tickets.map((data, index) => (
        <div key={`${index}-${data.name}`} className="modal__confirmation-text">
          <a href="#" onClick={() => window.open(data.url, '_blank')}>
            {data.name}
          </a>
        </div>
      ))}
    </div>
  </div>
);
