import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Chart } from 'chart.js';
import { toCurrency } from '@/helpers/masks/toCurrency';
import EventGeneralSaleChannelReport from '@/model/EventGeneralSaleChannelReport';

interface StyledPieProps {
  generalSale: EventGeneralSaleChannelReport[];
  backgrundColor1?: string;
  backgrundColor2?: string;
  label?: string;
  type?: string;
}

export const StyledPie: React.FC<StyledPieProps> = ({
  generalSale,
  backgrundColor1,
  backgrundColor2,
  label,
  type,
}) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const generateRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}`;
  };

  const data = {
    labels: generalSale.map(item => item.name),
    datasets: [
      {
        label,
        data: generalSale?.map(item => Number(item.percent.toFixed(2))),
        backgroundColor:
          backgrundColor1 && backgrundColor2
            ? [backgrundColor1, backgrundColor2]
            : generalSale?.map(_item => generateRandomColor()),
        borderWidth: 0,
      },
    ],
  };
  Chart.overrides.pie.plugins.legend.labels.usePointStyle = true;
  Chart.overrides.pie.plugins.legend.labels.pointStyle = 'circle';
  Chart.overrides.pie.plugins.legend.labels.boxHeight = 8;
  Chart.overrides.pie.plugins.legend.labels.boxWidth = 15;
  Chart.defaults.plugins.tooltip.enabled = true;
  return (
    <div className="d-flex">
      <div style={{ width: '200px' }}>
        <Pie width={200} height={200} data={data} />
      </div>
      <div style={{ flexDirection: 'column', justifyContent: 'center' }} className="d-flex">
        {type === 'total' && (
          <div className="mb-3">
            <span style={{ color: '#828282' }}>Emitidos: </span>
            {generalSale?.reduce((acc: any, item: any) => acc + item.value, 0)}
          </div>
        )}
        {generalSale?.map((item: any, index: any) => (
          <div key={index}>
            <div className="d-flex justify-content-end flex-column w-100 h-100">
              {item.name && (
                <div className="mb-3">
                  <span style={{ color: '#828282' }}>{item.name}: </span>
                  {type === 'currency' ? toCurrency(item.value) : item.value} (
                  {item.percent.toFixed(2)}%)
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
