import { TableColumn } from 'react-data-table-component';

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
}

// eslint-disable-next-line no-shadow
export enum FormInput {
  filterSearch = 'filterSearch',
  inputSearch = 'inputSearch',
}

export interface DataRow {
  id: string;
  image: string;
  status: number;
  name: string;
  startDate: string;
  endDate: string;
  city: string;
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Imagem',
    selector: row => row.image,
    width: '100px',
  },
  {
    name: 'Nome do evento',
    selector: row => row.name,
    minWidth: '280px',
    style: {
      cursor: 'pointer',
    },
  },
  {
    name: 'Cidade',
    selector: row => row.city,
    minWidth: '200px',
    style: {
      cursor: 'pointer',
    },
  },
  {
    name: 'Início evento',
    selector: row => row.startDate,
    minWidth: '200px',
    style: {
      cursor: 'pointer',
    },
  },
  {
    name: 'Fim evento',
    selector: row => row.endDate,
    minWidth: '200px',
    style: {
      cursor: 'pointer',
    },
  },
];

export const dataEventType = [
  { id: 0, name: 'Evento mono' },
  { id: 1, name: 'Evento pai' },
  { id: 2, name: 'Evento filho' },
];
