// eslint-disable-next-line no-shadow
enum CardBrand {
  AMEX,
  BANRICOMPRAS,
  CABAL,
  ELO,
  HIPERCARD,
  MASTERCARD,
  VISA,
}

export default CardBrand;
