import User from '@/model/User';

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
  registerDocument = 'registerDocument',
  registerDocumentCategory = 'registerDocumentCategory',
}

// eslint-disable-next-line no-shadow
export enum FormInputDocumentName {
  title = 'title',
  description = 'description',
  fileURL = 'fileURL',
  fileName = 'fileName',
  category = 'category',
  user = 'user',
}

// eslint-disable-next-line no-shadow
export enum FormInputDocumentCategoryName {
  name = 'name',
  description = 'description',
}

// eslint-disable-next-line no-shadow
export enum FormInputFilterName {
  filterSearch = 'filterSearch',
  inputSearch = 'inputSearch',
}

export interface DataRowDocument {
  title: string;
  category: string;
  bindingUser: string;
  bindingDate: string;
  actions: string;
}

export interface DataRowUser {
  name: string;
  action: string;
}

export type DocumentCategoryController = {
  handleAddDocumentCategory(): void;
  handleChangeDocumentCategory(inputName: string, index: number, event: string): void;
  handleRemoveDocumentCategory(index: number): void;
};

export type UserController = {
  listUsers: User[];
  usersSelected: User[];
  handleAddUser(userId: string): void;
  handleRemoveUser(index: number): void;
};

export type DocumentForm = {
  id: string;
  title: string;
  description: string;
  fileURL: string;
  category: string;
  bindingDate: Date;
  bindingUser: User;
};

export type DocumentCategoryForm = {
  id: string;
  name: string;
  description: string;
};
