import React from 'react';
import { ButtonGroup, InputText, SelectCustom } from '@/components';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { OptionProps } from '@/components/Select';
import PaymentType from '@/model/PaymentType';
import { updateMask as updateMaskCash } from '@/helpers/masks/cashNumber';
import { RegisterPaymentTypeFormInput } from '../../types';

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
}

interface DispatchProps {
  onChangeFormInput: OnChangeFormInput;
}

type Props = StateProps & DispatchProps;

export const RegisterPaymentTypeContent: React.FC<Props> = ({
  formData,
  formErrors,
  onChangeFormInput,
}): JSX.Element => {
  const mountOptions = (): OptionProps[] => {
    const list: OptionProps[] = [
      {
        value: `${PaymentType.CREDIT_CARD}`,
        label: 'Crédito',
      },
      {
        value: `${PaymentType.DEBIT_CARD}`,
        label: 'Débito',
      },
      {
        value: `${PaymentType.MONEY}`,
        label: 'Dinheiro',
      },
      {
        value: `${PaymentType.PIX}`,
        label: 'PIX',
      },
      {
        value: `${PaymentType.BANK_SLIP}`,
        label: 'Boleto Bnacário',
      },
    ];
    return list;
  };
  return (
    <Form
      noValidate={true}
      onSubmit={(e): void => {
        e.preventDefault();
      }}
    >
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectCustom
              name="paymentType"
              label="Forma de pagamento"
              placeholder="..."
              value={formData[RegisterPaymentTypeFormInput.paymentType]}
              onChange={e => {
                onChangeFormInput(RegisterPaymentTypeFormInput.paymentType)(e?.value as string);
              }}
              error={formErrors.paymentType && formErrors.paymentType[0]}
              options={mountOptions()}
            />
          </FormGroup>
        </Col>
      </Row>
      {(Number(formData[RegisterPaymentTypeFormInput.paymentType]) === PaymentType.PIX ||
        Number(formData[RegisterPaymentTypeFormInput.paymentType]) === PaymentType.BANK_SLIP) && (
        <Row>
          <Col className="ml-4 mr-2" md={3}>
            <ButtonGroup
              style={{ width: 'fit-content' }}
              name="feeType"
              label="Tipo"
              value={formData[RegisterPaymentTypeFormInput.feeType]}
              onChange={e => {
                onChangeFormInput(RegisterPaymentTypeFormInput.valueFee)('0');
                onChangeFormInput(RegisterPaymentTypeFormInput.feeType)(e.target.value);
              }}
              error={formErrors.feeType && formErrors.feeType[0]}
              options={[
                { value: 0, label: 'R$' },
                { value: 1, label: '%' },
              ]}
            />
          </Col>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                className={!formData[RegisterPaymentTypeFormInput.feeType] ? 'input__disabled' : ''}
                name="valueFee"
                label="Valor da taxa"
                placeholder="R$40,00 ou 50%"
                maxLength={formData[RegisterPaymentTypeFormInput.feeType] === '0' ? 10 : 5}
                value={
                  formData[RegisterPaymentTypeFormInput.feeType] === '0'
                    ? updateMaskCash(formData[RegisterPaymentTypeFormInput.valueFee])
                    : formData[RegisterPaymentTypeFormInput.valueFee]
                }
                onChange={e => {
                  const unitValueMoney = updateMaskCash(e.target.value);
                  // eslint-disable-next-line no-unused-expressions
                  formData[RegisterPaymentTypeFormInput.feeType] === '0'
                    ? onChangeFormInput(RegisterPaymentTypeFormInput.valueFee)(unitValueMoney)
                    : onChangeFormInput(RegisterPaymentTypeFormInput.valueFee)(unitValueMoney);
                }}
                error={formErrors.valueFee && formErrors.valueFee[0]}
                addon={formData[RegisterPaymentTypeFormInput.feeType] === '0' ? 'R$' : '%'}
                disabled={!formData[RegisterPaymentTypeFormInput.feeType]}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Form>
  );
};
