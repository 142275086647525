/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { HomeContainer, States } from '@/features/eventClose/screens/Home/ui';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import api from '@/services/api';
import EventClose from '@/model/EventClose';

export const HomeEventCloseScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [eventState, setEventState] = useState<EventClose>();
  const { id: eventId } = useParams<{ id: string }>();

  const handleGetEventClose = async (id: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventClose>(`/event-close/${id}`);
      setEventState(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnCloseEvent = async (): Promise<void> => {
    setState(States.loading);
    setTimeout(() => {
      setState(States.default);
      toast.error('Erro ao fechar evento');
    }, 2500);
  };

  useEffect(() => {
    handleGetEventClose(eventId);
  }, []);

  return <HomeContainer state={state} eventState={eventState} closeEvent={handleOnCloseEvent} />;
};
