import React, { useEffect, useState } from 'react';
import api from '@/services/api';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import Canceled from '@/model/Canceled';
import { States } from '@/helpers/common/states';
import { Loading } from '@/components';
import { ReportControllerProps } from '../../types';
import { CancellationsUI } from './ui';

interface CancellationsScreenProps {
  reportController: ReportControllerProps;
}

export const CancellationsScreen: React.FC<CancellationsScreenProps> = ({
  reportController,
}): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [reportCanceled, setReportCanceled] = useState<Canceled | undefined>(undefined);

  const handleReportCanceled = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Canceled>(`event/report/${reportController.eventId}/canceled`);
      if (data) {
        setReportCanceled(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  useEffect(() => {
    handleReportCanceled();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <CancellationsUI canceled={reportCanceled} />
    </>
  );
};
