import { Dialog, DropdonwFlags, Loading } from '@/components';
import React, { Fragment, ReactNode } from 'react';
import { ActionProps } from '@/components/Dialog';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { Container } from 'reactstrap';
import { ColumnImage, ColumnStatus, CustomTable } from '@/components/Table';
import Pagination from '@/components/Utils/Pagination';
import Event from '@/model/Event';
import Page from '@/model/Page';
import FilterVector from '@/assets/images/svg/FilterVector';
import dayjs from 'dayjs';
import { colors } from '@/styles/colors';
import EventStatus from '@/model/EventStatus';
import { useHistory } from 'react-router-dom';
import { FilterContent } from '../../../components/FilterContent';
import { States, ShouldShowModal, columns, dataEventType } from '../../../types';

interface StateProps {
  state: States;
  title: string | ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  page: Page<Event, Event>;
  formData: FormData;
  formErrors: FormErrors;
}

interface DispatchProps {
  onToggle: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
  }) => void;
  onPaginationChange: (newPage: number) => void;
  onChangeFormInput: OnChangeFormInput;
  onClearFilter: () => void;
  onFilter: () => void;
}

type Props = StateProps & DispatchProps;

export const EventCloseContainer: React.FC<Props> = ({
  state,
  title,
  visible,
  onToggle,
  page,
  onPaginationChange,
  onShouldShowModal,
  shouldShowModal,
  formData,
  formErrors,
  onChangeFormInput,
  onClearFilter,
  onFilter,
}): JSX.Element => {
  const history = useHistory();
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => onClearFilter(),
    theme: 'noneBorder',
  };

  const changeColorColumn = (status: EventStatus): string => {
    let color = colors.grey;
    switch (status) {
      case EventStatus.DRAFT:
        color = colors.lightBlue;
        break;
      case EventStatus.PENDING:
        color = colors.darkRed;
        break;
      case EventStatus.ACTIVE:
        color = colors.green;
        break;
      case EventStatus.REFUSED:
        color = colors.yellow;
        break;
      case EventStatus.BLOCKED:
        color = colors.red;
        break;
      case EventStatus.FINISHED:
        color = colors.lightGreen;
        break;
      case EventStatus.INACTIVE:
        color = colors.orange;
        break;
      case EventStatus.COMPLETED:
        color = colors.grey;
        break;
      default:
        color = colors.grey;
        break;
    }
    return color;
  };

  const dataTable = page.list
    ? page.list.map(event => ({
        id: event.id,
        image: (
          <ColumnStatus justify="right" statusColor={String(changeColorColumn(event.eventStatus))}>
            <ColumnImage srcImage={event.imageBase64} />
          </ColumnStatus>
        ),
        name: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>{event.name}</div>
            {
              <DropdonwFlags
                className="mt-2"
                dataColumn={dataEventType.filter(value => value.id === event.eventType)}
              />
            }
          </div>
        ),
        city: `${event.address.city}/${event.address.state}`,
        startDate:
          event.startDate === null
            ? '-----'
            : // eslint-disable-next-line no-useless-concat
              `${dayjs(event.startDate).format('DD/MM/YYYY')} às ${String(event.startDate)
                .split('T')[1]
                .slice(0, 5)}`,
        endDate:
          event.endDate === null
            ? '-----'
            : `${dayjs(event.endDate).format('DD/MM/YYYY')} às ${String(event.endDate)
                .split('T')[1]
                .slice(0, 5)}`,
      }))
    : [];

  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        footerBorder={'top'}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={shouldShowModal !== ShouldShowModal.filter}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: {
              title: 'Aplicar',
              onClick: onFilter,
            },
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formData}
                formErrors={formErrors}
                onChangeFormInput={onChangeFormInput}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div
          className="d-flex justify-content-between event-page-title-container"
          style={{ paddingBottom: '30px' }}
        >
          <div className="pageTitle event-page-title" style={{ display: 'grid' }}>
            <h5 className="pageTitle" style={{ marginBottom: '1px' }}>
              Eventos
            </h5>
          </div>
          <div className="button-filter-container event-button-filter">
            <div className="d-flex event-filter-container">
              <div className="filter-container m-filter">
                <div
                  className="filter-content"
                  onClick={(): void =>
                    onShouldShowModal({
                      value: ShouldShowModal.filter,
                      newTitleModal: '',
                    })
                  }
                >
                  <FilterVector />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={dataTable}
          numberRowsPerPage={10}
          getRowId={e => history.push(`/financial/event-close/${e.id}`)}
          theme="primary"
          progressPending={false}
        />
        <Pagination
          currentPage={page.page}
          totalCount={page.total}
          pageSize={page.pageSize}
          onPageChange={onPaginationChange}
          total={page.total}
        />
      </Container>
    </Fragment>
  );
};
