import { Button, CollapseCustom, Dialog, Loading } from '@/components';
import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { colors } from '@/styles/colors';
import { ReactComponent as Pen } from '@/assets/images/svg/pen.svg';
import { ReactComponent as ProductCombo } from '@/assets/images/svg/transfer.svg';
import { ReactComponent as Trash } from '@/assets/images/svg/lixeira.svg';
import { AcquiringFee } from '@/model/AcquiringFee';
import PaymentType from '@/model/PaymentType';
import { ActionProps } from '@/components/Dialog';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import ChargeSetup from '@/model/ChargeSetup';
import AcquiringFeeType from '@/model/AcquiringFeeType';
import CardBrand from '@/model/CardBrand';
import { AcquiringBrandFee } from '@/model/AcquiringBrandFee';
import { ShouldShowModal, States } from '../../types';
import { RegisterAcquiringFeeContent } from '../../components/RegisterAcquiringFeeContent';
import { RegisterPaymentTypeContent } from '../../components/RegisterPaymentTypeContent';
import { RegisterBrandContent } from '../../components/RegisterBrandContent';

interface StateProps {
  state: States;
  title: string;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  acquiringFee?: AcquiringFee;
  formDataRegisterAcquiringFee: FormData;
  formErrorsRegisterAcquiringFee: FormErrors;
  chargeSetups: ChargeSetup[];
  acquiringFees: AcquiringFee[];
  formDataRegisterPaymentType: FormData;
  formErrorsRegisterPaymentType: FormErrors;
  paymentType: PaymentType;
  formDataRegisterBrand: FormData;
  formErrorsRegisterBrand: FormErrors;
  brands: AcquiringBrandFee[];
}

interface DispatchProps {
  onToggle: () => void;
  onSaveAcquiringFee: () => void;
  onChangeFormInputRegisterAcquiringFee: OnChangeFormInput;
  onShouldShowModal: ({
    value,
    newTitleModal,
    id,
    paymentType,
    brandId,
    type,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    id?: string;
    paymentType?: string;
    brandId?: string;
    type?: PaymentType;
  }) => void;
  onChangeFormInputRegisterPaymentType: OnChangeFormInput;
  onSavePaymentType: () => void;
  onShowDelete: (id: string) => void;
  onShowDeletePaymentType: (acquiringFeeId: string, id: string) => void;
  onChangeFormInputRegisterBrand: OnChangeFormInput;
  onSaveBrand: () => void;
  changeBrandFeeInstallments: (index: number, value: string) => void;
  changeBrandFeeValue: (index: number, value: string) => void;
  addBrandFee: () => void;
  removeBrandFee: (index: number) => void;
  onShowDeleteBrand: (acquiringfeeId: string, paymentTypeId: string, brandId: string) => void;
}

type Props = StateProps & DispatchProps;

export const AcquiringFeeContainer: React.FC<Props> = ({
  state,
  title,
  visible,
  onToggle,
  shouldShowModal,
  acquiringFee,
  onSaveAcquiringFee,
  formDataRegisterAcquiringFee,
  formErrorsRegisterAcquiringFee,
  onChangeFormInputRegisterAcquiringFee,
  chargeSetups,
  acquiringFees,
  onShouldShowModal,
  formDataRegisterPaymentType,
  formErrorsRegisterPaymentType,
  onChangeFormInputRegisterPaymentType,
  onSavePaymentType,
  onShowDelete,
  onShowDeletePaymentType,
  paymentType,
  formDataRegisterBrand,
  formErrorsRegisterBrand,
  onChangeFormInputRegisterBrand,
  onSaveBrand,
  brands,
  changeBrandFeeInstallments,
  changeBrandFeeValue,
  addBrandFee,
  removeBrandFee,
  onShowDeleteBrand,
}): JSX.Element => {
  const renderActionDialogToCancel: ActionProps = {
    title: 'Cancelar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };

  const toPaymentType = (status: PaymentType): string => {
    switch (status) {
      case PaymentType.CREDIT_CARD:
        return 'Crédito:';
      case PaymentType.DEBIT_CARD:
        return 'Débito:';
      case PaymentType.MONEY:
        return 'Dinheiro:';
      case PaymentType.PIX:
        return 'PIX:';
      case PaymentType.BANK_SLIP:
        return 'Boleto Bancário:';
      case PaymentType.TWO_CREDIT_CARDS:
        return 'Dois cartões:';
      default:
        return 'Sem dados:';
    }
  };

  const toCardBrand = (brand: CardBrand): string => {
    switch (brand) {
      case CardBrand.AMEX:
        return 'AMEX:';
      case CardBrand.BANRICOMPRAS:
        return 'BANRICOMPRAS:';
      case CardBrand.CABAL:
        return 'CABAL:';
      case CardBrand.ELO:
        return 'ELO:';
      case CardBrand.HIPERCARD:
        return 'HIPERCARD:';
      case CardBrand.MASTERCARD:
        return 'MASTERCARD:';
      case CardBrand.VISA:
        return 'VISA:';
      default:
        return 'Sem dados:';
    }
  };

  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={'center'}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.registerAcquiringFee]: renderActionDialogToCancel,
            [ShouldShowModal.registerPaymentType]: renderActionDialogToCancel,
            [ShouldShowModal.registerBrand]: renderActionDialogToCancel,
          }[shouldShowModal],
          {
            [ShouldShowModal.registerAcquiringFee]: {
              title: acquiringFee?.id ? 'Salvar' : 'Cadastrar novo adquirente',
              onClick: () => onSaveAcquiringFee(),
              disabled:
                Object.keys(formErrorsRegisterAcquiringFee).length === 0 &&
                formErrorsRegisterAcquiringFee.constructor === Object,
            },
            [ShouldShowModal.registerPaymentType]: {
              title: acquiringFee?.id ? 'Salvar' : 'Cadastrar',
              onClick: () => onSavePaymentType(),
              disabled:
                Object.keys(formErrorsRegisterPaymentType).length === 0 &&
                formErrorsRegisterPaymentType.constructor === Object,
            },
            [ShouldShowModal.registerBrand]: {
              title: acquiringFee?.id ? 'Salvar' : 'Cadastrar',
              onClick: () => onSaveBrand(),
              disabled:
                Object.keys(formErrorsRegisterBrand).length === 0 &&
                formErrorsRegisterBrand.constructor === Object,
            },
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.registerAcquiringFee]: (
              <RegisterAcquiringFeeContent
                formData={formDataRegisterAcquiringFee}
                formErrors={formErrorsRegisterAcquiringFee}
                onChangeFormInput={onChangeFormInputRegisterAcquiringFee}
                chargeSetups={chargeSetups}
              />
            ),
            [ShouldShowModal.registerPaymentType]: (
              <RegisterPaymentTypeContent
                formData={formDataRegisterPaymentType}
                formErrors={formErrorsRegisterPaymentType}
                onChangeFormInput={onChangeFormInputRegisterPaymentType}
              />
            ),
            [ShouldShowModal.registerBrand]: (
              <RegisterBrandContent
                paymentType={paymentType}
                formData={formDataRegisterBrand}
                formErrors={formErrorsRegisterBrand}
                onChangeFormInput={onChangeFormInputRegisterBrand}
                brands={brands}
                changeBrandFeeInstallments={changeBrandFeeInstallments}
                changeBrandFeeValue={changeBrandFeeValue}
                addBrandFee={addBrandFee}
                removeBrandFee={removeBrandFee}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between mb-5">
          <div className="pageTitle d-flex">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <ArrowLeft color={colors.black} className="arrow-left" />
            </Link>

            <span className="ml-3 mb-0 mt-2">Taxas de Adquirentes</span>
          </div>
          <Button
            title="+ Cadastrar novo adquirente"
            onClick={(): void =>
              onShouldShowModal({
                value: ShouldShowModal.registerAcquiringFee,
                newTitleModal: 'Cadastrar novo Adquirente',
              })
            }
          />
        </div>
        <CollapseCustom
          className="tree-card"
          title={
            <>
              <ProductCombo width={20} className="mr-3" />
              Adquirentes
            </>
          }
        >
          {acquiringFees.length > 0 ? (
            <>
              <Row>
                <Col>
                  <p className="text-title-gruop">Nome do gateway</p>
                </Col>
              </Row>
              <Row className="tree-container">
                {acquiringFees.map((item, index) => (
                  <Col className="tree-item-container" key={`${index}-${item.id}`}>
                    <div className="d-flex">
                      <div className="d-flex justify-content-between text-gruop tree-main-text">
                        <div className="mv-auto">{`${item.chargeSetup.name} - ${
                          item.type === AcquiringFeeType.SITE ? 'On-Line' : 'Física'
                        }`}</div>
                        <div className="d-flex icon-content mv-auto">
                          <Pen
                            className="svg-icon action-icon sm-icon"
                            onClick={(): void =>
                              onShouldShowModal({
                                value: ShouldShowModal.registerAcquiringFee,
                                newTitleModal: 'Cadastrar novo Adquirente',
                                id: item.id,
                              })
                            }
                          />
                          <Trash
                            className="mr-0 svg-icon sm-icon action-icon svg-icon-trash"
                            onClick={() => {
                              onShowDelete(item.id);
                            }}
                          />
                        </div>
                      </div>
                      <div className="text-success-link-widht">
                        <a
                          className="text-success-link"
                          onClick={(): void =>
                            onShouldShowModal({
                              value: ShouldShowModal.registerPaymentType,
                              newTitleModal: `Cadastrar novo tipo de pagamento`,
                              id: item.id,
                            })
                          }
                        >
                          + Novo tipo de pagamento
                        </a>
                      </div>
                    </div>
                    <div className="tree">
                      <ul>
                        {item.types &&
                          item.types.length > 0 &&
                          item.types.map((type, i) => (
                            <li key={`${index}-${item.chargeSetup.id}-${i}-${type.id}`}>
                              <div>{toPaymentType(type.paymentType)}</div>
                              <div className="flex-shrink-0 ml-2">
                                <Pen
                                  className="mr-3 svg-icon action-icon sm-icon"
                                  onClick={(): void =>
                                    onShouldShowModal({
                                      value: ShouldShowModal.registerPaymentType,
                                      newTitleModal: `Cadastrar novo tipo de pagamento`,
                                      id: item.id,
                                      paymentType: type.id,
                                    })
                                  }
                                />
                                <Trash
                                  className="mr-5 svg-icon action-icon sm-icon svg-icon-trash"
                                  onClick={() => onShowDeletePaymentType(item.id, type.id)}
                                />
                                {(type.paymentType === PaymentType.CREDIT_CARD ||
                                  type.paymentType === PaymentType.DEBIT_CARD) && (
                                  <div
                                    className="text-success-link-widht"
                                    style={{ display: 'inline' }}
                                  >
                                    <a
                                      className="text-success-link"
                                      onClick={(): void =>
                                        onShouldShowModal({
                                          value: ShouldShowModal.registerBrand,
                                          newTitleModal: `Cadastrar nova bandeira`,
                                          id: item.id,
                                          paymentType: type.id,
                                          type: type.paymentType,
                                        })
                                      }
                                    >
                                      + Nova bandeira
                                    </a>
                                  </div>
                                )}
                                {type.brands && type.brands.length > 0 && (
                                  <div className="tree">
                                    <ul>
                                      {type.brands.map((brand, ix) => (
                                        <li
                                          key={`${index}-${item.chargeSetup.id}-${i}-${type.id}-${ix}-${brand.id}`}
                                        >
                                          <div>{toCardBrand(brand.cardBrand)}</div>
                                          <div className="flex-shrink-0 ml-2">
                                            <Pen
                                              className="mr-3 svg-icon action-icon sm-icon"
                                              onClick={(): void =>
                                                onShouldShowModal({
                                                  value: ShouldShowModal.registerBrand,
                                                  newTitleModal: `Cadastrar nova bandeira`,
                                                  id: item.id,
                                                  paymentType: type.id,
                                                  brandId: brand.id,
                                                  type: type.paymentType,
                                                })
                                              }
                                            />
                                            <Trash
                                              className="mr-5 svg-icon action-icon sm-icon svg-icon-trash"
                                              onClick={() => {
                                                onShowDeleteBrand(item.id, type.id, brand.id);
                                              }}
                                            />
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <div className="d-flex justify-content-center p-5 pt-lg-3">
              Nenhum gateway cadastrado
            </div>
          )}
        </CollapseCustom>
      </Container>
    </Fragment>
  );
};
