import React from 'react';
import { Route } from '@/navigation/Route';
import { FinancialScreen } from './screens';

export const FINANCIAL_ROUTES = {
  itself: '/financial/producer',
};

export const FinancialNavigation = (): JSX.Element => (
  <Route exact path={FINANCIAL_ROUTES.itself} component={FinancialScreen} isPrivateRoute />
);
