import React, { useEffect, useState } from 'react';
import { States } from '@/helpers/common/states';
import api, { AxiosError } from '@/services/api';
import OrderSite from '@/model/OrderSite';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { CancelRequestContainer } from './ui';
import { ShouldShowModal } from '../../types';
import { CancelContent } from '../../components/CancelContent';
import { ReactivateContent } from '../../components/ReativateContent';

export const CancelRequestScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [orders, setOrders] = useState<OrderSite[]>([]);
  const [modal, setModal] = useState<ShouldShowModal>(ShouldShowModal.detail);
  const [order, setOrder] = useState<OrderSite>();
  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const getOrders = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<OrderSite[]>('/support/cancellation');

      if (data) {
        setOrders(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShouldShowModal = (
    value: ShouldShowModal,
    newTitleModal: string | React.ReactNode,
    orderSelected: OrderSite,
  ): void => {
    setOrder(orderSelected);
    setModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnConfirmCancelOrder = async (orderSelected: OrderSite): Promise<void> => {
    try {
      setState(States.loading);
      await api.patch(`/support/reverse/${orderSelected.id}`);
      toast.success('Pedido cancelado com sucesso!');
      handleOnClose();
      getOrders();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShowCancelOrder = (orderSelected: OrderSite): void => {
    confirmDelete.show({
      title: '',
      children: <CancelContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero cancelar',
          onClick: (): Promise<void> => handleOnConfirmCancelOrder(orderSelected),
        },
      ],
    });
  };

  const handleOnConfirmReactivateOrder = async (orderSelected: OrderSite): Promise<void> => {
    try {
      setState(States.loading);
      await api.patch(`/support/reactivate/${orderSelected.id}`);
      toast.success('Pedido cancelado com sucesso!');
      handleOnClose();
      getOrders();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShowReactivate = (orderSelected: OrderSite): void => {
    confirmDelete.show({
      title: '',
      children: <ReactivateContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero reativar',
          onClick: (): Promise<void> => handleOnConfirmReactivateOrder(orderSelected),
        },
      ],
    });
  };

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <CancelRequestContainer
      state={state}
      orders={orders}
      onShouldShowModal={handleOnShouldShowModal}
      title={title}
      visible={visible}
      onToggle={onToggle}
      modal={modal}
      order={order}
      onShowCancelOrder={handleOnShowCancelOrder}
      onShowReactivate={handleOnShowReactivate}
    />
  );
};
