import React from 'react';
import { Route } from '@/navigation/Route';
import { CardFeeScreen } from './screens';

export const CARD_FEE_ROUTES = {
  itself: '/dashboard/card-fee',
};

export const CardFeeNavigation = (): JSX.Element => (
  <Route exact path={CARD_FEE_ROUTES.itself} component={CardFeeScreen} isPrivateRoute />
);
