import { CustomTable } from '@/components/Table';
import React from 'react';
import dayjs from 'dayjs';
import { updateMask } from '@/helpers/masks/cpf';
import { toCurrency } from '@/helpers/masks/toCurrency';
import PaymentStatus from '@/model/PaymentStatus';
import { Container } from 'reactstrap';
import { States } from '@/helpers/common/states';
import OrderSite from '@/model/OrderSite';
import OrderPayment from '@/model/OrderPayment';
import { ActionProps } from '@/components/Dialog';
import { Dialog, DropdonwFlags } from '@/components';
import { ReverseDetailContent } from '@/features/reverse/components/ReverseDetailContent';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import { ReactComponent as Transfer } from '@/assets/images/svg/transfer.svg';
import { ReactComponent as X } from '@/assets/images/svg/x.svg';
import { DataRow, columns } from './table';
import { DataColumn, ShouldShowModalReverse } from '../../types';

interface ClientInfoProps {
  state: States;
  currentPage: OrderSite[];
  order?: OrderSite;
  visible: boolean;
  modal: ShouldShowModalReverse;
  title: string | React.ReactNode;
  onShouldShowModal: (
    value: ShouldShowModalReverse,
    newTitleModal: string | React.ReactNode,
    orderSelected?: OrderSite,
  ) => void;
  onShowReverseOrder: (orderSelected: OrderSite) => void;
  onShowCancelOrder: (orderSelected: OrderSite) => void;
  onToggle: () => void;
}

export const ClientInfo: React.FC<ClientInfoProps> = ({
  state,
  currentPage,
  visible,
  modal,
  title,
  order,
  onShouldShowModal,
  onShowReverseOrder,
  onShowCancelOrder,
  onToggle,
}): JSX.Element => {
  const mountDataColumnPaymentType = (payments: OrderPayment[]): DataColumn[] => {
    const dataColumnProfiles: DataColumn[] = [];
    if (payments && payments.length > 0) {
      payments.forEach((data, index) => {
        dataColumnProfiles.push({
          id: String(index),
          name: data.transaction,
        });
      });
    }
    return dataColumnProfiles;
  };

  const mountTransaction = (
    payments: OrderPayment[],
    transferOrder?: string,
  ): string | JSX.Element => {
    if (transferOrder) {
      return transferOrder;
    }
    if (payments) {
      if (payments.length === 1) {
        return payments[0].transaction;
      }
      return (
        <DropdonwFlags pointerClass={true} dataColumn={mountDataColumnPaymentType(payments)} />
      );
    }
    return '';
  };

  const getStatus = (paymentStatus: PaymentStatus, transferOrder?: string): string => {
    let value = '';
    if (transferOrder) {
      value = 'Transferido';
    } else if (paymentStatus === PaymentStatus.APPROVED) {
      value = 'Aprovado';
    } else if (paymentStatus === PaymentStatus.DISAPPROVED) {
      value = 'Reprovado';
    } else if (paymentStatus === PaymentStatus.AWAITING_PAYMENT) {
      value = 'Aguardando Pagamento';
    } else if (paymentStatus === PaymentStatus.CANCELED) {
      value = 'Cancelado';
    } else if (paymentStatus === PaymentStatus.CANCELLATION_ANALYSIS) {
      value = 'Aguardando analise cancelamento';
    }

    return value;
  };

  const mountDataTable = (): DataRow[] => {
    const list: DataRow[] = [];
    if (currentPage && currentPage.length > 0) {
      currentPage.forEach(data => {
        const dataRow: DataRow = {
          client: `${data.client.name} - ${updateMask(data.client.cpf)}`,
          orderId: data.id,
          orderDate: dayjs(data.orderDate).format('DD/MM/YYYY [às] HH:mm'),
          orderValue: toCurrency(data.totalValue),
          transaction: mountTransaction(data.payments, data.transferOrder),
          paymentStatus: getStatus(data.paymentStatus, data.transferOrder),
          actions: (
            <React.Fragment>
              {!data.cancelDate && (
                <Transfer
                  title="Extornar pedido"
                  className={'mr-4 svg-icon-error action-icon'}
                  onClick={() => onShowReverseOrder(data)}
                />
              )}
              <Detail
                className={'mr-4 svg-icon action-icon'}
                onClick={() => onShouldShowModal(ShouldShowModalReverse.detail, 'Pedido', data)}
              />
              {!data.transferOrder && (
                <X
                  title="Cancelar pedido"
                  className={'mr-4 svg-icon-error action-icon'}
                  onClick={() => onShowCancelOrder(data)}
                />
              )}
            </React.Fragment>
          ),
        };
        list.push(dataRow);
      });
    }
    return list;
  };

  const renderActionDialogToCancel: ActionProps = {
    title: 'Fechar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };

  return (
    <React.Fragment>
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={'center'}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModalReverse.detail]: renderActionDialogToCancel,
          }[modal],
          {
            [ShouldShowModalReverse.detail]: {
              title: 'OK',
            },
          }[modal],
        ]}
      >
        {
          {
            [ShouldShowModalReverse.detail]: <ReverseDetailContent order={order} />,
          }[modal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <h5 className="pageTitle">Gerenciar pedido</h5>
        </div>
        <CustomTable
          columns={columns}
          data={mountDataTable()}
          numberRowsPerPage={1}
          progressPending={state === States.loading}
        />
      </Container>
    </React.Fragment>
  );
};
