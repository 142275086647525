import React from 'react';
import { Route } from '@/navigation/Route';
import { LogScreen } from '@/features/log/screens/List';

export const LOG_ROUTES = {
  itself: '/dashboard/log',
};

export const LogNavigation = (): JSX.Element => (
  <Route exact path={LOG_ROUTES.itself} component={LogScreen} isPrivateRoute />
);
