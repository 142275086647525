// eslint-disable-next-line no-shadow
enum SupportStatus {
  NEW,
  WAITING,
  IN_PROGRESS,
  WAITING_FOR_CLIENT,
  RESOLVED,
  CLOSED,
  REOPENED,
  CANCELED,
}
export default SupportStatus;
