import React from 'react';
import { Route } from '@/navigation/Route';
import { FinishedEventsScreen } from './screens/list';

export const FINISHED_EVENTS_ROUTES = {
  itself: '/dashboard/event/finished',
};

export const FinishedEventsNavigation = (): JSX.Element => (
  <Route
    exact
    path={FINISHED_EVENTS_ROUTES.itself}
    component={FinishedEventsScreen}
    isPrivateRoute
  />
);
